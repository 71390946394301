import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import eye from "@assets/icons/eye.svg";
import eyeClosed from "@assets/icons/eye-closed.svg";
import PasswordStrength from "../../Auth/components/PasswordStrength";
import IconWithLoading from "@components/common/IconWithLoading";
import {PageRoutes} from "../../../constants";
import Overlay from "@components/common/Overlay";
import {useNavigate} from "react-router-dom";


const PasswordChange = observer(() => {
  const {accountStore} = useStores();
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const passwordsMatch = newPassword === confirmPassword;
  const lengthPassed = newPassword.length >= 8;
  const upperCasePassed = /[A-Z]/.test(newPassword);
  const lowerCasePassed = /[a-z]/.test(newPassword);
  const numberPassed = /[0-9]/.test(newPassword);
  const incorrectPassword = !lengthPassed || !upperCasePassed || !lowerCasePassed || !numberPassed;

  const handleChangePassword = () => {
    if (incorrectPassword || !passwordsMatch) {
      return;
    }

    setIsLoading(true);

    accountStore
      .updatePassword(password, newPassword)
      .then((response) => {
        if (response.success) {
          setPassword('');
          setNewPassword('');
          setConfirmPassword('');
          handleResponse('Password updated successfully');
        } else {
          handleResponse(response.data);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        handleResponse(response.response.data);
      });
  };

  return (
    <div>
      <div className="tx-28 tx-light tx-center mb-1">
        Change Password
      </div>
      <div className="tx-13 tx-light tx-center mb-3">
        You can change your password<br />
        if you forgot it or want to update it!
      </div>

      <div className="form-group mb-4">
        <label className="tx-13 tx-light mb-1 me-auto">Current password</label>
        <div className="form-group">
          <input
            className={`form-control ${password && password.length < 8 ? 'is-invalid' : ''}`}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={'Enter current password'}
            id="password"
            disabled={isLoading}
          />
          <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
            <img src={showPassword ? eye : eyeClosed} alt="Show password" />
          </div>
        </div>
      </div>

      <div className="form-group mb-4">
        <div className="d-flex justify-content-between align-items-start">
          <label className="tx-13 tx-light mb-1 me-auto">New password</label>
          <PasswordStrength password={newPassword} />
        </div>
        <div className="form-group">
          <input
            className={`form-control ${newPassword && incorrectPassword ? 'is-invalid' : ''}`}
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={'Enter new password'}
            id="new-password"
            disabled={isLoading}
          />
          <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
            <img src={showPassword ? eye : eyeClosed} alt="Show password" />
          </div>
        </div>
        <div className={`${newPassword && incorrectPassword ? 'tx-danger' : 'tx-muted'} tx-light tx-11 mt-1`}>Minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number</div>
      </div>

      <div className="form-group mb-4">
        <label className="tx-13 tx-light mb-1">Confirm new password</label>
        <div className="form-group">
          <input
            className={`form-control ${!newPassword || !confirmPassword || passwordsMatch ? '' : 'is-invalid'}`}
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={'Repeat new password'}
            id="new-password"
            disabled={isLoading}
          />
          <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
            <img src={showPassword ? eye : eyeClosed} alt="Show password" />
          </div>
        </div>
        <div className={`tx-light tx-11 mt-1 ${!password || !newPassword || !confirmPassword || passwordsMatch ? 'text-muted' : 'tx-danger'}`}>Both passwords must be matched</div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2">
        <button
          className="btn btn-outline-primary tx-primary wd-100p me-2"
          onClick={handleChangePassword}
          disabled={isLoading}
        >
          <IconWithLoading isLoading={isLoading} className="me-2" />
          Update Password
        </button>
        <button
          className="btn btn-outline-danger tx-danger wd-100p ms-2"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
});

export default PasswordChange;

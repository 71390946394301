import {useState} from "react";
import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import FormattedNumber from "@components/common/FormattedNumber";
import {useStores} from "@hooks/useStores";
import IconWithLoading from "@components/common/IconWithLoading";
import useResponseHandler from "@hooks/useResponseHandler";

const Withdrawal = observer(() => {
  const navigate = useNavigate();
  const {accountStore} = useStores();
  const {refInfo} = accountStore;
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isAddressValid = (new RegExp('^T[a-zA-Z0-9]{33}$')).test(address);
  const handleResponse = useResponseHandler();

  const handleWithdraw = () => {
    if (!isAddressValid) {
      handleResponse('Invalid address', true);
      return;
    }

    setIsLoading(true);

    accountStore.withdrawReferralReward(address)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          handleResponse('Withdrawal request sent');
          setAddress('');
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        setIsLoading(false);
        handleResponse(response.response.data, true);
      })
  };

  return (
    <Overlay onClose={() => navigate(PageRoutes.COIN_LIST)} title="Referral program" titleClassName="tx-olney tx-center tx-18" noLogo>
      <div className="card bg-primary d-flex flex-row justify-content-between align-items-center my-5">
        <div className="tx-white opacity-50 tx-14">You will get</div>
        <FormattedNumber
          value={(refInfo?.totalUsdEarned || 0) - (refInfo?.totalUsdWithdrawn || 0)}
          suffix="$"
          decimals={2}
          className="tx-18 tx-white"
        />
      </div>

      <div className="mb-4">
        <div className="tx-13">Wallet address <span className="tx-primary">USDT TRC-20</span></div>
        <input
          type="text"
          className={`form-control ${address ? isAddressValid ? 'is-valid' : 'is-invalid' : ''}`}
          placeholder="Enter your USDT TRC-20 wallet address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          disabled={isLoading}
        />
      </div>

      <button
        className="btn btn-outline-primary tx-primary wd-100p tx-olney me-2"
        onClick={handleWithdraw}
        disabled={isLoading}
      >
        <IconWithLoading isLoading={isLoading} className="me-2" />
        Withdraw
      </button>
    </Overlay>
  );
});

export default Withdrawal;

import favorites from 'assets/images/favorites.png';
import {useStores} from "@hooks/useStores";
import {ConnectionStep} from "@stores/account-store";

const FavoritesLogin = () => {
  const {accountStore} = useStores();

  return (
    <div className="d-flex justify-content-center align-items-center flex-column ht-100p wd-100p tx-center mb-2">
      <img src={favorites} alt="favorites" className="wd-100 mx-auto" />
      <div className="tx-28 my-3">Favorite empty!</div>
      <div className="tx-muted mb-3 wd-md-400 px-2">To track changes on your favorite tokens, log in or sign up via email</div>

      <div className="d-flex justify-content-center wd-100p wd-md-400">
        <button
          className="btn btn-primary wd-100p"
          onClick={() => accountStore.setConnectionStep(ConnectionStep.SIGNUP)}
        >
          Sign Up
        </button>
        <button
          className="btn btn-primary wd-100p bg-semi-primary-3 border-0 tx-primary ms-3"
          onClick={() => accountStore.setConnectionStep(ConnectionStep.LOGIN)}
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default FavoritesLogin;

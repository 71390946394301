import IdentIcon from "@components/common/IndetIcon";
import {useState} from "react";
import linkPlaceholder from "@assets/icons/link-placeholder.svg";

interface TokenLogoProps {
  address: string;
  logo?: string | null;
  name: string;
  size?: number;
  className?: string;
}

export default function TokenLogo({logo, address, name, size = 50, className = ''}: TokenLogoProps) {
  const isLogoExist = logo && (logo.includes('.png') || logo.includes('.jpg') || logo.includes(address));
  const [isError, setIsError] = useState(false);

  return (
    <div className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} rounded-50 overflow-hidden token-logo d-flex ${className}`}>
      {isLogoExist && !isError
        ? <img
          src={logo}
          alt={name}
          className="rounded-50"
          onError={(currentTarget) => {
            // console.log('onError', currentTarget);
            // @ts-ignore
            currentTarget.onerror = null;
            setIsError(true);
          }}
        />
        : <IdentIcon string={address} size={size} />
      }
    </div>
  );
}

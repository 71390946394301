import React, {useEffect, useMemo, useState} from "react";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

interface ProgressSliderProps {
  value: number | number[];
  hideSteps?: boolean;
  multicolor?: boolean;
  hideValue?: boolean;
  gradeClassName?: string;
  className?: string;
}

const ProgressSlider = ({
  value,
  hideSteps = false,
  multicolor = false,
  hideValue = false,
  gradeClassName = 'tx-28 mb-3',
  className = 'd-flex flex-column align-items-center justify-content-center mt-3 ht-100p wd-100p px-5',
}: ProgressSliderProps) => {
  const [grade, setGrade] = useState<number>(0);
  const isMultiple = Array.isArray(value);
  const isValueShown = !hideValue && !isMultiple;
  const isStepsShown = !hideSteps && !isMultiple;

  useEffect(() => {
    if (!isMultiple) {
      setGrade(value > 100 ? 100 : value);
    }
  }, [isMultiple, value]);

  const gradeColor = useMemo(() => {
    if (grade < 25) {
      return 'danger';
    } else if (grade < 50) {
      return 'warning';
    }
    return 'success';
  }, [grade]);

  return (
    <div className={className}>
      {isValueShown && (
        <div className={gradeClassName}>
          Grade:
          <span className={`ms-2 tx-${gradeColor}`}>{grade}</span>
        </div>
      )}
      <div className={`progress-line ${multicolor ? 'multi-color' : ''} ${isMultiple ? 'with-bars' : ''}`}>
        {!isMultiple && (
          <div className="progress-line-value" style={{left: `calc(${grade ? grade + '% - 10px' : 0})`}}></div>
        )}
        {isMultiple && value.map((v, i) => (
          <div
            className={`progress-line--bar ${value.length === 3 && i === 1 ? 'middle-bar' : ''} ${v === 100 ? 'full-bar' : ''} ${v === 0 ? 'empty-bar' : ''}`}
            key={`progress-line-bar-${generateUniqueID()}-${v}-${i}`}
            style={{width: `${v}%`}}
          />
        ))}
      </div>
      {isStepsShown && (
        <div className="d-flex flex-row justify-content-between wd-100p tx-14 mt-2">
          <span>0</span>
          <span>25</span>
          <span>50</span>
          <span>75</span>
          <span>100</span>
        </div>
      )}
    </div>
  )
};

export default ProgressSlider;

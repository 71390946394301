import React, {useState} from "react";
import {useStores} from "@hooks/useStores";
import coin from "@assets/images/coin.svg";
import {Link} from "react-router-dom";
import Coinmarketcap from "@assets/images/grayscale/coinmarketcap.svg";
import Etherscan from "@assets/images/grayscale/etherscan.svg";
import Twitter from "@assets/images/grayscale/twitter.svg";
import Discord from "@assets/images/grayscale/discord.svg";
import Telegram from "@assets/images/grayscale/telegram.svg";
import {privacyPolicyUrl, termsOfServiceUrl} from "../constants";

const Footer = () => {
  const {globalStore, overlayStore} = useStores();
  const [email, setEmail] = useState<string>('');

  const handleSubmit= () => {
    if (email) {
      console.log(email);
    }
  };

  const ushiLinks = [
    {
      icon: Coinmarketcap,
      link: 'https://coinmarketcap.com/currencies/ushi/',
      title: 'Coinmarketcap',
    },
    {
      icon: Etherscan,
      link: 'https://etherscan.io/token/0x6Dca182ac5E3f99985bC4Ee0f726d6472aB1ec55',
      title: 'Etherscan',
    },
    {
      icon: Discord,
      link: 'https://discord.gg/2cxdNf6fGk',
      title: 'Discord',
    },
    {
      icon: Telegram,
      link: 'https://t.me/ushi_official_portal',
      title: 'Telegram',
    },
    {
      icon: Twitter,
      link: 'https://twitter.com/USHI_ETH',
      title: 'Twitter',
    },
  ];

  return (
    <div id="footer" className="no-gutter">
      <div className="row">
        <div className="col-md-2 mb-4 mb-md-0">
          <div className="d-flex align-items-center tx-22">
            <img src={coin} alt="$USHI" width={45} height={45} />
            <div className="tx-qore ms-3">Ushi</div>
          </div>
        </div>

        <div className="col-md-2 mb-4 mb-md-0">
          <div className="mb-2 mb-md-3">Ushi</div>
          <div>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Features</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">How it works</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Tokenomic</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Team</Link>
          </div>
        </div>

        <div className="col-md-2 mb-4 mb-md-0">
          <div className="mb-2 mb-md-3">Product</div>
          <div>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Launch app</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Buy USHI</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Partners</Link>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Community</Link>
          </div>
        </div>

        <div className="col-md-2 mb-4 mb-md-0">
          <div className="mb-2 mb-md-3">Contact us</div>
          <div>
            <Link to="/" className="tx-muted d-block text-decoration-none mb-2">Help & Support</Link>
            <a href="mailto:ushi@support.com" className="tx-muted d-block text-decoration-none mb-2">ushi@support.com</a>
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-2 mb-md-3">Join our email list to stay up to date on features and releases.</div>
          <div className="d-flex">
            <input
              type="email"
              className="form-control border-muted tx-14 tx-muted me-3 px-3"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <button
              className="btn btn-primary ht-100p"
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <div className="border-top border-muted pt-5 mt-5">
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="d-flex align-items-center justify-content-between">
            {ushiLinks.map(({link, icon, title}) => (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="me-3"
                key={title}
              >
                <img src={icon} alt={title} width={24} height={24} />
              </a>
            ))}
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between tx-14 tx-muted">
          <a href={privacyPolicyUrl} className="tx-muted text-decoration-none mb-3 mb-md-0" target="_blank" rel="noreferrer">Privacy Policy</a>
          <a href={termsOfServiceUrl} className="tx-muted text-decoration-none mb-3 mb-md-0" target="_blank" rel="noreferrer">Terms Of Service</a>
          <div>©2023 USHI. All rights reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";

export interface PreloaderProps {
  className?: string;
  iconClass?: string;
  textClass?: string;
  text?: string;
  inline?: boolean;
}

export default function Preloader({className, iconClass, textClass, text, inline}: PreloaderProps) {
  const defaultClass = inline === true ? 'tx-center tx-md-left tx-22 ' : 'preloader tx-center ';
  const defaultIconClass = inline === true ? 'me-2 ' : 'mb-2 ';

  return (
    <div className={defaultClass + className}>
      <span className={defaultIconClass + iconClass}><FontAwesomeIcon icon={faCircleNotch} spin/></span>
      <span className={textClass}>
        {text || 'Loading...'}
      </span>
    </div>
  );
}

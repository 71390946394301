import {TokenAudit, TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import InfoTooltip from "@components/common/InfoTooltip";
import {isNumber} from "@helpers/bignumber";
import {formatNumber} from "@helpers/numbers";
import React from "react";
import {format, formatDistance} from "date-fns";
import BigNumber from "bignumber.js";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import LabelValueCard from "@components/common/LabelValueCard";
import ProgressSlider from "@components/common/ProgressSlider";
import LiquidityTooltip from "@components/common/LiquidityTooltip";
import CircleChart from "@components/common/Charts/CircleChart";
import {faLock} from "@fortawesome/pro-solid-svg-icons/faLock";

interface KeyMetricsProps {
  token: TokenViewNew;
  percents: number;
  lockedColor: string;
  auditInfo: TokenAudit | null;
}

const KeyMetrics = observer(({token, percents, lockedColor, auditInfo}: KeyMetricsProps) => {
  const {globalStore} = useStores();
  const mcap = token.metrics.marketCap;
  const prices = token.pair.price24h || token.pair.price1h;

  const metrics = [
    {
      label: 'Liquidity',
      tooltip: 'Liquidity',
      value: token.pair.metrics.liquidity,
      unit: '$',
    },
    {
      label: 'Total Market Cap',
      short: 'MCap',
      tooltip: 'Total Market Cap',
      value: mcap || "0",
      unit: '$',
    },
    {
      label: '24H Volume',
      short: 'Volume',
      tooltip: '24H Volume',
      value: prices?.volume ? Math.round(prices.volume) : null,
      unit: '$',
    },
    {
      short: 'Liq/MCap',
      value: token.pair.metrics.liquidity && mcap ? formatNumber(new BigNumber(token.pair.metrics.liquidity).div(mcap).times(100).toNumber(), '%') : 'N/A'
    },
    {
      label: 'Holders',
      tooltip: 'Holders',
      value: token.metrics.holders,
    },
    {
      label: 'Pool Created',
      tooltip: 'Pool Created',
      short: 'Created',
      hide: true,
      value: token.pair.createdAt ? format(new Date(token.pair.createdAt * 1000), 'dd/MM/yyyy HH:mm') : 'N/A',
      shortValue: token.pair.createdAt ? formatDistance(new Date(token.pair.createdAt * 1000), new Date()) : 'N/A',
    },
    {
      label: 'Total Supply',
      short: 'Supply',
      tooltip: 'Total Supply',
      value: token.metrics.totalSupply,
    },
    {
      label: 'Total Tx',
      tooltip: 'Total Tx',
      hide: true,
      value: token.metrics.txCount,
    },
    {
      label: `Pooled ${token.symbol}`,
      tooltip: `Pooled ${token.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenReserve,
    },
    {
      label: `Pooled ${token.pair.tokenRef.symbol}`,
      tooltip: `Pooled ${token.pair.tokenRef.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenRefReserve,
    },
    {
      label: `% Pooled ${token.symbol}`,
      tooltip: `% Pooled ${token.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenReserve / token.metrics.totalSupply * 100,
      unit: '%',
    },
    {
      label: `1 ETH`,
      tooltip: `Amount of ${token.symbol} you get for 1 ETH`,
      hide: true,
      value: globalStore.ethPrice && token.pair.price ? new BigNumber(globalStore.ethPrice).dividedBy(token.pair.price).toFixed(6) : "N/A",
      unit: token.symbol,
    },
  ];

  return (
    <div className="card wd-100p ht-100p mb-3 flex-1">
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Key Metrics</span>
        <InfoTooltip text="Key metrics for coin analysis selected by DEX investing industry experts" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>
      <div className="d-none d-md-block">
        {metrics.map(({label, value, unit, tooltip}, index) => !!label && (
          <div className="d-flex align-items-center justify-content-between tx-14 mt-2 ht-25" key={`key-metric-${label}-${index}`}>
            <div className="tx-muted d-flex align-items-center">
              {!!tooltip && <InfoTooltip text={tooltip} className="d-inline-flex me-2" />}
              <span>{label}</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="word-break">{value && isNumber(value) ? formatNumber(value as number, unit) : value || 'N/A'}</span>
              {label === 'Liquidity' && (
                <LiquidityTooltip token={token} auditInfo={auditInfo}>
                  <CircleChart
                    value={percents > 100 ? 100 : percents}
                    color={lockedColor}
                    size="fixed"
                    fixedSize={24}
                    icon={faLock}
                    className="tx-12 d-inline-flex ms-2"
                  />
                </LiquidityTooltip>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="d-md-none">
        <div className="d-flex align-items-center tx-14 tx-muted">
          <InfoTooltip text="Volatility" className="d-inline-flex me-2" />
          <span>Volatility</span>
        </div>
        <ProgressSlider value={(new BigNumber(token.pair.metrics.liquidity).div(mcap).times(100).times(5).toNumber())} className="mt-1" hideValue hideSteps multicolor />

        <div className="row row-x-1 mt-3">
          <div className="col-4 px-1 mb-2">
            <div className="wd-100p card bg-semi-transparent d-flex flex-column align-items-center justify-content-center tx-center px-2 ht-100p py-0">
              <LiquidityTooltip token={token} auditInfo={auditInfo}>
                <CircleChart
                  value={percents > 100 ? 100 : (percents || 0)}
                  color={lockedColor}
                  size="fixed"
                  fixedSize={30}
                  icon={faLock}
                  className="tx-12 d-inline-flex"
                />
              </LiquidityTooltip>
            </div>
          </div>
          {metrics.filter((m) => !m.hide).map(({label, short, value, shortValue, unit, hide}, index) => (
            <div className={`col-4 px-1 ${index !== metrics.filter((m) => !m.hide).length - 1 ? 'mb-2' : ''}`} key={`key-metric-mobile-${label}-${index}`}>
              <LabelValueCard
                label={short || label || ''}
                value={shortValue || value || 'N/A'}
                unit={unit}
                className="p-2 word-break"
                labelClassName="tx-muted tx-12"
                valueClassName="tx-14"
              />
            </div>
          ))}
          <div className="col-8 px-1">
            <LabelValueCard
              label="Pool Created"
              value={token.pair.createdAt ? format(new Date(token.pair.createdAt * 1000), 'dd/MM/yyyy HH:mm') : 'N/A'}
              className="p-2 word-break"
              labelClassName="tx-muted tx-12"
              valueClassName="tx-14"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default KeyMetrics;

import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import ConnectWalletButton from "@components/ConnectWalletButton";
import {darkTheme, SupportedChainId, SwapWidget} from "@uniswap/widgets";
import {useSigner} from "wagmi";
import {TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import InfoTooltip from "@components/common/InfoTooltip";

interface SwapModalProps {
  token: TokenViewNew;
  mini?: boolean;
}

const SwapModal = observer(({token, mini}: SwapModalProps) => {
  const { accountStore } = useStores();
  const provider = useSigner().data?.provider;
  const {address} = accountStore;
  const [show, setShow] = useState(false);

  const swapAvailable = token.pair.chain === 'ether' && (token.pair.exchange === 'univ2' || token.pair.exchange === 'univ3');

  const handleClose = () => setShow(false);

  return !swapAvailable ? null : (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="pt-4 pb-0 tx-22">
          <InfoTooltip text="Swap tokens on Uniswap" size={24} className="me-2 tx-muted" />
          Swap
        </Modal.Header>
        <Modal.Body className="pt-0 pb-4 px-3">
          <div className="py-2 d-flex justify-content-center flex-column align-items-center" id="swap-widget">
            {!address || !provider ? (
              <div>
                <div>You need to connect your wallet to swap tokens.</div>

                <ConnectWalletButton />
              </div>
            ) : (
              <SwapWidget
                theme={darkTheme}
                jsonRpcUrlMap={['https://eth-mainnet.g.alchemy.com/v2/sS53pr4W_trGDG86HDBJ2vN5TpApw8mR']}
                tokenList={[{
                  chainId: SupportedChainId.MAINNET,
                  address: token.address,
                  symbol: token.symbol.replace(/[^\w\s]/gi, '').replaceAll(" ", "-"),
                  name: token.name.replace(/[^\w\s]/gi, '').replaceAll(" ", "-"),
                  decimals: 18,
                  logoURI: token.logo || undefined,
                }]}
                // @ts-ignore
                provider={provider ?? undefined}
                defaultOutputTokenAddress={token.address}
                defaultChainId={SupportedChainId.MAINNET}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <button className={`btn btn-primary ${mini ? 'ms-2 px-4 py-1' : 'ms-3 px-4'}`} onClick={() => setShow(true)}>
        <span className={mini ? 'tx-14' : 'px-3'}>Swap</span>
      </button>
    </>
  );
});

export default SwapModal;

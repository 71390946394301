import React from "react";
import {Modal} from "react-bootstrap";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import LoginSignupPage from "../../pages/Auth/LoginSignupPage";
import {ConnectionStep} from "@stores/account-store";
import ConnectWalledModal from "@components/ConnectWalletModal";
import ForgotPage from "../../pages/Auth/ForgotPage";
import VerifyPage from "../../pages/Auth/VerifyPage";
import FavoritesLogin from "../../pages/TokenList/components/FavoritesLogin";

const ConnectionModal = observer(() => {
  const { accountStore } = useStores();
  const { connectionStep } = accountStore;

  const handleClose = () => {
    accountStore.setConnectionStep(null);
  };

  return (
    <Modal show={!!connectionStep} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="pt-4 pb-0" />
      <Modal.Body className="pt-0 pb-4 px-4">
        <div className="py-2 px-3">
          {(connectionStep === ConnectionStep.LOGIN || connectionStep === ConnectionStep.SIGNUP) && (
            <LoginSignupPage isLogin={connectionStep === ConnectionStep.LOGIN} isModal />
          )}
          {connectionStep === ConnectionStep.WALLET && <ConnectWalledModal />}
          {connectionStep === ConnectionStep.FORGOT_PASSWORD && <ForgotPage />}
          {connectionStep === ConnectionStep.VERIFY_RESET && <VerifyPage isReset />}
          {connectionStep === ConnectionStep.VERIFY && <VerifyPage />}
          {connectionStep === ConnectionStep.FAVORITES && <FavoritesLogin />}
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default ConnectionModal;

import {useEffect, useState} from "react";
import ListItem from "./components/ListItem";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import sortDefault from "@assets/icons/sort-default.svg";
import sortAsc from "@assets/icons/sort-asc.svg";
import sortDesc from "@assets/icons/sort-desc.svg";
import {Sort} from "../../constants";
import {observer} from "mobx-react-lite";
import {TokenListInterval, TokensCategory, TokenSort} from "@stores/tokens-store";
import HotTokens from "./components/HotTokens";
import Highlights from "./components/Highlights";
import TokenListFilters from "./components/TokenListFilters";

const TokenList = observer(() => {
  const {tokensStore} = useStores();
  const {list, sort, isLoading, isTopOrVerified} = tokensStore;
  const {mCap, volume, liquidity} = sort;
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const {category} = tokensStore;

  const sortIcon = (type: Sort) => {
    switch (type) {
      case Sort.ASC:
        return sortAsc;
      case Sort.DESC:
        return sortDesc;
      default:
        return sortDefault;
    }
  };

  const handleSort = (_sort: Sort, type: TokenSort) => {
    switch (_sort) {
      case Sort.ASC:
        tokensStore.setSort(Sort.DESC, type);
        break;
      case Sort.DESC:
        tokensStore.setSort(Sort.DEFAULT, type);
        break;
      default:
        tokensStore.setSort(Sort.ASC, type);
        break;
    }
  };

  const handleCategoryChange = (value: TokensCategory) => {
    tokensStore.setCategory(value);
  }

  const handleIntervalChange = (value: TokenListInterval) => {
    tokensStore.setInterval(value);
  }

  useEffect(() => {
    const onScroll = () => tokensStore.setScrollTop(window.scrollY);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [tokensStore]);

  useEffect(() => {
    if (!isInitialized) {
      // @ts-ignore
      window.scrollTo({left: 0, top: tokensStore.scrollTop, behavior: tokensStore.scrollTop === 0 ? 'smooth' : 'instant'});
    }
    setIsInitialized(true);

    return () => {
      setIsInitialized(false);
    }
  }, [tokensStore.scrollTop, isInitialized]);

  const tableHeaders = [
    // {
    //   title: '',
    // },
    {
      title: 'Name',
    },
    {
      title: 'Liquidity',
      sorter: TokenSort.LIQUIDITY,
      sort: liquidity,
    },
    {
      title: 'Price',
    },
    {
      title: 'Price Change',
    },
    {
      title: 'MCap',
      sorter: TokenSort.MCAP,
      sort: mCap,
    },
    {
      title: 'Volume',
      sorter: TokenSort.VOLUME,
      sort: volume,
    },
    // {
    //   title: 'Holders',
    // },
    {
      title: 'Chain',
    },
    {
      title: 'Created',
    },
  ];

  return (
    <div>
      {/*<HotTokens />*/}
      {/*<Highlights />*/}
      {/*<TokenListFilters handleCategoryChange={handleCategoryChange} handleIntervalChange={handleIntervalChange} />*/}

      <h1>Top 50 tokens by USHI PRO</h1>
      {/*<div className="no-gutter px-0 ht-600 ht-lg-800 overflow-auto">*/}
      <div className="no-gutter px-0">
        <div className="div-table tx-14 wd-100p" id="tokens-table">
          <div className="table-header sticky" style={{top:0}}>
            <div className="table-row">
              {tableHeaders.map(({title, sort, sorter}, index) => (
                <div
                  className={`table-col tx-muted ${sort && isTopOrVerified ? 'cur-pointer' : ''}`}
                  key={`table-header-${index}-title`}
                  onClick={() => sort && isTopOrVerified && handleSort(sort, sorter)}
                >
                  {title || <>&nbsp;</>}
                  {sort && isTopOrVerified && <img src={sortIcon(sort)} alt={`sort-${sorter}`} className="wd-12 ms-2"/>}
                </div>
              ))}
            </div>
          </div>
          <div className="table-body">
            {(!list || isLoading) && <Preloader className="mb-2" inline/>}
            {list?.map((item, index) => (
              <ListItem
                key={`coin-list-${category}-item-${item.token.address}-mcap-${mCap}-volume-${volume}-liquidity-${liquidity}-${index}`}
                showAnalytics={!isTopOrVerified}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default TokenList;

import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import TokenLogo from "@components/common/TokenLogo";
import profileCircle from "@assets/icons/profile-circle.svg";
import wallets from "@assets/icons/wallets.svg";
import {ReactComponent as SignOutIcon} from "@assets/icons/sign-out.svg";
import {useStores} from "@hooks/useStores";
import {getEllipsisTxt} from "@helpers/formatters";
import {ConnectionStep} from "@stores/account-store";
import {ReactComponent as WalletIcon} from "@assets/icons/wallet-outline.svg";
import {ReactComponent as ProfileIcon} from "@assets/icons/profile.svg";
import {PageRoutes} from "../../constants";
import bull from "@assets/icons/bull.svg";
import referral from "@assets/icons/referral.svg";
import support from "@assets/icons/support.svg";
import lock from "@assets/icons/lock.svg";
import {useNavigate} from "react-router-dom";
import {useDisconnect} from "wagmi";
import useBackdropFilterToggle from "@hooks/useBackdropFilterToggle";
import {roundDown} from "@helpers/numbers";
import PremiumAlert from "@components/common/PremiumAlert";

interface LinkInterface {
  title: string;
  label?: string;
  onClick?: () => void;
}

interface LinkList {
  title: string;
  onClick?: () => void;
  links?: LinkInterface[];
  color?: string;
  icon: string;
  enabled?: boolean;
}

const Wallet = observer(() => {
  const [expanded, setExpanded] = useState(false);
  const {accountStore} = useStores();
  const {disconnectAsync} = useDisconnect();
  const {address, profile, balance, isSubscriber} = accountStore;
  const navigate = useNavigate();

  useBackdropFilterToggle(expanded);

  const handleClose = () => setExpanded(false);

  const navigation: LinkList[] = [
    {
      title: 'Premium',
      icon: bull,
      color: 'yellow',
      onClick: () => {
        handleClose();
        navigate(PageRoutes.SUBSCRIBE);
      },
    },
    {
      title: 'Referral Program',
      icon: referral,
      onClick: () => {
        handleClose();
        navigate(PageRoutes.REFERRAL_PROGRAM);
      },
      enabled: !!accountStore.profile,
    },
    {
      title: 'Change Password',
      icon: lock,
      onClick: () => {
        handleClose();
        navigate(PageRoutes.PROFILE_PASSWORD);
      },
      enabled: accountStore.isLoggedIn,
    },
    {
      title: 'Contact us',
      icon: support,
      onClick: () => {
        handleClose();
        window.open('https://t.me/ushi_support')
      },
    },
  ];

  const handleLogout = () => {
    handleClose();
    accountStore.logout();
  };

  const handleDisconnect = () => {
    disconnectAsync().then(() => {
      handleClose();
    });
  };

  return (
    <>
      {expanded && <div id="wallet-overlay" className="overlay" onClick={handleClose} />}
      <div className={`wallet-dropdown ${expanded ? 'expanded' : ''}`}>
        <div className="cur-pointer" onClick={() => setExpanded((prev) => !prev)}>
          {address && (
            <>
              <div className="d-none d-md-flex flex-row align-items-center mn-wd-200 ht-40 card px-3 py-2">
                <TokenLogo address={address} name={address} size={24} />
                <div className="ms-3 tx-14 d-block">
                  {getEllipsisTxt(address, 6)}
                </div>
              </div>
              <div className="d-flex d-md-none flex-row justify-content-center align-items-center ht-40 px-0 py-2 rounded-circle">
                <TokenLogo address={address} name={address} size={24} />
              </div>
            </>
          )}
          {!address && profile && (
            <>
              <div className="d-none d-md-flex flex-row align-items-center mn-wd-150 ht-40 card px-3 py-2">
                <img src={profileCircle} alt="profile" className="wd-25 ht-25 rounded-circle" />
                <div className="ms-3 tx-14 d-block text-truncate wd-100">
                  {profile.email}
                </div>
              </div>
              <div className="d-flex d-md-none flex-row justify-content-center align-items-center ht-40 wd-40 rounded-circle">
                <img src={profileCircle} alt="profile" className="wd-25 ht-25 rounded-circle" />
              </div>
            </>
          )}
        </div>
        {!address && !profile && (
          <button
            className="btn btn-secondary ht-40 tx-center d-flex flex-row align-items-center"
            onClick={() => accountStore.setConnectionStep(ConnectionStep.LOGIN)}
          >
            Connect
          </button>
        )}

        <div id="wallet-content" className="tx-center align-items-center">
          {!address && (
            <div className="px-2">
              <img src={wallets} alt="wallets" className="wd-75" />
              <div className="tx-28 mt-3 mb-2">Welcome to USHI</div>

              <div className="tx-16 tx-muted mb-3">
                Connect a wallet to access quick token purchases
              </div>
              <button
                className="btn btn-primary wd-100p ht-40 tx-center d-flex flex-row align-items-center justify-content-center"
                onClick={() => {
                  handleClose();
                  accountStore.setConnectionStep(ConnectionStep.WALLET);
                }}
              >
                <WalletIcon />
                <span className="ms-2">Connect Wallet</span>
              </button>
            </div>
          )}

          {address && (
            <div className="d-flex flex-column align-items-center">
              <div>
                <TokenLogo address={address} name={address} size={50} className="mb-3" />
              </div>
              {isSubscriber && (
                <div className="bg-semi-yellow rounded-1 mn-wd-80 wd-80 ht-25 tx-center tx-warning d-flex justify-content-center align-items-center tx-14">
                  Premium
                </div>
              )}
              <div className="tx-28 my-2">{Math.floor(balance || 0)},<span className="tx-muted">{balance ? roundDown(balance, 2).toString().split('.')[1] || '00' : '00'}</span> ETH</div>
              <div className="tx-14 text-truncate wd-100p px-2">{address}</div>

              <button
                className="btn btn-danger wd-100p mt-3"
                onClick={handleDisconnect}
              >
                Disconnect wallet
              </button>

              <PremiumAlert className="mt-3 tx-left tx-14" />
            </div>
          )}

          <div className="wallet-menu-list border-top border-bottom border-muted border-secondary py-1 mt-3">
            {navigation.map(({title, icon, color = 'white', onClick, enabled}) => {
              return enabled === false ? null : (
                <div className={`wallet-menu-button d-flex align-items-center tx-left justify-content-start cur-pointer wd-100p tx-${color}`} key={`wallet-nav-${title}`}>
                  <div className="wd-50 tx-center">
                    <img src={icon} />
                  </div>

                  <div
                    className="ms-0 wd-100p tx-18"
                    onClick={() => {
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    {title}
                  </div>
                </div>
              )
            })}
          </div>

          {!profile && (
            <div className="px-1">
              <button
                className="btn btn-primary wd-100p tx-left px-2 mt-3"
                onClick={() => {
                  handleClose();
                  accountStore.setConnectionStep(ConnectionStep.LOGIN);
                }}
              >
                <div className="wd-20 d-inline-block ">
                  <ProfileIcon />
                </div>
                <span className="ms-2">Log In</span>
              </button>
            </div>
          )}

          {profile && (
            <button
              className="btn btn-transparent border-0 wd-100p tx-left px-3 tx-danger mt-3"
              onClick={handleLogout}
            >
              <SignOutIcon />
              <span className="ms-2">Log Out</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
});

export default Wallet;

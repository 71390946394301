import FormattedNumber, {FormattedNumberProps} from "@components/common/FormattedNumber";
import React from "react";

interface NumberCardProps extends FormattedNumberProps {
  numberClassName?: string;
  positiveBg?: string;
  negativeBg?: string;
  positiveColor?: string;
  negativeColor?: string;
}

export default function NumberCard(props: NumberCardProps) {
  const {
    className = '',
    numberClassName = '',
    value,
    positiveBg = 'bg-semi-success-2',
    negativeBg = 'bg-semi-danger-2',
    positiveColor = 'tx-success',
    negativeColor = 'tx-danger',
    ...rest
  } = props;

  return (
    <div className={`ms-0 me-0 tx-14 mn-wd-90 rounded-2 tx-center py-2 px-2 ${(value || 0) >= 0 ? positiveBg : negativeBg} ${className}`}>
      <FormattedNumber
        value={value}
        className={`${(value || 0) >= 0 ? positiveColor : negativeColor} ${numberClassName}`}
        {...rest}
      />
    </div>
  );
}

import {observer} from "mobx-react-lite";
import {
  HolderType,
  TokenViewInterface,
  TokenHolder,
  TokenWalletProfitLoss, TokenViewNew
} from "@stores/tokens-store";
import FormattedNumber from "@components/common/FormattedNumber";
import BigNumber from "bignumber.js";
import IdentIcon from "@components/common/IndetIcon";
import {getEllipsisTxt} from "@helpers/formatters";
import React, {useCallback, useMemo, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import {useStores} from "@hooks/useStores";
import {HolderFilter} from "../../../../constants";
import HolderIcon from "./icons/HolderIcon";
import {getPercentage} from "@helpers/numbers";
import zerion from "@assets/icons/zerion.svg";

interface TokenHolderItemProps {
  holder: TokenHolder;
  token: TokenViewNew;
  index: number | string;
}

const TokenHolderItem = observer(({holder, token, index}: TokenHolderItemProps) => {
  const {tokensStore} = useStores();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<TokenWalletProfitLoss>();
  const [canOpen, setCanOpen] = useState<boolean>(true);
  const isBurnAddress = holder.address.includes('0x000');
  const isLiquidityPair = holder.address === token.pair.address;
  const tokenPrice = token.pair.price;

  const handleOpen = useCallback(() => {
    return;

    setIsOpened((prevState) => !prevState);
    if (!info) {
      setIsLoading(true);
      tokensStore.getWalletProfitLoss(token.address, holder.address)
        .then((response) => {
          if (response) {
            if (response.averageUsdTokenPrice === null || response.averageUsdTokenPrice === undefined || response.usdProfit === null || response.usdProfit === undefined) {
              setCanOpen(false);
              setIsOpened(false);
            } else {
              setInfo(response);
            }
          } else {
            setIsOpened(false);
            handleResponse(response);
          }
          setIsLoading(false);
        })
        .catch((response) => {
          setIsLoading(false);
          setIsOpened(false);
          handleResponse(response.response.data);
        });
    }
  }, [info, tokensStore, handleResponse, holder.address, token]);

  // const tokenBalance = new BigNumber(holder.tokenBalance).dividedBy(10 ** (+token.token.decimals)).toNumber();
  const tokenBalance = new BigNumber(holder.tokenBalance).toNumber();
  const overallCost = info ? info.averageUsdTokenPrice * tokenBalance : 0;
  const overallCostPercents = info && holder.totalUsdBalance ? 100 / (holder.totalUsdBalance + overallCost) * overallCost : 0;
  const tokenUsdBalance = new BigNumber(holder.tokenUsdBalance).toNumber() || new BigNumber(holder.tokenBalance).multipliedBy(tokenPrice).toNumber() || 0;

  const holderIcon = useMemo(() => {
    let type = HolderFilter.SHRIMP;
    const v = new BigNumber(holder.totalUsdBalance || 0).minus(tokenUsdBalance).toNumber();

    if (!v || v < 5000) {
      return type;
    }

    if (v > 5000 && v <= 50000) {
      type = HolderFilter.OCTOPUS;
    } else if (v > 50000 && v <= 100000) {
      type = HolderFilter.SHARK;
    } else if (v > 100000) {
      type = HolderFilter.WHALE;
    }

    return type;
  }, [holder]);

  return (
    <tr className="valign-middle border-muted-10 tx-14">
      <td>
        <div className="d-flex flex-row justify-content-start align-items-center">
          <div className="ms-0 wd-35 ht-35 rounded-20 overflow-hidden token-logo">
            <IdentIcon string={holder.address} size={35} />
          </div>
          <div className="d-flex flex-column ms-2 tx-left">
            <div>
              <a
                href={`https://etherscan.io/token/${token.address}?a=${holder.address}`}
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none tx-white d-none d-md-block"
              >
                {getEllipsisTxt(holder.address)}
              </a>
              <a
                href={`https://etherscan.io/token/${token.address}?a=${holder.address}`}
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none tx-white d-block d-md-none"
              >
                {getEllipsisTxt(holder.address, 4)}
              </a>
            </div>
            {/*{holder.type === HolderType.BURN_ADDRESS && (*/}
            {isBurnAddress && (
              <div className="tx-danger">Burn Address</div>
            )}
            {/*{holder.type === HolderType.LIQUIDITY_PAIR && (*/}
            {isLiquidityPair && (
              <div className="tx-primary">Liquidity Pool</div>
            )}
            {!isBurnAddress && !isLiquidityPair && !!holder.label && (
              <div className="tx-muted tx-12">{holder.label}</div>
            )}
            {!isBurnAddress && !isLiquidityPair && (
              <a href={`https://app.zerion.io/${holder.address}/overview`} className="tx-primary text-decoration-none tx-12" target="_blank" rel="noreferrer">
                View on Zerion <img src={zerion} alt="Zerion" className="wd-15 ht-15 ms-1" />
              </a>
            )}
            {/*{holder.type === HolderType.LABELED_WALLET && (*/}
            {/*  <div className="tx-primary">{holder.label}</div>*/}
            {/*)}*/}
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex flex-row justify-content-start align-items-center" onClick={handleOpen}>
          <div className="bg-semi-transparent rounded-6px px-2 py-0 d-flex justify-content-center align-items-center tx-12 me-2">
            {index}
          </div>

          <div>
            <FormattedNumber
              value={tokenBalance}
              postfix={token.symbol}
            />
          </div>

          {/*<div className="ps-3 d-flex justify-content-center align-items-center">*/}
          {/*  &nbsp;*/}
          {/*  /!*{canOpen && holder.type === HolderType.WALLET ? (*!/*/}
          {/*  /!*  <>*!/*/}
          {/*  /!*    {!isLoading ? <img src={chevron} alt="Details" className={isOpened ? 'rotate-270' : 'rotate-90'} /> : <IconWithLoading className="" isLoading={isLoading} />}*!/*/}
          {/*  /!*  </>*!/*/}
          {/*  /!*) : (<>&nbsp;</>)}*!/*/}
          {/*</div>*/}
        </div>
      </td>
      {/*<td>*/}
      {/*  &nbsp;*/}
      {/*</td>*/}
      <td>
        <FormattedNumber
          value={getPercentage(tokenBalance, new BigNumber(token.metrics.totalSupply).toNumber())}
          postfix="%"
          decimals={2}
        />
      </td>
      <td>
        {!isBurnAddress && !isLiquidityPair && (
          <FormattedNumber
            value={new BigNumber(holder.totalUsdBalance || 0).minus(new BigNumber(holder.tokenBalance || 0).multipliedBy(tokenPrice || 0)).toNumber()}
            suffix="$"
            decimals={0}
            floor
            useSpinner
          />
        )}
        {(isBurnAddress || isLiquidityPair) && (
          <div className="tx-muted">-</div>
        )}
      </td>
      <td className="tx-muted">
        {!isBurnAddress && !isLiquidityPair && (<HolderIcon type={holderIcon} />)}
      </td>
      {/*{isOpened && info && (*/}
      {/*  <div className="card pt-0 flex-row justify-content-between align-items-center">*/}
      {/*    <div className="px-2 py-0 d-flex justify-content-center align-items-center tx-12">*/}
      {/*      &nbsp;*/}
      {/*    </div>*/}
      {/*    <div className="wd-100p tx-12 mx-3">*/}
      {/*      <div className="d-flex flex-row justify-content-between mb-2">*/}
      {/*        <div className="tx-muted">Average token price</div>*/}
      {/*        <FormattedNumber*/}
      {/*          value={info.averageUsdTokenPrice}*/}
      {/*          suffix="$"*/}
      {/*          decimals={10}*/}
      {/*          useFormat*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="d-flex flex-row justify-content-between mb-2">*/}
      {/*        <div className="tx-muted">Overall cost</div>*/}
      {/*        <FormattedNumber*/}
      {/*          value={overallCost}*/}
      {/*          suffix="$"*/}
      {/*          postfix={` (${formatNumber(overallCostPercents, '%', 2)})`}*/}
      {/*          decimals={2}*/}
      {/*          useFormat*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="d-flex flex-row justify-content-between mb-2">*/}
      {/*        <div className="tx-muted">Profit</div>*/}
      {/*        <FormattedNumber*/}
      {/*          value={info.usdProfit}*/}
      {/*          suffix="$"*/}
      {/*          withSign*/}
      {/*          decimals={0}*/}
      {/*          floor*/}
      {/*          className={info.usdProfit > 0 ? 'tx-success' : 'tx-danger'}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="ps-3 d-flex justify-content-center align-items-center">*/}
      {/*      &nbsp;*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </tr>
  );
});

export default TokenHolderItem;

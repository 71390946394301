import {formatNumber} from "@helpers/numbers";
import {isNumber} from "@helpers/bignumber";
import ScoreBars from "@components/common/ScoreBars";
import React, {useMemo} from "react";

interface LabelValueCardProps {
  label: string;
  value: number | string;
  unit?: string;
  className?: string;
  color?: string;
  valueClassName?: string;
  labelClassName?: string;
  isScore?: boolean;
}

const LabelValueCard = ({ label, value, unit, color = 'primary', className = 'p-3', labelClassName = 'tx-14', valueClassName = 'tx-16', isScore = false}: LabelValueCardProps) => {
  const formattedValue = isNumber(value) ? formatNumber(value as number, unit) : value;

  const textColor = useMemo(() => {
    let scoreColor = 'tx-success';

    if (value < 50) {
      scoreColor = 'tx-danger';
    } else if (value < 80) {
      scoreColor = 'tx-primary';
    } else if (value <= 100) {
      scoreColor = 'tx-success';
    }

    return scoreColor;
  }, [value]);

  return (
    <div className={`wd-100p card bg-semi-transparent d-flex flex-column align-items-center justify-content-center tx-center tx-14 ${className}`}>
      <div className={labelClassName}>{label}{isScore && (<>: <span className={textColor}>{formattedValue}</span></>)}</div>
      {/*<div className={`wd-25 ht-1 my-2 bg-${color}`} />*/}
      {!isScore && (
        <div className={valueClassName}>{formattedValue}</div>
      )}
      {isScore && (
        <ScoreBars score={value as number} className="mx-auto wd-150 ht-auto" noValue />
      )}
    </div>
  );
};

export default LabelValueCard;

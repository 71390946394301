import React, {useCallback} from "react";
import {observer} from "mobx-react-lite";
import {TokenHolder, TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import DoughnutChart from "@components/common/Charts/DoughnutChart";
import CircleIcon from "@components/common/Charts/CircleIcon";
import {ChartColorsArray} from "../../../../constants";
import {getPercentage, numberWithCommas} from "@helpers/numbers";
import BigNumber from "bignumber.js";
import Preloader from "@components/common/Preloader";

interface HoldersDistributionProps {
  holders: TokenHolder[];
  isLoading: boolean;
  token: TokenViewNew;
}

const HoldersDistribution = observer(({holders, isLoading, token}: HoldersDistributionProps) => {
  const getPercentageOfTotalSupply = useCallback((value: string | number) => {
    return getPercentage(value, new BigNumber(token.metrics.totalSupply).toNumber())
  }, [token]);

  const liquidityPercentage = getPercentageOfTotalSupply(holders.find((holder) => holder.address === token.pair.address)?.tokenBalance || 0);
  const burnPercentage = getPercentageOfTotalSupply(holders.find((holder) => holder.address.includes('0x000'))?.tokenBalance || 0);
  const top100 = getPercentageOfTotalSupply(holders.filter((holder) => holder.address !== token.pair.address && !holder.address.includes('0x000')).sort((a,b) => new BigNumber(b.tokenBalance || 0).minus(a.tokenBalance || 0).toNumber()).slice(0, 100).reduce((acc, holder) => new BigNumber(acc).plus(holder.tokenBalance || 0).toNumber(), 0));

  const chartData = {
    values: [
      {value: liquidityPercentage, label: 'Liquidity'},
      {value: burnPercentage, label: 'Burn'},
      {value: top100, label: 'Top 100'},
      {value: 100 - top100 - burnPercentage - liquidityPercentage, label: 'All the others'},
    ],
    unit: '%',
    innerTitle: token.metrics.holders.toString(),
    innerSubTitle: 'Holders',
  };

  return (
    <div className="card wd-100p flex-1">
      <div className="tx-22 d-flex align-items-center mb-3">
        <span>Distribution</span>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          {!isLoading && !!holders.length && (<DoughnutChart chartClassName="mx-wd-250 mx-wd-md-200 mx-auto" {...chartData} />)}
          {isLoading && <Preloader inline />}
        </div>

        <div className="col-md-6 ht-100p d-flex flex-column justify-content-md-center">
          {chartData.values.map((item, index) => (
            <div className="d-flex align-items-center justify-content-between mb-2 tx-12" key={`chart-data-values-${item.label}-${item.value}`}>
              <CircleIcon color={ChartColorsArray[index]} />
              <div className="ms-2 me-auto" style={{color: ChartColorsArray[index]}}>{item.label}</div>
              <div>{numberWithCommas(item.value, false, 2, false)}{chartData.unit}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default HoldersDistribution;

import robot from 'assets/images/robot.png';
import React, {useState} from "react";
import Star from "@components/common/Star";

const NoFavorites = () => {
  const [isFavorite, setIsFavorite] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column ht-100p wd-100p tx-center mb-2">
      <img src={robot} alt="favorites" className="wd-65 mx-auto" />
      <div className="tx-28 my-3">
        Favorite empty!
        <div className="ms-2 me-2 mn-wd-40 wd-35 ht-35 d-inline-flex"
          onClick={() => setIsFavorite((prev) => !prev)}
        >
          <Star isFavorite={isFavorite} className="d-inline-flex btn btn-icon btn-secondary mn-wd-40 wd-35 ht-35 align-items-center justify-content-center bg-gray-900" />
        </div>
      </div>
      <div className="tx-muted mb-3 wd-250">Let our algorithm show its power - add tokens to favorites </div>
    </div>
  );
};

export default NoFavorites;

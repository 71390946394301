import {ReactComponent as CheckCircle} from "@assets/icons/check-circle.svg";
import {ReactComponent as InfoCircle} from "@assets/icons/info-circle.svg";
import {ReactComponent as CrossCircle} from "@assets/icons/cross-circle.svg";
import InfoTooltip from "@components/common/InfoTooltip";
import React from "react";

export interface TokenAnalysisCheckerProps {
  available?: any;
  condition?: boolean;
  conditionFail?: string;
  conditionSuccess?: string;
  conditions?: boolean[];
  title: string;
  staticText?: string;
  className?: string;
  value?: string | number;
  unit?: string;
}

const TokenAnalysisChecker = ({conditions, condition, title, conditionSuccess = 'Yes', conditionFail = 'No', staticText, className = 'mb-3', value, unit = ''}: TokenAnalysisCheckerProps) => {
  const index = conditions ? conditions.indexOf(true) : -1;
  let Icon = !conditions && condition ? CheckCircle : CrossCircle;
  let color = !conditions && condition ? 'light-green' : 'light-red';
  const text = condition ? conditionSuccess : conditionFail;

  switch (index) {
    case 0:
      Icon = CheckCircle;
      color = 'light-green';
      break;
    case 1:
      Icon = InfoCircle;
      color = 'warning';
      break;
    case 2:
      Icon = CrossCircle;
      color = 'light-red';
      break;
  }

  return (
    <div className="d-flex align-items-center justify-content-between tx-14 mt-2 ht-25">
      <div className="tx-muted d-flex align-items-center">
        {!!staticText && <InfoTooltip text={staticText} className="d-inline-flex me-2" />}
        <span>{title}</span>
      </div>
      <div className={`d-flex align-items-center tx-${color}`}>
        <span>
          {value}{unit}
          {!value && !conditions && text}
          <Icon width={16} height={16} className="ms-1" />
        </span>
      </div>
    </div>
  );
}

export default TokenAnalysisChecker;

import React, {Suspense} from "react";
import {useAccount, useConnect, useDisconnect} from "wagmi";
import Preloader from "@components/common/Preloader";
import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import {ConnectionStep} from "@stores/account-store";

const ConnectWalletButton = observer(() => {
  const { accountStore } = useStores();
  const { isConnected } = useAccount();
  const { isLoading } = useConnect();
  const { disconnect } = useDisconnect();

  const handleShow = () => accountStore.setConnectionStep(ConnectionStep.WALLET);

  return (
    <Suspense fallback={<Preloader/>}>
      <div className="tx-center">
        {isConnected && (
          <button
            className="btn btn-outline-danger tx-danger wd-100p mt-3"
            onClick={() => setTimeout(() => {
              disconnect();
            }, 50)}
          >
            Disconnect wallet
          </button>
        )}
        {!isConnected && (
          <button className="btn btn-outline-primary tx-primary wd-100p mt-3" onClick={handleShow} disabled={isLoading}>
            <IconWithLoading isLoading={isLoading} className="me-2"/>
            Connect Wallet
          </button>
        )}
      </div>
    </Suspense>
  );
});

export default ConnectWalletButton;

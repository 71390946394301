import React, {useMemo} from 'react';
import {exchanges} from "../../exchanges";
import {chainIcon} from "../../constants";

interface ChainExchangeProps {
  chain: string;
  exchange: string;
  className?: string;
  size?: number;
}

export default function ChainExchange({ chain, exchange, className = '', size = 30 }: ChainExchangeProps) {
  const exchangeIcon = useMemo(() => {
    try {
      // @ts-ignore
      const logo = exchanges.find((e) => e.chain === chain)?.data.find((e) => e.slug === exchange)?.logo;
      const result = logo ? require(`../../assets/images/exchanges/${logo}`) : null;
      return result;
    } catch (e) {
      return null;
    }
  }, [chain, exchange]);

  return (
    <div className={`exchange-logo-list ${className}`}>
      <div className={`exchange-logo-list--item no-border wd-${size} ht-${size}`}>
        <img src={chainIcon(chain)} alt={chain} className={`wd-${size} ht-${size}`} />
      </div>
      <div className={`exchange-logo-list--item no-border wd-${size} ht-${size}`}>
        {exchangeIcon ? <img src={exchangeIcon} alt={exchange} width={size} height={size} className={`wd-${size} ht-${size}`} /> : null}
      </div>
    </div>
  );
}

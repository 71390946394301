import {FavoritesTokenInterface, SearchTokenInterface, TokenInterface, TokenViewInterface} from "@stores/tokens-store";
import {useCallback, useState} from "react";
import {ConnectionStep} from "@stores/account-store";
import toast from "react-hot-toast";
import {useStores} from "@hooks/useStores";
import IconWithLoading from "@components/common/IconWithLoading";
import {observer} from "mobx-react-lite";

interface StarProps {
  isFavorite?: boolean;
  token?: SearchTokenInterface | TokenViewInterface | TokenInterface | FavoritesTokenInterface;
  className?: string;
}

const Star = observer(({isFavorite: _isFav, token, className = ''}: StarProps) => {
  const {tokensStore, accountStore} = useStores();
  const pair = token ? 'id' in token ? token.id.pair : token._id.pair : '';
  const isFavorite = _isFav || tokensStore.isFavorite(pair);
  const fill = isFavorite ? {fill: '#FFF'} : {};
  const [isLoading, setIsLoading] = useState(false);

  const toggleFavorite = useCallback(() => {
    if (!token || isLoading) {
      return;
    }

    if (!accountStore.isLoggedIn) {
      accountStore.setConnectionStep(ConnectionStep.FAVORITES);
      return;
    }

    setIsLoading(true);
    const isRemove = isFavorite;

    tokensStore.toggleFavorite(pair, token).then(() => {
      setIsLoading(false);
      toast.success(isRemove ? 'Token removed from favorites' : 'Token added to favorites');
    });
  }, [token, isLoading, accountStore, isFavorite, tokensStore, pair]);

  return (
    <div className={className} onClick={toggleFavorite}>
      {isLoading ? <IconWithLoading className="wd-20 ht-19 tx-16" isLoading /> : (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.7687 5.11995L13.4353 4.67765L12.7687 5.11995C13.0079 5.48042 13.3689 5.74271 13.7856 5.85878L18.3195 7.12159C18.4574 7.15999 18.5115 7.3265 18.4225 7.43859L15.4968 11.1252C15.2279 11.464 15.09 11.8884 15.1084 12.3206L15.3084 17.0229C15.3145 17.1658 15.1728 17.2687 15.0388 17.2188L10.6285 15.5755L10.3492 16.3251L10.6285 15.5755C10.2231 15.4244 9.77689 15.4244 9.37151 15.5755L9.65084 16.3251L9.37151 15.5755L4.96125 17.2188C4.82716 17.2687 4.68551 17.1658 4.69159 17.0229L4.89164 12.3206C4.91003 11.8884 4.77214 11.464 4.50321 11.1252L1.57747 7.43859C1.48852 7.3265 1.54262 7.15999 1.68047 7.12159L6.21437 5.85878C6.63112 5.74271 6.99212 5.48042 7.23129 5.11994L9.83335 1.19818C9.91246 1.07895 10.0875 1.07894 10.1667 1.19818L12.7687 5.11995Z"
            stroke="#FFF" strokeWidth="1.6" {...fill} />
        </svg>
      )}
    </div>
  );
});

export default Star;

import React, {useCallback} from "react";
import {TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import share from "@assets/icons/share.svg";
import confirmAlert from "@components/ConfirmAlert";
import CopyButton from "@components/common/CopyButton";
import toast from "react-hot-toast";

interface ShareModelProps {
  token: TokenViewNew;
  onlyIcon?: boolean;
}

export default function ShareModel({token, onlyIcon}: ShareModelProps) {
  const handleShare = useCallback(() => {
    if (!navigator.canShare) {
      confirmAlert({
        title: 'Share',
        noOk: true,
        noCancel: true,
        closeButton: true,
        cancelLabel: 'Close',
        confirmation: (
          <div className="d-flex flex-column justify-content-between">
            <div className="bg-semi-transparent rounded-10 px-2 py-3 mb-2 flex-column justify-content-center align-items-center tx-center text-truncate">
              {window.location.href}
            </div>
            <CopyButton
              text={window.location.href}
              element="Copy link"
              className="btn btn-primary tx-center tx-olney text-decoration-none wd-100p"
              iconClass="tx-white ms-3"
            />
          </div>
        ),
      });
    } else {
      try {
        navigator.share({
          url: window.location.href,
          title: `${token.name} token on uAnalytics`,
          text: `Check ${token.name} price, charts, trade history, audit and other information on uAnalytics`,
        });
      } catch (error) {
        toast.error('Share failed', {duration: 3000});
      }
    }
  }, [token]);

  return (
    <>
      <button
        onClick={handleShare}
        className={`btn btn-outline-secondary tx-white d-flex align-items-center ms-auto me-2 ${onlyIcon ? 'p-2' : 'px-3'}`}
      >
        <img src={share} alt="Share" className={onlyIcon ? '' : 'me-2'}/>
        {!onlyIcon && 'Share'}
      </button>
    </>
  );
}

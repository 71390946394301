import React, {useCallback} from "react";
import OverlayHeader, {OverlayHeaderProps} from "@components/common/Overlay/OverlayHeader";
import useCloseOverlay from "@hooks/useCloseOverlay";

interface OverlayProps extends OverlayHeaderProps {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  headerClassName?: string;
}

const Overlay = ({ children, className, onClose, containerClassName = 'container py-3', headerClassName, ...headerProps }: OverlayProps) => {
  const closeOverlay = useCloseOverlay();

  // const handleClose = useCallback(() => {
  //   closeOverlay();
  //   onClose && onClose();
  // }, [onClose, closeOverlay]);

  return (
    <div className={`overlay bg-secondary ${className}`}>
      <div className={containerClassName}>
        {/*<OverlayHeader*/}
        {/*  onClose={handleClose}*/}
        {/*  className={headerClassName}*/}
        {/*  {...headerProps}*/}
        {/*/>*/}
        {children}
      </div>
    </div>
  );
};

export default Overlay;

import {Link} from "react-router-dom";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {PageRoutes} from "../../constants";

interface PremiumAlertProps {
  withButton?: boolean;
  withText?: boolean;
  className?: string;
}

const PremiumAlert = observer(({withButton = false, withText = true, className = 'mt-3'}: PremiumAlertProps) => {
  const {accountStore} = useStores();
  const {isSubscriber} = accountStore;

  return isSubscriber ? null : (
    <div className={className}>
      {withButton && (
        <Link
          to={PageRoutes.SUBSCRIBE}
          className="btn btn-outline-primary tx-primary wd-100p mb-3"
        >
          Get Access
        </Link>
      )}
      {withText && (
        <div className="d-flex justify-content-between">
          <div className="bg-semi-yellow rounded-2 mn-wd-80 wd-80 ht-35 tx-center tx-warning me-3 d-flex justify-content-center align-items-center tx-14">
            Premium
          </div>
          <div>
            Having <span className="tx-primary">50 million Ushi tokens</span> in your wallet gives you unlimited access to paid features.
          </div>
        </div>
      )}
    </div>
  );
});

export default PremiumAlert;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />

    <Toaster
      toastOptions={{
        style: {
          border: 'none',
        },
        success: {
          style: {
            background: '#081E26',
            color: '#22C55E',
          },
        },
        error: {
          style: {
            background: '#181825',
            color: '#FD4438',
          },
        },
      }}/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

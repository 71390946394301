import ether from "@assets/images/chains/ether.svg";
import bsc from "@assets/images/chains/bsc.svg";
import polygon from "@assets/images/chains/polygon.svg";
import arbitrum from "@assets/images/chains/arbitrum.svg";
import avalanche from "@assets/images/chains/avalanche.svg";
import {_tenMlnAccounts, _thirtyMlnAccounts, _twentyMlnAccounts, _whiteListedAccounts} from "./whitelist";

// type Chain =
//   | "eth"
//   | "0x1"
//   | "ropsten"
//   | "0x3"
//   | "rinkeby"
//   | "0x4"
//   | "goerli"
//   | "0x5"
//   | "kovan"
//   | "0x2a"
//   | "polygon"
//   | "0x89"
//   | "mumbai"
//   | "0x13881"
//   | "bsc"
//   | "0x38"
//   | "bsc testnet"
//   | "0x61"
//   | "avalanche"
//   | "0xa86a"
//   | "avalanche testnet"
//   | "0xa869"
//   | "fantom"
//   | "0xfa"
//   | "cronos"
//   | "0x19"
//   | "cronos testnet"
//   | "0x152";

export const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam ultricies, nisl nunc aliquet nunc, quis aliquam nunc nisl nec nisl. Sed euismod, diam quis aliquam ultricies, nisl nunc aliquet nunc, quis aliquam nunc nisl nec nisl.';

export const ENV = {
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID as string,
  USHI_TOKEN: process.env.REACT_APP_USHI_TOKEN as string,
  SUBSCRIPTION_MANAGER: process.env.REACT_APP_SUBSCRIPTION_MANAGER as string,
  API_URL: process.env.REACT_APP_API_URL as string,
  API_V2_URL: process.env.REACT_APP_API_V2_URL as string,
}

export enum ChainId {
  ETH = 1,
  Polygon  = 137,
  BSC  = 56,
  Avalanche  = 43114,
  Arbitrum = 42161,
}

export const whiteListedAccounts = _whiteListedAccounts.map((address) => address.toLowerCase());

export const tenMlnAccounts = _tenMlnAccounts.map((address) => address.toLowerCase());

export const twentyMlnAccounts = _twentyMlnAccounts.map((address) => address.toLowerCase());

export const thirtyMlnAccounts = _thirtyMlnAccounts.map((address) => address.toLowerCase());

export enum PageRoutes {
  COIN_LIST = '/',
  SEARCH = '/search',
  FAVORITE_LIST = '/favorites',
  LOGIN = '/login',
  SIGNUP = '/registration',
  VERIFY = '/verify-account',
  VERIFY_RESET = '/verify-reset',
  FORGOT_PASSWORD = '/forgot-password',
  UPDATE_PASSWORD = '/reset/:token',
  ACCOUNT_CREATED = '/registration/:token',
  PROFILE = '/profile',
  // PROFILE_WALLET = '/profile/wallet',
  PROFILE_PASSWORD = '/profile/password',
  REFERRAL_PROGRAM = '/referral',
  REFERRAL_WITHDRAW = '/referral/withdraw',
  SUBSCRIBE = '/subscribe',
  SUBSCRIBE_STEP = '/subscribe/:page',
}

export enum Exchange {
  UNISWAP = 'UNISWAP',
  UNISWAP_V2 = 'UNISWAP_V2',
  UNISWAP_V3 = 'UNISWAP_V3',
  PANCAKESWAP = 'PANCAKESWAP',
  SUSHISWAP = 'SUSHISWAP',
}

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default',
}

export enum ChartColors {
  BLUE = '#528CFF',
  GREEN = '#AAFF3E',
  PINK = '#B918EC',
  GRAY = '#516376',
  ORANGE = '#F5B912',
  CYAN = '#6FD3EA',
  RED = '#FF486B',
  PRIMARY = '#299FBA',
  LIGHT_RED = '#EF6B7E',
}

export enum Currency {
  USDT = 'USDT',
  ETH = 'ETH',
  BTC = 'BTC',
}

export const emailRx = RegExp(/(.+)@(.+){2,}\.(.+){2,}/);

export const ChartColorsArray = Object.entries(ChartColors).map(([_key, value]) => value);

export enum TokenActions {
  INFO = 'info',
  OVERVIEW = 'overview',
  LINKS = 'links',
  AUDIT = 'audit',
  ANALYTICS = 'analytics',
  TWITTER = 'twitter',
  HOLDERS = 'holders',
  SWAP = 'swap',
}

export enum HolderFilter {
  WHALE = 'whale',
  SHARK = 'shark',
  OCTOPUS = 'octopus',
  SHRIMP = 'shrimp',
  LABELED = 'labeled',
}

export const ushiAddress = '0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55';
export const alchemyToken = 'sS53pr4W_trGDG86HDBJ2vN5TpApw8mR';
export const walletConnectProjectId = '8b7f67fdba5ea8a3722691ac9ba47ea3';
export const privacyPolicyUrl = 'https://docs.google.com/document/d/1dNBoVQOmk5nlEdUwUyzQwWdGlRkHPy43JO89yEds3Ac';
export const termsOfServiceUrl = 'https://docs.google.com/document/d/1sDAL66uCNgF5YveYtRXg05jtxMZ7N6NbKeUuAcrNd9A';

export const chainIcon = (chain: string) => {
  switch (chain) {
    case 'ethereum':
      return ether;
    case 'bsc':
      return bsc;
    case 'polygon':
      return polygon;
    case 'arbitrum':
      return arbitrum;
    case 'avalanche':
      return avalanche;
    default:
      return ether;
  }
};

export interface Chain {
  id: ChainId;
  symbol: string;
  title: string;
  icon: string;
}

export const chains: Chain[] = [
  {
    id: ChainId.ETH,
    symbol: 'ether',
    title: 'Ethereum',
    icon: chainIcon('ether'),
  },
  {
    id: ChainId.BSC,
    symbol: 'bsc',
    title: 'BSC',
    icon: chainIcon('bsc'),
  },
  {
    id: ChainId.Polygon,
    symbol: 'polygon',
    title: 'Polygon',
    icon: chainIcon('polygon'),
  },
  {
    id: ChainId.Avalanche,
    symbol: 'avalanche',
    title: 'Avalanche',
    icon: chainIcon('avalanche'),
  },
  {
    id: ChainId.Arbitrum,
    symbol: 'arbitrum',
    title: 'Arbitrum',
    icon: chainIcon('arbitrum'),
  }
];

export const chainSymbols = chains.map((chain) => chain.symbol);

import {
  TokenViewInterface,
  TokenHolder,
  HolderProfile, TokenViewNew,
} from "@stores/tokens-store";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {HolderFilter} from "../../../../constants";
import HolderIcon from "./icons/HolderIcon";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import TokenHolderItem from "./TokenHolderItem";
import PremiumAlert from "@components/common/PremiumAlert";
import HoldersLoader from "./HoldersLoader";
import {observer} from "mobx-react-lite";
import InfoTooltip from "@components/common/InfoTooltip";
import HoldersTrend from "./HoldersTrend";
import HoldersMakingMoney from "./HoldersMakingMoney";
import HoldersDistribution from "./HoldersDistribution";
import getChainId from "@helpers/getChainId";
import BigNumber from "bignumber.js";

const TokenHolders = observer(({token}: {token: TokenViewNew}) => {
  const {tokensStore, accountStore} = useStores();
  const {isSubscriber} = accountStore;
  const allHolders = Object.values(HolderFilter);
  const [holdersFilter, setHoldersFilter] = useState<HolderFilter[]>(allHolders);
  const [holders, setHolders] = useState<TokenHolder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profiles, setProfiles] = useState<HolderProfile[]>([]);
  const [isProfilesLoaded, setIsProfilesLoaded] = useState(false);
  const handleResponse = useResponseHandler();
  const tokenPrice = token.pair.price || 0;
  const filterHolders = useCallback((filter: HolderFilter) => {
    let min = 500;
    let max = Infinity;

    switch (filter) {
      case HolderFilter.WHALE:
        min = 100000;
        break;
      case HolderFilter.SHARK:
        min = 50000;
        max = 100000;
        break;
      case HolderFilter.OCTOPUS:
        min = 5000;
        max = 50000;
        break;
      case HolderFilter.SHRIMP:
      default:
        max = 5000;
        break;
    }

    return holders.filter((holder) => {
      const usdBalanceExcludeTokens = holder.totalUsdBalance ? holder.totalUsdBalance - holder.tokenBalance * tokenPrice : 0;
      return usdBalanceExcludeTokens >= min && usdBalanceExcludeTokens <= max;
    })
  }, [holders]);

  const whaleHolders = useMemo(() => filterHolders(HolderFilter.WHALE), [filterHolders]);
  const sharkHolders = useMemo(() => filterHolders(HolderFilter.SHARK), [filterHolders]);
  const octopusHolders = useMemo(() => filterHolders(HolderFilter.OCTOPUS), [filterHolders]);
  const shrimpHolders = useMemo(() => filterHolders(HolderFilter.SHRIMP), [filterHolders]);
  const labeledHolders = useMemo(() => holders.filter((h) => h.label), [holders]);

  const filters = [
    {
      key: HolderFilter.WHALE,
      count: whaleHolders.length,
    },
    {
      key: HolderFilter.SHARK,
      count: sharkHolders.length,
    },
    {
      key: HolderFilter.OCTOPUS,
      count: octopusHolders.length,
    },
    {
      key: HolderFilter.SHRIMP,
      count: shrimpHolders.length,
    },
  ];

  const filteredHolders = useMemo(() => {
    let _list: TokenHolder[] = [];

    if (holdersFilter.length === 1 && holdersFilter.includes(HolderFilter.LABELED)) {
      _list = labeledHolders;
    } else if (holdersFilter.length === allHolders.length) {
      _list = holders;
    } else {
      if (holdersFilter.includes(HolderFilter.WHALE)) {
        _list = _list.concat(whaleHolders);
      }
      if (holdersFilter.includes(HolderFilter.SHARK)) {
        _list = _list.concat(sharkHolders);
      }
      if (holdersFilter.includes(HolderFilter.OCTOPUS)) {
        _list = _list.concat(octopusHolders);
      }
      if (holdersFilter.includes(HolderFilter.SHRIMP)) {
        _list = _list.concat(shrimpHolders);
      }
    }

    return _list.sort((a,b) => new BigNumber(b.tokenBalance).minus(a.tokenBalance).toNumber());
  }, [holders, allHolders, whaleHolders, sharkHolders, octopusHolders, shrimpHolders, holdersFilter, labeledHolders]);

  useEffect(() => {
    setIsLoading(true);
    tokensStore
      .getTokenHolders(token.address, getChainId(token.pair.chain))
      .then((response) => {
        setHolders(response);
        setIsLoading(false);
      })
      .catch(handleResponse);
  }, [tokensStore, token, handleResponse, isSubscriber]);

  useEffect(() => {
    setIsProfilesLoaded(false);
    tokensStore.getHolderProfiles(token.address, token.pair.chain === 'ether' ? 'eth' : token.pair.chain)
      .then((data) => {
        setIsProfilesLoaded(true);
        if (!data || !Array.isArray(data)) {
          setProfiles([]);
        } else {
          setProfiles(data);
        }
      })
      .catch((e) => {
        setIsProfilesLoaded(true);
        console.error(e);
      });
  }, [tokensStore, token]);

  return (
    <div>
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Holders</span>
        <InfoTooltip text="Holder analysis is extremely important in DEX it allows you to find undervalued coins, which have already found more experienced investors or those who know the insider information" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <HoldersTrend token={token} />
        </div>
        <div className="col-md-6 mb-3 d-flex flex-column">
          <HoldersMakingMoney data={profiles} isLoaded={isProfilesLoaded} />
          <HoldersDistribution holders={holders} isLoading={isLoading} token={token} />
        </div>
      </div>

      <div className="card wd-100p pb-0">
        <div className="tx-22 d-flex align-items-center mb-2">
          <span>Holders List</span>
          <InfoTooltip text="Shrimp [up to $5k], Octopus [$5k to $50k], Shark [$50k to $100k], Whale [from $100k]" size={20} className="d-inline-flex tx-muted ms-2" />
        </div>

        {isSubscriber && (
          <>
            <div className="d-flex mb-2 mb-md-3 wd-100p" role="group" aria-label="Basic example">
              {filters.map(({count, key}, index) => {
                const isActive = holdersFilter.includes(key);
                const isLast = index === filters.length - 1;

                return (
                  <button
                    type="button"
                    key={`token-holders-filter-${key}`}
                    className={`btn btn-secondary ${!isLast ? 'me-2' : 'me-md-2'} bg-semi-transparent tx-12 tx-olney wd-100p px-1 ${isActive ? 'tx-white' : 'tx-muted'}`}
                    onClick={() => {
                      if (!holders.length) {
                        return;
                      }

                      if (holdersFilter.length === allHolders.length) {
                        setHoldersFilter([key]);
                      } else if (isActive) {
                        setHoldersFilter((prevState) => prevState.filter((item) => item !== key));
                      } else {
                        setHoldersFilter((prevState) => [...prevState, key]);
                      }
                    }}
                  >
                    <HolderIcon type={key} />
                    <div className="tx-capitalize mt-1">{key.toLowerCase()}</div>
                    <div>{holders.length ? count : '-'}</div>
                  </button>
                )
              })}
              <div className="d-none d-md-flex flex-column gap-2">
                <button
                  type="button"
                  className="btn btn-secondary bg-semi-transparent tx-12 tx-olney tx-muted px-5"
                  onClick={() => {
                    if (!holders.length) {
                      return;
                    }

                    setHoldersFilter([HolderFilter.LABELED]);
                  }}
                >
                  <div className="px-3">Show Labeled</div>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary bg-semi-transparent tx-12 tx-olney tx-muted px-5"
                  onClick={() => {
                    if (!holders.length) {
                      return;
                    }

                    setHoldersFilter(allHolders);
                  }}
                >
                  <div className="px-3">Show All</div>
                </button>
              </div>
            </div>

            <div className="d-flex d-md-none flex-row gap-2 wd-100p">
              <button
                type="button"
                className="btn btn-secondary bg-semi-transparent tx-12 tx-olney tx-muted flex-1"
                onClick={() => {
                  if (!holders.length) {
                    return;
                  }

                  setHoldersFilter([HolderFilter.LABELED]);
                }}
              >
                <div className="px-3">Show Labeled</div>
              </button>
              <button
                type="button"
                className="btn btn-secondary bg-semi-transparent tx-12 tx-olney tx-muted flex-1"
                onClick={() => {
                  if (!holders.length) {
                    return;
                  }

                  setHoldersFilter(allHolders);
                }}
              >
                <div className="px-3">Show All</div>
              </button>
            </div>

            {isLoading ? (
              <HoldersLoader />
            ) : (
              <div className="ht-300 ht-md-600 overflow-auto">
                <div className="table-responsive mt-3 pb-2" id="trade-history-table">
                  <table className="table">
                    <thead>
                      <tr className="border-muted-10 border-top">
                        <th className="mn-wd-200">Address</th>
                        <th className="mn-wd-200">Balance</th>
                        {/*<th className="mn-wd-130">*/}
                        {/*  <div className="d-flex">*/}
                        {/*    <InfoTooltip text="Change sn the number of these tokens in the portfolio over 7 days" />*/}
                        {/*    <span className="ms-1">Change 7D</span>*/}
                        {/*  </div>*/}
                        {/*</th>*/}
                        <th className="mn-wd-120">
                          <div className="d-flex">
                            <InfoTooltip text="Percentage of the total issue of tokens from this holder" />
                            <span className="ms-1">Supply</span>
                          </div>
                        </th>
                        <th className="mn-wd-200">
                          <div className="d-flex">
                            <InfoTooltip text="The amount of funds in the wallet without taking into account this token" />
                            <span className="ms-1">Wallet Balance</span>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredHolders.map((holder, index) => (
                        <TokenHolderItem
                          holder={holder}
                          token={token}
                          index={index + 1}
                          key={`token-${token.address}-holder-${holder.address}`}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
        {!isSubscriber && (
          <div>
            <div className="btn-group mb-3 wd-100p" role="group" aria-label="Basic example">
              {filters.map(({key}) => {
                return (
                  <button
                    type="button"
                    key={`token-holders-filter-${key}`}
                    className={`btn btn-outline-secondary tx-12 tx-olney wd-100p px-1 tx-primary`}
                  >
                    <HolderIcon type={key} />
                    <div className="tx-capitalize mt-1">{key.toLowerCase()}</div>
                    <div className="blur-5">1000</div>
                  </button>
                )
              })}
              <button
                type="button"
                className={`btn btn-outline-secondary tx-12 tx-olney tx-primary px-1 wd-100p`}
              >
                <div>Show All</div>
              </button>
            </div>

            <div className="border-1 border-solid border-secondary rounded-10 overflow-hidden pos-relative">
              <div className="blur-10">
                <div className="table-responsive mt-3 pb-2" id="trade-history-table">
                  <table className="table">
                    <thead>
                    <tr className="border-muted-10 border-top">
                      <th className="mn-wd-200">Address</th>
                      <th className="mn-wd-200">Balance</th>
                      {/*<th className="mn-wd-130">*/}
                      {/*  <div className="d-flex">*/}
                      {/*    <InfoTooltip text="Change sn the number of these tokens in the portfolio over 7 days" />*/}
                      {/*    <span className="ms-1">Change 7D</span>*/}
                      {/*  </div>*/}
                      {/*</th>*/}
                      <th className="mn-wd-120">
                        <div className="d-flex">
                          <InfoTooltip text="Percentage of the total issue of tokens from this holder" />
                          <span className="ms-1">Supply</span>
                        </div>
                      </th>
                      <th className="mn-wd-200">
                        <div className="d-flex">
                          <InfoTooltip text="The amount of funds in the wallet without taking into account this token" />
                          <span className="ms-1">Wallet Balance</span>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                      {holders.slice(0, 5).map((holder, index) => (
                        <TokenHolderItem
                          holder={holder}
                          token={token}
                          index={index + 1}
                          key={`token-${token.address}-holder-${holder.address}`}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pos-absolute absolute-item wd-100p ht-100p d-flex flex-column justify-content-center align-items-center p-4">
                <div className="tx-primary tx-18 tx-center">Holders filtering</div>
                <div className="tx-muted tx-14 tx-center">
                  To unlock holders filter you need<br />
                  to have a subscription
                </div>
                <PremiumAlert withButton withText={false} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default TokenHolders;

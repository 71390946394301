import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {HolderProfile, HolderProfileProfit, HolderProfileType} from "@stores/tokens-store";
import InfoTooltip from "@components/common/InfoTooltip";
import ProgressSlider from "@components/common/ProgressSlider";
import FormattedNumber from "@components/common/FormattedNumber";
import {getPercentage} from "@helpers/numbers";
import Preloader from "@components/common/Preloader";

interface HoldersMakingMoneyProps {
  data: HolderProfile[];
  isLoaded: boolean;
}

const HoldersMakingMoney = observer(({data, isLoaded}: HoldersMakingMoneyProps) => {
  const holdersAll = useMemo(() => {
    const result = data.filter((item) => item.holderType === HolderProfileType.WALLET).reduce((acc, item) => acc + item.amount, 0);
    return isNaN(result) ? 0 : result;
  }, [data]);

  const holdersAt = useMemo(() => {
    const result = getPercentage(data.filter((item) => item.holderType === HolderProfileType.WALLET && item.profit === HolderProfileProfit.AT).reduce((acc, item) => acc + item.amount, 0), holdersAll);
    return isNaN(result) ? 0 : result;
  }, [holdersAll, data]);

  const holdersOut = useMemo(() => {
    const result = getPercentage(data.filter((item) => item.holderType === HolderProfileType.WALLET && item.profit === HolderProfileProfit.OUT).reduce((acc, item) => acc + item.amount, 0), holdersAll);
    return isNaN(result) ? 0 : result;
  }, [holdersAll, data]);

  const holdersIn = useMemo(() => {
    const result = getPercentage(data.filter((item) => item.holderType === HolderProfileType.WALLET && item.profit === HolderProfileProfit.IN).reduce((acc, item) => acc + item.amount, 0), holdersAll);
    return isNaN(result) ? 0 : result;
  }, [holdersAll, data]);

  return (
    <div className="card wd-100p mb-3">
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Holders Making Money</span>
        <InfoTooltip text="In - the share of investors who are currently in profit on this token. At - share of investors, the average price of the token in the portfolio is approximately equal to the current. Out - the share of investors, which are in the negative for token at the moment" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>

      {!isLoaded && <Preloader inline />}
      {isLoaded && data.length ? (
        <>
          <div className="mt-3 d-flex">
            <FormattedNumber
              value={holdersIn}
              postfix="%"
              suffix="In"
              suffixClass="tx-white me-1"
              className="tx-success me-2"
            />
            <FormattedNumber
              value={holdersAt}
              postfix="%"
              suffix="At"
              suffixClass="tx-white me-1"
              className="tx-muted me-2"
            />
            <FormattedNumber
              value={holdersOut}
              postfix="%"
              suffix="Out"
              suffixClass="tx-white me-1"
              className="tx-danger me-2"
            />
          </div>

          <ProgressSlider value={[holdersIn, holdersAt, holdersOut]} className="mt-3" />
        </>
      ) : (<div className="tx-muted">🕔 We are working to make this data available as soon as the indexing process is complete. Please check back again soon for the latest numbers.</div>)}
    </div>
  );
});

export default HoldersMakingMoney;

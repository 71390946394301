export const exchanges = [
  {
    "chain": "ether",
    "data": [
      {
        "slug": "univ2",
        "factory": "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
        "router": [
          "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
          "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
          "0xef1c6e67703c7bd7107eed8303fbe6ec2554bf6b",
          "0xEf1c6E67703c7BD7107eed8303Fbe6EC2554BF6B",
          "0x1111111254EEB25477B68fb85Ed929f73A960582",
          "0x1111111254eeb25477b68fb85ed929f73a960582",
          "0xe66B31678d6C16E9ebf358268a790B763C133750",
          "0xe66b31678d6c16e9ebf358268a790b763c133750",
          "0x881D40237659C251811CEC9c364ef91dC08D300C",
          "0x881d40237659c251811cec9c364ef91dc08d300c",
          "0xA88800CD213dA5Ae406ce248380802BD53b47647",
          "0xa88800cd213da5ae406ce248380802bd53b47647",
          "0xDef1C0ded9bec7F1a1670819833240f027b25EfF",
          "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          "0x1111111254fb6c44bAC0beD2854e76F90643097d",
          "0x1111111254fb6c44bac0bed2854e76f90643097d",
          "0xA88800CD213dA5Ae406ce248380802BD53b47647",
          "0xa88800cd213da5ae406ce248380802bd53b47647",
          "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
          "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
          "0xA88800CD213dA5Ae406ce248380802BD53b47647",
          "0xa88800cd213da5ae406ce248380802bd53b47647",
          "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
          "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
          "0x84D99Aa569D93a9CA187D83734c8C4a519c4e9b1",
          "0x84d99aa569d93a9ca187d83734c8c4a519c4e9b1",
          "0xe8eA8bAE250028a8709A3841E0Ae1a44820d677b",
          "0xe8ea8bae250028a8709a3841e0ae1a44820d677b",
          "0xD1742B3C4fBB096990C8950fA635Aec75B30781A",
          "0xd1742b3c4fbb096990c8950fa635aec75b30781a",
          "0x11111112542D85B3EF69AE05771c2dCCff4fAa26",
          "0x11111112542d85b3ef69ae05771c2dccff4faa26",
          "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
          "0x3fc91a3afd70395cd496c647d5a6cc9d4b2b7fad",
          "0x3b3ae790Df4F312e745D270119c6052904FB6790",
          "0x3b3ae790df4f312e745d270119c6052904fb6790",
          "0x9008D19f58AAbD9eD0D60971565AA8510560ab41",
          "0x9008d19f58aabd9ed0d60971565aa8510560ab41",
          "0xe0C38b2a8D09aAD53f1C67734B9A95E43d5981c0",
          "0xe0c38b2a8d09aad53f1c67734b9a95e43d5981c0"
        ],
        "factoryBlock": 10008355,
        "isDefault": true,
        "name": "Uniswap",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://v2.info.uniswap.org",
        "urlSwap": "https://app.uniswap.org/#/swap",
        "logo": "uniswap.svg",
        "logoStyles": "",
        "version": "v2",
        "urlUnicrypt": "https://app.unicrypt.network/amm/uni-v2/pair/",
        "backgroundColor": "#ff2d79",
        "textColor": "#fff",
        "limitBotSupported": "true"
      },
      {
        "slug": "sushi",
        "factory": "0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac",
        "router": [
          "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f"
        ],
        "factoryBlock": 10745500,
        "isDefault": false,
        "name": "Sushiswap",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "0x795065dcc9f64b5614c407a6efdc400da6221fb0",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://analytics.sushi.com/",
        "urlSwap": "https://app.sushi.com/swap",
        "logo": "sushiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "https://app.unicrypt.network/amm/sushi-v1/pair/",
        "backgroundColor": "#3e3e7c",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "univ3",
        "factory": "0x1f98431c8ad98523631ae4a59f267346ea31f984",
        "router": [
          "0xE592427A0AEce92De3Edee1F18E0157C05861564",
          "0xe592427a0aece92de3edee1f18e0157c05861564",
          "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
          "0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45"
        ],
        "factoryBlock": 12369739,
        "isDefault": false,
        "name": "Uniswap",
        "alike": "univ3",
        "chainId": "ether",
        "pairDefault": "0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d",
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "urlInfo": "https://v3.info.uniswap.org",
        "urlSwap": "https://app.uniswap.org/#/swap",
        "logo": "uniswap2.svg",
        "logoStyles": "",
        "version": "v3",
        "urlUnicrypt": "",
        "backgroundColor": "#ff2d79",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "shiba",
        "factory": "0x115934131916c8b277dd010ee02de363c09d037c",
        "router": [
          "0x03f7724180aa6b939894b5ca4314783b0b36b329",
          "0x03f7724180AA6b939894B5Ca4314783B0b36b329"
        ],
        "factoryBlock": 12771547,
        "isDefault": false,
        "name": "Shibaswap",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://shibaswap.com",
        "urlSwap": "https://shibaswap.com/#/swap",
        "logo": "shibaswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ffa409",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "crodefi",
        "factory": "0x9deb29c9a4c7a88a3c0257393b7f3335338d9a9d",
        "router": [
          "0xceb90e4c17d626be0facd78b79c9c87d7ca181b3",
          "0xCeB90E4C17d626BE0fACd78b79c9c87d7ca181b3"
        ],
        "factoryBlock": 10828475,
        "isDefault": false,
        "name": "CroDefiswap",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://crypto.com/eea/defi-wallet",
        "urlSwap": "https://crypto.com/defi/swap/",
        "logo": "cryptodefi.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#002d74",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "kyber",
        "factory": "0x833e4083b7ae46cea85695c4f7ed25cdad8886de",
        "router": [
          "0x1c87257F5e8609940Bc751a07BB085Bb7f8cDBE6",
          "0x1c87257f5e8609940bc751a07bb085bb7f8cdbe6",
          "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5"
        ],
        "factoryBlock": 12178218,
        "isDefault": false,
        "name": "KyberSwap Classic",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": true,
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "logo": "kyberswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#31cb9e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "minty",
        "factory": "0xa3bcedadb23cfaa592ac42f5a18c98f98a37da77",
        "router": [
          "0xfe1f5bb11db3a9dead9d00ceb6f4e607b847d08e",
          "0XFE1F5BB11DB3A9DEAD9D00CEB6F4E607B847D08E"
        ],
        "factoryBlock": 13313783,
        "isDefault": false,
        "name": "Mintyswap",
        "alike": "",
        "chainId": "ether",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://mintyswap.com/",
        "urlSwap": "https://dex.mintyswap.com/#/swap",
        "logo": "minty.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#61e2a1",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xd36aba9ec96523b0a89886c76065852adfe2eb39",
        "__v": 0,
        "backgroundColor": "#eaa20a",
        "chainId": "ether",
        "inactive": false,
        "logo": "justmoney.svg",
        "name": "Just Money",
        "slug": "dexe2eb39",
        "textColor": "#fff",
        "factoryBlock": 13371699,
        "router": [
          "0x57884b46d815d7cF42C04Cf226728a0D787eF58d",
          "0x57884b46d815d7cf42c04cf226728a0d787ef58d"
        ],
        "urlInfo": "https://eth.justmoney.exchange/",
        "urlSwap": "https://eth.justmoney.exchange/"
      },
      {
        "factory": "0x26023843814cff92b8d75311d64d1c032b8b29f2",
        "__v": 0,
        "backgroundColor": "#008a81",
        "chainId": "ether",
        "inactive": false,
        "logo": "safemoon.svg",
        "name": "Safemoonswap",
        "slug": "dex8b29f2",
        "textColor": "#fff",
        "factoryBlock": 13114071,
        "router": [
          "0xcf7d4b75b7bccdb8b4f992fe05970680e2ee1a02",
          "0xcf7d4b75b7bccdb8b4f992fe05970680e2ee1a02"
        ],
        "urlInfo": "https://safemoon.net/",
        "urlSwap": "https://swap.safemoon.net/#/swap"
      },
      {
        "factory": "0xd34971bab6e5e356fd250715f5de0492bb070452",
        "__v": 0,
        "backgroundColor": "#2e17f2",
        "chainId": "ether",
        "inactive": false,
        "logo": "swapr.svg",
        "name": "Swapr",
        "slug": "dex070452",
        "textColor": "#fff",
        "factoryBlock": 11401277,
        "router": [
          "0xb9960d9bca016e9748be75dd52f02188b9d0829f",
          "0xb9960d9bca016e9748be75dd52f02188b9d0829f"
        ],
        "urlInfo": "https://dxstats.eth.limo/#/home",
        "urlSwap": "https://swapr.eth.limo/#/swap?chainId=1",
        "logoStyles": "{ background-color: #2e17f2; }"
      },
      {
        "factory": "0xd674b01e778cf43d3e6544985f893355f46a74a5",
        "__v": 0,
        "backgroundColor": "#ae9159",
        "chainId": "ether",
        "inactive": false,
        "logo": "empiredex.png",
        "name": "EmpireDEX",
        "slug": "dex6a74a5",
        "textColor": "#fff",
        "factoryBlock": 13563060,
        "urlInfo": "https://eth-analytics.empiredex.org/home",
        "urlSwap": "https://eth.empiredex.org/#/swap"
      },
      {
        "factory": "0x7cf1d51c25e9bcd023ebf318b99824121941ebcf",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "ether",
        "inactive": false,
        "logo": "ballswapper.png",
        "name": "Ballswapper",
        "slug": "dex41ebcf",
        "textColor": "#fff",
        "router": [
          "0x2776534Cf44f3338AdC1ec33412ef2Ed9Acff5FE",
          "0x2776534cf44f3338adc1ec33412ef2ed9acff5fe"
        ],
        "urlInfo": "https://ballswapper.com/",
        "urlSwap": "https://app.ballswapper.com/swap"
      },
      {
        "factory": "0x0388c1e0f210abae597b7de712b9510c6c36c857",
        "__v": 0,
        "backgroundColor": "#fbbb44",
        "chainId": "ether",
        "inactive": false,
        "logo": "luaswap.png",
        "name": "LuaSwap",
        "slug": "dex36c857",
        "textColor": "#fff",
        "router": [
          "0x1d5C6F1607A171Ad52EFB270121331b3039dD83e",
          "0x1d5c6f1607a171ad52efb270121331b3039dd83e"
        ],
        "urlInfo": "https://info.luaswap.org/home",
        "urlSwap": "https://app.luaswap.org/#/swap"
      },
      {
        "factory": "0x3823ac41b77e51bf0e6536ce465479cbdedcaea9",
        "__v": 0,
        "backgroundColor": "#f1e8e0",
        "chainId": "ether",
        "inactive": false,
        "logo": "bamboo.svg",
        "name": "Bamboo",
        "slug": "dexdcaea9",
        "textColor": "#000",
        "factoryBlock": 11777694,
        "router": [
          "0xa9190eb4b853db4538bbc6d3b0df5d5240e38fd3",
          "0xa9190eb4b853db4538bbc6d3b0df5d5240e38fd3"
        ],
        "urlInfo": "https://www.bamboodefi.com/pages/information",
        "urlSwap": "https://www.bamboodefi.com/pages/tokenvalue/eth/ethereum"
      },
      {
        "factory": "0x05a7e5f18113196795676538bf22db601890c07a",
        "__v": 0,
        "backgroundColor": "#040a80",
        "chainId": "ether",
        "inactive": false,
        "logo": "renaswap.svg",
        "name": "RenaSwap",
        "slug": "dex90c07a",
        "textColor": "#fff",
        "factoryBlock": 12385878,
        "router": [
          "0xb648c6f73fd94a39740348cd5a8c4a72d8e02926",
          "0xb648C6F73Fd94a39740348cD5A8C4a72d8E02926"
        ],
        "urlInfo": "https://rena.finance",
        "urlSwap": "https://rena.finance/#/renaswap"
      },
      {
        "factory": "0xf5d8e98ef1f66f1cab949a2555837fe755fc2d68",
        "__v": 0,
        "backgroundColor": "#e0077d",
        "chainId": "ether",
        "inactive": false,
        "logo": "polkabridgeamm.svg",
        "name": "PolkaBridge AMM",
        "slug": "dexfc2d68",
        "textColor": "#fff",
        "factoryBlock": 14295386,
        "router": [
          "0xff0e9437818258bdedd1aa374095968abc82a9e2",
          "0xff0e9437818258bdedd1aa374095968abc82a9e2"
        ],
        "urlInfo": "https://polkabridge.org/",
        "urlSwap": "https://swap.polkabridge.org/"
      },
      {
        "factory": "0x5c515455efb90308689579993c11a84fc41229c0",
        "__v": 0,
        "backgroundColor": "#FFF86B",
        "chainId": "ether",
        "inactive": false,
        "logo": "rareswap.svg",
        "name": "RareSwap",
        "slug": "dex1229c0",
        "textColor": "#1489F3",
        "factoryBlock": 14326777,
        "router": [
          "0x4bf3E2287D4CeD7796bFaB364C0401DFcE4a4f7F",
          "0x4bf3e2287d4ced7796bfab364c0401dfce4a4f7f"
        ],
        "urlInfo": "https://rareswap.app",
        "urlSwap": "https://rareswap.app/#/swap"
      },
      {
        "factory": "0xaca5afa30910da31bda70aa3496eeeae0d1bfe06",
        "__v": 0,
        "backgroundColor": "#d23d1c",
        "chainId": "ether",
        "inactive": false,
        "logo": "dojo.svg",
        "name": "Dojoswap",
        "slug": "dex1bfe06",
        "textColor": "#fff",
        "factoryBlock": 14372429,
        "router": [
          "0x34F33f910C6a133fB516919cA6aCb4b3182B3Fbc",
          "0x34f33f910c6a133fb516919ca6acb4b3182b3fbc"
        ],
        "urlInfo": "https://shibnobi.zendesk.com/hc/en-us",
        "urlSwap": "https://www.dojoswap.ninja/#/swap"
      },
      {
        "factory": "0xc480b33ee5229de3fbdfad1d2dcd3f3bad0c56c6",
        "__v": 0,
        "backgroundColor": "#3346ff",
        "chainId": "ether",
        "inactive": false,
        "logo": "integral.png",
        "name": "Integral",
        "slug": "dex0c56c6",
        "textColor": "#fff",
        "router": [
          "0x782534550e2553A42CDFf8D5a94066d8c7B6729B",
          "0x782534550e2553a42cdff8d5a94066d8c7b6729b"
        ],
        "urlInfo": "https://size.integral.link/pools",
        "urlSwap": "https://size.integral.link/swap"
      },
      {
        "factory": "0x91fae1bc94a9793708fbc66adcb59087c46dee10",
        "__v": 0,
        "backgroundColor": "#da291c",
        "chainId": "ether",
        "inactive": false,
        "logo": "radioshack.svg",
        "name": "RadioShack",
        "slug": "dex6dee10",
        "textColor": "#fff",
        "factoryBlock": 14418573,
        "router": [
          "0x3e445e3280C5747a188DB8d0aB7762838A50E4ff",
          "0x3e445e3280c5747a188db8d0ab7762838a50e4ff"
        ],
        "urlInfo": "https://www.radioshack.org/",
        "urlSwap": "https://app.radioshack.org/swap"
      },
      {
        "factory": "0x066a5cb7ddc6d55384e2f6ca13d5dd2cd2685cbd",
        "__v": 0,
        "backgroundColor": "#513d89",
        "chainId": "ether",
        "inactive": false,
        "logo": "concave.svg",
        "name": "Concave",
        "slug": "dex685cbd",
        "textColor": "#fff",
        "factoryBlock": 14498177,
        "router": [
          "0x0A3e1c20B5384eB97D2CCfF9a96bc91f0c77e7dB",
          "0x0a3e1c20b5384eb97d2ccff9a96bc91f0c77e7db"
        ],
        "urlInfo": "https://concave.lol/",
        "urlSwap": "https://app.concave.lol/swap"
      },
      {
        "factory": "0xa2f8f1fab81300c48208dc0ae540c6675d19f4cd",
        "__v": 0,
        "backgroundColor": "#ec649c",
        "chainId": "ether",
        "inactive": false,
        "logo": "pyeswap.svg",
        "name": "PYESwap",
        "slug": "dex19f4cd",
        "textColor": "#fff",
        "factoryBlock": 14757872,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0x4F71E29C3D5934A15308005B19Ca263061E99616",
          "0x4f71e29c3d5934a15308005b19ca263061e99616"
        ],
        "urlInfo": "https://eth.pyeswap.com/info",
        "urlSwap": "https://eth.pyeswap.com/swap"
      },
      {
        "factory": "0xb076b06f669e682609fb4a8c6646d2619717be4b",
        "__v": 0,
        "backgroundColor": "#ededef",
        "chainId": "ether",
        "inactive": false,
        "logo": "fraxfinance.png",
        "name": "Frax Finance",
        "slug": "dex17be4b",
        "textColor": "#000",
        "factoryBlock": 14775229,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0x1C6cA5DEe97C8C368Ca559892CCce2454c8C35C7",
          "0x1c6ca5dee97c8c368ca559892ccce2454c8c35c7"
        ],
        "urlInfo": "https://app.frax.finance",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "factory": "0x16789fadac0ecb8f96c579edcc53ef6fd1269359",
        "__v": 0,
        "backgroundColor": "#FFF86B",
        "chainId": "ether",
        "inactive": false,
        "logo": "rareswap.svg",
        "name": "RareSwap (DegenSwap)",
        "slug": "dex269359",
        "textColor": "#1489F3",
        "factoryBlock": 14768939,
        "router": [
          "0x5244d856A383fE3cc1339368107B8698B65FFAb8",
          "0x5244d856a383fe3cc1339368107b8698b65ffab8"
        ],
        "urlInfo": "https://rareswap.app",
        "urlSwap": "https://rareswap.app/#/swap"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Stable",
        "slug": "dodov2stable",
        "textColor": "#000",
        "factoryBlock": 12269078,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x6fddb76c93299d985f4d3fc7ac468f9a168577a4",
        "chainId": "ether"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Private",
        "slug": "dodov2private",
        "textColor": "#000",
        "factoryBlock": 13397058,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x5336ede8f971339f6c0e304c66ba16f1296a2fbe",
        "chainId": "ether"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Zoo",
        "slug": "dodov1",
        "textColor": "#000",
        "factoryBlock": 10613640,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x3a97247df274a17c59a3bd12735ea3fcdfb49950",
        "chainId": "ether"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Vendor Machine",
        "slug": "dodov2public",
        "textColor": "#000",
        "factoryBlock": 11704651,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x72d220ce168c4f361dd4dee5d826a01ad8598f6c",
        "chainId": "ether"
      },
      {
        "factory": "0x5f1dddbf348ac2fbe22a163e30f99f9ece3dd50a",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "ether",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex3dd50a",
        "textColor": "#fff",
        "factoryBlock": 14932476,
        "router": [
          "0xC1e7dFE73E1598E3910EF4C7845B68A9Ab6F4c83",
          "0xc1e7dfe73e1598e3910ef4c7845b68a9ab6f4c83"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "alike": "univ3"
      },
      {
        "factory": "0xb9a089fa07b4d92bc3cffa4158e969c2e99654e8",
        "__v": 0,
        "backgroundColor": "#aaff01",
        "chainId": "ether",
        "inactive": false,
        "logo": "shibnobi.svg",
        "name": "Shibnobi Swap",
        "slug": "dex9654e8",
        "textColor": "#000",
        "factoryBlock": 14952248,
        "router": [
          "0xB890198a4107091Cc228B67129972Be9954dffdf",
          "0xb890198a4107091cc228b67129972be9954dffdf"
        ],
        "urlInfo": "https://shibnobi.com",
        "urlSwap": "https://swap.shibnobi.com"
      },
      {
        "factory": "0xee3e9e46e34a27dc755a63e2849c9913ee1a06e2",
        "__v": 0,
        "backgroundColor": "#0394fb",
        "chainId": "ether",
        "inactive": false,
        "logo": "versedex.png",
        "name": "Verse DEX",
        "slug": "dex1a06e2",
        "textColor": "#fff",
        "factoryBlock": 14931469,
        "router": [
          "0xb4b0ea46fe0e9e8eab4afb765b527739f2718671",
          "0xB4B0ea46Fe0E9e8EAB4aFb765b527739F2718671"
        ],
        "urlInfo": "https://analytics.verse.bitcoin.com/",
        "urlSwap": "https://verse.bitcoin.com/"
      },
      {
        "factory": "0x1e895bfe59e3a5103e8b7da3897d1f2391476f3c",
        "__v": 0,
        "backgroundColor": "#ff007a",
        "chainId": "ether",
        "inactive": false,
        "logo": "dooarswap.svg",
        "name": "Dooarswap",
        "slug": "dex476f3c",
        "textColor": "#fff",
        "factoryBlock": 15088146,
        "router": [
          "0x53e0e51b5Ed9202110D7Ecd637A4581db8b9879F",
          "0x53e0e51b5ed9202110d7ecd637a4581db8b9879f"
        ],
        "urlInfo": "https://dooarswap.notdao.io",
        "urlSwap": "https://dooarswap.notdao.io/#/swap"
      },
      {
        "factory": "0x35113a300ca0d7621374890abfeac30e88f214b1",
        "__v": 0,
        "backgroundColor": "#8131ea",
        "chainId": "ether",
        "inactive": false,
        "logo": "saitaswap.png",
        "name": "Saitaswap",
        "slug": "dexf214b1",
        "textColor": "#fff",
        "factoryBlock": 15210780,
        "router": [
          "0x0c17e776CD218252ADFca8D4e761D3fe757e9778",
          "0x0c17e776cd218252adfca8d4e761d3fe757e9778"
        ],
        "urlInfo": "https://www.saitamatoken.com/saitapro/",
        "urlSwap": "https://dex.saita.pro/"
      },
      {
        "factory": "0x1c758af0688502e49140230f6b0ebd376d429be5",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "ether",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Classic",
        "slug": "dex429be5",
        "textColor": "#fff",
        "router": [
          "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0",
          "0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0x7fa13890f6c99e7b91ba1d1f4922e6bb68035c0b",
        "__v": 0,
        "backgroundColor": "#672611",
        "chainId": "ether",
        "inactive": false,
        "logo": "luchowswap.png",
        "name": "Luchowswap",
        "slug": "dex035c0b",
        "textColor": "#fff",
        "router": [
          "0x7aB3bcC282b7000969A7551B1549105A809E27dF",
          "0x7ab3bcc282b7000969a7551b1549105a809e27df"
        ],
        "urlInfo": "https://bsc.luchowswap.com/info",
        "urlSwap": "https://bsc.luchowswap.com/swap"
      },
      {
        "factory": "0x71bcc0bf974aae8cc55d033c17d50486fc77e3ae",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "ether",
        "inactive": false,
        "logo": "spicetrade.jpg",
        "name": "Spice Trade",
        "slug": "dex77e3ae",
        "textColor": "#fff",
        "factoryBlock": 15290148,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x3018234c038d524147ff47148baa8381306f28cf"
        ],
        "urlInfo": "https://app.spicetrade.ai/",
        "urlSwap": "https://swap.spicetrade.ai/swap"
      },
      {
        "factory": "0x43ec799eadd63848443e2347c49f5f52e8fe0f6f",
        "__v": 0,
        "backgroundColor": "#fff",
        "chainId": "ether",
        "inactive": false,
        "logo": "fraxfinance.png",
        "name": "Fraxswap",
        "slug": "dexfe0f6f",
        "textColor": "#000",
        "factoryBlock": 15373819,
        "router": [
          "0xC14d550632db8592D1243Edc8B95b0Ad06703867",
          "0xc14d550632db8592d1243edc8b95b0ad06703867",
          "0x25e9acA5951262241290841b6f863d59D37DC4f0",
          "0x25e9aca5951262241290841b6f863d59d37dc4f0"
        ],
        "urlInfo": "https://app.frax.finance/",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "factory": "0x28aaf78f6d62a9c9e2335287c96b615754382d96",
        "__v": 0,
        "backgroundColor": "#612180",
        "chainId": "ether",
        "inactive": false,
        "logo": "despace.svg",
        "name": "Despace",
        "slug": "dex382d96",
        "textColor": "#fff",
        "factoryBlock": 15296980,
        "urlInfo": "https://despace.app/",
        "urlSwap": "https://despace.app/swap",
        "router": [
          "0x9D555D668A774C0919E4c53AE9ab6Eb10d1116ce",
          "0x9d555d668a774c0919e4c53ae9ab6eb10d1116ce"
        ]
      },
      {
        "factory": "0x6435f8ebe37b5d3c5e2f6f0bca27264cba6b8ed2",
        "__v": 0,
        "backgroundColor": "#1ba895",
        "chainId": "ether",
        "inactive": false,
        "logo": "mintydao.png",
        "name": "MintyDAO",
        "slug": "dex6b8ed2",
        "textColor": "#fff",
        "factoryBlock": 15519060,
        "router": [
          "0x9aB8E17bFdE144F0Ca46D81ED1144616d6433DA3",
          "0x9ab8e17bfde144f0ca46d81ed1144616d6433da3"
        ],
        "urlInfo": "https://mintydao.io",
        "urlSwap": "https://defi.mintydao.io/swap/"
      },
      {
        "factory": "0x1097053fd2ea711dad45caccc45eff7548fcb362",
        "router": [
          "0xeff92a263d31888d860bd50809a8d171709b7b1c"
        ],
        "factoryBlock": 15614590,
        "__v": 0,
        "chainId": "ether",
        "slug": "dexfcb362",
        "name": "Pancakeswap",
        "alike": "",
        "inactive": false,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "logo": "pancakeswap.png",
        "urlInfo": "https://pancakeswap.finance/info?chainId=1",
        "urlSwap": "https://pancakeswap.finance/swap?chainId=1",
        "pairDefault": "",
        "backgroundColor": "#f8b612",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xca1c4e582211f56c687f20e09ef6f59f7f127fe6",
        "__v": 0,
        "backgroundColor": "#1263ff",
        "chainId": "ether",
        "inactive": false,
        "logo": "playground.png",
        "name": "Playground",
        "slug": "dex127fe6",
        "textColor": "#fff",
        "factoryBlock": 15855232,
        "router": [
          "0xd0c2ce21b55fc153e1298455276418D8F2fA6431",
          "0xd0c2ce21b55fc153e1298455276418d8f2fa6431"
        ],
        "urlInfo": "https://playground.finance/",
        "urlSwap": "https://app.playground.finance/"
      },
      {
        "factory": "0x777de5fe8117caaa7b44f396e93a401cf5c9d4d6",
        "__v": 0,
        "alike": "velodrome",
        "backgroundColor": "#695aff",
        "chainId": "ether",
        "inactive": false,
        "logo": "solidly.png",
        "name": "Solidly",
        "slug": "dexc9d4d6",
        "textColor": "#fff",
        "factoryBlock": 16303991,
        "router": [
          "0x77784f96C936042A3ADB1dD29C91a55EB2A4219f",
          "0x77784f96c936042a3adb1dd29c91a55eb2a4219f"
        ],
        "urlInfo": "https://solidly.com/",
        "urlSwap": "https://solidly.com/swap/"
      },
      {
        "factory": "0x6c565c5bbdc7f023cae8a2495105a531caac6e54",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "ether",
        "inactive": false,
        "logo": "groveswap.png",
        "name": "GroveSwap",
        "slug": "dexac6e54",
        "textColor": "#fff",
        "router": [
          "0x3711020351da65a99eb9a821Bb7706E193C9aaa0",
          "0x3711020351da65a99eb9a821bb7706e193c9aaa0"
        ],
        "urlInfo": "https://www.grovecoin.io/groveswap",
        "urlSwap": "https://app.groveswap.io/swap"
      },
      {
        "factory": "0x3711020351da65a99eb9a821bb7706e193c9aaa0",
        "__v": 0,
        "backgroundColor": "#97a452",
        "chainId": "ether",
        "inactive": false,
        "logo": "groveswap.png",
        "name": "Groveswap",
        "slug": "dexa910cb",
        "textColor": "#fff",
        "factoryBlock": 16314423,
        "router": [
          "0xe4d96cf7f47656200fbc3b6110dc8e785216ef0e",
          "0xe4D96Cf7f47656200FBC3b6110DC8E785216EF0E"
        ],
        "urlInfo": "https://dashboard.groveswap.io",
        "urlSwap": "https://app.groveswap.io/swap"
      },
      {
        "factory": "0xa407f4bac1d84eec6468661b7d3bbf839f45c2ca",
        "__v": 0,
        "backgroundColor": "#3a90f2",
        "chainId": "ether",
        "inactive": false,
        "logo": "bionicswap.png",
        "name": "Bionic-Swap",
        "slug": "dex45c2ca",
        "textColor": "#fff",
        "factoryBlock": 16428855,
        "router": [
          "0xa407F4Bac1D84eEc6468661b7d3Bbf839f45c2ca",
          "0xa407f4bac1d84eec6468661b7d3bbf839f45c2ca"
        ],
        "urlInfo": "https://bionic-inu.vercel.app/",
        "urlSwap": "https://bionic-inu.vercel.app/"
      },
      {
        "factory": "0xe48aee124f9933661d4dd3eb265fa9e153e32cbe",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "ether",
        "inactive": false,
        "logo": "antfarmfinance.png",
        "name": "Antfarm Finance",
        "slug": "dexe32cbe",
        "textColor": "#fff",
        "factoryBlock": 16421686,
        "router": [
          "0x6D9f0eb21D77C6d24bE49a579508471E937D5418",
          "0x6d9f0eb21d77c6d24be49a579508471e937d5418"
        ],
        "urlInfo": "https://app.antfarm.finance/",
        "urlSwap": "https://app.antfarm.finance/trade"
      },
      {
        "factory": "0x7de800467afce442019884f51a4a1b9143a34fac",
        "__v": 0,
        "backgroundColor": "#fff",
        "chainId": "ether",
        "inactive": false,
        "logo": "xchange.png",
        "name": "Xchange",
        "slug": "dexa34fac",
        "textColor": "#000",
        "factoryBlock": 16532219,
        "router": [
          "0x7DE8063E9fB43321d2100e8Ddae5167F56A50060",
          "0x7de8063e9fb43321d2100e8ddae5167f56a50060"
        ],
        "urlInfo": "https://app.x7.finance",
        "urlSwap": "https://app.x7.finance/#/swap"
      },
      {
        "factory": "0x460b2005b3318982feada99f7ebf13e1d6f6effe",
        "__v": 0,
        "backgroundColor": "#3db02d",
        "chainId": "ether",
        "inactive": false,
        "logo": "pepepal.png",
        "name": "Pepepal",
        "slug": "dexf6effe",
        "textColor": "#fff",
        "router": [
          "0x724F7c8a9Cb973Ee7C0689a643D29908bC3865b5",
          "0x724f7c8a9cb973ee7c0689a643d29908bc3865b5"
        ],
        "urlInfo": "https://pepepal.com/",
        "urlSwap": "https://pepepal.com/"
      },
      {
        "factory": "0x2164de1e537a2e14ffa80dd0b127e5f1b044c9e5",
        "__v": 0,
        "backgroundColor": "#ffc300",
        "chainId": "ether",
        "inactive": false,
        "logo": "safuswap.png",
        "name": "SafuSwap",
        "slug": "dex44c9e5",
        "textColor": "#fff",
        "router": [
          "0x161c77b4618979A73D49E34d951B5BdC2d12C206",
          "0x161c77b4618979a73d49e34d951b5bdc2d12c206"
        ],
        "urlInfo": "https://www.safu.io/",
        "urlSwap": "https://www.safu.io/swap"
      },
      {
        "factory": "0x7753f36e711b66a0350a753aba9f5651bae76a1d",
        "__v": 0,
        "backgroundColor": "#00e9b1",
        "chainId": "ether",
        "inactive": false,
        "logo": "smardex.png",
        "name": "Smardex",
        "slug": "dexe76a1d",
        "textColor": "#000",
        "factoryBlock": 16793955,
        "router": [
          "0x9a5132e149c547F254C73226DA7f770d43D9EA44",
          "0x9a5132e149c547f254c73226da7f770d43d9ea44"
        ],
        "urlInfo": "https://smardex.io/analytics",
        "urlSwap": "https://smardex.io/swap",
        "alike": "smardex"
      },
      {
        "factory": "0x90e00ace148ca3b23ac1bc8c240c2a7dd9c2d7f5",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve",
        "slug": "dexc2d7f5",
        "textColor": "#fff",
        "alike": "curve",
        "factoryBlock": 12195750,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x519c0dd454aa15b0c264fb372056865de70bf0d4",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve",
        "slug": "dex0bf0d4",
        "textColor": "#fff",
        "alike": "curve",
        "factoryBlock": 16095569,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x0959158b6040d32d04c301a72cbfd6b39e21c9ae",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Metapool",
        "slug": "dex21c9ae",
        "textColor": "#fff",
        "alike": "curveMetapoolFactory",
        "factoryBlock": 11942404,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0xb9fc157394af804a3578134a6585c0dc9cc990d4",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Factory",
        "slug": "dexc990d4",
        "textColor": "#fff",
        "alike": "curveFactory",
        "factoryBlock": 12903979,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x8f942c20d02befc377d41445793068908e2250d0",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dex2250d0",
        "textColor": "#fff",
        "alike": "curveCryptoRegistry",
        "factoryBlock": 13986752,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0xf18056bbd320e96a48e3fbf8bc061322531aac99",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "ether",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dex1aac99",
        "textColor": "#fff",
        "alike": "curveCryptoFactory",
        "factoryBlock": 14005321,
        "urlInfo": "https://curve.fi/#/ethereum/pools",
        "urlSwap": "https://curve.fi/#/ethereum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x0bfbcf9fa4f9c56b0f40a671ad40e0805a091865",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#f8b612",
        "chainId": "ether",
        "inactive": false,
        "logo": "pancakeswap.png",
        "name": "Pancakeswap",
        "slug": "dex091865",
        "textColor": "#5a5a5a",
        "router": [
          "0x1b81d678ffb9c0263b24a97847620c99d213eb14",
          "0x1b81D678ffb9C0263b24A97847620C99d213eB14"
        ],
        "version": "v3",
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "limitBotSupported": "false",
        "urlInfo": "https://pancakeswap.finance/?chain=eth",
        "urlSwap": "https://pancakeswap.finance/swap?chain=eth"
      },
      {
        "factory": "0x2e645e73e0a2443be4a0adac85126b402953b928",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "ether",
        "inactive": false,
        "logo": "peppswap.png",
        "name": "Peppswap",
        "slug": "dex53b928",
        "textColor": "#fff",
        "router": [],
        "urlInfo": "https://pepp.fans/",
        "urlSwap": "https://app.pepp.fans/#/swap"
      },
      {
        "factory": "0x1404ddd5bc09a6375c7f11516bb5601625f231a3",
        "__v": 0,
        "backgroundColor": "#cca001",
        "chainId": "ether",
        "inactive": false,
        "logo": "inediblex.png",
        "name": "InedibleX",
        "slug": "dexf231a3",
        "textColor": "#000000",
        "router": [
          "0x2f24FF4b205B24E352b05139D49dca2e4CC5D171",
          "0x2f24ff4b205b24e352b05139d49dca2e4cc5d171"
        ],
        "urlInfo": "https://www.inediblecoin.com/",
        "urlSwap": "https://app.inediblex.com/swap"
      }
    ]
  },
  {
    "chain": "bsc",
    "data": [
      {
        "slug": "wault",
        "factory": "0xb42e3fe71b7e0673335b3331b3e1053bd9822570",
        "router": [
          "0xD48745E39BbED146eEC15b79cBF964884F9877c2",
          "0xd48745e39bbed146eec15b79cbf964884f9877c2"
        ],
        "factoryBlock": 6657566,
        "isDefault": false,
        "name": "Wault",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://app.wault.finance/bsc",
        "urlSwap": "https://swap.wault.finance/bsc/#/swap",
        "logo": "wault.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#4bafaa",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "slug": "mdex",
        "factory": "0x3cd1c46068daea5ebb0d3f55f6915b10648062b8",
        "router": [
          "0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "Mdex",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://mdex.com/",
        "urlSwap": "https://ht.mdex.com/#/swap",
        "logo": "mdex.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#0d184e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x31affd875e9f68cd6cd12cee8943566c9a4bba13",
        "__v": 0,
        "backgroundColor": "#378174",
        "chainId": "bsc",
        "inactive": false,
        "logo": "elkfinance.svg",
        "name": "Elk Finance",
        "slug": "dex4bba13",
        "textColor": "#fff",
        "factoryBlock": 10090109,
        "router": [
          "0xA63B831264183D755756ca9AE5190fF5183d65D6",
          "0xa63b831264183d755756ca9ae5190ff5183d65d6"
        ],
        "urlInfo": "https://bsc-info.elk.finance/home",
        "urlSwap": "https://app.elk.finance/#/swap/"
      },
      {
        "slug": "sheep",
        "factory": "0x571521f8c16f3c4ed5f2490f19187ba7a5a3cbdf",
        "router": [
          "0xc1d62747a2DdD3a2C228842ED7A469D5c1B840Ab",
          "0xc1d62747a2ddd3a2c228842ed7a469d5c1b840ab"
        ],
        "factoryBlock": 11640600,
        "isDefault": false,
        "name": "SheepDex",
        "alike": "univ3",
        "chainId": "bsc",
        "pairDefault": "TRUE",
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "urlInfo": "https://sheepdex.org/",
        "urlSwap": "https://sheepdex.org/#/swap",
        "logo": "sheepdex.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#0076ff",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "kyber",
        "factory": "0x878dfe971d44e9122048308301f540910bbd934c",
        "router": [
          "0x78df70615ffc8066cc0887917f2Cd72092C86409",
          "0x78df70615ffc8066cc0887917f2cd72092c86409"
        ],
        "factoryBlock": 10475002,
        "isDefault": false,
        "name": "KyberSwap Classic",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": true,
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "logo": "kyberswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#31cb9e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "firebird",
        "factory": "0x5de74546d3b86c8df7feec30253865e1149818c8",
        "router": [
          "0xF6fa9Ea1f64f1BBfA8d71f7f43fAF6D45520bfac",
          "0xf6fa9ea1f64f1bbfa8d71f7f43faf6d45520bfac"
        ],
        "factoryBlock": 7883044,
        "isDefault": false,
        "name": "Firebird",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://firebird.finance/",
        "urlSwap": "https://app.firebird.finance/",
        "logo": "firebird.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#db5b30",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "jliquidity",
        "factory": "0x553990f2cba90272390f62c5bdb1681ffc899675",
        "router": [
          "0xbd67d157502a23309db761c41965600c2ec788b2"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "JustLiquidiy",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://dexbeta.julswap.com/",
        "urlSwap": "https://dexbeta.julswap.com/",
        "logo": "justliquidity.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "https://app.unicrypt.network/amm/julswap-v1/pair/",
        "backgroundColor": "#bd1616",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x585b0b05cf12d08e44dd98889e0598d61bcfdbcd",
        "__v": 0,
        "backgroundColor": "#3d5af1",
        "chainId": "bsc",
        "inactive": false,
        "logo": "swapx.svg",
        "name": "SwapX",
        "slug": "dexcfdbcd",
        "textColor": "#fff",
        "factoryBlock": 10621813,
        "router": [
          "0xe7EE9E2d07AD78243f801C3a623029c98194A5C1",
          "0xe7ee9e2d07ad78243f801c3a623029c98194a5c1"
        ],
        "urlInfo": "https://swapx.co",
        "urlSwap": "https://swapx.co/swap"
      },
      {
        "slug": "mochi",
        "factory": "0xcbac17919f7aad11e623af4fea98b10b84802eac",
        "router": [
          "0xa8cC4B94624B31465e777f06ee3591fd5AFe5821",
          "0xa8cc4b94624b31465e777f06ee3591fd5afe5821"
        ],
        "factoryBlock": 5270228,
        "isDefault": false,
        "name": "Mochiswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://bsc.mochiswap.io/",
        "urlSwap": "https://bsc.mochiswap.io/swap",
        "logo": "mochiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#fe019a",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xfc2604a3bcb3ba6016003806a288e7abf75c8aa3",
        "__v": 0,
        "backgroundColor": "#f1e8e0",
        "chainId": "bsc",
        "inactive": false,
        "logo": "bamboo.svg",
        "name": "Bamboo",
        "slug": "dex5c8aa3",
        "textColor": "#000",
        "factoryBlock": 9150889,
        "router": [
          "0xBA9DF2c143C502A433d6A876C1a291C9FC940cf9",
          "0xba9df2c143c502a433d6a876c1a291c9fc940cf9"
        ],
        "urlInfo": "https://www.bamboodefi.com/pages/information",
        "urlSwap": "https://www.bamboodefi.com/pages/tokenvalue/eth/ethereum"
      },
      {
        "factory": "0x73d9f93d53505cb8c4c7f952ae42450d9e859d10",
        "__v": 0,
        "backgroundColor": "#85c559",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dinosaureggs.png",
        "name": "Dinosaur eggs",
        "slug": "dex859d10",
        "textColor": "#fff",
        "factoryBlock": 11363467,
        "urlInfo": "https://dsgmetaverse.com/#/",
        "urlSwap": "https://dsgmetaverse.com/#/swap"
      },
      {
        "slug": "sushi",
        "factory": "0xc35dadb65012ec5796536bd9864ed8773abc74c4",
        "router": [
          "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "Sushiswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://analytics-bsc.sushi.com/",
        "urlSwap": "https://app.sushi.com/swap",
        "logo": "sushiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#3e3e7c",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x0f2c36cce00036dfd3e3569061adfe935a7237ce",
        "__v": 0,
        "backgroundColor": "#0098ff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "hodlx.svg",
        "name": "Hodlx",
        "slug": "dex7237ce",
        "textColor": "#fff",
        "factoryBlock": 16573759,
        "router": [
          "0xd4dd4bf4abe7454a1C04199321AAeFD85A7beAE1",
          "0xd4dd4bf4abe7454a1c04199321aaefd85a7beae1"
        ],
        "urlInfo": "https://hodlx.exchange",
        "urlSwap": "https://hodlx.exchange/swap"
      },
      {
        "slug": "coinone",
        "factory": "0xb8da6677658b095b43a5725c4ecb6c70125a52e6",
        "router": [
          "0x867254aF06801C21C5812690a5c3FAd82537C6Dc",
          "0x867254af06801c21c5812690a5c3fad82537c6dc"
        ],
        "factoryBlock": 11180502,
        "isDefault": false,
        "name": "CoinOne",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.coinoneswap.finance/",
        "urlSwap": "https://www.coinoneswap.finance/swap",
        "logo": "coinone.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#425bcc",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xe0636f192a88de6f1c9ed1a6a0f265c9775c8596",
        "__v": 0,
        "backgroundColor": "#aa3e80",
        "chainId": "bsc",
        "inactive": false,
        "logo": "niob.svg",
        "name": "Niobswap",
        "slug": "dex5c8596",
        "textColor": "#fff",
        "factoryBlock": 13680077,
        "urlInfo": "https://niob.app/",
        "urlSwap": "https://niob.app/trade/exchange"
      },
      {
        "factory": "0x2fcd5b3b7a5088509babc9910ed2f1b6fe5775b6",
        "__v": 0,
        "backgroundColor": "#eba453",
        "chainId": "bsc",
        "inactive": false,
        "logo": "unitedfarmers.png",
        "name": "United farmers",
        "slug": "dex5775b6",
        "textColor": "#fff",
        "factoryBlock": 13674130,
        "router": [
          "0xad02320a81606fbB760C32e065495A8ddbf322A8",
          "0xad02320a81606fbb760c32e065495a8ddbf322a8"
        ],
        "urlInfo": "https://app.unitedfarmers.finance/",
        "urlSwap": "https://app.unitedfarmers.finance/swap"
      },
      {
        "factory": "0x8ba1a4c24de655136ded68410e222cca80d43444",
        "__v": 0,
        "backgroundColor": "#7000ff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "sphynx.svg",
        "name": "Sphynx",
        "slug": "dexd43444",
        "textColor": "#fff",
        "factoryBlock": 12033321,
        "router": [
          "0x0c8094a69e8e44404371676f07b2c32923b5699c",
          "0x0c8094a69e8e44404371676f07b2c32923b5699c"
        ],
        "urlInfo": "https://sphynxlabs.co/",
        "urlSwap": "https://thesphynx.co/swap/56",
        "logoStyles": "{ background-color: #202020; }"
      },
      {
        "slug": "jet",
        "factory": "0x0eb58e5c8aa63314ff5547289185cc4583dfcbd5",
        "router": [
          "0xBe65b8f75B9F20f4C522e0067a3887FADa714800",
          "0xbe65b8f75b9f20f4c522e0067a3887fada714800"
        ],
        "factoryBlock": 7091833,
        "isDefault": false,
        "name": "Jetswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://jetswap.finance/",
        "urlSwap": "https://exchange.jetswap.finance/#/swap",
        "logo": "jetswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#fec803",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x4233ad9b8b7c1ccf0818907908a7f0796a3df85f",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "if.png",
        "name": "Impossible Finance",
        "slug": "dex3df85f",
        "textColor": "#fff",
        "factoryBlock": 13466387,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x56f6ca0a3364fa3ac9f0e8e9858b2966cdf39d03"
        ],
        "urlInfo": "https://impossible.finance/",
        "urlSwap": "https://app.impossible.finance/swap"
      },
      {
        "factory": "0xf02df0238a1d1d06553bf9942e9161c59acf42db",
        "__v": 0,
        "backgroundColor": "#ffa900",
        "chainId": "bsc",
        "inactive": false,
        "logo": "extendswap.png",
        "name": "Extendswap",
        "slug": "dexcf42db",
        "textColor": "#fff",
        "factoryBlock": 8090032,
        "router": [
          "0xD1E15281258959B3dE7CbC119bB230a668cD92A6",
          "0xd1e15281258959b3de7cbc119bb230a668cd92a6"
        ],
        "urlInfo": "https://info.extendswap.com/home",
        "urlSwap": "https://www.extendswap.com/#/swap"
      },
      {
        "slug": "pancakev2",
        "factory": "0xca143ce32fe78f1f7019d7d551a6402fc5350c73",
        "router": [
          "0x10ed43c718714eb63d5aa57b78b54704e256024e",
          "0xB099ED146fAD4d0dAA31E3810591FC0554aF62bB",
          "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
          "0x10ED43C718714eb63d5aA57B78B54704E256024E",
          "0x1a1ec25DC08e98e5E93F1104B5e5cdD298707d31",
          "0x1111111254fb6c44bAC0beD2854e76F90643097d",
          "0x13f4EA83D0bd40E75C8222255bc855a974568Dd4",
          "0xb099ed146fad4d0daa31e3810591fc0554af62bb",
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          "0x10ed43c718714eb63d5aa57b78b54704e256024e",
          "0x1a1ec25dc08e98e5e93f1104b5e5cdd298707d31",
          "0x1111111254fb6c44bac0bed2854e76f90643097d",
          "0x13f4ea83d0bd40e75c8222255bc855a974568dd4",
          "0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39",
          "0xb45a2dda996c32e93b8c47098e90ed0e7ab18e39",
          "0x6352a56caadC4F1E25CD6c75970Fa768A3304e64",
          "0x6352a56caadc4f1e25cd6c75970fa768a3304e64",
          "0x9696969e67b37403196A3519EDC176276714a724",
          "0x9696969e67b37403196a3519edc176276714a724"
        ],
        "factoryBlock": 6810706,
        "isDefault": false,
        "name": "Pancakeswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://pancakeswap.info",
        "urlSwap": "https://pancakeswap.finance/swap",
        "logo": "pancakeswap.png",
        "logoStyles": "",
        "version": "v2",
        "urlUnicrypt": "https://app.unicrypt.network/amm/pancake-v2/pair/",
        "backgroundColor": "#f8b612",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x80908495143ff92fb497a937b681a1d44021ec62",
        "__v": 0,
        "backgroundColor": "#caefff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "swappery.svg",
        "name": "The Swappery",
        "slug": "dex21ec62",
        "textColor": "#000000",
        "factoryBlock": 15896797,
        "router": [
          "0x38326a16FDCDEb681d47C3DdfebE4911c7019A86",
          "0x38326a16fdcdeb681d47c3ddfebe4911c7019a86"
        ],
        "urlInfo": "https://www.the-swappery.io/",
        "urlSwap": "https://www.the-swappery.io/swap"
      },
      {
        "factory": "0x267cd46ff9d8cae04e5106c9be56a74fb0a97485",
        "__v": 0,
        "backgroundColor": "#1b2137",
        "chainId": "bsc",
        "inactive": false,
        "logo": "reflectoswap.svg",
        "name": "Reflecto",
        "slug": "dexa97485",
        "textColor": "#fff",
        "factoryBlock": 16429176,
        "router": [
          "0x2Cc9C6096deDE93f166c9d7c7ad620d8DbaF5a56",
          "0x2cc9c6096dede93f166c9d7c7ad620d8dbaf5a56"
        ],
        "urlInfo": "https://reflectocoin.com",
        "urlSwap": "https://reflectoswap.com"
      },
      {
        "factory": "0xac653ce27e04c6ac565fd87f18128ad33ca03ba2",
        "__v": 0,
        "backgroundColor": "#44c4e2",
        "chainId": "bsc",
        "inactive": false,
        "logo": "hyperjump.png",
        "name": "Hyperjump",
        "slug": "dexa03ba2",
        "textColor": "#fff",
        "factoryBlock": 2093701,
        "router": [
          "0x3bc677674df90a9e5d741f28f6ca303357d0e4ec",
          "0x3bc677674df90a9e5d741f28f6ca303357d0e4ec"
        ],
        "urlInfo": "https://bsc.hyperjump.app/",
        "urlSwap": "https://bsc.hyperjump.app/swap"
      },
      {
        "factory": "0x8a01d7f2e171c222372f0962bea84b8eb5a3368e",
        "__v": 0,
        "backgroundColor": "#ffc107",
        "chainId": "bsc",
        "inactive": false,
        "logo": "teddy.svg",
        "name": "Teddy",
        "slug": "dexa3368e",
        "textColor": "#fff",
        "factoryBlock": 14903673,
        "router": [
          "0x70748b755fe5BAe7CdC8BE3cc57b7f47fc5B8A64",
          "0x70748b755fe5bae7cdc8be3cc57b7f47fc5b8a64"
        ],
        "urlInfo": "https://info.teddyswap.co/home",
        "urlSwap": "https://teddyswap.co/#/swap"
      },
      {
        "factory": "0xe9cabbc746c03010020fd093cd666e40823e0d87",
        "__v": 0,
        "backgroundColor": "#ffffff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "moonlift.svg",
        "name": "Moonlift",
        "slug": "dex3e0d87",
        "textColor": "#000000",
        "factoryBlock": 12774454,
        "router": [
          "0x0dC614eCb00Fe2f6312cd1736e61a04969a4fe7B",
          "0x0dc614ecb00fe2f6312cd1736e61a04969a4fe7b"
        ],
        "urlInfo": "https://moonlift.capital/",
        "urlSwap": "https://dex.moonlift.capital/"
      },
      {
        "factory": "0xf8e28c1a5d11bfdf8f9bf007fbfb2e36c206a156",
        "__v": 0,
        "backgroundColor": "#fdca3d",
        "chainId": "bsc",
        "inactive": false,
        "logo": "cox.svg",
        "name": "Cox",
        "slug": "dex06a156",
        "textColor": "#fff",
        "factoryBlock": 11673355,
        "router": [
          "0x6b4b93f17d7b6dffdac6a95bec651fec9a7eea1d",
          "0x6b4b93f17d7b6dffdac6a95bec651fec9a7eea1d"
        ],
        "urlInfo": "https://coxswap.com",
        "urlSwap": "https://coxswap.com/swap"
      },
      {
        "slug": "baby",
        "factory": "0x86407bea2078ea5f5eb5a52b2caa963bc1f889da",
        "router": [
          "0x325E343f1dE602396E256B67eFd1F61C3A6B38Bd",
          "0x325e343f1de602396e256b67efd1f61c3a6b38bd"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "Babyswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://home.babyswap.finance/",
        "urlSwap": "https://exchange.babyswap.finance/#/swap",
        "logo": "babyswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#e89d39",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x9a272d734c5a0d7d84e0a892e891a553e8066dce",
        "__v": 0,
        "backgroundColor": "#f3b71e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "fst.png",
        "name": "Fstswap",
        "slug": "dex066dce",
        "textColor": "#fff",
        "factoryBlock": 13426673,
        "router": [
          "0x1B6C9c20693afDE803B27F8782156c0f892ABC2d",
          "0x1b6c9c20693afde803b27f8782156c0f892abc2d"
        ],
        "urlInfo": "https://www.fstswap.pro",
        "urlSwap": "https://www.fstswap.pro/?v=1#/swap"
      },
      {
        "factory": "0x3165d94dd2f71381495cb897832de02710a0dce5",
        "__v": 0,
        "backgroundColor": "#ffad00",
        "chainId": "bsc",
        "inactive": false,
        "logo": "cobra.svg",
        "name": "Cobraswap",
        "slug": "dexa0dce5",
        "textColor": "#fff",
        "factoryBlock": 9503477,
        "router": [
          "0xb2E8D6BD3ECA4fd91FDC14C43580e80183979F2c",
          "0xb2e8d6bd3eca4fd91fdc14c43580e80183979f2c"
        ],
        "urlInfo": "https://cobra.exchange",
        "urlSwap": "https://cobra.exchange/#/swap"
      },
      {
        "factory": "0xb28885193dabfd5d155bf94a38f173c1876094cd",
        "__v": 0,
        "backgroundColor": "#fbb81b",
        "chainId": "bsc",
        "inactive": false,
        "logo": "sea.png",
        "name": "Seadex",
        "slug": "dex6094cd",
        "textColor": "#fff",
        "factoryBlock": 14897373,
        "router": [
          "0xb69DeC4EF7d327a7896d36d520780F552BC003B0",
          "0xb69dec4ef7d327a7896d36d520780f552bc003b0"
        ],
        "urlInfo": "https://www.seadex.finance",
        "urlSwap": "https://www.seadex.finance/swap"
      },
      {
        "factory": "0x40dfc2f530469452d5a9bb33356b071be0758c4c",
        "__v": 0,
        "backgroundColor": "#51a168",
        "chainId": "bsc",
        "inactive": false,
        "logo": "borgswap.png",
        "name": "Borgswap",
        "slug": "dex758c4c",
        "textColor": "#fff",
        "factoryBlock": 5763812,
        "router": [
          "0xb3eeb69945d278fe6f755830316a6a43eeb09bd5",
          "0xb3eEb69945D278Fe6f755830316a6A43eeB09Bd5"
        ],
        "urlInfo": "https://farm.borgswap.exchange/info",
        "urlSwap": "https://swap.borgswap.exchange/"
      },
      {
        "factory": "0x4d05d0045df5562d6d52937e93de6ec1fecdad21",
        "__v": 0,
        "backgroundColor": "#008a81",
        "chainId": "bsc",
        "inactive": false,
        "logo": "safemoon.svg",
        "name": "Safemoonswap V2",
        "slug": "dexcdad21",
        "textColor": "#fff",
        "factoryBlock": 16405276,
        "router": [
          "0x37da632c6436137BD4D0CA30c98d3c615974120b",
          "0x37da632c6436137bd4d0ca30c98d3c615974120b"
        ],
        "urlInfo": "https://safemoon.com",
        "urlSwap": "https://swap.safemoon.com/#/swap",
        "urlUnicrypt": "https://app.unicrypt.network/amm/safemoon-bsc-v1/pair/"
      },
      {
        "factory": "0x90f3afa101a9438b70b427be4c90eb661a80a374",
        "__v": 0,
        "backgroundColor": "#151745",
        "chainId": "bsc",
        "inactive": false,
        "logo": "robustswap.svg",
        "name": "Robust Swap",
        "slug": "dex80a374",
        "textColor": "#fff",
        "factoryBlock": 16657169,
        "router": [
          "0xc93c2ac34bd2054b4e91d2414110b4f875cd8fb1",
          "0xc93c2ac34Bd2054B4e91D2414110B4F875cd8FB1"
        ],
        "urlInfo": "https://robustswap.com",
        "urlSwap": "https://exchange.robustswap.com/#/swap"
      },
      {
        "slug": "pancake",
        "factory": "0xbcfccbde45ce874adcb698cc183debcf17952812",
        "router": [
          "0x05ff2b0db69458a0750badebc4f9e13add608c7f"
        ],
        "factoryBlock": 880843,
        "isDefault": true,
        "name": "Pancakeswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": true,
        "hasPoolRatios": true,
        "urlInfo": "https://pancakeswap.info",
        "urlSwap": "https://v1exchange.pancakeswap.finance/#/swap",
        "logo": "pancakeswap.png",
        "logoStyles": "",
        "version": "v1",
        "urlUnicrypt": "https://app.unicrypt.network/amm/pancake-v1/pair/",
        "backgroundColor": "#f8b612",
        "textColor": "#5a5a5a",
        "limitBotSupported": "true"
      },
      {
        "factory": "0x6f12482d9869303b998c54d91bcd8bccba81f3be",
        "__v": 0,
        "backgroundColor": "#f13e12",
        "chainId": "bsc",
        "inactive": false,
        "logo": "mars.png",
        "name": "Mars Ecosystem",
        "slug": "dex81f3be",
        "textColor": "#fff",
        "factoryBlock": 8281241,
        "router": [
          "0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D",
          "0xb68825c810e67d4e444ad5b9deb55ba56a66e72d"
        ],
        "urlInfo": "https://marsecosystem.com/home",
        "urlSwap": "https://app.marsecosystem.com/swap"
      },
      {
        "factory": "0xf0bc2e21a76513aa7cc2730c7a1d6dee0790751f",
        "__v": 0,
        "backgroundColor": "#ff720d",
        "chainId": "bsc",
        "inactive": false,
        "logo": "knight.svg",
        "name": "Knight Finance",
        "slug": "dex90751f",
        "textColor": "#fff",
        "factoryBlock": 12161176,
        "router": [
          "0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f",
          "0x05e61e0cdcd2170a76f9568a110cee3afdd6c46f"
        ],
        "urlInfo": "https://app.knightswap.financial/",
        "urlSwap": "https://dex.knightswap.financial/#/swap"
      },
      {
        "factory": "0xf2fb1b5be475e7e1b3c31082c958e781f73a1712",
        "__v": 0,
        "backgroundColor": "#eaa20a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "justmoney.svg",
        "name": "Just Money",
        "slug": "dex3a1712",
        "textColor": "#fff",
        "factoryBlock": 11106295,
        "router": [
          "0x9112b207ED0084e1389AC9C3f53C67906EC8E9Ad",
          "0x9112b207ed0084e1389ac9c3f53c67906ec8e9ad"
        ],
        "urlInfo": "https://bsc.justmoney.exchange/",
        "urlSwap": "https://bsc.justmoney.exchange/"
      },
      {
        "factory": "0x86a859773cf6df9c8117f20b0b950ada84e7644d",
        "__v": 0,
        "backgroundColor": "#008a81",
        "chainId": "bsc",
        "inactive": false,
        "logo": "safemoon.svg",
        "name": "Safemoonswap",
        "slug": "dexe7644d",
        "textColor": "#fff",
        "factoryBlock": 10396829,
        "router": [
          "0xE804f3C3E6DdA8159055428848fE6f2a91c2b9AF",
          "0xe804f3c3e6dda8159055428848fe6f2a91c2b9af"
        ],
        "urlInfo": "https://safemoon.net/",
        "urlSwap": "https://swap.safemoon.net/#/swap",
        "urlUnicrypt": "https://app.unicrypt.network/amm/safemoon-bsc-v1/pair/"
      },
      {
        "factory": "0xa053582601214feb3778031a002135cbbb7dba18",
        "__v": 0,
        "backgroundColor": "#100C21",
        "chainId": "bsc",
        "inactive": false,
        "logo": "planet.png",
        "name": "Planet Finance",
        "slug": "dex7dba18",
        "textColor": "#fff",
        "factoryBlock": 8166568,
        "urlInfo": "https://planetfinance.io/",
        "urlSwap": "https://blue.planetfinance.io/swap"
      },
      {
        "factory": "0xdda79ec4af818d1e95f0a45b3e7e60461d5228cb",
        "__v": 0,
        "backgroundColor": "#e0077d",
        "chainId": "bsc",
        "inactive": false,
        "logo": "polkabridgeamm.svg",
        "name": "PolkaBridge AMM",
        "slug": "dex5228cb",
        "textColor": "#fff",
        "factoryBlock": 16528940,
        "router": [
          "0x8547e2E16783Fdc559C435fDc158d572D1bD0970",
          "0x8547e2e16783fdc559c435fdc158d572d1bd0970"
        ],
        "urlInfo": "https://polkabridge.org/",
        "urlSwap": "https://swap.polkabridge.org/"
      },
      {
        "factory": "0xd6715a8be3944ec72738f0bfdc739d48c3c29349",
        "__v": 0,
        "backgroundColor": "#00a0ff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "nomi.png",
        "name": "Nomiswap",
        "slug": "dexc29349",
        "textColor": "#fff",
        "factoryBlock": 13908914,
        "router": [
          "0xD654953D746f0b114d1F85332Dc43446ac79413d",
          "0xd654953d746f0b114d1f85332dc43446ac79413d"
        ],
        "urlInfo": "https://nomiswap.io/",
        "urlSwap": "https://nomiswap.io/swap"
      },
      {
        "factory": "0xf83f344194c7eec496ed9e9283b2edeede34cb0d",
        "__v": 0,
        "backgroundColor": "#03ffff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "ethax.svg",
        "name": "Ethax",
        "slug": "dex34cb0d",
        "textColor": "#000",
        "factoryBlock": 15242314,
        "router": [
          "0x55216c7BF00A93f11cc7435D6FDC692E2d80Bc81",
          "0x55216c7bf00a93f11cc7435d6fdc692e2d80bc81"
        ],
        "urlInfo": "https://ethax.io/",
        "urlSwap": "https://exchange.ethax.io/#/swap"
      },
      {
        "slug": "bakery",
        "factory": "0x01bf7c66c6bd861915cdaae475042d3c4bae16a7",
        "router": [
          "0xcde540d7eafe93ac5fe6233bee57e1270d3e330f"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "Bakeryswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.bakeryswap.org/",
        "urlSwap": "https://www.bakeryswap.org/#/swap",
        "logo": "bakery.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#722f0d",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x6cc587515137ee5b89ebd0b25cc5fc2b93015357",
        "__v": 0,
        "backgroundColor": "#030a1f",
        "chainId": "bsc",
        "inactive": false,
        "logo": "infinity.svg",
        "name": "Infinity",
        "slug": "dex015357",
        "textColor": "#fff",
        "factoryBlock": 7046376,
        "router": [
          "0xeB1c0EfE6655E019E2c6AB01e0Bd0c97f4899A4e",
          "0xeb1c0efe6655e019e2c6ab01e0bd0c97f4899a4e",
          ""
        ],
        "urlInfo": "https://infinitycrypto.com/analytics",
        "urlSwap": "https://infinitycrypto.com/swap"
      },
      {
        "factory": "0xd06320b86b7bd7d71f3e45aeb2c48676a74e3dc5",
        "__v": 0,
        "backgroundColor": "#432d60",
        "chainId": "bsc",
        "inactive": false,
        "logo": "evoc.svg",
        "name": "Evocswap",
        "slug": "dex4e3dc5",
        "textColor": "#fff",
        "factoryBlock": 15884085,
        "router": [
          "0xECce839e3Cfe75A81ba7c27B6A13356a69464f75",
          "0xecce839e3cfe75a81ba7c27b6a13356a69464f75"
        ],
        "urlInfo": "https://docs.evocswap.com/",
        "urlSwap": "https://evocswap.com/swap"
      },
      {
        "slug": "ape",
        "factory": "0x0841bd0b734e4f5853f0dd8d7ea041c241fb0da6",
        "router": [
          "0xcf0febd3f17cef5b47b0cd257acf6025c5bff3b7"
        ],
        "factoryBlock": 880843,
        "isDefault": false,
        "name": "Apeswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://apeswap.finance/",
        "urlSwap": "https://app.apeswap.finance/swap",
        "logo": "apeswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#a16552",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "bi",
        "factory": "0x858e3312ed3a876947ea49d572a7c42de08af7ee",
        "router": [
          "0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8",
          "0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8"
        ],
        "factoryBlock": 7664646,
        "isDefault": false,
        "name": "Biswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://biswap.org/",
        "urlSwap": "https://biswap.org/#/swap",
        "logo": "biswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "https://app.unicrypt.network/amm/biswap-bsc-v1/pair/",
        "backgroundColor": "#0058f1",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "acy",
        "factory": "0x3d077c05c3abce52257e453607209f81d9db01fc",
        "router": [
          "0x4DCa8E42634abdE1925ebB7f82AC29Ea00d34bA2",
          "0x4dca8e42634abde1925ebb7f82ac29ea00d34ba2"
        ],
        "factoryBlock": 13547902,
        "isDefault": false,
        "name": "ACY Finance",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://acy.finance/",
        "urlSwap": "https://app.acy.finance/#/exchange",
        "logo": "acyfinance.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#eb5c20",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x43ebb0cb9bd53a3ed928dd662095ace1cef92d19",
        "__v": 0,
        "backgroundColor": "#ff5532",
        "chainId": "bsc",
        "inactive": false,
        "logo": "definix.png",
        "name": "Definix",
        "slug": "dexf92d19",
        "textColor": "#fff",
        "factoryBlock": 6162477,
        "urlInfo": "https://www.definix.com/en",
        "urlSwap": "https://g2.klaytn.definix.com/swap"
      },
      {
        "factory": "0x542b6524abf0bd47dc191504e38400ec14d0290c",
        "__v": 0,
        "backgroundColor": "#20A079",
        "chainId": "bsc",
        "inactive": false,
        "logo": "yoshi.svg",
        "name": "Yoshi",
        "slug": "dexd0290c",
        "textColor": "#fff",
        "factoryBlock": 13159231,
        "router": [
          "0x82E801e701BC8B3C0c0b43CA1Cc2DBf8B1655Bbc",
          "0x82e801e701bc8b3c0c0b43ca1cc2dbf8b1655bbc"
        ],
        "urlInfo": "https://yoshi.exchange/",
        "urlSwap": "https://yoshi.exchange/"
      },
      {
        "factory": "0xaf042b1b77240063bc713b9357c39abedec1b691",
        "__v": 0,
        "backgroundColor": "#672611",
        "chainId": "bsc",
        "inactive": false,
        "logo": "luchowswap.png",
        "name": "Luchowswap",
        "slug": "dexc1b691",
        "textColor": "#fff",
        "router": [
          "0x10ED43C718714eb63d5aA57B78B54704E256024E",
          "0x10ed43c718714eb63d5aa57b78b54704e256024e"
        ],
        "urlInfo": "https://bsc.luchowswap.com/info",
        "urlSwap": "https://bsc.luchowswap.com/swap"
      },
      {
        "factory": "0x24df3f586b3d313a98704478f83a4315ae5b19a9",
        "__v": 0,
        "backgroundColor": "#e69f36",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pankuku.png",
        "name": "Pankuku",
        "slug": "dex5b19a9",
        "textColor": "#fff",
        "router": [
          "0x24195412938290F63ac47F0130ABBdb39319168a",
          "0x24195412938290f63ac47f0130abbdb39319168a"
        ],
        "urlInfo": "https://pankuku.net/",
        "urlSwap": "https://exchange.pankuku.net/#/swap"
      },
      {
        "factory": "0x0944ab692786d9104ae9a29778285c41c33c0415",
        "__v": 0,
        "backgroundColor": "#ed4b4a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pls2e.svg",
        "name": "Pls2e",
        "slug": "dex3c0415",
        "textColor": "#fff",
        "factoryBlock": 13030307,
        "router": [
          "0x7529740ECa172707D8edBCcdD2Cba3d140ACBd85",
          "0x7529740eca172707d8edbccdd2cba3d140acbd85"
        ],
        "urlInfo": "https://info.pls2e.io/home",
        "urlSwap": "https://www.pls2e.io/swap"
      },
      {
        "factory": "0x03879e2a3944fd601e7638dfcbc9253fb793b599",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "baryon.svg",
        "name": "Baryon",
        "slug": "dex93b599",
        "textColor": "#fff",
        "factoryBlock": 16040807,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x3f8aadd297e60ebe7c1bb8d3f6d413b9f39efb8b"
        ],
        "urlInfo": "https://www.baryon.network/",
        "urlSwap": "https://www.baryon.network/swap"
      },
      {
        "factory": "0x13c075b159ba5b4c2c1a71b1a27eb60438ab5eb3",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "myteamex.png",
        "name": "MyTeamEx",
        "slug": "dexab5eb3",
        "textColor": "#fff",
        "factoryBlock": 10992483,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x8a1bfee3cfd21bebd5b20c3b625dbd792513395d"
        ],
        "urlInfo": "https://www.myteamex.com/",
        "urlSwap": "https://www.myteamex.com/swap"
      },
      {
        "slug": "empire",
        "factory": "0x06530550a48f990360dfd642d2132354a144f31d",
        "router": [
          "0xdADaae6cDFE4FA3c35d54811087b3bC3Cd60F348",
          "0xdadaae6cdfe4fa3c35d54811087b3bc3cd60f348"
        ],
        "factoryBlock": 9761362,
        "isDefault": false,
        "name": "EmpireDex",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.empiredex.org/",
        "urlSwap": "https://bsc.empiredex.org/#/swap",
        "logo": "empiredex.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ae9159",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xeb10f4fe2a57383215646b4ac0da70f8edc69d4f",
        "__v": 0,
        "backgroundColor": "#ea1e62",
        "chainId": "bsc",
        "inactive": false,
        "logo": "leonicorn.svg",
        "name": "Leonicorn",
        "slug": "dexc69d4f",
        "textColor": "#fff",
        "factoryBlock": 11736511,
        "router": [
          "0x9de7c5E05d87D20cC9798C83844dd9813EC74FDF",
          "0x9de7c5e05d87d20cc9798c83844dd9813ec74fdf"
        ],
        "urlInfo": "https://dex.leonicornswap.com/info",
        "urlSwap": "https://dex.leonicornswap.com/swap"
      },
      {
        "factory": "0x98957ab49b8bc9f7ddbcfd8bcc83728085ecb238",
        "__v": 0,
        "backgroundColor": "#da291c",
        "chainId": "bsc",
        "inactive": false,
        "logo": "radioshack.svg",
        "name": "RadioShack",
        "slug": "dexecb238",
        "textColor": "#fff",
        "factoryBlock": 15626857,
        "router": [
          "0x5bc3ED94919af03279c8613cB807ab667cE5De99",
          "0x5bc3ed94919af03279c8613cb807ab667ce5de99"
        ],
        "urlInfo": "https://www.radioshack.org/",
        "urlSwap": "https://app.radioshack.org/swap"
      },
      {
        "slug": "pad",
        "factory": "0xb836017acf10b8a7c6c6c9e99efe0f5b0250fc45",
        "router": [
          "0x60Ca5ed40957607D6f15688d5bc693f008210058",
          "0x60ca5ed40957607d6f15688d5bc693f008210058"
        ],
        "factoryBlock": 6317146,
        "isDefault": false,
        "name": "Padswap",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://padswap.exchange/#/swap",
        "urlSwap": "https://padswap.exchange/#/swap",
        "logo": "padswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#26d8ac",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "shibanova",
        "factory": "0x251912dE998ec91DFDf67EfBe032d6f4aB5EC485",
        "router": [
          "0x251912dE998ec91DFDf67EfBe032d6f4aB5EC485",
          "0x251912de998ec91dfdf67efbe032d6f4ab5ec485"
        ],
        "factoryBlock": 9212526,
        "isDefault": false,
        "name": "Shibanova",
        "alike": "",
        "chainId": "bsc",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://app.shibanova.io/",
        "urlSwap": "https://swap.shibanova.io/#/swap",
        "logo": "shibanova.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#0a209e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0x7897c32cbda1935e97c0b59f244747562d4d97c1",
        "__v": 0,
        "backgroundColor": "#fdd436",
        "chainId": "bsc",
        "inactive": false,
        "logo": "bxh.png",
        "name": "BXH",
        "slug": "dex4d97c1",
        "textColor": "#000",
        "factoryBlock": 9382014,
        "urlInfo": "https://bscinfo.bxh.com/#/home",
        "urlSwap": "https://bscswap.bxh.com/#/swap"
      },
      {
        "factory": "0x2af5c23798fec8e433e11cce4a8822d95cd90565",
        "__v": 0,
        "backgroundColor": "#c7a919",
        "chainId": "bsc",
        "inactive": false,
        "logo": "smb.svg",
        "name": "SMB",
        "slug": "dexd90565",
        "textColor": "#fff",
        "factoryBlock": 15396166,
        "router": [
          "0x009d611490eCfED2dC3F306231Bba7e7F3E9196E",
          "0x009d611490ecfed2dc3f306231bba7e7f3e9196e"
        ],
        "urlInfo": "https://smbswap.finance/info",
        "urlSwap": "https://smbswap.finance/swap"
      },
      {
        "factory": "0x80f112cd8ac529d6993090a0c9a04e01d495bfbf",
        "__v": 0,
        "backgroundColor": "#26e2b3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "swych.svg",
        "name": "Swych",
        "slug": "dex95bfbf",
        "textColor": "#fff",
        "factoryBlock": 16285291,
        "router": [
          "0x2bf55d1596786f1ae8160e997d655dbe6d9bca7a",
          "0x2bf55d1596786f1ae8160e997d655dbe6d9bca7a"
        ],
        "urlInfo": "https://app.titano.finance/",
        "urlSwap": "https://app.titano.finance/swap"
      },
      {
        "factory": "0x47b3cb3c0f3a7cfd484a884c77257b409f2a5ab2",
        "__v": 0,
        "backgroundColor": "#ff737b",
        "chainId": "bsc",
        "inactive": false,
        "logo": "smeg.svg",
        "name": "Smeg",
        "slug": "dex2a5ab2",
        "textColor": "#fff",
        "factoryBlock": 16823578,
        "router": [
          "0x1a6C61E04FAAAb956176A22056f7183C6B77849E",
          "0x1a6c61e04faaab956176a22056f7183c6b77849e"
        ],
        "urlInfo": "www.smexswap.com",
        "urlSwap": "https://app.smexswap.com/swap"
      },
      {
        "factory": "0x39862605e3194ae48287d9376fa861812c774785",
        "__v": 0,
        "backgroundColor": "#f8892e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pieswap.svg",
        "name": "Pieswap",
        "slug": "dex774785",
        "textColor": "#fff",
        "factoryBlock": 16796127,
        "router": [
          "0x87e36223ff92c0fcbe037005bd31ab69a5c5b735",
          "0x87e36223ff92c0fcbe037005bd31ab69a5c5b735"
        ],
        "urlInfo": "https://pieswap.io",
        "urlSwap": "https://app.pieswap.io/swap"
      },
      {
        "factory": "0xcc045ebc2664daf316aa0652e72237609ea6cb4f",
        "__v": 0,
        "backgroundColor": "#ce9300",
        "chainId": "bsc",
        "inactive": false,
        "logo": "kronosdao.svg",
        "name": "Kronos DAO",
        "slug": "dexa6cb4f",
        "textColor": "#fff",
        "factoryBlock": 16855497,
        "router": [
          "0x98aE5A2f6C3C39B4171a84beE4c36C80e024053D",
          "0x98ae5a2f6c3c39b4171a84bee4c36c80e024053d"
        ],
        "urlInfo": "https://kronosdao.ai",
        "urlSwap": "https://exchange.kronosdao.ai"
      },
      {
        "factory": "0xfd377f10c2082d6d610879b714450ada47a787c4",
        "__v": 0,
        "backgroundColor": "#1682E6",
        "chainId": "bsc",
        "inactive": false,
        "logo": "linkedfinance.svg",
        "name": "LFWSwap",
        "slug": "dexa787c4",
        "textColor": "#fff",
        "factoryBlock": 16473583,
        "router": [
          "0x776c91B0048745df496A77299911b835f8424Fa3",
          "0x776c91b0048745df496a77299911b835f8424fa3"
        ],
        "urlInfo": "https://lfw.finance/#/dashboard",
        "urlSwap": "https://lfw.finance/#/swap"
      },
      {
        "factory": "0x79c342fddbbf376ca6b4efac7aaa457d6063f8cb",
        "__v": 0,
        "backgroundColor": "#9a4056",
        "chainId": "bsc",
        "inactive": false,
        "logo": "winery.svg",
        "name": "Winery",
        "slug": "dex63f8cb",
        "textColor": "#fff",
        "factoryBlock": 17051274,
        "router": [
          "0x8bfFB5562ff30f555894E101E6DAD31D271EEd5a",
          "0x8bffb5562ff30f555894e101e6dad31d271eed5a"
        ],
        "urlInfo": "https://winery.finance/",
        "urlSwap": "https://winery.finance/amm/#/trade/swap"
      },
      {
        "factory": "0x65b3cc7a7cb167221266fc93884717de2dbd074e",
        "__v": 0,
        "backgroundColor": "#3e80ca",
        "chainId": "bsc",
        "inactive": false,
        "logo": "bitblocks.png",
        "name": "BitBlocks",
        "slug": "dexbd074e",
        "textColor": "#fff",
        "factoryBlock": 17292746,
        "router": [
          "0xdf81889adfb8ce92142f0089388cc9e5edfee765",
          "0xdF81889adfB8CE92142f0089388Cc9E5edfee765"
        ],
        "urlInfo": "https://info.bitblocks.finance/",
        "urlSwap": "https://exchange.bitblocks.finance//#/swap"
      },
      {
        "factory": "0x4136a450861f5cfe7e860ce93e678ad12158695c",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "cryptoswap.png",
        "name": "CryptoSwap",
        "slug": "dex58695c",
        "textColor": "#fff",
        "factoryBlock": 17454449,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0xe4964c56f46063f25f2a77269b36a773140ab325"
        ],
        "urlInfo": "https://cryptoswapdex.com/info",
        "urlSwap": "https://cryptoswapdex.com/swap"
      },
      {
        "factory": "0xee638aa41e12b6c6df71f88410eb1ddd2fb28973",
        "__v": 0,
        "backgroundColor": "#629800",
        "chainId": "bsc",
        "inactive": false,
        "logo": "farmageddon.svg",
        "name": "Farmageddon",
        "slug": "dexb28973",
        "textColor": "#fff",
        "factoryBlock": 17597515,
        "router": [
          "0xF909649F05E26211eC16D4131349492F066cAe7A",
          "0xf909649f05e26211ec16d4131349492f066cae7a"
        ],
        "urlInfo": "https://farmageddon.farm",
        "urlSwap": "https://farmageddon.farm/#/swap"
      },
      {
        "factory": "0xff9a4e72405df3ca3d909523229677e6b2b8dc71",
        "__v": 0,
        "backgroundColor": "#844ded",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pandora.png",
        "name": "Pandora",
        "slug": "dexb8dc71",
        "textColor": "#fff",
        "factoryBlock": 17729573,
        "router": [
          "0xC2426F2018f13Fdc5b260Bd0A88be753ee7FaFc5",
          "0xc2426f2018f13fdc5b260bd0a88be753ee7fafc5"
        ],
        "urlInfo": "https://pandora.digital/info",
        "urlSwap": "https://pandora.digital/swap"
      },
      {
        "factory": "0xa007a9716dba05289df85a90d0fd9d39bee808de",
        "__v": 0,
        "backgroundColor": "#ededef",
        "chainId": "bsc",
        "inactive": false,
        "logo": "fraxfinance.svg",
        "name": "Frax Finance",
        "slug": "dexe808de",
        "textColor": "#000",
        "factoryBlock": 18057956,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0x0AE84c1A6E142Ed90f8A35a7E7B216CB25469E37",
          "0x0ae84c1a6e142ed90f8a35a7e7b216cb25469e37"
        ],
        "urlInfo": "https://app.frax.finance",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "backgroundColor": "#ffe804",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Vendor Machine",
        "slug": "dodov2public",
        "textColor": "#000",
        "factoryBlock": 4926603,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x790b4a80fb1094589a3c0efc8740aa9b0c1733fb"
      },
      {
        "backgroundColor": "#ffe804",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Stable",
        "slug": "dodov2stable",
        "textColor": "#000",
        "factoryBlock": 6695425,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x0fb9815938ad069bf90e14fe6c596c514bede767"
      },
      {
        "backgroundColor": "#ffe804",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Private",
        "slug": "dodov2private",
        "textColor": "#000",
        "factoryBlock": 11677242,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0xd9cac3d964327e47399aebd8e1e6dcc4c251daae"
      },
      {
        "backgroundColor": "#ffe804",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Zoo",
        "slug": "dodov1",
        "textColor": "#000",
        "factoryBlock": 726278,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0xca459456a45e300aa7ef447dbb60f87cccb42828"
      },
      {
        "factory": "0x1a04afe9778f95829017741bf46c9524b91433fb",
        "__v": 0,
        "backgroundColor": "#7c50d6",
        "chainId": "bsc",
        "inactive": false,
        "logo": "orbital.svg",
        "name": "Orbital",
        "slug": "dex1433fb",
        "textColor": "#fff",
        "factoryBlock": 18169105,
        "router": [
          "0x6B45064F128cA5ADdbf79825186F4e3e3c9E7EB5",
          "0x6b45064f128ca5addbf79825186f4e3e3c9e7eb5"
        ],
        "urlInfo": "https://orbitalswap.com/info",
        "urlSwap": "https://orbitalswap.com/swap"
      },
      {
        "factory": "0x6a397fce59cb8282d1d05e58ef23534934607ef1",
        "__v": 0,
        "backgroundColor": "#336699",
        "chainId": "bsc",
        "inactive": false,
        "logo": "bluelotus.svg",
        "name": "Blue Lotus",
        "slug": "dex607ef1",
        "textColor": "#fff",
        "factoryBlock": 17695461,
        "router": [
          "0x70D319F74090d3BB6bfEA496Bea73692C59B1AD1",
          "0x70d319f74090d3bb6bfea496bea73692c59b1ad1"
        ],
        "urlInfo": "https://www.bluelotusdao.org",
        "urlSwap": "https://www.bluelotusdao.org/swap"
      },
      {
        "factory": "0x95f9c44fa1585811e1d1a0f59e74174b657b37a5",
        "__v": 0,
        "backgroundColor": "#133444",
        "chainId": "bsc",
        "inactive": false,
        "logo": "veneraswap.svg",
        "name": "Venera Swap",
        "slug": "dex7b37a5",
        "textColor": "#fff",
        "router": [
          "0x601ab3ccc7b2de314bc007cde7d59a3f604016e7",
          "0x601AB3cCc7B2de314Bc007Cde7D59A3f604016E7"
        ],
        "urlInfo": "https://veneraswap.com",
        "urlSwap": "https://exchange.veneraswap.com/#/swap",
        "factoryBlock": 18503409
      },
      {
        "factory": "0xb9ceae811d8ec7aef0e77c3c553a04f2c825bba2",
        "__v": 0,
        "backgroundColor": "#3275bb",
        "chainId": "bsc",
        "inactive": false,
        "logo": "nativefinance.svg",
        "name": "Native Finance",
        "slug": "dex25bba2",
        "textColor": "#fff",
        "factoryBlock": 18516344,
        "router": [
          "0xE6D27d0182fc07Ee7C6B17e59A7D4d7926543b9b",
          "0xe6d27d0182fc07ee7c6b17e59a7d4d7926543b9b"
        ],
        "urlInfo": "https://nativefinance.io/",
        "urlSwap": "https://swap.nativefinance.io/#/"
      },
      {
        "factory": "0x5f1dddbf348ac2fbe22a163e30f99f9ece3dd50a",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex3dd50a",
        "textColor": "#fff",
        "factoryBlock": 18532980,
        "router": [
          "0xC1e7dFE73E1598E3910EF4C7845B68A9Ab6F4c83",
          "0xc1e7dfe73e1598e3910ef4c7845b68a9ab6f4c83"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "alike": "univ3"
      },
      {
        "factory": "0x6126e30de58a77de3d7649e06a76635efbef2ffc",
        "__v": 0,
        "backgroundColor": "#13055e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "digidex.svg",
        "name": "Digidex",
        "slug": "dexef2ffc",
        "textColor": "#fff",
        "factoryBlock": 18643898,
        "router": [
          "0x60f40fA375BB1BD1293D1929f24C92c243473105",
          "0x60f40fa375bb1bd1293d1929f24c92c243473105"
        ],
        "urlInfo": "https://digidex.finance",
        "urlSwap": "https://digidex.finance/swap"
      },
      {
        "factory": "0x4693b62e5fc9c0a45f89d62e6300a03c85f43137",
        "__v": 0,
        "backgroundColor": "#0b9ed8",
        "chainId": "bsc",
        "inactive": false,
        "logo": "babydogeswap.png",
        "name": "BabyDoge",
        "slug": "dexf43137",
        "textColor": "#fff",
        "factoryBlock": 18973559,
        "router": [
          "0xC9a0F685F39d05D835c369036251ee3aEaaF3c47",
          "0xc9a0f685f39d05d835c369036251ee3aeaaf3c47"
        ],
        "urlInfo": "https://babydogeswap.com/info",
        "urlSwap": "https://babydogeswap.com/swap",
        "urlUnicrypt": "https://app.unicrypt.network/amm/babydoge-bsc-v1/pair/"
      },
      {
        "factory": "0xd04a80baeef12fd7b1d1ee6b1f8ad354f81bc4d7",
        "__v": 0,
        "backgroundColor": "#7b2d2d",
        "chainId": "bsc",
        "inactive": false,
        "logo": "w3swap.svg",
        "name": "W3Swap",
        "slug": "dex1bc4d7",
        "textColor": "#fff",
        "factoryBlock": 19248971,
        "router": [
          "0xF29acE1FE5f36389d0dDe450a0195A30c3770245",
          "0xf29ace1fe5f36389d0dde450a0195a30c3770245"
        ],
        "urlInfo": "https://w3swap.finance/",
        "urlSwap": "https://w3swap.finance/"
      },
      {
        "factory": "0x1e895bfe59e3a5103e8b7da3897d1f2391476f3c",
        "__v": 0,
        "backgroundColor": "#ff007a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "dooarswap.svg",
        "name": "Dooarswap",
        "slug": "dex476f3c",
        "textColor": "#fff",
        "factoryBlock": 19272072,
        "urlInfo": "https://dooarswap.notdao.io",
        "urlSwap": "https://dooarswap.notdao.io/#/swap"
      },
      {
        "factory": "0x1c758af0688502e49140230f6b0ebd376d429be5",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Classic",
        "slug": "dex429be5",
        "textColor": "#fff",
        "router": [
          "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0",
          "0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0x0efc2d2d054383462f2cd72ea2526ef7687e1016",
        "__v": 0,
        "backgroundColor": "#d3f85a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "cone.svg",
        "name": "Cone",
        "slug": "dex7e1016",
        "textColor": "#000",
        "factoryBlock": 19657538,
        "router": [
          "0xbf1fc29668e5f5Eaa819948599c9Ac1B1E03E75F",
          "0xbf1fc29668e5f5eaa819948599c9ac1b1e03e75f"
        ],
        "urlInfo": "https://info.cone.exchange/home",
        "urlSwap": "https://www.cone.exchange/swap"
      },
      {
        "factory": "0xccc1372d4e008725fcbf47ab0f58bda5366cd9e2",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "techswap.webp",
        "name": "TechSwap",
        "slug": "dex6cd9e2",
        "textColor": "#fff",
        "factoryBlock": 19980392,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x70ce114cd13a4e1d1b72fbe00d6a0a6ed4b37b59"
        ],
        "urlInfo": "https://techswap.finance",
        "urlSwap": "https://techswap.finance/es/swap"
      },
      {
        "factory": "0x19813b4aa783a4aeef5f9155875af2492a2a207d",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "spicetrade.jpg",
        "name": "Spice Trade",
        "slug": "dex2a207d",
        "textColor": "#fff",
        "factoryBlock": 20187331,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x936e4fd4ade51d694c6df68987fd90daa68058cc"
        ],
        "urlInfo": "https://app.spicetrade.ai/",
        "urlSwap": "https://swap.spicetrade.ai/swap"
      },
      {
        "factory": "0xfef108536e8d871375f36d5f4d8a2922d6f9e9ed",
        "__v": 0,
        "backgroundColor": "#734ef2",
        "chainId": "bsc",
        "inactive": false,
        "logo": "metaswap.svg",
        "name": "Metaswap",
        "slug": "dexf9e9ed",
        "textColor": "#fff",
        "factoryBlock": 20312744,
        "router": [
          "0x9d57e4ac9145795703CE407cD4D378525bfe7B22",
          "0x9d57e4ac9145795703ce407cd4d378525bfe7b22"
        ],
        "urlInfo": "https://home.metaswap.cx/",
        "urlSwap": "https://metaswap.cx/#/swap"
      },
      {
        "factory": "0xb7e5848e1d0cb457f2026670fcb9bbdb7e9e039c",
        "__v": 0,
        "backgroundColor": "#ffffff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "melegaswap.svg",
        "name": "MelegaSwap",
        "slug": "dex9e039c",
        "textColor": "#000",
        "factoryBlock": 20329400,
        "router": [
          "0xc25033218D181b27D4a2944Fbb04FC055da4EAB3",
          "0xc25033218d181b27d4a2944fbb04fc055da4eab3"
        ],
        "urlInfo": "http://www.melegaswap.finance",
        "urlSwap": "https://melega.finance/swap"
      },
      {
        "factory": "0xe4b72158459de95e645a6c42fde02a4d997073bd",
        "__v": 0,
        "backgroundColor": "#612180",
        "chainId": "bsc",
        "inactive": false,
        "logo": "despace.svg",
        "name": "Despace",
        "slug": "dex7073bd",
        "textColor": "#fff",
        "factoryBlock": 18737751,
        "router": [
          "0xE037d12345dA559C2Ed052Bc67545e967b8639bA",
          "0xe037d12345da559c2ed052bc67545e967b8639ba"
        ],
        "urlInfo": "https://despace.app/",
        "urlSwap": "https://despace.app/swap"
      },
      {
        "factory": "0x25c62ab78c1595431f6e47769538a965576b9e46",
        "__v": 0,
        "backgroundColor": "#282866",
        "chainId": "bsc",
        "inactive": false,
        "logo": "swapocean.svg",
        "name": "Swapocean",
        "slug": "dex6b9e46",
        "textColor": "#fff",
        "factoryBlock": 20466038,
        "router": [
          "0x509c97d4A7b98C91f1264304f228e3639416F118",
          "0x509c97d4a7b98c91f1264304f228e3639416f118"
        ],
        "urlInfo": "https://swapocean.com",
        "urlSwap": "https://swapocean.com/#/swap"
      },
      {
        "factory": "0x5a9fc5a9695e89dc0eb5cbedcbfa652d60bea7d4",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "bullcoin.png",
        "name": "Bullcoin",
        "slug": "dexbea7d4",
        "textColor": "#fff",
        "router": [
          "0xF6C637ffcDDbdD0BA8440CaCa00483A830005Dd1",
          "0xf6c637ffcddbdd0ba8440caca00483a830005dd1"
        ],
        "urlInfo": "https://bullcoin.io/",
        "urlSwap": "https://bullcoin.io/trade"
      },
      {
        "factory": "0xa06764ce7b68ae86c566e5c192660751bd42d95e",
        "__v": 0,
        "backgroundColor": "#3a393a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "darswap.svg",
        "name": "Dar Swap",
        "slug": "dex42d95e",
        "textColor": "#fff",
        "factoryBlock": 20522956,
        "router": [
          "0x94c88517959aaa7a79dd9f5db55e7d767512413e",
          "0x94c88517959aaa7a79dd9f5db55e7d767512413e"
        ],
        "urlInfo": "https://dartoken.io/home",
        "urlSwap": "https://dutstaking.com/definance/#/swap"
      },
      {
        "factory": "0x589a02cfa7021923541176718460f8c705e370b4",
        "__v": 0,
        "backgroundColor": "#df960a",
        "chainId": "bsc",
        "inactive": false,
        "logo": "oooswap.png",
        "name": "Oooswap",
        "slug": "dexe370b4",
        "textColor": "#fff",
        "factoryBlock": 20574378,
        "router": [
          "0xF38dB397CAdD097A0C85ad2EC0aDd8EDE10036C7",
          "0xf38db397cadd097a0c85ad2ec0add8ede10036c7"
        ],
        "urlInfo": "https://oooswap.app",
        "urlSwap": "https://oooswap.app/#/swap"
      },
      {
        "factory": "0xb664bdce35b5ee182e8832d4f3b615232e98a51e",
        "__v": 0,
        "backgroundColor": "#ec649c",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pyeswap.svg",
        "name": "PYESwap",
        "slug": "dex98a51e",
        "textColor": "#fff",
        "factoryBlock": 20582783,
        "logoStyle": "{ background-color: #000; }",
        "router": [
          "0x98Cc2Cd55Ca2092034146EBD8eb043F9f976623a",
          "0x98cc2cd55ca2092034146ebd8eb043f9f976623a"
        ],
        "urlInfo": "https://pyeswap.com/info",
        "urlSwap": "https://pyeswap.com/swap"
      },
      {
        "factory": "0x51cdbbed695a6179bbd97b6105ef1907e61a403e",
        "__v": 0,
        "backgroundColor": "#d90700",
        "chainId": "bsc",
        "inactive": false,
        "logo": "tresleches.svg",
        "name": "Tres Leches",
        "slug": "dex1a403e",
        "textColor": "#fff",
        "factoryBlock": 20726879,
        "router": [
          "0x4169Db906fcBFB8b12DbD20d98850Aee05B7D889",
          "0x4169db906fcbfb8b12dbd20d98850aee05b7d889"
        ],
        "urlInfo": "https://tresleches.finance/",
        "urlSwap": "https://swap.tresleches.finance"
      },
      {
        "factory": "0x0a8717c6439d7bbea149ceb9f9050cda3f22322f",
        "__v": 0,
        "backgroundColor": "#43e8a4",
        "chainId": "bsc",
        "inactive": false,
        "logo": "zswap.svg",
        "name": "Zswap",
        "slug": "dex22322f",
        "textColor": "#000",
        "factoryBlock": 20756993,
        "router": [
          "0x95d6c685979667AD7864809f29FE356F97D98A1D",
          "0x95d6c685979667ad7864809f29fe356f97d98a1d"
        ],
        "urlInfo": "https://zoompro.finance/#/main/swap",
        "urlSwap": "https://zoompro.finance/#/main/swap"
      },
      {
        "factory": "0x98292f82b12efcc06a10bde65acff4c866c52df9",
        "__v": 0,
        "backgroundColor": "#21b298",
        "chainId": "bsc",
        "inactive": false,
        "logo": "kasaswap.svg",
        "name": "KasaSwap",
        "slug": "dexc52df9",
        "textColor": "#fff",
        "factoryBlock": 20892699,
        "router": [
          "0x63A00B93fA04Cf87Cdb152DE37688DDf7B57e29c",
          "0x63a00b93fa04cf87cdb152de37688ddf7b57e29c"
        ],
        "urlInfo": "https://swap.kasacentral.net/info",
        "urlSwap": "https://swap.kasacentral.net/swap?"
      },
      {
        "factory": "0x19e5ebc005688466d11015e646fa182621c1881e",
        "__v": 0,
        "backgroundColor": "#8330eb",
        "chainId": "bsc",
        "inactive": false,
        "logo": "saitaswap.png",
        "name": "Saitaswap",
        "slug": "dexc1881e",
        "textColor": "#fff",
        "factoryBlock": 21150576,
        "router": [
          "0x744a4c9c9f40a443ac2a5747d4f3b773e5d86763",
          "0x744A4c9c9F40A443ac2A5747D4f3b773e5d86763"
        ],
        "urlInfo": "https://www.saitamatoken.com/saitapro/",
        "urlSwap": "https://dex.saita.pro/"
      },
      {
        "factory": "0x2492f0d01b94ef7b8c9c1689f2faa4b8ba7f7d92",
        "__v": 0,
        "backgroundColor": "#fd2b28",
        "chainId": "bsc",
        "inactive": false,
        "logo": "blockstar.svg",
        "name": "Blockstar",
        "slug": "dex7f7d92",
        "textColor": "#fff",
        "factoryBlock": 21444733,
        "router": [
          "0x5009633c920acf842e3845e3bf4e4c1fc5178980",
          "0x5009633C920ACf842E3845e3BF4e4c1fC5178980"
        ],
        "urlInfo": "https://launchpad.blockstar.site",
        "urlSwap": "https://launchpad.blockstar.site/swap/"
      },
      {
        "factory": "0x72d892a36ec529cb2b81430e8d73364ab78277f0",
        "__v": 0,
        "backgroundColor": "#00acfd",
        "chainId": "bsc",
        "inactive": false,
        "logo": "novation.svg",
        "name": "Novation",
        "slug": "dex8277f0",
        "textColor": "#fff",
        "factoryBlock": 21503081,
        "router": [
          "0x0Fa0544003C3Ad35806d22774ee64B7F6b56589b",
          "0x0fa0544003c3ad35806d22774ee64b7f6b56589b"
        ],
        "urlInfo": "https://dapp.thenovation.com/",
        "urlSwap": "https://dapp.thenovation.com/swap"
      },
      {
        "factory": "0x1d2c2821033146afcb59728f246175723dcbbeb7",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "edenswap.png",
        "name": "Solaris",
        "slug": "dexcbbeb7",
        "textColor": "#fff",
        "factoryBlock": 22023657,
        "router": [
          "0x9F0De7a15bDA1F0226b65654854aB5DD90fbA484",
          "0x9f0de7a15bda1f0226b65654854ab5dd90fba484"
        ],
        "urlInfo": "https://eden.solar",
        "urlSwap": "https://eden.solar/#/swap"
      },
      {
        "factory": "0x7b60eb6a0db0dc1d40f3227a04fbb0e941c22810",
        "__v": 0,
        "backgroundColor": "#d7a50e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "robocex.png",
        "name": "Robocex",
        "slug": "dexc22810",
        "textColor": "#fff",
        "factoryBlock": 22704365,
        "router": [
          "0xb2F645d57671f346Dae16B725354c3082EfdD0D2",
          "0xb2f645d57671f346dae16b725354c3082efdd0d2"
        ],
        "urlInfo": "https://www.robocex.live",
        "urlSwap": "https://robocex.live/#/swap"
      },
      {
        "factory": "0x184411227f47f614e49cfab277d0f3bfc65d2316",
        "__v": 0,
        "backgroundColor": "#f37d03",
        "chainId": "bsc",
        "inactive": false,
        "logo": "wakandainu.png",
        "name": "Wakandainu",
        "slug": "dex5d2316",
        "textColor": "#fff",
        "router": [
          "0x94EBC526024828b3c5aDBC834F71cB3eAf4f2c33",
          "0x94ebc526024828b3c5adbc834f71cb3eaf4f2c33"
        ],
        "urlInfo": "https://dex.wakandainu.com/info",
        "urlSwap": "https://dex.wakandainu.com/swap"
      },
      {
        "factory": "0xab9463828be3858eb122b41579dea595c1544bd0",
        "__v": 0,
        "backgroundColor": "#eb57ba",
        "chainId": "bsc",
        "inactive": false,
        "logo": "twoge.png",
        "name": "Twoge",
        "slug": "dex544bd0",
        "textColor": "#fff",
        "factoryBlock": 23223570,
        "router": [
          "0xc0f6bb66e2da1d26a68e11e046c19e29db484ea0",
          "0xC0f6BB66e2DA1d26a68e11e046C19E29dB484ea0"
        ],
        "urlInfo": "https://twogeinu.io/",
        "urlSwap": "https://dex.twogeinu.io/"
      },
      {
        "factory": "0x716d94d0b70f5a50133a61c12d37309dad93bab4",
        "__v": 0,
        "backgroundColor": "#1ec01e",
        "chainId": "bsc",
        "inactive": false,
        "logo": "litedex.svg",
        "name": "Litedex",
        "slug": "dex93bab4",
        "textColor": "#fff",
        "factoryBlock": 23491728,
        "router": [
          "0x2813203479c8D52a0a7BF4620b9325a753Fda9f8",
          "0x2813203479c8d52a0a7bf4620b9325a753fda9f8"
        ],
        "urlInfo": "https://litedex.io/",
        "urlSwap": "https://swap.litedex.io/#/swap"
      },
      {
        "factory": "0x1c3e50dbbcd05831c3a695d45d2b5bcd691ad8d8",
        "__v": 0,
        "backgroundColor": "#dff41f",
        "chainId": "bsc",
        "inactive": false,
        "logo": "kyotoswap.png",
        "name": "KyotoSwap",
        "slug": "dex1ad8d8",
        "textColor": "#000",
        "factoryBlock": 23601269,
        "router": [
          "0x9fd7764e2303E7748736D115304350eC64E403B2",
          "0x9fd7764e2303e7748736d115304350ec64e403b2"
        ],
        "urlInfo": "https://www.kyotoswap.io/",
        "urlSwap": "https://www.kyotoswap.io/swap"
      },
      {
        "factory": "0x10b140e31bc8c844767442f32354e5ba5bc70a8c",
        "__v": 0,
        "backgroundColor": "#edb614",
        "chainId": "bsc",
        "inactive": false,
        "logo": "metastake.png",
        "name": "Metastake",
        "slug": "dexc70a8c",
        "textColor": "#fff",
        "factoryBlock": 23695056,
        "router": [
          "0x3194403edfda78671289b251dc75913984aafdff",
          "0x3194403EDFDA78671289b251Dc75913984AaFDFF"
        ],
        "urlInfo": "https://metastakeswap.com/",
        "urlSwap": "https://metastakeswap.com/swap"
      },
      {
        "factory": "0xbfcd8ac1884a95b77514e59c0407cee9e33db15f",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "starzswap.png",
        "name": "St",
        "slug": "dex3db15f",
        "textColor": "#fff",
        "factoryBlock": 23919639,
        "router": [
          "0x1355A5E85bDE471B9Aa418F869f936446357748B",
          "0x1355a5e85bde471b9aa418f869f936446357748b"
        ],
        "urlInfo": "https://starzeconomy.com/",
        "urlSwap": "https://starzswap.com/"
      },
      {
        "factory": "0xc272b6ae4ad34a6fd0349ca7d102ead5c9193f51",
        "__v": 0,
        "backgroundColor": "#97a452",
        "chainId": "bsc",
        "inactive": false,
        "logo": "groveswap.png",
        "name": "Groveswaap",
        "slug": "dex193f51",
        "textColor": "#fff",
        "factoryBlock": 24417928,
        "router": [
          "0xe764842bdc348ea1166eda4d18504911088eb7c0",
          "0xE764842BdC348EA1166Eda4d18504911088EB7C0",
          "0xe4D96Cf7f47656200FBC3b6110DC8E785216EF0E",
          "0xe4d96cf7f47656200fbc3b6110dc8e785216ef0e"
        ],
        "urlInfo": "https://dashboard.groveswap.io/",
        "urlSwap": "https://app.groveswap.io/swap"
      },
      {
        "factory": "0xafd89d21bdb66d00817d4153e055830b1c2b3970",
        "__v": 0,
        "alike": "velodrome",
        "backgroundColor": "#d200bc",
        "chainId": "bsc",
        "inactive": false,
        "logo": "thena.png",
        "name": "Thena",
        "slug": "dex2b3970",
        "textColor": "#fff",
        "factoryBlock": 24468802,
        "router": [
          "0x20a304a7d126758dfe6B243D0fc515F83bCA8431",
          "0x20a304a7d126758dfe6b243d0fc515f83bca8431"
        ],
        "urlInfo": "https://thena.gitbook.io/thena/",
        "urlSwap": "https://www.thena.fi/swap"
      },
      {
        "factory": "0x198342f35f75bfeb067d15425b4c4482c283a478",
        "__v": 0,
        "backgroundColor": "#f9b707",
        "chainId": "bsc",
        "inactive": false,
        "logo": "swaperyx.png",
        "name": "Swaperyx",
        "slug": "dex83a478",
        "textColor": "#fff",
        "factoryBlock": 25100527,
        "router": [
          "0xfaA9119B13D7567abe98Cd1A4A6f591B94fbEE1D",
          "0xfaa9119b13d7567abe98cd1a4a6f591b94fbee1d"
        ],
        "urlInfo": "https://swaperyx.com/",
        "urlSwap": "https://swaperyx.com/app/#/swap"
      },
      {
        "factory": "0xbe31edb28e1b49fa20daa54d397826ef3d8e3692",
        "__v": 0,
        "backgroundColor": "#aaff01",
        "chainId": "bsc",
        "inactive": false,
        "logo": "shibnobi.svg",
        "name": "Shibnobi Swap",
        "slug": "dex8e3692",
        "textColor": "#000",
        "factoryBlock": 25487466,
        "router": [
          "0x45738c687b1704bef8ddb67d32fc63a55638e5b9",
          "0x45738C687B1704bef8Ddb67D32Fc63a55638e5B9"
        ],
        "urlInfo": "https://www.shibnobi.com",
        "urlSwap": "https://www.shibnobi.com"
      },
      {
        "factory": "0x7de800467afce442019884f51a4a1b9143a34fac",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "",
        "name": "X7finance",
        "slug": "dexa34fac",
        "textColor": "#fff",
        "router": [
          "0x7DE8063E9fB43321d2100e8Ddae5167F56A50060",
          "0x7de8063e9fb43321d2100e8ddae5167f56a50060"
        ],
        "urlInfo": "https://x7.finance/#/swap",
        "urlSwap": "https://app.x7.finance/#/swap"
      },
      {
        "factory": "0x4f8bdc85e3eec5b9de67097c3f59b6db025d9986",
        "__v": 0,
        "backgroundColor": "#14163b",
        "chainId": "bsc",
        "inactive": false,
        "logo": "traderjoe.png",
        "name": "Trader Joe",
        "slug": "dex5d9986",
        "textColor": "#fff",
        "factoryBlock": 26142762,
        "router": [
          "0xbeE5c10Cf6E4F68f831E11C1D9E59B43560B3642",
          "0xbee5c10cf6e4f68f831e11c1d9e59b43560b3642"
        ],
        "urlInfo": "https://traderjoexyz.com/bnb-chain",
        "urlSwap": "https://traderjoexyz.com/bnb-chain/trade"
      },
      {
        "factory": "0x8182b4519faf0df0b8aa3643fed630a9079776d7",
        "__v": 0,
        "backgroundColor": "#ea1e62",
        "chainId": "bsc",
        "inactive": false,
        "logo": "leonicorn.png",
        "name": "Leonicorn",
        "slug": "dex9776d7",
        "textColor": "#fff",
        "factoryBlock": 26218167,
        "router": [
          "0x01DA755E5C278a2FD1dcE3723752f256Bcc7A87E",
          "0x01da755e5c278a2fd1dce3723752f256bcc7a87e"
        ],
        "urlInfo": "https://www.leonicornswap.com/",
        "urlSwap": "https://dex.leonicornswap.com/swap"
      },
      {
        "factory": "0xdb1d10011ad0ff90774d0c6bb92e5c5c8b4461f7",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#ff2d79",
        "chainId": "bsc",
        "inactive": false,
        "logo": "uniswap2.svg",
        "name": "Uniswap V3",
        "slug": "dex4461f7",
        "textColor": "#fff",
        "factoryBlock": 26324014,
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "limitBotSupported": "false",
        "router": [
          "0x5dc88340e1c5c6366864ee415d6034cadd1a9897",
          "0x5Dc88340E1c5c6366864Ee415d6034cadd1A9897"
        ],
        "urlInfo": "https://v3.info.uniswap.org",
        "urlSwap": "https://app.uniswap.org/#/swap"
      },
      {
        "factory": "0x0bfbcf9fa4f9c56b0f40a671ad40e0805a091865",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#f8b612",
        "chainId": "bsc",
        "inactive": false,
        "logo": "pancakeswap.png",
        "name": "Pancakeswap",
        "slug": "dex091865",
        "textColor": "#5a5a5a",
        "version": "v3",
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "limitBotSupported": "false",
        "urlInfo": "https://pancakeswap.info",
        "urlSwap": "https://pancakeswap.finance/swap",
        "router": [
          "0x1b81d678ffb9c0263b24a97847620c99d213eb14",
          "0x1b81D678ffb9C0263b24A97847620C99d213eB14"
        ]
      },
      {
        "factory": "0xe184a4047df6d3fb4ac962a4606d75f5e8e87094",
        "__v": 0,
        "backgroundColor": "#4ded54",
        "chainId": "bsc",
        "inactive": false,
        "logo": "",
        "name": "Stronghands",
        "slug": "dexe87094",
        "textColor": "#000",
        "router": [
          "0x1d17b0fB5989318c9e7F522A2162C5B99289852A",
          "0x1d17b0fb5989318c9e7f522a2162c5b99289852a"
        ],
        "urlInfo": "https://www.stronghands.io/",
        "urlSwap": "https://dex.stronghands.io/swap"
      },
      {
        "factory": "0xcf06c6b25e2bc9e6640cd38f52922ff580045271",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "foxdex.png",
        "name": "Foxdex",
        "slug": "dex045271",
        "textColor": "#fff",
        "router": [
          "0x645516a9519CD5DA39e7d309d1bae9a4f2b40844",
          "0x645516a9519cd5da39e7d309d1bae9a4f2b40844"
        ],
        "urlInfo": "https://foxdex.finance/",
        "urlSwap": "https://foxdex.finance/swap"
      },
      {
        "factory": "0xac1504222a94b700e337bfe4e6daf0ea843d2e7b",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "bsc",
        "inactive": false,
        "logo": "shibceo.png",
        "name": "Shibceo",
        "slug": "dex3d2e7b",
        "textColor": "#fff",
        "router": [
          "0x99273A590E9D11076AD6cAfD2B2f8706B6B11837",
          "0x99273a590e9d11076ad6cafd2b2f8706b6b11837"
        ],
        "urlInfo": "https://shibceo.com/",
        "urlSwap": "https://swap.shibceo.com/"
      },
      {
        "factory": "0x510eda2e4a6e559094e82aed878aead6c99345b1",
        "__v": 0,
        "backgroundColor": "#45bcff",
        "chainId": "bsc",
        "inactive": false,
        "logo": "hkswap.png",
        "name": "Hkswap",
        "slug": "dex9345b1",
        "textColor": "#fff",
        "router": [
          "0x9821bAF31d0F621e275A1533a2ab48CdD7e1Fcf9",
          "0x9821baf31d0f621e275a1533a2ab48cdd7e1fcf9"
        ],
        "urlInfo": "https://hk-swap.co/",
        "urlSwap": "https://hk-swap.co/swap"
      },
      {
        "factory": "0xa8ef6fea013034e62e2c4a9ec1cdb059fe23af33",
        "__v": 0,
        "backgroundColor": "#00e9b1",
        "chainId": "bsc",
        "inactive": false,
        "logo": "smardex.png",
        "name": "Smardex",
        "slug": "dex23af33",
        "textColor": "#000",
        "factoryBlock": 30036610,
        "router": [
          "0x391BeCc8DAaf32b9ba8e602e9527Bf9DA04C8deb",
          "0x391becc8daaf32b9ba8e602e9527bf9da04c8deb"
        ],
        "urlInfo": "https://smardex.io/analytics",
        "urlSwap": "https://smardex.io/swap",
        "alike": "smardex"
      }
    ]
  },
  {
    "chain": "polygon",
    "data": [
      {
        "slug": "quick",
        "factory": "0x5757371414417b8c6caad45baef941abc7d3ab32",
        "router": [
          "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
          "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
          "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
          "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
          "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          "0x1111111254EEB25477B68fb85Ed929f73A960582",
          "0x1111111254eeb25477b68fb85ed929f73a960582"
        ],
        "factoryBlock": 5486521,
        "isDefault": true,
        "name": "Quickswap",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://info.quickswap.exchange/",
        "urlSwap": "https://quickswap.exchange/#/swap",
        "logo": "quickswap.png",
        "logoStyles": "",
        "urlUnicrypt": "https://app.unicrypt.network/amm/quickswap-v1/pair/",
        "backgroundColor": "#1a3b8d",
        "textColor": "#fff",
        "limitBotSupported": "true",
        "version": "v2"
      },
      {
        "slug": "sushi",
        "factory": "0xc35dadb65012ec5796536bd9864ed8773abc74c4",
        "router": [
          "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
          "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506"
        ],
        "factoryBlock": 5486521,
        "isDefault": false,
        "name": "Sushiswap",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://analytics-polygon.sushi.com/",
        "urlSwap": "https://app.sushi.com/swap",
        "logo": "sushiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#3e3e7c",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "dfyn",
        "factory": "0xe7fb3e833efe5f9c441105eb65ef8b261266423b",
        "router": [
          "0xA102072A4C07F06EC3B4900FDC4C7B80b6c57429",
          "0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429"
        ],
        "factoryBlock": 5486521,
        "isDefault": false,
        "name": "DFYN",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://info.dfyn.network/home/",
        "urlSwap": "https://exchange.dfyn.network/#/swap",
        "logo": "dfyn.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#2172e5",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "ape",
        "factory": "0xcf083be4164828f00cae704ec15a36d711491284",
        "router": [
          "0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607",
          "0xc0788a3ad43d79aa53b09c2eacc313a787d1d607"
        ],
        "factoryBlock": 5486521,
        "isDefault": false,
        "name": "Apeswap",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://polygon.info.apeswap.finance/",
        "urlSwap": "https://app.apeswap.finance/swap",
        "logo": "apeswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#a16552",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "wault",
        "factory": "0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef",
        "router": [
          "0x3a1D87f206D12415f5b0A33E786967680AAb4f6d",
          "0x3a1d87f206d12415f5b0a33e786967680aab4f6d"
        ],
        "factoryBlock": 5486521,
        "isDefault": false,
        "name": "Wault",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://app.wault.finance/polygon/",
        "urlSwap": "https://swap.wault.finance/polygon/#/swap",
        "logo": "wault.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#4bafaa",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "slug": "jet",
        "factory": "0x668ad0ed2622c62e24f0d5ab6b6ac1b9d2cd4ac7",
        "router": [
          "0x5C6EC38fb0e2609672BDf628B1fD605A523E5923",
          "0x5c6ec38fb0e2609672bdf628b1fd605a523e5923"
        ],
        "factoryBlock": 5486521,
        "isDefault": false,
        "name": "Jetswap",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://jetswap.finance/",
        "urlSwap": "https://exchange.jetswap.finance/#/swap",
        "logo": "jetswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#fec803",
        "textColor": "#5a5a5a",
        "limitBotSupported": "false"
      },
      {
        "slug": "kyber",
        "factory": "0x5f1fe642060b5b9658c15721ea22e982643c095c",
        "router": [
          "0x546C79662E028B661dFB4767664d0273184E4dD1",
          "0x546c79662e028b661dfb4767664d0273184e4dd1"
        ],
        "factoryBlock": 15968294,
        "isDefault": false,
        "name": "KyberSwap Classic",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": true,
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "logo": "kyberswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#31cb9e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "empire",
        "factory": "0x06530550a48f990360dfd642d2132354a144f31d",
        "router": [
          "0xdADaae6cDFE4FA3c35d54811087b3bC3Cd60F348",
          "0xdadaae6cdfe4fa3c35d54811087b3bc3cd60f348"
        ],
        "factoryBlock": 19333401,
        "isDefault": false,
        "name": "EmpireDex",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.empiredex.org/",
        "urlSwap": "https://polygon.empiredex.org/#/swap",
        "logo": "empiredex.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ae9159",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "univ3poly",
        "factory": "0x1f98431c8ad98523631ae4a59f267346ea31f984",
        "router": [
          "0xE592427A0AEce92De3Edee1F18E0157C05861564",
          "0xe592427a0aece92de3edee1f18e0157c05861564",
          "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
          "0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45",
          "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5",
          "0x4c60051384bd2d3c01bfc845cf5f4b44bcbe9de5",
          "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
          "0x1a1ec25DC08e98e5E93F1104B5e5cdD298707d31",
          "0xD1ca1F4dBB645710f5D5a9917AA984a47524f49A",
          "0xf5bfcbda96de6a55a3a80af5175a1cbb088d5338",
          "0x75dC8e5F50C8221a82CA6aF64aF811caA983B65f",
          "0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE",
          "0xb31D1B1eA48cE4Bf10ed697d44B747287E785Ad4",
          "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
          "0x1a1ec25dc08e98e5e93f1104b5e5cdd298707d31",
          "0xd1ca1f4dbb645710f5d5a9917aa984a47524f49a",
          "0xf5bfcbda96de6a55a3a80af5175a1cbb088d5338",
          "0x75dc8e5f50c8221a82ca6af64af811caa983b65f",
          "0x1231deb6f5749ef6ce6943a275a1d3e7486f4eae",
          "0xb31d1b1ea48ce4bf10ed697d44b747287e785ad4",
          "0x39E3e49C99834C9573c9FC7Ff5A4B226cD7B0E63",
          "0x39e3e49c99834c9573c9fc7ff5a4b226cd7b0e63",
          "0x1111111254fb6c44bAC0beD2854e76F90643097d",
          "0x1111111254fb6c44bac0bed2854e76f90643097d",
          "0x11111112542D85B3EF69AE05771c2dCCff4fAa26",
          "0x11111112542d85b3ef69ae05771c2dccff4faa26"
        ],
        "factoryBlock": 22757547,
        "isDefault": false,
        "name": "Uniswap",
        "alike": "univ3",
        "chainId": "polygon",
        "pairDefault": "TRUE",
        "hasFeePercentagePools": true,
        "hasPoolRatios": false,
        "urlInfo": "https://info.uniswap.org/#/polygon/",
        "urlSwap": "https://app.uniswap.org/#/swap",
        "logo": "uniswap2.svg",
        "logoStyles": "",
        "version": "v3",
        "urlUnicrypt": "",
        "backgroundColor": "#ff2d79",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "polycat",
        "factory": "0x477ce834ae6b7ab003cce4bc4d8697763ff456fa",
        "router": [
          "0x277FEf984D1115a6f543d3D65A2d138faF1db856",
          "0x277fef984d1115a6f543d3d65a2d138faf1db856"
        ],
        "factoryBlock": 17703715,
        "isDefault": false,
        "name": "Polycat",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://polycat.finance/",
        "urlSwap": "https://polycat.finance/swap",
        "logo": "polycat.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#e6e6e6",
        "textColor": "#818ea3",
        "limitBotSupported": "false"
      },
      {
        "slug": "gravity",
        "factory": "0x3ed75aff4094d2aaa38fafca64ef1c152ec1cf20",
        "router": [
          "0x02bc4F42b998FC23355c780A756446d9998d78dE",
          "0x02bc4f42b998fc23355c780a756446d9998d78de"
        ],
        "factoryBlock": 18134755,
        "isDefault": false,
        "name": "Gravity Finance",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://gravityfinance.io/",
        "urlSwap": "https://gravityfinance.io/swap",
        "logo": "gravityfinance.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#fea602",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "firebird",
        "factory": "0x5de74546d3b86c8df7feec30253865e1149818c8",
        "router": [
          "0xF6fa9Ea1f64f1BBfA8d71f7f43fAF6D45520bfac",
          "0xf6fa9ea1f64f1bbfa8d71f7f43faf6d45520bfac"
        ],
        "factoryBlock": 15139510,
        "isDefault": false,
        "name": "Firebird",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://firebird.finance/",
        "urlSwap": "https://app.firebird.finance/",
        "logo": "firebird.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#db5b30",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "acy",
        "factory": "0x3d077c05c3abce52257e453607209f81d9db01fc",
        "router": [
          "0x4DCa8E42634abdE1925ebB7f82AC29Ea00d34bA2",
          "0x4dca8e42634abde1925ebb7f82ac29ea00d34ba2"
        ],
        "factoryBlock": 23016226,
        "isDefault": false,
        "name": "ACY Finance",
        "alike": "",
        "chainId": "polygon",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://acy.finance/",
        "urlSwap": "https://app.acy.finance/#/exchange",
        "logo": "acyfinance.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#eb5c20",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xe3bd06c7ac7e1ceb17bdd2e5ba83e40d1515af2a",
        "__v": 0,
        "backgroundColor": "#378174",
        "chainId": "polygon",
        "inactive": false,
        "logo": "elkfinance.svg",
        "name": "Elk Finance",
        "slug": "dex15af2a",
        "textColor": "#fff",
        "factoryBlock": 13103735,
        "router": [
          "0xf38a7A7Ac2D745E2204c13F824c00139DF831FFf",
          "0xf38a7a7ac2d745e2204c13f824c00139df831fff"
        ],
        "urlInfo": "https://matic-info.elk.finance/home",
        "urlSwap": "https://app.elk.finance/#/swap/"
      },
      {
        "factory": "0x800b052609c355ca8103e06f022aa30647ead60a",
        "__v": 0,
        "backgroundColor": "#57c2ff",
        "chainId": "polygon",
        "inactive": false,
        "logo": "cometh.png",
        "name": "Comethswap",
        "slug": "dexead60a",
        "textColor": "#fff",
        "factoryBlock": 11633169,
        "router": [
          "0x93bcDc45f7e62f89a8e901DC4A0E2c6C427D9F25",
          "0x93bcdc45f7e62f89a8e901dc4a0e2c6c427d9f25"
        ],
        "urlInfo": "https://www.cometh.io/",
        "urlSwap": "https://swap.cometh.io/#/swap"
      },
      {
        "factory": "0x43ce21cdceec70828220df623b3b183d86ed1db2",
        "__v": 0,
        "backgroundColor": "#51ccc5",
        "chainId": "polygon",
        "inactive": false,
        "logo": "pippi.svg",
        "name": "Pippi.finance",
        "slug": "dexed1db2",
        "textColor": "#fff",
        "factoryBlock": 18579641,
        "router": [
          "0x07839F6F3CD0BB5b92F51c4fAc3Ab6d62cB16B47",
          "0x07839f6f3cd0bb5b92f51c4fac3ab6d62cb16b47"
        ],
        "urlInfo": "https://info.pippi.finance/home",
        "urlSwap": "https://swap.pippi.finance/"
      },
      {
        "factory": "0x624ccf581371f8a4493e6abde46412002555a1b6",
        "__v": 0,
        "backgroundColor": "#94cc76",
        "chainId": "polygon",
        "inactive": false,
        "logo": "dino.svg",
        "name": "Dino",
        "slug": "dex55a1b6",
        "textColor": "#fff",
        "factoryBlock": 22634106,
        "router": [
          "0x6AC823102CB347e1f5925C634B80a98A3aee7E03",
          "0x6ac823102cb347e1f5925c634b80a98a3aee7e03"
        ],
        "urlInfo": "https://dinoswap.exchange/?t=l",
        "urlSwap": "https://trade.dinoswap.exchange/?t=l#/swap"
      },
      {
        "factory": "0x293f45b6f9751316672da58ae87447d712af85d7",
        "__v": 0,
        "backgroundColor": "#fa7005",
        "chainId": "polygon",
        "inactive": false,
        "logo": "vulcandex.svg",
        "name": "Vulcan DEX",
        "slug": "dexaf85d7",
        "textColor": "#fff",
        "factoryBlock": 24667303,
        "router": [
          "0x667127d30d2112966503c0ce34071e23f982f18e",
          "0x667127d30D2112966503C0ce34071e23f982F18e"
        ],
        "urlInfo": "https://vulcandex.vulcanforged.com/home",
        "urlSwap": "https://vulcandex.vulcanforged.com/swap"
      },
      {
        "factory": "0x56cfc115e04dfcda3ad47eae1db86f70c1f8d8a5",
        "__v": 0,
        "backgroundColor": "#7b2d2d",
        "chainId": "polygon",
        "inactive": false,
        "logo": "jamon.png",
        "name": "Jamonswap",
        "slug": "dexf8d8a5",
        "textColor": "#fff",
        "factoryBlock": 21641376,
        "urlInfo": "https://jamonswap.finance",
        "urlSwap": "https://jamonswap.finance/swap"
      },
      {
        "factory": "0x684d8c187be836171a1af8d533e4724893031828",
        "__v": 0,
        "backgroundColor": "#686df1",
        "chainId": "polygon",
        "inactive": false,
        "logo": "tetu.svg",
        "name": "Tetu",
        "slug": "dex031828",
        "textColor": "#fff",
        "factoryBlock": 20350444,
        "router": [
          "0xBCA055F25c3670fE0b1463e8d470585Fe15Ca819",
          "0xbca055f25c3670fe0b1463e8d470585fe15ca819"
        ],
        "urlInfo": "https://app.tetu.io/",
        "urlSwap": "https://swap.tetu.io/#/"
      },
      {
        "factory": "0xbb0fa753ae6fddb78bb66d70fc223e4a10d5f4d4",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "polygon",
        "inactive": false,
        "logo": "smartswap.png",
        "name": "SmartSwap",
        "slug": "dexd5f4d4",
        "textColor": "#fff",
        "factoryBlock": 23705464,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x5f5acbd6c1c98d380aa12e614510bacf39fbd531"
        ],
        "urlInfo": "https://prodocs.rigelprotocol.com/smartswap-v2/info",
        "urlSwap": "https://smartswap.rigelprotocol.com/swap?chain=pn"
      },
      {
        "factory": "0x1b44bf43111a26206f9735319a0c438dd1c030b2",
        "__v": 0,
        "backgroundColor": "#ff501a",
        "chainId": "polygon",
        "inactive": false,
        "logo": "nacho.png",
        "name": "Nacho Finance",
        "slug": "dexc030b2",
        "textColor": "#fff",
        "factoryBlock": 24532805,
        "router": [
          "0x4237a813604bD6815430d55141EA2C24D4543e44",
          "0x4237a813604bd6815430d55141ea2c24d4543e44"
        ],
        "urlInfo": "https://www.nachoxyz.com/",
        "urlSwap": "https://www.nachoxyz.com/"
      },
      {
        "factory": "0xb581d0a3b7ea5cdc029260e989f768ae167ef39b",
        "__v": 0,
        "backgroundColor": "#a16552",
        "chainId": "polygon",
        "inactive": false,
        "logo": "radioshack.png",
        "name": "RadioShack",
        "slug": "dex7ef39b",
        "textColor": "#fff",
        "factoryBlock": 24980768,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0xaf877420786516fc6692372c209e0056169eebaf"
        ],
        "urlInfo": "https://info137.radioshack.org",
        "urlSwap": "https://app.radioshack.org/es/swap"
      },
      {
        "factory": "0xd36aba9ec96523b0a89886c76065852adfe2eb39",
        "__v": 0,
        "backgroundColor": "#eaa20a",
        "chainId": "polygon",
        "inactive": false,
        "logo": "justmoney.svg",
        "name": "Just Money",
        "slug": "dexe2eb39",
        "textColor": "#fff",
        "factoryBlock": 22056827,
        "router": [
          "0x57884B46D815D7CF42C04CF226728A0D787EF58D",
          "0x57884b46d815d7cf42c04cf226728a0d787ef58d"
        ],
        "urlInfo": "https://poly.justmoney.exchange/",
        "urlSwap": "https://poly.justmoney.exchange/"
      },
      {
        "factory": "0x09413fff3a08e260c98ec7e165e814023db252f9",
        "__v": 0,
        "backgroundColor": "#000",
        "chainId": "polygon",
        "inactive": false,
        "logo": "famoso.svg",
        "name": "Famoso",
        "slug": "dexb252f9",
        "textColor": "#fff",
        "factoryBlock": 25718435,
        "router": [
          "0x3aB8a591d22147c7Ad2E2B2e166fc2E3A2041301",
          "0x3ab8a591d22147c7ad2e2b2e166fc2e3a2041301"
        ],
        "urlInfo": "https://info.santafeapp.io",
        "urlSwap": "https://dapp.santafeapp.io",
        "logoStyles": "{ background-color: #000; }"
      },
      {
        "factory": "0x9476d561622e905f20613f08fa796ea2562dfbb0",
        "__v": 0,
        "backgroundColor": "#1e67f1",
        "chainId": "polygon",
        "inactive": false,
        "logo": "stableworld.svg",
        "name": "StableWorld",
        "slug": "dex2dfbb0",
        "textColor": "#fff",
        "factoryBlock": 25986489,
        "router": [
          ""
        ],
        "urlInfo": "https://stableworld.io/#/",
        "urlSwap": "https://stableworld.io/#/swap"
      },
      {
        "factory": "0x1d21db6cde1b18c7e47b0f7f42f4b3f68b9beec9",
        "__v": 0,
        "backgroundColor": "#33284c",
        "chainId": "polygon",
        "inactive": false,
        "logo": "dystopia.svg",
        "name": "Dystopia",
        "slug": "dex9beec9",
        "textColor": "#fff",
        "factoryBlock": 27986220,
        "router": [
          "0xbE75Dd16D029c6B32B7aD57A0FD9C1c20Dd2862e",
          "0xbe75dd16d029c6b32b7ad57a0fd9c1c20dd2862e"
        ],
        "urlInfo": "https://info.dystopia.exchange/home",
        "urlSwap": "https://www.dystopia.exchange/swap"
      },
      {
        "factory": "0xc2544a32872a91f4a553b404c6950e89de901fdb",
        "__v": 0,
        "backgroundColor": "#ededef",
        "chainId": "polygon",
        "inactive": false,
        "logo": "fraxfinance.svg",
        "name": "Frax Finance",
        "slug": "dex901fdb",
        "textColor": "#000",
        "factoryBlock": 28641563,
        "router": [
          "0x9bc2152fD37b196C0Ff3C16f5533767c9A983971",
          "0x9bc2152fd37b196c0ff3c16f5533767c9a983971"
        ],
        "urlInfo": "https://app.frax.finance/",
        "urlSwap": "https://app.frax.finance/swap/main",
        "logoStyles": "{ background-color: #000; }"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Private",
        "slug": "dodov2private",
        "textColor": "#000",
        "factoryBlock": 20094984,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0xd24153244066f0afa9415563bfc7ba248bfb7a51",
        "chainId": "polygon"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Stable",
        "slug": "dodov2stable",
        "textColor": "#000",
        "factoryBlock": 14604445,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x43c49f8dd240e1545f147211ec9f917376ac1e87",
        "chainId": "polygon"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Zoo",
        "slug": "dodov1",
        "textColor": "#000",
        "factoryBlock": 14359435,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x357c5e9cfa8b834edcef7c7aabd8f9db09119d11",
        "chainId": "polygon"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Vendor Machine",
        "slug": "dodov2public",
        "textColor": "#000",
        "factoryBlock": 14604330,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x79887f65f83bdf15bcc8736b5e5bcdb48fb8fe13",
        "chainId": "polygon"
      },
      {
        "factory": "0x5f1dddbf348ac2fbe22a163e30f99f9ece3dd50a",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "polygon",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex3dd50a",
        "textColor": "#fff",
        "factoryBlock": 29347468,
        "router": [
          "0xC1e7dFE73E1598E3910EF4C7845B68A9Ab6F4c83",
          "0xc1e7dfe73e1598e3910ef4c7845b68a9ab6f4c83"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "alike": "univ3"
      },
      {
        "factory": "0x1c758af0688502e49140230f6b0ebd376d429be5",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "polygon",
        "inactive": false,
        "logo": "kyberswap.png",
        "name": "KyberSwap Classic",
        "slug": "dex429be5",
        "textColor": "#fff",
        "alike": "kyber",
        "router": [
          "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0",
          "0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0"
        ]
      },
      {
        "factory": "0x8df775934b923c969d4c782c596e7a23257a0f8a",
        "__v": 0,
        "backgroundColor": "#121926",
        "chainId": "polygon",
        "inactive": false,
        "logo": "dexvers.svg",
        "name": "Dexvers",
        "slug": "dex7a0f8a",
        "textColor": "#fff",
        "factoryBlock": 30073981,
        "router": [
          "0x5488a6db5e6de0ffaf58588213580220bd197115",
          "0x5488A6Db5E6DE0ffAF58588213580220bD197115"
        ],
        "urlInfo": "https://app.dexvers.com",
        "urlSwap": "https://app.dexvers.com"
      },
      {
        "factory": "0x7cfb780010e9c861e03bcbc7ac12e013137d47a5",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "polygon",
        "inactive": false,
        "logo": "mmf.png",
        "name": "Mad Meerkat Finance",
        "slug": "dex7d47a5",
        "textColor": "#fff",
        "factoryBlock": 31388443,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x51aBA405De2b25E5506DeA32A6697F450cEB1a17",
          "0x51aba405de2b25e5506dea32a6697f450ceb1a17"
        ],
        "urlInfo": "https://polymm.finance/",
        "urlSwap": "https://polymm.finance/swap"
      },
      {
        "factory": "0x9ef87ba6eec990e44f1bb56676c65fd8f5bb5770",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "polygon",
        "inactive": false,
        "logo": "spicetrade.jpg",
        "name": "Spice Trade",
        "slug": "dexbb5770",
        "textColor": "#fff",
        "factoryBlock": 31595281,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0xbc3e39a7da290263a74338fd45ff71f482ae123a"
        ],
        "urlInfo": "https://app.spicetrade.ai/",
        "urlSwap": "https://swap.spicetrade.ai/swap"
      },
      {
        "factory": "0xa0ead99f785f09ceb1607cef27181cedcecaa4c6",
        "__v": 0,
        "backgroundColor": "#612180",
        "chainId": "polygon",
        "inactive": false,
        "logo": "despace.svg",
        "name": "Despace",
        "slug": "dexcaa4c6",
        "textColor": "#fff",
        "factoryBlock": 29651375,
        "router": [
          "0x275eB6da9De810b2A3072d6Bbf4CD61f2269581E",
          "0x275eb6da9de810b2a3072d6bbf4cd61f2269581e"
        ],
        "urlInfo": "https://despace.app",
        "urlSwap": "https://despace.app/swap"
      },
      {
        "factory": "0x54f454d747e037da288db568d4121117eab34e79",
        "__v": 0,
        "backgroundColor": "#fff",
        "chainId": "polygon",
        "inactive": false,
        "logo": "fraxfinance.svg",
        "name": "Fraxswap",
        "slug": "dexb34e79",
        "textColor": "#000",
        "router": [
          "0xE52D0337904D4D0519EF7487e707268E1DB6495F",
          "0xe52d0337904d4d0519ef7487e707268e1db6495f"
        ],
        "urlInfo": "https://app.frax.finance/",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "factory": "0x411b0facc3489691f28ad58c47006af5e3ab3a28",
        "__v": 0,
        "backgroundColor": "#1a3b8d",
        "chainId": "polygon",
        "inactive": false,
        "logo": "quickswap.png",
        "name": "Quickswap",
        "slug": "quickswapv3",
        "textColor": "#fff",
        "alike": "univ3",
        "factoryBlock": 32610688,
        "router": [
          "0xf5b509bB0909a69B1c207E495f687a596C168E12",
          "0xf5b509bb0909a69b1c207e495f687a596c168e12"
        ],
        "urlInfo": "https://info.quickswap.exchange/",
        "urlSwap": "https://quickswap.exchange/#/swap",
        "urlUnicrypt": "https://app.unicrypt.network/amm/quickswap-v1/pair/",
        "version": "v3"
      },
      {
        "factory": "0xab7dac1daf712693539d770a967a9bc7ba47470c",
        "__v": 0,
        "backgroundColor": "#0066ff",
        "chainId": "polygon",
        "inactive": false,
        "logo": "crowdswap.png",
        "name": "CrowdSwap",
        "slug": "dex47470c",
        "textColor": "#fff",
        "factoryBlock": 37984740,
        "router": [
          "0xE33cfc23F457304D64f6091E0cdD1387b56D04D2",
          "0xe33cfc23f457304d64f6091e0cdd1387b56d04d2"
        ],
        "urlInfo": "https://crowdswap.org/",
        "urlSwap": "https://app.crowdswap.org/swap"
      },
      {
        "factory": "0x722272d36ef0da72ff51c5a65db7b870e2e8d4ee",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "polygon",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Factory",
        "slug": "dexe8d4ee",
        "textColor": "#fff",
        "factoryBlock": 19613852,
        "alike": "curveFactory",
        "urlInfo": "https://curve.fi/#/polygon/pools",
        "urlSwap": "https://curve.fi/#/polygon/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x094d12e5b541784701fd8d65f11fc0598fbc6332",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "polygon",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve",
        "slug": "dexbc6332",
        "textColor": "#fff",
        "factoryBlock": 13991825,
        "alike": "curve",
        "urlInfo": "https://curve.fi/#/polygon/pools",
        "urlSwap": "https://curve.fi/#/polygon/swap",
        "router": [
          "0xfA9a30350048B2BF66865ee20363067c66f67e58",
          "0xfa9a30350048b2bf66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x47bb542b9de58b970ba50c9dae444ddb4c16751a",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "polygon",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dex16751a",
        "textColor": "#fff",
        "alike": "curveCryptoRegistry",
        "factoryBlock": 23556360,
        "urlInfo": "https://curve.fi/#/polygon/pools",
        "urlSwap": "https://curve.fi/#/polygon/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0xe5de15a9c9bbedb4f5ec13b131e61245f2983a69",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "polygon",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dex983a69",
        "textColor": "#fff",
        "alike": "curveCryptoFactory",
        "urlInfo": "https://curve.fi/#/polygon/pools",
        "urlSwap": "https://curve.fi/#/polygon/swap",
        "factoryBlock": 26192231,
        "router": [
          "0xfA9a30350048B2BF66865ee20363067c66f67e58",
          "0xfa9a30350048b2bf66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x9a1e1681f6d59ca051776410465afada6384398f",
        "__v": 0,
        "backgroundColor": "#00e9b1",
        "chainId": "polygon",
        "inactive": false,
        "logo": "smardex.png",
        "name": "Smardex",
        "slug": "dex84398f",
        "textColor": "#000",
        "factoryBlock": 45180001,
        "router": [
          "0xA8EF6FEa013034E62E2C4A9Ec1CDb059fE23Af33",
          "0xa8ef6fea013034e62e2c4a9ec1cdb059fe23af33"
        ],
        "urlInfo": "https://smardex.io/analytics",
        "urlSwap": "https://smardex.io/swap",
        "alike": "smardex"
      }
    ]
  },
  {
    "chain": "avalanche",
    "data": [
      {
        "slug": "pangolin",
        "factory": "0xefa94de7a4656d787667c749f7e1223d71e9fd88",
        "router": [
          "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
          "0xe54ca86531e17ef3616d22ca28b0d458b6c89106"
        ],
        "factoryBlock": 56877,
        "isDefault": false,
        "name": "Pangolin",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://pangolin.exchange/",
        "urlSwap": "https://app.pangolin.exchange/#/swap",
        "logo": "pangolin.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ff6b00",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "lydia",
        "factory": "0xe0c1bb6df4851feeedc3e14bd509feaf428f7655",
        "router": [
          "0xA52aBE4676dbfd04Df42eF7755F01A3c41f28D27",
          "0xa52abe4676dbfd04df42ef7755f01a3c41f28d27"
        ],
        "factoryBlock": 1160616,
        "isDefault": false,
        "name": "Lydia Finance",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.lydia.finance/",
        "urlSwap": "https://exchange.lydia.finance/#/swap",
        "logo": "lydiafinance.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#17223e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "elk",
        "factory": "0x091d35d7f63487909c863001ddca481c6de47091",
        "router": [
          "0x9E4AAbd2B3E60Ee1322E94307d0776F2c8e6CFbb",
          "0x9e4aabd2b3e60ee1322e94307d0776f2c8e6cfbb"
        ],
        "factoryBlock": 659666,
        "isDefault": false,
        "name": "Elk Finance",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://elk.finance/",
        "urlSwap": "https://app.elk.finance/#/swap/",
        "logo": "elkfinance.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#009f55",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "baguette",
        "factory": "0x3587b8c0136c2c3605a9e5b03ab54da3e4044b50",
        "router": [
          "0xf8C7F3f754709a7A4353fB647C3aAb6B1b0c84aD",
          "0xf8c7f3f754709a7a4353fb647c3aab6b1b0c84ad"
        ],
        "factoryBlock": 1115896,
        "isDefault": false,
        "name": "Baguette",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://baguette.exchange/",
        "urlSwap": "https://app.baguette.exchange/#/swap",
        "logo": "baguette.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#eac178",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "hurricane",
        "factory": "0x7009b3619d5ee60d0665ba27cf85edf95fd8ad01",
        "router": [
          "0xb9a9BB6CC39387548BAA7185fbFf51d47eef8771",
          "0xb9a9bb6cc39387548baa7185fbff51d47eef8771"
        ],
        "factoryBlock": 7651621,
        "isDefault": false,
        "name": "Hurricane Swap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://hurricaneswap.com/#/",
        "urlSwap": "https://hurricaneswap.com/#/swap",
        "logo": "hurricaneswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#442150",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "sushi",
        "factory": "0xc35dadb65012ec5796536bd9864ed8773abc74c4",
        "router": [
          "0xC4729E56b831d74bBc18797e0e17A295fA77488c",
          "0xc4729e56b831d74bbc18797e0e17a295fa77488c"
        ],
        "factoryBlock": 506190,
        "isDefault": false,
        "name": "Sushiswap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://sushiswap.vision",
        "urlSwap": "https://app.sushi.com/swap",
        "logo": "sushiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#3e3e7c",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "olive",
        "factory": "0x4fe4d8b01a56706bc6cad26e8c59d0c7169976b3",
        "router": [
          "0x0c45FB63001b56a21e29c7dcc1727bfDA273a368",
          "0x0c45fb63001b56a21e29c7dcc1727bfda273a368"
        ],
        "factoryBlock": 935569,
        "isDefault": false,
        "name": "Olive Cash",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://olive.cash/",
        "urlSwap": "https://olive.cash/swap",
        "logo": "olivecash.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#b18856",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "dct",
        "factory": "0xfba564939397e71c75c9cbb29e6e23b89e4272be",
        "router": [
          "0xFBA564939397e71c75c9CbB29E6E23b89e4272BE",
          "0xfba564939397e71c75c9cbb29e6e23b89e4272be"
        ],
        "factoryBlock": 1357932,
        "isDefault": false,
        "name": "DCT DAO",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://dctdao.org/",
        "urlSwap": "https://dex.dctdao.org/#/swap",
        "logo": "dctdao.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#fff",
        "textColor": "#6da8ff",
        "limitBotSupported": "false"
      },
      {
        "slug": "complus",
        "factory": "0x5c02e78a3969d0e64aa2cfa765acc1d671914ac0",
        "router": [
          "0x78c18E6BE20df11f1f41b9635F3A18B8AD82dDD1",
          "0x78c18e6be20df11f1f41b9635f3a18b8ad82ddd1"
        ],
        "factoryBlock": 20982,
        "isDefault": false,
        "name": "Complus",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://complus.exchange/",
        "urlSwap": "https://avadex.complus.exchange/#/",
        "logo": "complus.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#b22234",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "unify",
        "factory": "0x839547067bc885db205f5fa42dcfeecdff5a8530",
        "router": [
          "0xBd562d5cF2c62Da3143D862aF39eDb6dF59A4679",
          "0xbd562d5cf2c62da3143d862af39edb6df59a4679"
        ],
        "factoryBlock": 6716451,
        "isDefault": false,
        "name": "Unify",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://unifiprotocol.com/",
        "urlSwap": "https://utrade.finance/",
        "logo": "unify.png",
        "logoStyles": "{ background-color: #000; }",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#000",
        "textColor": "#00e676",
        "limitBotSupported": "false"
      },
      {
        "slug": "empire",
        "factory": "0x06530550a48f990360dfd642d2132354a144f31d",
        "router": [
          "0xd12CB7ccD5423D287A429B3528D3a1d16b7f50AF",
          "0xd12cb7ccd5423d287a429b3528d3a1d16b7f50af"
        ],
        "factoryBlock": 3748837,
        "isDefault": false,
        "name": "EmpireDex",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.empiredex.org/",
        "urlSwap": "https://avax.empiredex.org/#/swap",
        "logo": "empiredex.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ae9159",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "bao",
        "factory": "0xf9418c8f2dc02305df5036d9de3e4146bf1abc46",
        "router": [
          "0xb7453127E28EE32f97fbA7a4E990de6C92C8aC50",
          "0xb7453127e28ee32f97fba7a4e990de6c92c8ac50"
        ],
        "factoryBlock": 64698,
        "isDefault": false,
        "name": "Baoswap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.baoswap.xyz/",
        "urlSwap": "https://www.baoswap.xyz/#/swap",
        "logo": "baoswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#ffc3ab",
        "textColor": "#000",
        "limitBotSupported": "false"
      },
      {
        "slug": "traderjoe",
        "factory": "0x9ad6c38be94206ca50bb0d90783181662f0cfa10",
        "router": [
          "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
          "0x60ae616a2155ee3d9a68541ba4544862310933d4",
          "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          "0xe0C38b2a8D09aAD53f1C67734B9A95E43d5981c0",
          "0xe0c38b2a8d09aad53f1c67734b9a95e43d5981c0"
        ],
        "factoryBlock": 2486392,
        "isDefault": true,
        "name": "Trader Joe",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://traderjoexyz.com/avalanche",
        "urlSwap": "https://traderjoexyz.com/avalanche/trade",
        "logo": "traderjoe.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "https://app.unicrypt.network/amm/traderjoe-v1/pair/",
        "backgroundColor": "#14163b",
        "textColor": "#fff",
        "limitBotSupported": "true"
      },
      {
        "slug": "yeti",
        "factory": "0x58c8cd291fa36130119e6deb9e520fbb6aca1c3a",
        "router": [
          "0x262DcFB36766C88E6A7a2953c16F8defc40c378A",
          "0x262dcfb36766c88e6a7a2953c16f8defc40c378a"
        ],
        "factoryBlock": 184726,
        "isDefault": false,
        "name": "Yeti Swap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://www.yetiswap.app/",
        "urlSwap": "https://exchange.yetiswap.app/#/swap",
        "logo": "yetiswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#2c2f36",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "party",
        "factory": "0x58a08bc28f3e8dab8fb2773d8f243bc740398b09",
        "router": [
          "0xB2c835d90E96203Ba940462145AEB61481443605",
          "0xb2c835d90e96203ba940462145aeb61481443605"
        ],
        "factoryBlock": 3237021,
        "isDefault": false,
        "name": "PartySwap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://partyswap.io/",
        "urlSwap": "https://app.partyswap.io/#/home",
        "logo": "partyswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#262652",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "storm",
        "factory": "0x03c51a75a94b1cd075d6686846405dbdafbde390",
        "router": [
          "0xA05728c5F9e4B30C66033C429bBdB5279e399d21",
          "0xa05728c5f9e4b30c66033c429bbdb5279e399d21"
        ],
        "factoryBlock": 7626373,
        "isDefault": false,
        "name": "StormSwap",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://stormswap.finance/",
        "urlSwap": "https://swap.stormswap.finance/swap",
        "logo": "stormswap.png",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#f6f7f7",
        "textColor": "#000",
        "limitBotSupported": "false"
      },
      {
        "slug": "canary",
        "factory": "0xcfba329d49c24b70f3a8b9cc0853493d4645436b",
        "router": [
          "0x06f8ED60393AC6A4B16900273C9313222dfe9940",
          "0x06f8ed60393ac6a4b16900273c9313222dfe9940"
        ],
        "factoryBlock": 2134964,
        "isDefault": false,
        "name": "Canary Exchange",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://canary.exchange/",
        "urlSwap": "https://app.canary.exchange/",
        "logo": "canaryexchange.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#2c2f36",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "thorus",
        "factory": "",
        "router": [
          "0xb5b2444eDF79b00d40f463f79158D1187a0D0c25",
          "0xb5b2444edf79b00d40f463f79158d1187a0d0c25"
        ],
        "factoryBlock": 8630502,
        "isDefault": false,
        "name": "Thorus",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "urlInfo": "https://thorus.fi/",
        "urlSwap": "https://app.thorus.fi/dashboard",
        "logo": "thorus.svg",
        "logoStyles": "{ background-color: #1a1b23; }",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#1a1b23",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "slug": "kyber",
        "factory": "0x10908c875d865c66f271f5d3949848971c9595c9",
        "router": [
          "0x8Efa5A9AD6D594Cf76830267077B78cE0Bc5A5F8",
          "0x839547067bc885db205f5fa42dcfeecdff5a8530"
        ],
        "factoryBlock": 3662467,
        "isDefault": false,
        "name": "KyberSwap Classic",
        "alike": "",
        "chainId": "avalanche",
        "pairDefault": "",
        "hasFeePercentagePools": false,
        "hasPoolRatios": true,
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "logo": "kyberswap.svg",
        "logoStyles": "",
        "version": "",
        "urlUnicrypt": "",
        "backgroundColor": "#31cb9e",
        "textColor": "#fff",
        "limitBotSupported": "false"
      },
      {
        "factory": "0xd9362aa8e0405c93299c573036e7fb4ec3be1240",
        "__v": 0,
        "backgroundColor": "#54de73",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "alligator.svg",
        "name": "Alligator",
        "slug": "dexbe1240",
        "textColor": "#fff",
        "factoryBlock": 8797449,
        "router": [
          "0x80B8d36f2A85EC557eC1955c373961e2221c7540",
          "0x80b8d36f2a85ec557ec1955c373961e2221c7540"
        ],
        "urlInfo": "https://www.alligator.exchange/",
        "urlSwap": "https://app.alligator.exchange/#/swap"
      },
      {
        "factory": "0x2db46feb38c57a6621bca4d97820e1fc1de40f41",
        "__v": 0,
        "backgroundColor": "#09bcab",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "hakuswap.png",
        "name": "Hakuswap",
        "slug": "dexe40f41",
        "textColor": "#fff",
        "factoryBlock": 9565446,
        "router": [
          "0x5F1FdcA239362c5b8A8Ada26a256ac5626CC33E0",
          "0x5f1fdca239362c5b8a8ada26a256ac5626cc33e0"
        ],
        "urlInfo": "https://hakuswap.com/",
        "urlSwap": "https://exchange.hakuswap.com/#/swap"
      },
      {
        "factory": "0xa0fbfda09b8815dd42ddc70e4f9fe794257cd9b6",
        "__v": 0,
        "backgroundColor": "#da291c",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "radioshack.svg",
        "name": "RadioShack",
        "slug": "dex7cd9b6",
        "textColor": "#fff",
        "factoryBlock": 12317416,
        "router": [
          "0x763D8D37669bB18064b410e17E3bB46BCF34F487",
          "0x763d8d37669bb18064b410e17e3bb46bcf34f487"
        ],
        "urlInfo": "https://www.radioshack.org/",
        "urlSwap": "https://app.radioshack.org/swap"
      },
      {
        "factory": "0x21cadeb92c8bbfbef98c3098846f0999209c3a97",
        "__v": 0,
        "backgroundColor": "#54de73",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "apex.svg",
        "name": "Apex",
        "slug": "dex9c3a97",
        "textColor": "#fff",
        "factoryBlock": 14033946,
        "router": [
          "0x8aa8e24b5D70ee45861315cf692e98751489C614",
          "0x8aa8e24b5d70ee45861315cf692e98751489c614"
        ],
        "urlInfo": "https://apexnodes.io/",
        "urlSwap": "https://apexswap.io/#/swap"
      },
      {
        "factory": "0x5ca135cb8527d76e932f34b5145575f9d8cbe08e",
        "__v": 0,
        "backgroundColor": "#ededef",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "fraxfinance.svg",
        "name": "Frax Finance",
        "slug": "dexcbe08e",
        "textColor": "#000",
        "factoryBlock": 15079772,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0xc2544A32872A91F4A553b404C6950e89De901fdb",
          "0xc2544a32872a91f4a553b404c6950e89de901fdb"
        ],
        "urlInfo": "https://app.frax.finance",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Vendor Machine",
        "slug": "dodov2public",
        "chainId": "avalanche",
        "textColor": "#000",
        "factoryBlock": 8488454,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0xff133a6d335b50bdaa6612d19e1352b049a8ae6a"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Private",
        "slug": "dodov2private",
        "chainId": "avalanche",
        "textColor": "#000",
        "factoryBlock": 8488710,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0xb7865a5cee051d35b09a48b624d7057d3362655a"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Zoo",
        "slug": "dodov1",
        "textColor": "#000",
        "factoryBlock": 8512457,
        "chainId": "avalanche",
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x8ab2d334ce64b50be9ab04184f7ccba2a6bb6391"
      },
      {
        "backgroundColor": "#ffe804",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Stable",
        "slug": "dodov2stable",
        "textColor": "#000",
        "chainId": "avalanche",
        "factoryBlock": 8488786,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "factory": "0x2b0d94eb7a63b8a2909de1cb3951ecf7ae76d2fe"
      },
      {
        "factory": "0x9c60c867ce07a3c403e2598388673c10259ec768",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "swapsicle.png",
        "name": "Swapsicle",
        "slug": "dex9ec768",
        "textColor": "#fff",
        "router": [
          "0xC7f372c62238f6a5b79136A9e5D16A2FD7A3f0F5",
          "0xc7f372c62238f6a5b79136a9e5d16a2fd7a3f0f5"
        ],
        "urlInfo": "https://www.swapsicle.io/",
        "urlSwap": "https://www.swapsicle.io/swap"
      },
      {
        "factory": "0x0c6a0061f9d0afb30152b8761a273786e51bec6d",
        "__v": 0,
        "backgroundColor": "#014448",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "hunnyswap.png",
        "name": "Hunnyswap",
        "slug": "dex1bec6d",
        "textColor": "#fff",
        "factoryBlock": 15664107,
        "urlInfo": "https://hunnyswap.com",
        "urlSwap": "https://hunnyswap.com/swap"
      },
      {
        "factory": "0x557ade9f0c89d07c396b19c4efac102e4008736e",
        "__v": 0,
        "backgroundColor": "#da0303",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "avatanetwork.svg",
        "name": "Avata Network",
        "slug": "dex08736e",
        "textColor": "#fff",
        "factoryBlock": 15579216,
        "router": [
          "0x7aE9D51DC773Ea7518509786d2017bb964606663",
          "0x7ae9d51dc773ea7518509786d2017bb964606663"
        ],
        "urlInfo": "https://avata-network.gitbook.io/avata/the-project/introduction",
        "urlSwap": "https://app.avata.network/trade"
      },
      {
        "factory": "0x5f1dddbf348ac2fbe22a163e30f99f9ece3dd50a",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex3dd50a",
        "textColor": "#fff",
        "factoryBlock": 15795578,
        "router": [
          "0xC1e7dFE73E1598E3910EF4C7845B68A9Ab6F4c83",
          "0xc1e7dfe73e1598e3910ef4c7845b68a9ab6f4c83"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "alike": "univ3"
      },
      {
        "factory": "0x2ffa939c7db9d4b4278713add0154b70cb82aa82",
        "__v": 0,
        "backgroundColor": "#ff0546",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "avaxpad.svg",
        "name": "AvaxPad",
        "slug": "dex82aa82",
        "textColor": "#000000",
        "factoryBlock": 16374260,
        "router": [
          "0x67fA34C8382d8433f5855F647444759e5e9a829B",
          "0x67fa34c8382d8433f5855f647444759e5e9a829b"
        ],
        "urlInfo": "https://avaxpad.fi/",
        "urlSwap": "https://app.avaxpad.fi/exchange"
      },
      {
        "factory": "0x1c758af0688502e49140230f6b0ebd376d429be5",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Classic",
        "slug": "dex429be5",
        "textColor": "#fff",
        "router": [
          "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0",
          "0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0xe61a092225a6639844693626491200be1333d5cb",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "spicetrade.jpg",
        "name": "Spice Trade",
        "slug": "dex33d5cb",
        "textColor": "#fff",
        "factoryBlock": 18298481,
        "hasFeePercentagePools": false,
        "hasPoolRatios": false,
        "isDefault": false,
        "limitBotSupported": "false",
        "router": [
          "0x52a7b6b5cda855b4dad8bcb8fe40242635d1359f"
        ],
        "urlInfo": "https://app.spicetrade.ai/",
        "urlSwap": "https://swap.spicetrade.ai/swap"
      },
      {
        "factory": "0xc009a670e2b02e21e7e75ae98e254f467f7ae257",
        "__v": 0,
        "backgroundColor": "#14d3ed",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "vapordex.png",
        "name": "Vapor DEX",
        "slug": "dex7ae257",
        "textColor": "#000",
        "factoryBlock": 19911183,
        "router": [
          "0x19C0FC4562A4b76F27f86c676eF5a7e38D12a20d",
          "0x19c0fc4562a4b76f27f86c676ef5a7e38d12a20d"
        ],
        "urlInfo": "https://www.vaporfi.io/",
        "urlSwap": "https://app.vapordex.io/swap"
      },
      {
        "factory": "0x8474ddbe98f5aa3179b3b3f5942d724afcdec9f6",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve",
        "slug": "dexdec9f6",
        "textColor": "#fff",
        "factoryBlock": 5254206,
        "alike": "curve",
        "urlInfo": "https://curve.fi/#/avalanche/pools",
        "urlSwap": "https://curve.fi/#/avalanche/swap",
        "router": [
          "0xfA9a30350048B2BF66865ee20363067c66f67e58",
          "0xfa9a30350048b2bf66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0xb17b674d9c5cb2e441f8e196a2f048a81355d031",
        "__v": 0,
        "backgroundColor": "##1763fd",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Factory",
        "slug": "dex55d031",
        "textColor": "#fff",
        "factoryBlock": 6571428,
        "alike": "curveFactory",
        "urlInfo": "https://curve.fi/#/avalanche/pools",
        "urlSwap": "https://curve.fi/#/avalanche/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x90f421832199e93d01b64daf378b183809eb0988",
        "__v": 0,
        "backgroundColor": "#1763fd",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dexeb0988",
        "textColor": "#fff",
        "alike": "curveCryptoRegistry",
        "factoryBlock": 9384663,
        "urlInfo": "https://curve.fi/#/avalanche/pools",
        "urlSwap": "https://curve.fi/#/avalanche/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0xac7b7eac8310170109301034b8fdb75eca4cc491",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "glacierfinance.png",
        "name": "Glacier Finance",
        "slug": "dex4cc491",
        "textColor": "#fff",
        "factoryBlock": 28094493,
        "router": [
          "0xC5B8Ce3C8C171d506DEb069a6136a351Ee1629DC ",
          "0xc5b8ce3c8c171d506deb069a6136a351ee1629dc"
        ],
        "urlInfo": "https://www.glacier.exchange",
        "urlSwap": "https://www.glacier.exchange/swap"
      },
      {
        "factory": "0x740b1c1de25031c31ff4fc9a62f554a55cdc1bad",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#ff2d79",
        "chainId": "avalanche",
        "inactive": false,
        "logo": "uniswap2.svg",
        "name": "Uniswap V3",
        "slug": "dexdc1bad",
        "textColor": "#fff",
        "factoryBlock": 27832972,
        "hasFeePercentagePools": true,
        "router": [
          "0xbb00FF08d01D300023C629E8fFfFcb65A5a578cE",
          "0xbb00ff08d01d300023c629e8ffffcb65a5a578ce"
        ],
        "urlInfo": "https://v3.info.uniswap.org",
        "urlSwap": "https://app.uniswap.org/#/swap"
      }
    ]
  },
  {
    "chain": "arbitrum",
    "data": [
      {
        "factory": "0xc35dadb65012ec5796536bd9864ed8773abc74c4",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "sushiswap.png",
        "name": "Sushiswap",
        "slug": "dexbc74c4",
        "textColor": "#fff",
        "factoryBlock": 70,
        "router": [
          "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
          "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
          "0x53b08DbD70327b7Ba3B7886Fc9987BC985d27262",
          "0x53b08dbd70327b7ba3b7886fc9987bc985d27262",
          "0x177d36dBE2271A4DdB2Ad8304d82628eb921d790",
          "0x177d36dbe2271a4ddb2ad8304d82628eb921d790",
          "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
          "0x6131b5fae19ea4f9d964eac0408e4408b66337b5",
          "0xdd94018F54e565dbfc939F7C44a16e163FaAb331",
          "0xdd94018f54e565dbfc939f7c44a16e163faab331",
          "0x0c6134Abc08A1EafC3E2Dc9A5AD023Bb08Da86C3",
          "0x0c6134abc08a1eafc3e2dc9a5ad023bb08da86c3"
        ],
        "urlInfo": "https://sushi.com/",
        "urlSwap": "https://app.sushi.com/en/swap",
        "isDefault": true,
        "urlUnicrypt": "https://app.unicrypt.network/amm/sushi-arb1/pair/"
      },
      {
        "factory": "0xbf9173b60a30b9ff8c37cac787b6ee87d5e47916",
        "__v": 0,
        "backgroundColor": "#2e17f2",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "swapr.svg",
        "name": "Swapr",
        "slug": "dexe47916",
        "textColor": "#fff",
        "factoryBlock": 222103,
        "logoStyles": "{ background-color: #2e17f2 }",
        "router": [
          "0x530476d5583724A89c8841eB6Da76E7Af4C0F17E",
          "0x530476d5583724a89c8841eb6da76e7af4c0f17e"
        ],
        "urlInfo": "https://dxstats.eth.limo/#/home",
        "urlSwap": "https://swapr.eth.limo/#/swap?chainId=2"
      },
      {
        "factory": "0x1f98431c8ad98523631ae4a59f267346ea31f984",
        "__v": 0,
        "backgroundColor": "#ff007a",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "uniswap2.svg",
        "name": "Uniswap",
        "slug": "dex31f984",
        "textColor": "#fff",
        "alike": "univ3",
        "hasFeePercentagePools": true,
        "hasPoolRatios": true,
        "isDefault": true,
        "router": [
          "0xE592427A0AEce92De3Edee1F18E0157C05861564",
          "0xe592427a0aece92de3edee1f18e0157c05861564",
          "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5",
          "0x4c60051384bd2d3c01bfc845cf5f4b44bcbe9de5",
          "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
          "0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45"
        ],
        "urlInfo": "https://uniswap.org/",
        "urlSwap": "https://app.uniswap.org/#/swap",
        "factoryBlock": 165,
        "version": "v3"
      },
      {
        "factory": "0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429",
        "__v": 0,
        "backgroundColor": "#f15353",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dfyn.png",
        "name": "DFYN",
        "slug": "dexc57429",
        "textColor": "#fff",
        "factoryBlock": 294140,
        "router": [
          "0xaedE1EFe768bD8A1663A7608c63290C60B85e71c",
          "0xaede1efe768bd8a1663a7608c63290c60b85e71c"
        ],
        "urlInfo": "https://dfyn.network/",
        "urlSwap": "https://exchange.dfyn.network/#/swap"
      },
      {
        "factory": "0x359f20ad0f42d75a5077e65f30274cabe6f4f01a",
        "__v": 0,
        "backgroundColor": "#2e17f2",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "swapr.svg",
        "name": "Swapr",
        "slug": "dexf4f01a",
        "textColor": "#fff",
        "factoryBlock": 277186,
        "logoStyles": "{ background-color: #2e17f2; }",
        "router": [
          "0x530476d5583724A89c8841eB6Da76E7Af4C0F17E",
          "0x530476d5583724a89c8841eb6da76e7af4c0f17e"
        ],
        "urlInfo": "https://dxstats.eth.limo/#/home",
        "urlSwap": "https://swapr.eth.limo/#/swap"
      },
      {
        "factory": "0xd9bfe9979e9ca4b2fe84ba5d4cf963bbcb376974",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Classic",
        "slug": "kyber",
        "textColor": "#fff",
        "factoryBlock": 5837265,
        "hasFeePercentagePools": false,
        "hasPoolRatios": true,
        "router": [
          "0xEaE47c5D99f7B31165a7f0c5f7E0D6afA25CFd55",
          "0xeae47c5d99f7b31165a7f0c5f7e0d6afa25cfd55"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0xd394e9cc20f43d2651293756f8d320668e850f1b",
        "__v": 0,
        "backgroundColor": "#3595e2",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "arbswap.svg",
        "name": "Arbswap",
        "slug": "dex850f1b",
        "textColor": "#fff",
        "factoryBlock": 3692134,
        "router": [
          "0xD01319f4b65b79124549dE409D36F25e04B3e551",
          "0xd01319f4b65b79124549de409d36f25e04b3e551"
        ],
        "urlInfo": "https://arbswap.io",
        "urlSwap": "https://arbswap.io/swap"
      },
      {
        "factory": "0xa59b2044eafd15ee4def138d410d764c9023e1f0",
        "__v": 0,
        "backgroundColor": "#009f55",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "elkfinance.png",
        "name": "Elk Finance",
        "slug": "dex23e1f0",
        "textColor": "#fff",
        "factoryBlock": 2412294,
        "router": [
          "0x0A2e5A3Dc2f74E5Bfaf0Bf90685A5A899f379Cb0",
          "0x0a2e5a3dc2f74e5bfaf0bf90685a5a899f379cb0"
        ],
        "urlInfo": "https://arbitrum-info.elk.finance/home",
        "urlSwap": "https://app.elk.finance/swap"
      },
      {
        "factory": "0xa6cf3d163358af376ec5e8b7cc5e102a05fde63d",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo private",
        "slug": "dodov2private",
        "textColor": "#000",
        "factoryBlock": 2112221,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io"
      },
      {
        "factory": "0xc8fe2440744dcd733246a4db14093664defd5a53",
        "__v": 0,
        "backgroundColor": "#ffe804",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Stable",
        "slug": "dodov2stable",
        "textColor": "#000",
        "factoryBlock": 226585,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io"
      },
      {
        "factory": "0xda4c4411c55b0785e501332354a036c04833b72b",
        "__v": 0,
        "backgroundColor": "#ffe804",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Vendor Machine",
        "slug": "dodov2public",
        "textColor": "#000",
        "factoryBlock": 226578,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io"
      },
      {
        "factory": "0xbcc3401e16c25eaf4d3fed632ce3288503883b1f",
        "__v": 0,
        "backgroundColor": "#ffe804",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dodo.svg",
        "name": "Dodo Zoo",
        "slug": "dodov1",
        "textColor": "#000",
        "factoryBlock": 226652,
        "urlInfo": "https://dodoex.io",
        "urlSwap": "https://app.dodoex.io",
        "router": [
          "0x88CBf433471A0CD8240D2a12354362988b4593E5",
          "0x88cbf433471a0cd8240d2a12354362988b4593e5"
        ]
      },
      {
        "factory": "0x5ca135cb8527d76e932f34b5145575f9d8cbe08e",
        "__v": 0,
        "backgroundColor": "#ededef",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "fraxfinance.svg",
        "name": "Frax Finance",
        "slug": "dexcbe08e",
        "textColor": "#000",
        "factoryBlock": 12798979,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0xc2544A32872A91F4A553b404C6950e89De901fdb",
          "0xc2544a32872a91f4a553b404c6950e89de901fdb"
        ],
        "urlInfo": "https://app.frax.finance",
        "urlSwap": "https://app.frax.finance/swap/main"
      },
      {
        "factory": "0x5f1dddbf348ac2fbe22a163e30f99f9ece3dd50a",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex3dd50a",
        "textColor": "#fff",
        "factoryBlock": 14137735,
        "router": [
          "0xC1e7dFE73E1598E3910EF4C7845B68A9Ab6F4c83",
          "0xc1e7dfe73e1598e3910ef4c7845b68a9ab6f4c83"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap",
        "alike": "univ3"
      },
      {
        "factory": "0x1c758af0688502e49140230f6b0ebd376d429be5",
        "__v": 0,
        "backgroundColor": "#31cb9e",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Classic",
        "slug": "dex429be5",
        "textColor": "#fff",
        "router": [
          "0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0",
          "0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0x717ef162cf831db83c51134734a15d1ebe9e516a",
        "__v": 0,
        "backgroundColor": "#3346ff",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "integral.png",
        "name": "Integral",
        "slug": "dex9e516a",
        "textColor": "#fff",
        "router": [
          "0x7b96662d4dbbf6894a888ba53afbe2f98359c973",
          "0x7b96662d4dbbf6894a888ba53afbe2f98359c973"
        ],
        "urlInfo": "https://size.integral.link/pools",
        "urlSwap": "https://size.integral.link/swap"
      },
      {
        "factory": "0x6eccab422d763ac031210895c81787e87b43a652",
        "__v": 0,
        "backgroundColor": "#ffaf1d",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "camelot.png",
        "name": "Camelot",
        "slug": "dex43a652",
        "textColor": "#fff",
        "factoryBlock": 35061163,
        "router": [
          "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
          "0xc873fecbd354f5a56e00e710b90ef4201db2448d"
        ],
        "urlInfo": "https://info.camelot.exchange/home",
        "urlSwap": "https://app.camelot.exchange/",
        "urlUnicrypt": "https://app.unicrypt.network/amm/camelot/pair/"
      },
      {
        "factory": "0xd158bd9e8b6efd3ca76830b66715aa2b7bad2218",
        "__v": 0,
        "backgroundColor": "#98ffff",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "3xcalibur.png",
        "name": "3xcalibur",
        "slug": "dexad2218",
        "textColor": "#000",
        "factoryBlock": 35566461,
        "logoStyles": "{ background-color: #000; }",
        "router": [
          "0x8e72bf5A45F800E182362bDF906DFB13d5D5cb5d",
          "0x8e72bf5a45f800e182362bdf906dfb13d5d5cb5d"
        ],
        "urlInfo": "https://www.3xcalibur.com/",
        "urlSwap": "https://app.3xcalibur.com/swap/"
      },
      {
        "factory": "0x71539d09d3890195dda87a6198b98b75211b72f3",
        "__v": 0,
        "backgroundColor": "#ff71b0",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "swapfish.png",
        "name": "Swapfish",
        "slug": "dex1b72f3",
        "textColor": "#fff",
        "factoryBlock": 35972125,
        "router": [
          "0xcDAeC65495Fa5c0545c5a405224214e3594f30d8",
          "0xcdaec65495fa5c0545c5a405224214e3594f30d8"
        ],
        "urlInfo": "https://swapfish.fi/",
        "urlSwap": "https://swapfish.fi/swap"
      },
      {
        "factory": "0x20fafd2b0ba599416d75eb54f48cda9812964f46",
        "__v": 0,
        "backgroundColor": "#3685ed",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "oreoswap.png",
        "name": "Oreoswap",
        "slug": "dex964f46",
        "textColor": "#fff",
        "factoryBlock": 46646983,
        "router": [
          "0x38eEd6a71A4ddA9d7f776946e3cfa4ec43781AE6",
          "0x38eed6a71a4dda9d7f776946e3cfa4ec43781ae6"
        ],
        "urlInfo": "https://oreoswap.finance/",
        "urlSwap": "https://oreoswap.finance/swap"
      },
      {
        "factory": "0xae4ec9901c3076d0ddbe76a520f9e90a6227acb7",
        "__v": 0,
        "backgroundColor": "#14163b",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "traderjoe.png",
        "name": "Trader JOe",
        "slug": "dex27acb7",
        "textColor": "#fff",
        "factoryBlock": 47838075,
        "router": [
          "0xbeE5c10Cf6E4F68f831E11C1D9E59B43560B3642",
          "0xbee5c10cf6e4f68f831e11c1d9e59b43560b3642"
        ],
        "urlInfo": "https://traderjoexyz.com/arbitrum",
        "urlSwap": "https://traderjoexyz.com/arbitrum/trade"
      },
      {
        "factory": "0x1486397b3c0e6feb8c483fad2f03e8c404caede7",
        "__v": 0,
        "backgroundColor": "#fa0178",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "sugarswap.png",
        "name": "SugarSwap",
        "slug": "dexcaede7",
        "textColor": "#fff",
        "factoryBlock": 50907974,
        "router": [
          "0x95111118bd0b4f82cb6e8ca2533571acb5ce99a7",
          "0x95111118bd0B4F82CB6E8Ca2533571acb5ce99A7"
        ],
        "urlInfo": "https://sugarfinance.io/",
        "urlSwap": "https://sugarfinance.io/swap"
      },
      {
        "factory": "0xac2ee06a14c52570ef3b9812ed240bce359772e7",
        "__v": 0,
        "backgroundColor": "#00836a",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "zyberswap.png",
        "name": "Zyberswap",
        "slug": "dex9772e7",
        "textColor": "#fff",
        "factoryBlock": 54768263,
        "router": [
          "0x16e71B13fE6079B4312063F7E81F76d165Ad32Ad",
          "0x16e71b13fe6079b4312063f7e81f76d165ad32ad"
        ],
        "urlInfo": "https://analytics.zyberswap.io/",
        "urlSwap": "https://app.zyberswap.io/exchange/swap"
      },
      {
        "factory": "0x734d84631f00dc0d3fcd18b04b6cf42bfd407074",
        "__v": 0,
        "alike": "velodrome",
        "backgroundColor": "#f0ca71",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "solidlizard.png",
        "name": "Solidlizard",
        "slug": "dex407074",
        "textColor": "#000",
        "factoryBlock": 56682138,
        "router": [
          "0xF26515D5482e2C2FD237149bF6A653dA4794b3D0",
          "0xf26515d5482e2c2fd237149bf6a653da4794b3d0"
        ],
        "urlInfo": "https://info.solidlizard.finance/home",
        "urlSwap": "https://solidlizard.finance/swap"
      },
      {
        "factory": "0xac9d019b7c8b7a4bbac64b2dbf6791ed672ba98b",
        "__v": 0,
        "backgroundColor": "#00f666",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "alienfi.png",
        "name": "Alien",
        "slug": "dex2ba98b",
        "textColor": "#000",
        "factoryBlock": 61419853,
        "logoStyles": "{ background-color: #fff; }",
        "router": [
          "0x863e9610E9E0C3986DCc6fb2cD335e11D88f7D5f",
          "0x863e9610e9e0c3986dcc6fb2cd335e11d88f7d5f"
        ],
        "urlInfo": "https://www.alien.fi",
        "urlSwap": "https://www.alien.fi/swap"
      },
      {
        "factory": "0x36800286f652ddc9bdcffedc4e71fdd207c1d07c",
        "__v": 0,
        "backgroundColor": "#2974e8",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "sharkyswap.png",
        "name": "Sharkyswap",
        "slug": "dexc1d07c",
        "textColor": "#fff",
        "factoryBlock": 61857915,
        "router": [
          "0x29631E68d81b0e02a507f54a799402E8Cfa6a4d8",
          "0x29631e68d81b0e02a507f54a799402e8cfa6a4d8"
        ],
        "urlInfo": "https://sharkyswap.xyz",
        "urlSwap": "https://sharkyswap.xyz/swap"
      },
      {
        "factory": "0x88f1e030eb4c6c4320da3992070baf6c648ce37f",
        "__v": 0,
        "backgroundColor": "#fee094",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "arbiswap.png",
        "name": "Arbiswap",
        "slug": "dex8ce37f",
        "textColor": "#fff",
        "factoryBlock": 61876712,
        "router": [
          "0xc640fD6f9Baa1fce48eD1aBE823355e0356e60a9",
          "0xc640fd6f9baa1fce48ed1abe823355e0356e60a9"
        ],
        "urlInfo": "https://arbiswap.io/",
        "urlSwap": "https://arbiswap.io/swap/"
      },
      {
        "factory": "0xcf083be4164828f00cae704ec15a36d711491284",
        "__v": 0,
        "backgroundColor": "#a16552",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "apeswap.svg",
        "name": "Apeswap",
        "slug": "dex491284",
        "textColor": "#fff",
        "router": [
          "0x7d13268144adcdbEBDf94F654085CC15502849Ff",
          "0x7d13268144adcdbebdf94f654085cc15502849ff"
        ],
        "urlInfo": "https://apeswap.finance/",
        "urlSwap": "https://app.apeswap.finance/swap"
      },
      {
        "factory": "0x1c6e968f2e6c9dec61db874e28589fd5ce3e1f2c",
        "__v": 0,
        "backgroundColor": "#818ea3",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "unknown.png",
        "name": "Arbitrum DEX",
        "slug": "dex3e1f2c",
        "textColor": "#fff",
        "router": [
          "0x3E48298A5Fe88E4d62985DFf65Dee39a25914975",
          "0x3e48298a5fe88e4d62985dff65dee39a25914975"
        ]
      },
      {
        "factory": "0x66020547ce3c861dec7632495d86e1b93da6542c",
        "__v": 0,
        "backgroundColor": "#45808f",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "ponyswap.png",
        "name": "Ponyswap",
        "slug": "dexa6542c",
        "textColor": "#fff",
        "factoryBlock": 69880093,
        "router": [
          "0xab0fc89ADe2aFA4925daa7fF1e0aCcbc30A17153",
          "0xab0fc89ade2afa4925daa7ff1e0accbc30a17153"
        ],
        "urlInfo": "https://ponyswap.finance/",
        "urlSwap": "https://swap.ponyswap.finance/"
      },
      {
        "factory": "0xb17b674d9c5cb2e441f8e196a2f048a81355d031",
        "__v": 0,
        "alike": "curveFactory",
        "backgroundColor": "#1763fd",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Factory",
        "slug": "dexc990d4",
        "textColor": "#fff",
        "factoryBlock": 1413161,
        "urlInfo": "https://curve.fi/#/arbitrum/pools",
        "urlSwap": "https://curve.fi/#/arbitrum/swap",
        "router": [
          "0xfa9a30350048b2bf66865ee20363067c66f67e58",
          "0xfA9a30350048B2BF66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x445fe580ef8d70ff569ab36e80c647af338db351",
        "__v": 0,
        "alike": "curve",
        "backgroundColor": "#1763fd",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve",
        "slug": "dex8db351",
        "textColor": "#fff",
        "factoryBlock": 1362056,
        "urlInfo": "https://curve.fi/#/arbitrum/pools",
        "urlSwap": "https://curve.fi/#/arbitrum/swap",
        "router": [
          "0xfA9a30350048B2BF66865ee20363067c66f67e58",
          "0xfa9a30350048b2bf66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x0e9fbb167df83ede3240d6a5fa5d40c6c6851e15",
        "__v": 0,
        "alike": "curveCryptoRegistry",
        "backgroundColor": "#1763fd",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "curve.png",
        "name": "Curve Crypto",
        "slug": "dex851e15",
        "textColor": "#fff",
        "factoryBlock": 4530115,
        "urlInfo": "https://curve.fi/#/arbitrum/pools",
        "urlSwap": "https://curve.fi/#/arbitrum/swap",
        "router": [
          "0xfA9a30350048B2BF66865ee20363067c66f67e58",
          "0xfa9a30350048b2bf66865ee20363067c66f67e58"
        ]
      },
      {
        "factory": "0x61f4ecd130291e5d5d7809a112f9f9081b8ed3a5",
        "__v": 0,
        "backgroundColor": "#6f0",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "antfarmfinance.png",
        "name": "Antfarm finance",
        "slug": "dex8ed3a5",
        "textColor": "#000",
        "factoryBlock": 73218666,
        "router": [
          "0x1272BA8C0BD855Ff15C4B29bAD660E6F154Fca28",
          "0x1272ba8c0bd855ff15c4b29bad660e6f154fca28"
        ],
        "urlInfo": "https://antfarm.finance/",
        "urlSwap": "https://app.antfarm.finance/trade"
      },
      {
        "factory": "0xe5552e0318531f9ec585c83bdc8956c08bf74b71",
        "__v": 0,
        "backgroundColor": "#141c2f",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "lfgswap.png",
        "name": "LFGswap",
        "slug": "dexf74b71",
        "textColor": "#fff",
        "factoryBlock": 73424886,
        "router": [
          "0xF83675ac64a142D92234681B7AfB6Ba00fa38dFF",
          "0xf83675ac64a142d92234681b7afb6ba00fa38dff"
        ],
        "urlInfo": "https://www.lfgswap.finance/pools?chainId=42161",
        "urlSwap": "https://www.lfgswap.finance/swap?chainId=42161"
      },
      {
        "factory": "0x8f086a081621bbc13b6d02a9e1123212cf07fdf8",
        "__v": 0,
        "backgroundColor": "#3831e2",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "zenithswap.png",
        "name": "Zenithswap",
        "slug": "dex07fdf8",
        "textColor": "#fff",
        "factoryBlock": 74877891,
        "router": [
          "0x8CA9EF098F84ceDa319Ec12B9d21EeF50AA3624C",
          "0x8ca9ef098f84ceda319ec12b9d21eef50aa3624c"
        ],
        "urlInfo": "https://www.zenithswap.com/<",
        "urlSwap": "https://app.zenithswap.com/swap"
      },
      {
        "factory": "0x2f0a2b314eecc6ba33b3dd4f46816a2196c8af3a",
        "__v": 0,
        "backgroundColor": "#ad351a",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "forge.png",
        "name": "Forge",
        "slug": "dexc8af3a",
        "textColor": "#fff",
        "router": [
          "0x1ca3ec7C382183138134B9da66D36F6c042d8DAD",
          "0x1ca3ec7c382183138134b9da66d36f6c042d8dad"
        ],
        "urlInfo": "https://forge.sx/",
        "urlSwap": "https://forge.sx/trade"
      },
      {
        "factory": "0x670bcb29389464e6f5e31f2335a4368244869d37",
        "__v": 0,
        "backgroundColor": "#4cd0aa",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "",
        "name": "Kittyswap",
        "slug": "dex869d37",
        "textColor": "#000",
        "router": [
          "0xe442Cd9e685376619578a0f8a0a3cEe0dBCCCE37",
          "0xe442cd9e685376619578a0f8a0a3cee0dbccce37"
        ],
        "urlInfo": "https://kittyswap.org/",
        "urlSwap": "https://kittyswap.org/swap"
      },
      {
        "factory": "0xe80f95c8cdbe1c7dac28e8dc9329923136e65fa5",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#3172e5",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "",
        "name": "Arbether",
        "slug": "dexe65fa5",
        "textColor": "#fff",
        "router": [
          "0x529d9f3BBdfaE930FEF9ecE85b8525EC3AC7D0D1",
          "0x529d9f3bbdfae930fef9ece85b8525ec3ac7d0d1"
        ],
        "urlInfo": "https://www.arbether.com/",
        "urlSwap": "https://arbether.finance/#/swap?chain=arbitrum"
      },
      {
        "factory": "0xce9240869391928253ed9cc9bcb8cb98cb5b0722",
        "__v": 0,
        "alike": "velodrome",
        "backgroundColor": "#7d83f4",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "chronos.png",
        "name": "Chronos",
        "slug": "dex5b0722",
        "textColor": "#fff",
        "router": [
          "0xE708aA9E887980750C040a6A2Cb901c37Aa34f3b",
          "0xe708aa9e887980750c040a6a2cb901c37aa34f3b"
        ],
        "urlInfo": "https://app.chronos.exchange/liquidity",
        "urlSwap": "https://app.chronos.exchange/"
      },
      {
        "factory": "0x91060b46442af0a8ae4e3e3efe9a065847211afa",
        "__v": 0,
        "backgroundColor": "#ffc300",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "safuswap.png",
        "name": "SafuSwap",
        "slug": "dex211afa",
        "textColor": "#fff",
        "router": [
          "0x4Bc22E25979632393e76f11fdA8C57eaf48E3ab3",
          "0x4bc22e25979632393e76f11fda8c57eaf48e3ab3"
        ],
        "urlInfo": "https://www.safu.io/",
        "urlSwap": "https://www.safu.io/swap"
      },
      {
        "factory": "0xaa2cd7477c451e703f3b9ba5663334914763edf8",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#59523e",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "ramses.png",
        "name": "Ramses",
        "slug": "dex63edf8",
        "textColor": "#fff",
        "router": [
          "0xAA23611badAFB62D37E7295A682D21960ac85A90",
          "0xaa23611badafb62d37e7295a682d21960ac85a90"
        ],
        "urlInfo": "https://cl.analytics.ramses.exchange/#/arbitrum/",
        "urlSwap": "https://cl.ramses.exchange/"
      },
      {
        "factory": "0xc7a590291e07b9fe9e64b86c58fd8fc764308c4a",
        "__v": 0,
        "alike": "univ3",
        "backgroundColor": "#818ea3",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "kyberswap.svg",
        "name": "KyberSwap Elastic",
        "slug": "dex308c4a",
        "textColor": "#fff",
        "router": [
          "0xF9c2b5746c946EF883ab2660BbbB1f10A5bdeAb4",
          "0xf9c2b5746c946ef883ab2660bbbb1f10a5bdeab4"
        ],
        "urlInfo": "https://kyber.network/",
        "urlSwap": "https://kyberswap.com/#/swap"
      },
      {
        "factory": "0x3e40739d8478c58f9b973266974c58998d4f9e8b",
        "__v": 0,
        "backgroundColor": "#4d9edc",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "dexfinance.png",
        "name": "Dexfinance",
        "slug": "dex4f9e8b",
        "textColor": "#fff",
        "router": [
          "0x6513b561c61d6032D0Ac2dB54739DFaFb8381d62",
          "0x6513b561c61d6032d0ac2db54739dfafb8381d62"
        ],
        "urlInfo": "https://app.dexfinance.com/",
        "urlSwap": "https://app.dexfinance.com/swap"
      },
      {
        "factory": "0x41a00e3fbe7f479a99ba6822704d9c5deb611f22",
        "__v": 0,
        "backgroundColor": "#00e9b1",
        "chainId": "arbitrum",
        "inactive": false,
        "logo": "smardex.png",
        "name": "Smardex",
        "slug": "dex611f22",
        "textColor": "#000",
        "alike": "smardex",
        "factoryBlock": 112072483,
        "router": [
          "0xdd4536dD9636564D891c919416880a3e250f975A",
          "0xdd4536dd9636564d891c919416880a3e250f975a"
        ],
        "urlInfo": "https://smardex.io/analytics",
        "urlSwap": "https://smardex.io/swap"
      }
    ]
  },
];

import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import accountCreated from "@assets/images/account-created.svg";
import {useStores} from "@hooks/useStores";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Preloader from "@components/common/Preloader";
import useResponseHandler from "@hooks/useResponseHandler";
import {PageRoutes} from "../../constants";
import {errorsContainCode} from "@helpers/errorsContainCode";
import {SubscriptionFlow} from "@stores/account-store";

const AccountCreatedPage = observer(() => {
  const {accountStore} = useStores();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleResponse = useResponseHandler();

  const handleRedirect = () => {
    navigate(PageRoutes.COIN_LIST);
  };

  useEffect(() => {
    if (pathname.includes('registration/') && pathname.split('/').length === 3) {
      const token = pathname.split('/')[2];

      accountStore
        .confirm(token)
        .then((response) => {
          if (response.success) {
            setIsLoading(false);
            accountStore.setToken(response.data.token);
            if (accountStore.subscriptionFlow) {
              navigate(PageRoutes.SUBSCRIBE + '/' + accountStore.subscriptionFlow);
            }
          } else {
            if (errorsContainCode(response.errors, 'err.invalid-token')) {
              handleRedirect();
            } else {
              handleResponse(response.data);
            }
          }
        })
        .catch((response) => {
          if (errorsContainCode(response.response.data.errors, 'err.invalid-token')) {
            handleRedirect();
          } else {
            handleResponse(response.response.data);
          }
        });
    }
  }, [navigate, pathname, handleResponse, accountStore]);

  return (
    <div>
      <div className="d-flex flex-column justify-content-center">
        <img src={accountCreated} alt="Verify" className="mx-auto"/>
        {isLoading && (
          <>
            <div className="tx-28 mt-4 mb-2 tx-center tx-light">Confirming registration</div>
            <Preloader className="tx-center-f" inline />
          </>
        )}
        {!isLoading && (
          <div className="tx-center tx-light">
            <div className="tx-28 mt-4">Account created</div>
            <div className="my-4">
              Your email has been verified and now you are signed in with your new account.
            </div>

            <button
              className="btn btn-outline-primary tx-primary wd-100p"
              onClick={() => {
                if (accountStore.subscriptionFlow) {
                  const route = accountStore.subscriptionFlow === SubscriptionFlow.REFERRAL_PROGRAM ? PageRoutes.REFERRAL_PROGRAM : PageRoutes.SUBSCRIBE + '/' + accountStore.subscriptionFlow;
                  navigate(route);
                } else {
                  navigate(PageRoutes.COIN_LIST);
                }
              }}
            >
              {accountStore.subscriptionFlow ? 'Continue with premium' : 'Find your first Gem'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default AccountCreatedPage;

import {observer} from "mobx-react-lite";
import SignupTab from "./components/SignupTab";
import LoginTab from "./components/LoginTab";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {useEffect} from "react";
import {useStores} from "@hooks/useStores";
import {ConnectionStep} from "@stores/account-store";
import {ReactComponent as Wallet} from "@assets/icons/wallet.svg";

interface LoginSignupOverlayProps {
  isLogin: boolean;
  isModal?: boolean;
}

const LoginSignupPage = observer(({isLogin, isModal}: LoginSignupOverlayProps) => {
  const navigate = useNavigate();
  const {accountStore} = useStores();
  const {isLoggedIn} = accountStore;

  useEffect(() => {
    if (isLoggedIn) {
      // navigate(PageRoutes.COIN_LIST);
      accountStore.setConnectionStep(null);
    }
  }, [isLoggedIn, navigate]);

  const handleNavigate = (route: PageRoutes) => {
    if (!isModal) {
      navigate(route);
    } else {
      accountStore.setConnectionStep(route === PageRoutes.LOGIN ? ConnectionStep.LOGIN : ConnectionStep.SIGNUP);
    }
  };

  return (
    <div>
      {isLogin ? (
        <>
          <div className="tx-34 tx-light tx-center">
            Hi, welcome back
          </div>
          <div className="tx-16 tx-muted tx-center mb-4">
            To keep find gems please enter your details.
          </div>
        </>
      ) : (
        <>
          <div className="tx-34 tx-light tx-center">
            Welcome to uAnalytics
          </div>
          <div className="tx-16 tx-muted tx-center mb-4">
            Start your DeFi journey with us!
          </div>
        </>
      )}

      <div className="card flex-row p-1 mb-2 justify-content-between">
        <div
          className={`tx-14 wd-50p rounded-10px py-1 tx-center cur-pointer ${!isLogin ? 'tx-muted' : 'bg-semi-transparent'}`}
          onClick={() => handleNavigate(PageRoutes.LOGIN)}
        >
          Log In
        </div>
        <div
          className={`tx-14 wd-50p rounded-10px py-1 tx-center cur-pointer ${isLogin ? 'tx-muted' : 'bg-semi-transparent'}`}
          onClick={() => handleNavigate(PageRoutes.SIGNUP)}
        >
          Sign Up
        </div>
      </div>

      {isLogin ? <LoginTab /> : <SignupTab />}

      <div className="text-over-line tx-14 mt-3">
        <span />
        <span>Or</span>
        <span />
      </div>

      <div className="mt-3 tx-center">
        <button
          className="btn btn-outline-light border-muted tx-15 wd-100p btn-hover-svg-fill-black"
          onClick={() => accountStore.setConnectionStep(ConnectionStep.WALLET)}
        >
          <div className="d-inline-block me-2">
            <Wallet />
          </div>
          Continue with Wallet
        </button>
      </div>
    </div>
  );
});

export default LoginSignupPage;

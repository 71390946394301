import {Doughnut} from "react-chartjs-2";
import {ChartColorsArray} from "../../../constants";

interface ChartValueProp {
  value: number;
  label: string;
}

interface ChartProps {
  values: ChartValueProp[];
  innerTitle?: string;
  innerSubTitle?: string;
  chartClassName?: string;
}

const DoughnutChart = ({values, innerTitle, innerSubTitle, chartClassName = 'mx-wd-150 mx-wd-md-200 mx-auto'}: ChartProps) => {
  const chartData = {
    labels: values.map((item) => item.label),
    datasets: [
      {
        label: '% of total',
        data: values.map((item) => item.value),
        backgroundColor: ChartColorsArray,
        borderWidth: 2,
        borderColor: '#071524',
      },
    ],
  };

  return (
    <div className={chartClassName}>
      <Doughnut
        id="token-analytics-chart"
        options={{
          cutout: '80%',
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        plugins={[
          {
            id:"token-analytics-chart",
            beforeDraw: function(chart: any) {
              const {width, height, ctx} = chart;
              ctx.restore();
              let fontSize = (height / 90).toFixed(2);
              ctx.font = fontSize + "em Olney, sans-serif";
              ctx.textBaseline = "middle";
              ctx.fillStyle = '#FFF';
              const text = innerTitle || '',
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
              ctx.fillText(text, textX, textY + 10);

              fontSize = (height / 200).toFixed(2);
              ctx.fillStyle = '#516376';
              ctx.font = fontSize + "em Olney, sans-serif";
              const text2 = innerSubTitle || '',
                text2X = Math.round((width - ctx.measureText(text2).width) / 2),
                text2Y = height / 2;
              ctx.fillText(text2, text2X, text2Y - 20);

              ctx.save();
            },
          },
        ]}
        data={chartData}
      />
    </div>
  );
};

export default DoughnutChart;

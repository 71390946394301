import {ChartColors} from "../../../constants";

interface CircleIconProps {
  color?: ChartColors | string;
  size?: number;
  className?: string;
  fill?: boolean;
}

const CircleIcon = ({color = 'currentColor', size = 12, className = '', fill = false}: CircleIconProps) => {
  return (
  <svg width={size} height={size} className={className} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      {fill ? (
        <circle cx="5" cy="5" r="5" fill={color}/>
      ) : (
        <circle cx="5" cy="5" r="4.2" stroke={color} strokeWidth="1.6"/>
      )}
    </svg>
  );
};

export default CircleIcon;

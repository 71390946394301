import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import {useNavigate} from "react-router-dom";
import {emailRx, PageRoutes} from "../../../constants";
import IconWithLoading from "@components/common/IconWithLoading";
import {errorsContainCode} from "@helpers/errorsContainCode";
import eye from "@assets/icons/eye.svg";
import eyeClosed from "@assets/icons/eye-closed.svg";
import {ConnectionStep, SubscriptionFlow} from "@stores/account-store";

const LoginTab = observer(() => {
  const {accountStore} = useStores();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const incorrectEmail = !emailRx.test(email);
  const incorrectPassword = password.length < 8;

  const handleLogin = () => {
    if (!email || incorrectEmail) {
      setEmailError('Invalid email address');
      return;
    }

    if (!password || incorrectPassword) {
      setPasswordError('Minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number');
      return;
    }

    setIsLoading(true);

    accountStore
      .login(email, password)
      .then((response) => {
        if (response.success) {
          accountStore.setLoggedIn(true);
          if (accountStore.subscriptionFlow) {
            const route = accountStore.subscriptionFlow === SubscriptionFlow.REFERRAL_PROGRAM ? PageRoutes.REFERRAL_PROGRAM : PageRoutes.SUBSCRIBE + '/' + accountStore.subscriptionFlow;
            navigate(route);
          } else {
            navigate(PageRoutes.COIN_LIST);
          }
          accountStore.setToken(response.data.token);
        } else {
          const _emailError = errorsContainCode(response.errors, 'err.email');
          const _passwordError = errorsContainCode(response.errors, 'err.password');
          if (_passwordError) {
            setPasswordError(_passwordError);
          } else if (_emailError) {
            setEmailError(_emailError);
          } else {
            handleResponse(response.data);
          }
        }
        setIsLoading(false);
      })
      .catch((response) => {
        const emailError = errorsContainCode(response.response.data.errors, 'err.email');
        const passwordError = errorsContainCode(response.response.data.errors, 'err.password');
        if (passwordError) {
          setPasswordError(passwordError);
        } else if (emailError) {
          setEmailError(emailError);
        } else {
          handleResponse(response.response.data);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setEmailError('');
  }, [email]);

  useEffect(() => {
    setPasswordError('');
  }, [password]);

  return (
    <div>
      <div className="form-group mb-4">
        <label className="tx-13 tx-light mb-1">Email</label>
        <input
          className={`form-control ${emailError && 'is-invalid'}`}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={'Enter your email'}
          id="email"
          disabled={isLoading}
        />
        {!!emailError && (
          <div className="tx-danger tx-light tx-11 mt-1">
            {emailError}
          </div>
        )}
      </div>

      <div className="form-group mb-4">
        <div className="d-flex justify-content-between align-items-start">
          <label className="tx-13 tx-light mb-1 me-auto">Password</label>
          <button
            className="btn btn-link tx-primary d-inline py-0 px-0 wd-100p tx-right tx-13 text-decoration-none"
            // onClick={() => navigate(PageRoutes.FORGOT_PASSWORD)}
            onClick={() => accountStore.setConnectionStep(ConnectionStep.FORGOT_PASSWORD)}
          >
            Forgot Password?
          </button>
        </div>
        <div className="form-group">
          <input
            className={`form-control ${passwordError && 'is-invalid'}`}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={'Enter your password'}
            id="password"
            disabled={isLoading}
          />
          <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
            <img src={showPassword ? eye : eyeClosed} alt="Show password" />
          </div>
        </div>
        {!!passwordError && (
          <div className="tx-danger tx-light tx-11 mt-1">
            {passwordError}
          </div>
        )}
      </div>

      <button
        className="btn btn-primary wd-100p mt-2"
        onClick={handleLogin}
        disabled={isLoading}
      >
        <IconWithLoading isLoading={isLoading} className="me-2" />
        Log In
      </button>
    </div>
  )
});

export default LoginTab;

import React from "react";
import {useStores} from "@hooks/useStores";
import {Overlays} from "@stores/overlay-store";
import {Link, useLocation} from "react-router-dom";
import {ReactComponent as Rocket} from "@assets/icons/navigation/rocket.svg";
import {ReactComponent as Star} from "@assets/icons/navigation/star.svg";
import {ReactComponent as Search} from "@assets/icons/navigation/search.svg";
import {PageRoutes} from "../constants";
import {observer} from "mobx-react-lite";

const MobileNavigation = observer(() => {
  const {overlayStore} = useStores();
  const location = useLocation();
  const searchOpened = overlayStore.activeOverlay === Overlays.SEARCH;

  const openSearch = () => {
    overlayStore.setActiveOverlay(Overlays.SEARCH);
  };

  const handleClassName = (route: string | PageRoutes) => {
    let result = 'btn btn-link p-0 d-flex flex-column justify-content-center align-items-center cur-pointer wd-100p tx-12 text-decoration-none';
    if ((route === 'search' && searchOpened) || (!searchOpened && location.pathname === route)) {
      result += ' tx-primary';
    } else {
      result += ' tx-muted';
    }
    return result;
  };

  return (
    <div id="mobile-navigation">
      <Link
        to={PageRoutes.COIN_LIST}
        className={handleClassName(PageRoutes.COIN_LIST)}
      >
        <Rocket className="wd-20" />
        <span className="mt-1">Top Tokens</span>
      </Link>
      <Link
        to={PageRoutes.SEARCH}
        className={handleClassName(PageRoutes.SEARCH)}
      >
        <Search className="wd-20" />
        <span className="mt-1">Search</span>
      </Link>
      {/*<Link*/}
      {/*  to={PageRoutes.FAVORITE_LIST}*/}
      {/*  className={handleClassName(PageRoutes.FAVORITE_LIST)}*/}
      {/*>*/}
      {/*  <Star className="wd-20" />*/}
      {/*  <span className="mt-1">Favorites</span>*/}
      {/*</Link>*/}
      {/*<button*/}
      {/*  onClick={openSearch}*/}
      {/*  className={handleClassName('search')}*/}
      {/*>*/}
      {/*  <Search className="wd-20" />*/}
      {/*  <span className="mt-1">Search</span>*/}
      {/*</button>*/}
    </div>
  );
});

export default MobileNavigation;

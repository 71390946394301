import FormattedNumber from "@components/common/FormattedNumber";
import {SearchTokenInterface} from "@stores/tokens-store";
import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {formatNumber, getPercentageDifference} from "@helpers/numbers";
import TokenLogo from "@components/common/TokenLogo";
import Star from "@components/common/Star";
import {observer} from "mobx-react-lite";
import ChainExchange from "@components/common/ChainExchange";
import useCloseOverlay from "@hooks/useCloseOverlay";

const SearchItemMobile = observer((props: SearchTokenInterface) => {
  const {
    id,
    token,
    price,
    price24h,
    price6h,
    price1h,
    price5m,
    metrics,
    symbolRef,
  } = props;

  const {
    pair,
  } = id;

  const {
    symbol,
    name,
    logo,
  } = token;

  const {
    liquidity,
  } = metrics;

  const navigate = useNavigate();
  const priceDiffValue = getPercentageDifference(price, price24h || price6h || price1h || price5m || parseInt(''));
  const closeOverlay = useCloseOverlay();

  const handleClick = useCallback(() => {
    closeOverlay();
    navigate(`/${props.id.chain}/${pair}`);
  }, [closeOverlay, navigate, pair, props.id.chain]);

  return (
    <div className="row">
      <div className="d-flex d-md-none align-items-center justify-content-between py-2 border-bottom border-muted-10">
        <div className="ms-0 me-2 wd-100p" onClick={handleClick}>
          <div className="d-flex d-md-none align-items-center justify-content-between">
            <TokenLogo logo={logo} name={name} address={id.pair} size={40} className="ms-0" />

            <div className="d-flex flex-column ms-3 me-auto">
              <div className="d-flex flex-row align-items-center justify-content-start">
                <div className="tx-16 lh-2">{symbol}<span className="tx-14 tx-muted"> / {symbolRef}</span></div>
              </div>
              <div className="tx-muted tx-13">{name}</div>
            </div>

            <div className="d-flex flex-column justify-content-between align-items-end ms-auto me-0 mn-wd-70">
              <div className="tx-16 lh-2">
                {price && (
                  <FormattedNumber
                    suffix="$"
                    value={price}
                    decimals={+token.decimals}
                    floor={false}
                    subZeros
                    className="word-break"
                  />
                )}
                {!price && (
                  <div className="tx-muted">N/A</div>
                )}
              </div>
              {!!priceDiffValue && (
                <FormattedNumber
                  suffix="24H "
                  suffixClass="tx-muted"
                  value={priceDiffValue}
                  withSign={priceDiffValue !== 0}
                  className={`tx-13 ${priceDiffValue > 0 ? 'text-success' : 'text-danger'}`}
                  postfix="%"
                />
              )}
            </div>
          </div>

          <div className="d-flex align-items-start justify-content-between">
            <div className="ms-0 me-auto">
              <ChainExchange chain={id.chain} exchange={id.exchange} size={20} />
            </div>
            <div className="tx-13">
              Liq. {formatNumber(liquidity, '', 0)}
            </div>
          </div>
        </div>

        <Star token={props} className="btn btn-secondary bg-semi-transparent ms-auto me-0 p-0 mn-wd-35 mx-wd-35 ht-35 d-flext justify-content-center align-items-center" />
      </div>
    </div>
  );
});

export default SearchItemMobile;

import React from "react";
import {getPercentage} from "@helpers/numbers";

interface ProgressProps {
  value: number;
  max?: number;
  forceColor?: string;
  primaryColor?: string;
  bgColor?: string;
  className?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const Progress = ({value, max = 100, forceColor, primaryColor = 'primary', bgColor, className, left, right}: ProgressProps) => {
  const percent = getPercentage(value, max);
  let color = forceColor || primaryColor;

  if (!forceColor) {
    if (percent < 50) {
      color = 'dark-red';
    } else if (percent < 75) {
      color = 'orange';
    } else if (percent < 80) {
      color = 'dark-green';
    }
  }

  return (
    <div className={className ?? className}>
      {(left || right) && (
        <div className="d-flex justify-content-between mb-1 tx-13">
          {left && <div>{left}</div>}
          {right && <div>{right}</div>}
        </div>
      )}
      <div className="progress">
        <div className={`progress-bar-bg bg-${bgColor || color}`}/>
        <div
          className={`progress-bar bg-${color}`}
          role="progressbar"
          style={{width: `${percent}%`}}
          aria-valuenow={value}
          aria-valuemin={0}
          aria-valuemax={max}
        />
      </div>
    </div>
  );
};

export default Progress;

import {action, makeAutoObservable, observable} from 'mobx';
import api from "@helpers/api";
import {clearAuthTokens, setAuthTokens} from "axios-jwt";
import {Alchemy, Network, Utils} from "alchemy-sdk";
import {convertStringToNumber} from "@helpers/bignumber";
import {
  alchemyToken,
  tenMlnAccounts,
  thirtyMlnAccounts,
  twentyMlnAccounts,
  ushiAddress,
  whiteListedAccounts
} from "../constants";
import {StatusResponse} from "@hooks/useResponseHandler";
import {isPast} from "date-fns";

interface UserProfile {
  email: string;
  username: string;
  registrationDate: string;
  wallets: string[];
}

interface ReferralLine {
  line: number;
  count: number;
  usdReward: number;
}

interface ReferralWithdrawal {
  creationTimestamp: string;
  updateTimestamp: string;
  status: string;
  usdAmount: number;
  address: string;
  txHash: string;
}

interface ReferralInfo {
  refId: string;
  lines: ReferralLine[];
  withdrawals: ReferralWithdrawal[];
  totalUsdEarned: number;
  totalUsdWithdrawn: number;
}

export enum SubscriptionType {
  FREE = 'FREE',
  PAID = 'PAID',
  TOKEN_PASS = 'TOKEN_PASS',
}

export enum SubscriptionPeriod {
  MONTH = 'MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
}

export const SubscriptionPeriodLabel = {
  [SubscriptionPeriod.MONTH]: 'Month',
  [SubscriptionPeriod.THREE_MONTHS]: '3 Months',
}

export enum SubscriptionDepositCurrency {
  BTC = 'BTC',
  ETH = 'ETH',
  BNB_BSC = 'BNB_BSC',
  BUSD_BEP20 = 'BUSD_BEP20',
  DAI = 'DAI',
  DAI_BEP20 = 'DAI_BEP20',
  USDC = 'USDC',
  USDC_BEP20 = 'USDC_BEP20',
  USDC_TRC20 = 'USDC_TRC20',
  USDT_BEP20 = 'USDT_BEP20',
  USDT_ERC20 = 'USDT_ERC20',
  USDT_TRC20 = 'USDT_TRC20',
}

export enum SubscriptionStatus {
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export const SubscriptionStatusLabel = {
  [SubscriptionStatus.WAITING_FOR_PAYMENT]: 'Waiting for payment',
  [SubscriptionStatus.PAID]: 'Paid',
  [SubscriptionStatus.CANCELED]: 'Canceled',
}

interface SubscriptionResponse {
  type: SubscriptionType;
  startDate: string;
  endDate: string;
}

export interface SubscriptionInvoice {
  id: string;
  status: SubscriptionStatus;
  creationTimestamp: string;
  updateTimestamp: string;
  subscriptionPeriod: SubscriptionPeriod;
  subscriptionUsdPrice: number;
  depositCurrency: SubscriptionDepositCurrency;
  depositAmount: number;
  depositAddress: string;
  depositMinConfirms: number;
  depositExpiration: string;
}

export enum SubscriptionFlow {
  NONE = 'NONE',
  MONTHLY = 'MONTHLY',
  THREE_MONTHS = 'THREE_MONTHS',
  REFERRAL_PROGRAM = 'THREE_MONTHS',
}

export enum ConnectionStep {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  VERIFY = 'VERIFY',
  VERIFY_RESET = 'VERIFY_RESET',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  WALLET = 'WALLET',
  FAVORITES = 'FAVORITES',
}

export class AccountStore {
  @observable
  private _refID: string | null = localStorage.getItem('referral');

  @observable
  private _alchemy: Alchemy;

  @observable
  private _address: string | null = null;

  @observable
  private _token: string | null = null;

  @observable
  private _profile: UserProfile | null = null;

  @observable
  private _refInfo: ReferralInfo | null = null;

  @observable
  private _emailFromGet: string | null = localStorage.getItem('emailFromGet');

  @observable
  private _isLoggedIn: boolean = false;

  @observable
  private _lastUsedEmail: string = '';

  @observable
  private _subscription: SubscriptionResponse | null = null;

  @observable
  private _web3Enabled: boolean = false;

  @observable
  private _allowance: number | null = null;

  @observable
  private _balance: number | null = null;

  @observable
  private _ushiBalance: number | null = null;

  @observable
  private _subscriptionFlow: SubscriptionFlow | null = null;

  @observable
  private _connectionStep: ConnectionStep | null = null;

  @action
  public loadAccountInfo() {
    if (!this._token) {
      return;
    }

    if (!this._profile) {
      this.loadProfile();
      this.loadRefInfo();
    }

    // if (this._web3Enabled) {
    //   getSubscription(this._account)
    //     .then((response) => {
    //       this._subscription = response;
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     });
    //   allowance(this._account)
    //     .then((response) => {
    //       this._allowance = response;
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     });
    // }
  }

  constructor() {
    makeAutoObservable(this);
    this._isLoggedIn = !!localStorage.getItem('token');
    this.setToken(localStorage.getItem('token'));
    // @ts-ignore
    this._web3Enabled = typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';

    const config = {
      apiKey: alchemyToken,
      network: Network.ETH_MAINNET,
    };

    this._alchemy = new Alchemy(config);

    this.loadAccountInfo();

    // Moralis.enableWeb3().then(() => {
    //   this._web3Enabled = true;
    //   this.loadAccountInfo();
    // });
  }

  @action
  public handleGetParams(searchParams: URLSearchParams) {
    this.setRefID(searchParams.get('ref'));
    this.setEmailFromGet(searchParams.get('email'));

    const flow = searchParams.get('flow');
    if (flow) {
      this.setSubscriptionFlow(flow as SubscriptionFlow);
    }
  }

  @action
  setRefID(value: string | null) {
    if (value) {
      this._refID = value;
      localStorage.setItem('referral', value);
    }
  }

  @action
  setEmailFromGet(value: string | null) {
    if (value) {
      this._emailFromGet = value;
      localStorage.setItem('emailFromGet', value);
    }
  }

  @action
  setSubscriptionFlow(value: SubscriptionFlow | null) {
    if (value) {
      this._subscriptionFlow = value;
    }
  }

  get
  refID() {
    return this._refID;
  }

  get
  emailFromGet() {
    return this._emailFromGet;
  }

  @action
  logout() {
    this.setConnectionStep(null);
    this.setToken(null);
  }

  @action
  setAddress(value: string | null) {
    this._address = value;

    if (!value) {
      this._balance = null;
      this._ushiBalance = null;
      this._allowance = null;
    }
  }

  @action
  setToken(value: string | null) {
    this._token = value;

    if (value) {
      localStorage.setItem('token', value);
      setAuthTokens({
        accessToken: value,
        refreshToken: value,
      });
      this.loadAccountInfo();
      this.loadSubscription();
      this._isLoggedIn = true;
    } else {
      this._profile = null;
      this._isLoggedIn = false;
      this._subscription = null;
      localStorage.removeItem('token');
      clearAuthTokens();
    }
  }

  get
  address() {
    return this._address;
  }

  get
  token() {
    return this._token;
  }

  get
  subscription() {
    return this._subscription;
  }

  get
  allowance() {
    return this._allowance;
  }

  get
  isLoggedIn() {
    return this._isLoggedIn;
  }

  @action
  setLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  get
  profile() {
    return this._profile;
  }

  get
  refInfo() {
    return this._refInfo;
  }

  get
  email () {
    return this._lastUsedEmail;
  }

  get
  balance () {
    return this._balance;
  }

  get
  ushiBalance () {
    return this._ushiBalance;
  }

  get
  subscriptionFlow () {
    return this._subscriptionFlow;
  }

  get
  isWhiteListed () {
    return this._address && whiteListedAccounts.includes(this._address.toLowerCase());
  }

  get
  isWhiteListedFor10Mln () {
    return this._address && tenMlnAccounts.includes(this._address.toLowerCase());
  }

  get
  isWhiteListedFor20Mln () {
    return this._address && twentyMlnAccounts.includes(this._address.toLowerCase());
  }

  get
  isWhiteListedFor30Mln () {
    return this._address && thirtyMlnAccounts.includes(this._address.toLowerCase());
  }

  get
  isEnoughBalance () {
    let requiredBalance = 50;
    if (this.isWhiteListedFor10Mln) {
      requiredBalance = 10;
    } else if (this.isWhiteListedFor20Mln) {
      requiredBalance = 20;
    } else if (this.isWhiteListedFor30Mln) {
      requiredBalance = 30;
    }
    return this._ushiBalance !== null && this._ushiBalance > requiredBalance * 1000 * 1000;
  }

  get
  isSubscriber () {
    if (this.isWhiteListed) {
      return true;
    }

    return this.isEnoughBalance || (this._subscription && this._subscription.type === SubscriptionType.PAID && !isPast(new Date(this._subscription.endDate)));
  }

  get
  connectionStep () {
    return this._connectionStep;
  }

  @action
  setConnectionStep (value: ConnectionStep | null) {
    this._connectionStep = value;
  }

  @action
  register(email: string, password: string, inviter: string | null) {
    this._lastUsedEmail = email;
    let data: any = {email, password, inviter};
    if (this._subscriptionFlow) {
      data = {...data, flow: this._subscriptionFlow};
    }
    return api<any>({method: 'post', path: '/registration', data});
  }

  @action
  resend() {
    return api<any>({method: 'post', path: '/registration/resend', data: {email: this._lastUsedEmail}});
  }

  @action
  confirm(token: string) {
    return api<any>({method: 'post', path: '/registration/confirm', data: {token}});
  }

  @action
  login(email: string, password: string) {
    return api<any>({method: 'post', path: '/login', data: {email, password}});
  }

  @action
  reset(email: string) {
    this._lastUsedEmail = email;
    return api<any>({method: 'post', path: '/reset-password', data: {email}});
  }

  @action
  resetResend() {
    return api<any>({method: 'post', path: '/reset-password/resend', data: {email: this._lastUsedEmail}});
  }

  @action
  resetConfirm(token: string, password: string) {
    return api<any>({method: 'post', path: '/reset-password/confirm', data: {token, password}});
  }

  @action
  updatePassword(oldPassword: string, newPassword: string) {
    return api<any>({method: 'post', path: '/profile/password', data: {oldPassword, newPassword}});
  }

  @action
  verifyWallet(signer: string, signature: string) {
    return api<any>({method: 'post', path: '/profile/wallet/verify', data: {signer, signature}});
  }

  @action
  loadProfile() {
    return api<UserProfile>({method: 'get', path: '/profile'}).then((response) => {
      this._profile = response;
    }).catch((response) => {
      if (response.response.status === 403) {
        this.logout();
      }
      console.error(response.response.data);
    });
  }

  @action
  loadRefInfo() {
    return api<ReferralInfo>({method: 'get', path: '/referral'}).then((response) => {
      this._refInfo = response;
    }).catch((response) => {
      console.error(response.response.data);
    });
  }

  @action
  loadBalance(ownerAddress: string) {
    this._alchemy.core.getBalance(ownerAddress, 'latest').then((response) => {
      this._balance = convertStringToNumber(Utils.formatEther(response).replace(' ETH', ''));
    });

    const tokenContractAddresses = [ushiAddress];

    this._alchemy.core.getTokenBalances(ownerAddress, tokenContractAddresses).then((response) => {
      this._ushiBalance = convertStringToNumber(Utils.formatEther(response.tokenBalances[0].tokenBalance || 0).replace(' ETH', ''));
    });
  }

  @action
  buySubscription(period: SubscriptionPeriod, currency = SubscriptionDepositCurrency.USDT_TRC20) {
    return api<StatusResponse<SubscriptionInvoice>>({method: 'post', path: '/subscription/buy', data: {period, currency}});
  }

  @action
  withdrawReferralReward(address: string) {
    return api<StatusResponse<any>>({method: 'post', path: '/referral/reward/withdraw', data: {address}});
  }

  @action
  loadSubscription() {
    return api<SubscriptionResponse>({method: 'get', path: `/subscription`})
      .then((response) => {
        this._subscription = response;
      }).catch((response) => {
        if (response.response.status === 403) {
          this.logout();
        }
        console.error(response.response.data);
      });
  }

  @action
  getSubscriptionInvoice(id: string) {
    return api<SubscriptionInvoice>({method: 'get', path: `/subscription/invoice/${id}`});
  }

  @action
  resetStore() {
    localStorage.removeItem('token');
    clearAuthTokens();
    this._subscription = null;
    this._allowance = null;
    this._subscription = null;
    this._profile = null;
    this._address = null;
    this._isLoggedIn = false;
    this._balance = null;
    this._token = null;
  }
}

import IconWithLoading from "@components/common/IconWithLoading";

interface AsyncValueProps {
  value: any;
  className?: string;
}

export default function AsyncValue(props: AsyncValueProps) {
  const {value, className = ''} = props;

  return value !== null && value !== undefined ? value : (
    <IconWithLoading isLoading className={className} />
  );
}

import React, {useState} from 'react';
import more from '@assets/icons/more.svg';
import close from "@assets/icons/close.svg";
import Overlay from "@components/common/Overlay";
import external from "@assets/icons/external.svg";

interface SocialLink {
  title: string;
  icon?: string;
  link: string;
  enabled?: boolean;
}

export interface MobileSocialLinksProps {
  common: SocialLink[];
  explorers: SocialLink[];
  socials: SocialLink[];
}

const MobileSocialLinks = ({common, explorers, socials}: MobileSocialLinksProps) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  return (
    <>
      <div className="d-md-none py-4 border-top border-secondary no-gutter">
        <div className="card d-flex flex-row justify-content-end px-3 py-2 align-items-center">
          <div className="tx-muted tx-14 ms-0 me-auto">Links</div>
          {common.map(({link, title, enabled, icon}) => !!link && enabled !== false && icon && (
            <a
              href={link}
              key={`link-shortcut-${link}-${title}`}
              target="_blank"
              rel="noreferrer"
              className="d-block me-3"
            >
              <img src={icon} alt={title} width={24} />
            </a>
          ))}
          {socials.slice(0, 3).map(({link, title, enabled, icon}) => !!link && enabled !== false && icon && (
            <a
              href={link}
              key={`link-shortcut-${link}-${title}`}
              target="_blank"
              rel="noreferrer"
              className="d-block me-3"
            >
              <img src={icon} alt={title} width={24} />
            </a>
          ))}
          <button
            className="btn btn-link px-0 py-0"
            onClick={() => setShowOverlay(true)}
          >
            <img src={more} alt="More" width={24} />
          </button>
        </div>
      </div>

      <Overlay className={`pb-5 z-index-2000 ${showOverlay ? 'd-block d-md-none' : 'd-none'}`} containerClassName="py-3">
        <div className="d-flex px-3 justify-content-between pb-2 mb-2 border-bottom border-muted-10">
          <div className="mn-wd-40 mx-wd-40 ht-40 me-2">&nbsp;</div>
          <div className="wd-100p ht-40 d-flex align-items-center justify-content-center tx-center">
            Social Links
          </div>
          <div className="mn-wd-40 mx-wd-40 ht-40 ms-2">
            <button className="btn btn-secondary bg-semi-transparent p-0 d-flex justify-content-center align-items-center wd-100p ht-100p" onClick={() => setShowOverlay(false)}>
              <img src={close} alt="Close" />
            </button>
          </div>
        </div>

        <div className="py-3 px-3 tx-14 tx-muted">
          Links
        </div>
        <div className="border-top border-muted-10">
          {!common.length && (
            <div className="py-3 border-bottom border-muted-10">
              No links available
            </div>
          )}
          {common.map(({link, title, enabled, icon}) => !!link && enabled !== false && (
            <a
              href={link}
              key={`mobile-link-${link}-${title}`}
              target="_blank"
              rel="noreferrer"
              className="d-block py-3 px-3 tx-12 tx-white text-decoration-none border-bottom border-muted-10"
            >
              <div className="d-flex align-items-center">
                {icon && <img src={icon} alt={title} width={16} className="me-2" />}
                <div className="me-2">{title}</div>
                <img src={external} alt="External" width={16} />
              </div>
            </a>
          ))}
        </div>

        <div className="py-3 px-3 tx-14 tx-muted mt-3">
          Community
        </div>
        <div className="border-top border-muted-10">
          {!socials.length && (
            <div className="py-3 border-bottom border-muted-10">
              No links available
            </div>
          )}
          {socials.map(({link, title, enabled, icon}) => !!link && enabled !== false && (
            <a
              href={link}
              key={`mobile-link-${link}-${title}`}
              target="_blank"
              rel="noreferrer"
              className="d-block py-3 px-3 tx-12 tx-white text-decoration-none border-bottom border-muted-10"
            >
              <div className="d-flex align-items-center">
                <img src={icon} alt={title} width={16} />
                <div className="mx-2">{title}</div>
                <img src={external} alt="External" width={16} />
              </div>
            </a>
          ))}
        </div>

        <div className="py-3 px-3 tx-14 tx-muted mt-3">
          Explorers
        </div>
        <div className="border-top border-muted-10">
          {!explorers.length && (
            <div className="py-3 border-bottom border-muted-10">
              No links available
            </div>
          )}
          {explorers.map(({link, title, enabled, icon}) => !!link && enabled !== false && (
            <a
              href={link}
              key={`mobile-link-${link}-${title}`}
              target="_blank"
              rel="noreferrer"
              className="d-block py-3 px-3 tx-12 tx-white text-decoration-none border-bottom border-muted-10"
            >
              <div className="d-flex align-items-center">
                <img src={icon} alt={title} width={16} />
                <div className="mx-2">{title}</div>
                <img src={external} alt="External" width={16} />
              </div>
            </a>
          ))}
        </div>
      </Overlay>
    </>
  );
};

export default MobileSocialLinks;

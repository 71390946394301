import FormattedNumber from "@components/common/FormattedNumber";
import {FavoritesTokenInterface} from "@stores/tokens-store";
import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {formatNumber, getPercentageDifference} from "@helpers/numbers";
import {format, formatDistance} from "date-fns";
import TokenLogo from "@components/common/TokenLogo";
import useCloseOverlay from "@hooks/useCloseOverlay";
import Star from "@components/common/Star";
import {observer} from "mobx-react-lite";
import ChainExchange from "@components/common/ChainExchange";

const FavoriteListItem = observer((props: FavoritesTokenInterface) => {
  const {
    id,
    token,
    price,
    price24h,
    volume,
    metrics,
    symbolRef,
  } = props;

  const {
    token: address,
  } = id;

  const {
    creationTime,
    symbol,
    name,
    logo,
  } = token;

  const {
    holders,
    fdv: mcap,
  } = token.metrics;

  const {liquidity} = metrics;

  const navigate = useNavigate();
  const priceDiffValue = getPercentageDifference(price, price24h || parseInt(''));
  const closeOverlay = useCloseOverlay();

  const data = [
    {
      key: 'liquidity',
      title: 'Liquidity',
      value: liquidity,
      unit: '$',
    },
    {
      key: 'price',
      title: 'Price',
      value: price,
      unit: '$',
    },
    {
      key: 'price-change',
      title: 'Price Change',
      value: price,
      unit: '$',
    },
    {
      key: 'mcap',
      title: 'MCap',
      value: mcap,
      unit: '$',
    },
    {
      key: 'volume',
      title: 'Volume',
      value: volume,
      unit: '$',
    },
    {
      key: 'holders',
      title: 'Holders',
      value: holders,
      decimals: 0,
    },
  ];

  const handleClick = useCallback(() => {
    closeOverlay();
    navigate(`/${props.id.chain}/${props.id.pair}`);
  }, [navigate, props.id, closeOverlay]);

  return (
    <div className="table-row">
      <div className="table-col col-favorite">
        <Star token={props} className="cur-pointer tx-center" />
      </div>

      <div className="table-col col-logo cur-pointer" onClick={handleClick}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="pos-relative">
            <div className="overflow-hidden token-logo">
              <TokenLogo logo={logo} name={name} address={address} size={40} />
            </div>
            <ChainExchange chain={id.chain} exchange={id.exchange} className="d-md-none" size={20} />
          </div>
          <div className="d-flex flex-column justify-content-between ms-2 me-auto">
            <div className="tx-16 lh-2">{symbol}<span className="tx-muted tx-14"> / {symbolRef}</span></div>
            <div className="tx-13 lh-2 tx-muted">{name}<span className="d-md-none ms-"> · {creationTime ? format(new Date(creationTime), 'dd.MM.yyyy') : 'N/A'}</span></div>
          </div>
        </div>
      </div>

      {data.map(({title, value, unit, decimals, key}) => {
        const isPrice = title === 'Price';
        const isPriceChange = title === 'Price Change';
        return (
          <div className={`table-col cur-pointer col-${key}`} key={`token-list-${address}-${key}`} onClick={handleClick}>
            <div>
              {!isPrice && !isPriceChange && (
                <div className="tx-muted d-md-none">{title}</div>
              )}
              {value && isPrice && (
                <FormattedNumber
                  suffix="$ "
                  value={price}
                  decimals={18}
                  floor={false}
                  subZeros
                  className="word-break"
                />
              )}
              {isPrice && (
                <div className="d-md-none">
                  {!isNaN(priceDiffValue) && (
                    <FormattedNumber
                      value={priceDiffValue}
                      postfix="%"
                      className={`word-break ${priceDiffValue >= 0 ? 'tx-success' : 'tx-danger'}`}
                      withSign={priceDiffValue !== 0}
                    />
                  )}
                </div>
              )}
              {isPriceChange && !isNaN(priceDiffValue) && (
                <div className={`ms-0 me-0 tx-14 wd-90 rounded-1 tx-center py-2 ${priceDiffValue >= 0 ? 'bg-semi-success-2' : 'bg-semi-danger-2'}`}>
                  <FormattedNumber
                    value={priceDiffValue}
                    postfix="%"
                    className={`word-break ${priceDiffValue >= 0 ? 'tx-success' : 'tx-danger'}`}
                    withSign={priceDiffValue !== 0}
                  />
                </div>
              )}
              {(!isPrice || !value) && !isPriceChange && (
                <>
                  {value ? formatNumber(value, unit || '', decimals) : 'N/A'}
                </>
              )}
            </div>
          </div>
        );
      })}

      <div className="table-col col-exchanges cur-pointer" onClick={handleClick}>
        <ChainExchange chain={id.chain} exchange={id.exchange} className="ms-auto me-3" size={20} />
      </div>

      <div className="table-col col-date cur-pointer" onClick={handleClick}>
        {creationTime ? formatDistance(new Date(creationTime), new Date()) : 'N/A'}
      </div>
    </div>
  );
});

export default FavoriteListItem;

import {observer} from "mobx-react-lite";
import verify from "@assets/images/verify.png";
import {useState} from "react";
import useInterval from "@hooks/useInterval";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import IconWithLoading from "@components/common/IconWithLoading";
import {PageRoutes} from "../../constants";
import {useNavigate} from "react-router-dom";

interface VerifyOverlayProps {
  isReset?: boolean;
}

const VerifyPage = observer(({isReset = false}: VerifyOverlayProps) => {
  const {accountStore} = useStores();
  const [timeLeft, setTimeLeft] = useState(59);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const handleResend = () => {
    setIsLoading(true);
    const resendFunc = !isReset ? accountStore.resend() : accountStore.resetResend();

    resendFunc
      .then((response) => {
        if (response.success) {
          setTimeLeft(59);
        } else {
          handleResponse(response.data);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        handleResponse(response.response.data);
      });
  };

  useInterval(() => {
    setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
  }, timeLeft > 0 ? 1000 : null);

  return (
    <div className="d-flex flex-column justify-content-center tx-center">
      <img src={verify} alt="Verify" className="mx-auto wd-65"/>
      <div className="tx-center">
        <div className="tx-28 mt-4">
          {!isReset ? 'Verify your account' : 'Check your email'}
        </div>
        <div className="my-4 tx-muted">
          We’ve sent an email to
          <div className="tx-primary text-decoration-underline">{accountStore.email}</div>
          with a link to {!isReset ? 'verify your account' : 'reset your password'}.
        </div>
        {timeLeft > 0 && (
          <div>
            <span>Resend if it doesn’t arrive in</span>
            <div>00:{timeLeft < 10 ? '0' : ''}{timeLeft}</div>
          </div>
        )}
        {timeLeft === 0 && (
          <button
            className="btn btn-primary wd-100p"
            onClick={handleResend}
          >
            <IconWithLoading isLoading={isLoading} className="me-2" />
            Resend
          </button>
        )}

        {!isReset && (
          <div className="mt-4 tx-muted">
            Already have an account?
            <button
              className="btn btn-link tx-primary d-inline py-0 px-0 ms-2"
              onClick={() => navigate(PageRoutes.LOGIN)}
            >
              Log in
            </button>
          </div>
        )}
        {isReset && (
          <div className="mt-4 tx-muted">
            If email doesn’t show up soon, check your spam folder or make sure you enter correct email.
          </div>
        )}
      </div>
    </div>
  );
});

export default VerifyPage;

import React, {useMemo, useState} from "react";
import FavoriteListItem from "./components/FavoriteListItem";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import {observer} from "mobx-react-lite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import GainersLosers from "./components/GainersLosers";
import InfoTooltip from "@components/common/InfoTooltip";
import {getPercentageDifference} from "@helpers/numbers";
import FormattedNumber from "@components/common/FormattedNumber";
import FavoritesLogin from "../TokenList/components/FavoritesLogin";
import NoFavorites from "../TokenList/components/NoFavorites";

const FavoriteList = observer(() => {
  const {tokensStore, accountStore} = useStores();
  const {favorites, isFavoritesLoading} = tokensStore;

  // const sortIcon = (type: Sort) => {
  //   switch (type) {
  //     case Sort.ASC:
  //       return sortAsc;
  //     case Sort.DESC:
  //       return sortDesc;
  //     default:
  //       return sortDefault;
  //   }
  // };

  // const handleSort = (_sort: Sort, type: TokenSort) => {
  //   switch (_sort) {
  //     case Sort.ASC:
  //       tokensStore.setSort(Sort.DESC, type);
  //       break;
  //     case Sort.DESC:
  //       tokensStore.setSort(Sort.DEFAULT, type);
  //       break;
  //     default:
  //       tokensStore.setSort(Sort.ASC, type);
  //       break;
  //   }
  //   setPage(0);
  // };

  // const handleCategoryChange = (value: TokensCategory) => {
  //   tokensStore.setCategory(value);
  //   setPage(0);
  // }

  // const handleIntervalChange = (value: TokenListInterval) => {
  //   tokensStore.setInterval(value);
  //   setPage(0);
  // }

  // useEffect(() => {
  //   const onScroll = () => tokensStore.setScrollTop(window.scrollY);
  //   // clean up code
  //   window.removeEventListener('scroll', onScroll);
  //   window.addEventListener('scroll', onScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, [tokensStore]);

  // useEffect(() => {
  //   if (!isInitialized) {
  //     // @ts-ignore
  //     window.scrollTo({left: 0, top: tokensStore.scrollTop, behavior: tokensStore.scrollTop === 0 ? 'smooth' : 'instant'});
  //   }
  //   setIsInitialized(true);
  //
  //   return () => {
  //     setIsInitialized(false);
  //   }
  // }, [tokensStore.scrollTop, isInitialized, tokensStore.currentPage]);

  // useEffect(() => {
  //   if (tokensStore.currentPage !== page) {
  //     window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  //   }
  //
  //   tokensStore.setPage(page);
  // }, [tokensStore, page]);

  const tableHeaders = [
    {
      title: '',
    },
    {
      title: 'Name',
    },
    {
      title: 'Liquidity',
    },
    {
      title: 'Price',
    },
    {
      title: 'Price Change',
    },
    {
      title: 'MCap',
    },
    {
      title: 'Volume',
    },
    {
      title: 'Holders',
    },
    {
      title: 'Chain',
    },
    {
      title: 'Created',
    },
  ];

  const totalChange = useMemo(() => {
    return favorites.map((t) => getPercentageDifference(t.price, t.price24h) || 0)?.reduce((acc, diff) => {
      return acc + diff;
    }, 0) || 0;
  }, [favorites]);

  if (!accountStore.isLoggedIn) {
    return <FavoritesLogin />;
  }

  return (
    <div>
      {(isFavoritesLoading || favorites.length > 0) ? (<>
        <GainersLosers />

        <div className="border-top border-bottom border-secondary no-gutter d-md-flex justify-content-between align-items-center ht-md-70">
          <div className="wd-100p d-flex justify-content-start align-items-center">
            <div className="tx-22">Total Change:</div>
            <div className={`d-inline-block ms-3 me-0 tx-14 rounded-1 tx-center py-2 px-3 ${totalChange >= 0 ? 'bg-semi-success-2' : 'bg-semi-danger-2'}`}>
              <FormattedNumber
                value={totalChange}
                postfix="%"
                className={`word-break ${totalChange >= 0 ? 'tx-success' : 'tx-danger'}`}
                withSign={totalChange !== 0}
              />
            </div>
          </div>
        </div>

        <div className="no-gutter px-0">
          <div className="div-table tx-14 wd-100p" id="tokens-table">
            <div className="table-header sticky" style={{top:80}}>
              <div className="table-row">
                {tableHeaders.map(({title}, index) => (
                  <div
                    className={`table-col tx-muted`}
                    key={`table-header-${index}-title`}
                  >
                    {title || <>&nbsp;</>}
                  </div>
                ))}
              </div>
            </div>
            <div className="table-body">
              {isFavoritesLoading && <Preloader className="mb-2" inline />}
              {!isFavoritesLoading && favorites.length > 0 && favorites.map((item, index) => (
                <FavoriteListItem
                  key={`favorite-list-item-${item.id.token}-${index}`}
                  {...item}
                />
              ))}
            </div>
          </div>
        </div>
      </>) : <NoFavorites />}

      {/*{tokensStore.totalTokens > 10 && (*/}
      {/*  <ReactPaginate*/}
      {/*    forcePage={page}*/}
      {/*    previousLabel={null}*/}
      {/*    nextLabel={null}*/}
      {/*    breakLabel={<FontAwesomeIcon icon={faChevronRight}/>}*/}
      {/*    pageCount={tokensStore.totalPages}*/}
      {/*    marginPagesDisplayed={0}*/}
      {/*    pageRangeDisplayed={4}*/}
      {/*    containerClassName={'pagination mt-4'}*/}
      {/*    activeClassName={'active'}*/}
      {/*    onPageChange={(data) => setPage(data.selected)}*/}
      {/*    // eventListener={isLoading ? '' : 'onClick'}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
});

export default FavoriteList;

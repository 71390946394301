import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStores} from "@hooks/useStores";
import PasswordStrength from "./PasswordStrength";
import useResponseHandler from "@hooks/useResponseHandler";
import IconWithLoading from "@components/common/IconWithLoading";
import eye from "@assets/icons/eye.svg";
import {errorsContainCode} from "@helpers/errorsContainCode";
import {emailRx} from "../../../constants";
import eyeClosed from "@assets/icons/eye-closed.svg";
import TermsPrivacy from "@components/common/TermsPrivacy";
import useQuery from "@hooks/useQuery";
import {ConnectionStep} from "@stores/account-store";

const SignupTab = observer(() => {
  const {accountStore} = useStores();
  const [email, setEmail] = useState<string>(accountStore.emailFromGet || '');
  const [password, setPassword] = useState<string>('');
  const [referralId, setReferralId] = useState<string>(useQuery().get('ref') || accountStore.refID || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [uplinerError, setUplinerError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleResponse = useResponseHandler();

  const incorrectEmail = !emailRx.test(email);
  const lengthPassed = password.length >= 8;
  const upperCasePassed = /[A-Z]/.test(password);
  const lowerCasePassed = /[a-z]/.test(password);
  const numberPassed = /[0-9]/.test(password);
  const incorrectPassword = !lengthPassed || !upperCasePassed || !lowerCasePassed || !numberPassed;

  const handleRegister = () => {
    if (!email || incorrectEmail) {
      setEmailError('Invalid email address');
      return;
    }

    if (!password || incorrectPassword) {
      setPasswordError('Minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number');
      return;
    }

    setIsLoading(true);

    accountStore
      .register(email, password, referralId || null)
      .then((response) => {
        if (response.success) {
          accountStore.setConnectionStep(ConnectionStep.VERIFY);
        } else {
          const _emailError = errorsContainCode(response.errors, 'err.email');
          const _passwordError = errorsContainCode(response.errors, 'err.password');
          const _uplinerError = errorsContainCode(response.errors, 'err.upliner');
          if (_passwordError) {
            setPasswordError(_passwordError);
          } else if (_emailError) {
            setEmailError(_emailError);
          } else if (_uplinerError) {
            setUplinerError(_uplinerError);
          } else {
            handleResponse(response.data);
          }
        }
        setIsLoading(false);
      })
      .catch((response) => {
        const emailError = errorsContainCode(response.response.data.errors, 'err.email');
        const passwordError = errorsContainCode(response.response.data.errors, 'err.password');
        const _uplinerError = errorsContainCode(response.response.data.errors, 'err.upliner');
        if (passwordError) {
          setPasswordError(passwordError);
        } else if (emailError) {
          setEmailError(emailError);
        } else if (_uplinerError) {
          setUplinerError(_uplinerError);
        } else {
          handleResponse(response.response.data);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setEmailError('');
  }, [email]);

  useEffect(() => {
    setPasswordError('');
  }, [password]);

  return (
    <div>
      <div className="form-group mb-4">
        <label className="tx-13 tx-light mb-1">Email</label>
        <input
          className={`form-control ${emailError && 'is-invalid'}`}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={'Enter your email'}
          id="email"
          disabled={isLoading}
        />
        {!!emailError && (
          <div className={`tx-danger tx-light tx-11 mt-1`}>
            {emailError}
          </div>
        )}
      </div>

      <div className="form-group mb-4">
        <div className="d-flex justify-content-between align-items-start">
          <label className="tx-13 tx-light mb-1 me-auto">Password</label>
          <PasswordStrength password={password} />
        </div>
        <div className="form-group">
          <input
            className={`form-control ${passwordError && 'is-invalid'}`}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={'Enter your password'}
            id="password"
            disabled={isLoading}
          />
          <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
            <img src={showPassword ? eye : eyeClosed} alt="Show password" />
          </div>
        </div>
        <div className={`${passwordError ? 'tx-danger' : 'tx-muted'} tx-light tx-11 mt-1`}>
          {passwordError || 'Minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number'}
        </div>
      </div>

      <div className="form-group mb-4">
        <label className="tx-13 tx-light mb-1">Referral ID (optional)</label>
        <input
          className={`form-control ${uplinerError && 'is-invalid'}`}
          type="text"
          value={referralId}
          onChange={(e) => setReferralId(e.target.value)}
          placeholder={'Enter your referral ID'}
          id="referralId"
          disabled={isLoading}
        />
        {!!uplinerError && (
          <div className={`tx-danger tx-light tx-11 mt-1`}>
            {uplinerError}
          </div>
        )}
      </div>

      <button
        className="btn btn-outline-primary tx-primary wd-100p mt-2"
        onClick={handleRegister}
        disabled={isLoading}
      >
        <IconWithLoading isLoading={isLoading} className="me-2" />
        Create Account
      </button>

      <div className="mt-3 tx-center tx-12 tx-muted">
        By creating account, I agree to the
        <TermsPrivacy
          className="tx-muted"
          linkClassName="tx-primary text-decoration-none"
          separator="and"
        />
      </div>
    </div>
  )
});

export default SignupTab;

import {useState} from "react";
import useInterval from "@hooks/useInterval";
import CircleChart from "@components/common/Charts/CircleChart";

export default function HoldersLoader() {
  const [loaded, setLoaded] = useState<number>(0);

  useInterval(() => {
    setLoaded(prevValue => prevValue + 1);
  }, loaded !== 99 ? 600 : null);

  return (
    <div className="tx-center">
      <CircleChart
        value={loaded}
        color="tx-primary"
        size="xl"
        valueClassName="tx-white"
        drawValue
      />
      <div className="my-2 tx-28">Loading...</div>
      <div className="tx-14">
        Please wait as holders are loading.<br />
        This process may take up to 1 minute
      </div>
    </div>
  );
};

import {privacyPolicyUrl, termsOfServiceUrl} from "../../constants";
import React from "react";

interface TermsPrivacyProps {
  className?: string;
  linkClassName?: string;
  separator?: string;
}

export default function TermsPrivacy({className = 'mt-5 tx-center tx-muted tx-14', linkClassName = 'tx-muted text-decoration-none', separator = '|'}: TermsPrivacyProps) {
  return (
    <div className={className}>
      <a
        href={termsOfServiceUrl}
        target="_blank"
        className={linkClassName}
        rel="noreferrer"
      >
        Term of Service
      </a>
      <span className="mx-2">{separator}</span>
      <a
        href={privacyPolicyUrl}
        target="_blank"
        className={linkClassName}
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </div>
  );
}

import React from "react";
import Header from "@components/Header";
import Sidebar from "@components/Sidebar";
import Footer from "@components/Footer";
import MobileNavigation from "@components/MobileNavigation";

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout (props: MainLayoutProps) {
  const {children} = props;

  return (
    <div id="main-layout">
      <Sidebar />
      <div id="container">
        <Header />
        <div id="content">
          {children}
          <Footer />
        </div>
      </div>
      <MobileNavigation />
    </div>
  );
}

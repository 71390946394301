import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import {faChevronUp} from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Information = observer(({token}: {token: TokenViewNew}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const description = token.description;

  return (
    <div className={`card wd-100p mt-3 ${description.length > 200 ? 'pb-5' : ''} overflow-hidden`}>
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Information</span>
      </div>

      <div className="tx-muted tx-14">
        {description && (
          <>
            {description.substring(0, showMore ? 10000 : 200)}
            {description.length > 200 && !showMore && '...'}
          </>
        )}
        {!description && (
          <>No information</>
        )}
      </div>

      {description.length > 200 && (
        <button
          className="card-button"
          onClick={() => setShowMore((prev) => (!prev))}
        >
          {showMore ? 'Show less' : 'Show more'}
          <FontAwesomeIcon icon={showMore ? faChevronUp : faChevronDown} className="ms-2" />
        </button>
      )}
    </div>
  );
});

export default Information;

import React from "react";
// import star from "@assets/icons/star-white.svg";
import chevronsUp from "@assets/icons/chevrons-up.svg";
import chevronsDown from "@assets/icons/chevrons-down.svg";
import gas from "@assets/icons/gas.svg";
import search from "@assets/icons/search.svg";
import {useStores} from "@hooks/useStores";
import FormattedNumber from "@components/common/FormattedNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
// import Search from "@components/Search";
import Wallet from "@components/Wallet";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {PageRoutes} from "../constants";

const Header = observer(() => {
  const {globalStore} = useStores();
  const navigate = useNavigate();

  return (
    <div id="header" className="sticky">
      <div className="mn-wd-md-310 d-flex align-items-center tx-13 tx-md-14">
        <span className="tx-muted">ETH: </span>
        <FormattedNumber
          suffix="$"
          value={globalStore.ethPrice || null}
          decimals={0}
          className="tx-white mx-1"
        />

        <FormattedNumber
          suffix="("
          postfix="%)"
          value={globalStore.ethDiff || null}
          decimals={2}
          withSign
          className={`mx-1 ${globalStore.ethDiff > 0 ? 'tx-success' : 'tx-danger'}`}
        />

        <img alt="ETH price change" src={globalStore.ethDiff > 0 ? chevronsUp : chevronsDown} />

        {!!globalStore.ethGasBase && (
          <Dropdown id="gas-dropdown">
            <Dropdown.Toggle variant="transparent" className="ms-2 ms-md-3 d-none d-md-flex align-items-center" >
              <img src={gas} alt="gas" className="wd-15 wd-md-auto" />
              <FormattedNumber
                value={globalStore.ethGasBase || null}
                decimals={0}
                className="mx-1 mx-md-2 lh-1"
              />
              <FontAwesomeIcon icon={faChevronDown} className="tx-12 d-block lh-1" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {globalStore.ethGas.slice().sort((a, b) => a.acceptance - b.acceptance).map((gas, index) => (
                <Dropdown.Item key={`eth-gas-${index}-${gas.acceptance}`}>
                  <span className="tx-muted">
                    {index === 0 && 'Low: '}
                    {index === 1 && 'Medium: '}
                    {index === 2 && 'Fast: '}
                  </span>
                  <FormattedNumber
                    value={gas.baseFee || null}
                    postfix="GWEI"
                    decimals={0}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {/*<div className="ms-3 ms-md-4 d-flex align-items-center">*/}
        {/*  <img src={gas} alt="gas" className="wd-15 wd-md-auto" />*/}
        {/*  <FormattedNumber*/}
        {/*    value={globalStore.ethGasBase || null}*/}
        {/*    decimals={0}*/}
        {/*    className="mx-1 mx-md-2 lh-1"*/}
        {/*  />*/}
        {/*  <FontAwesomeIcon icon={faChevronDown} className="tx-12 d-block lh-1" />*/}
        {/*</div>*/}
      </div>

      {/*<Search />*/}

      {/*<button className="d-none d-md-inline btn btn-icon btn-secondary me-2 mn-wd-40 wd-40 ht-40" onClick={() => navigate(PageRoutes.FAVORITE_LIST)}>*/}
      {/*  <img src={star} alt="star" />*/}
      {/*</button>*/}


      <button
        className="btn btn-secondary ht-40 tx-center flex-row align-items-center ms-auto me-3 d-none d-sm-flex"
        onClick={() => navigate(PageRoutes.SEARCH)}
      >
        <img src={search} alt="Search" />
      </button>

      <Wallet />
    </div>
  );
});

export default Header;

import {useMemo} from "react";
import Progress from "@components/common/Progress";


interface PasswordStrengthProps {
  password: string;
}

export default function PasswordStrength({password}: PasswordStrengthProps) {
  const lengthPassed = password.length >= 8;
  const upperCasePassed = /[A-Z]/.test(password);
  const lowerCasePassed = /[a-z]/.test(password);
  const numberPassed = /[0-9]/.test(password);

  const strength = useMemo(() => {
    return (lengthPassed ? 1 : 0) + (upperCasePassed ? 1 : 0) + (lowerCasePassed ? 1 : 0) + (numberPassed ? 1 : 0);
  }, [lengthPassed, upperCasePassed, lowerCasePassed, numberPassed]);

  const strengthText = useMemo(() => {
    switch (strength) {
      case 1:
        return 'Weak';
      case 2:
        return 'So-so';
      case 3:
        return 'Good';
      case 4:
        return 'Great';
      default:
        return '';
    }
  }, [strength]);

  const strengthColor = useMemo(() => {
    switch (strength) {
      case 1:
        return 'dark-red';
      case 2:
        return 'orange';
      case 3:
        return 'success';
      case 4:
        return 'dark-green';
      default:
        return '';
    }
  }, [strength]);

  if (!password) {
    return null;
  }

  return (
    <div className="password-strength d-flex justify-content-between align-items-center tx-13">
      <Progress value={strength} max={4} forceColor={strengthColor} className="wd-150" />
      <div className={`wd-60 tx-right tx-${strengthColor}`}>{strengthText}</div>
    </div>
  );
};

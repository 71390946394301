import React from "react";
import {observer} from "mobx-react-lite";
import bull from "@assets/icons/bull.svg";
import {darkTheme, SupportedChainId, SwapWidget} from "@uniswap/widgets";
import {useStores} from "@hooks/useStores";
import {useSigner} from "wagmi";
import ConnectWalletButton from "@components/ConnectWalletButton";

const SubscribeStepLifetime = observer(() => {
  const { accountStore } = useStores();
  const provider = useSigner().data?.provider;
  const {address} = accountStore;

  return (
    <>
      <div className="tx-center mt-4">
        <img src={bull} alt="Bull" className="wd-100 mb-3" />
        <div className="tx-28">
          Lifetime Subscription
        </div>
        <div className="tx-14 my-3">
          When you buy and hold 50,000,000 USHI tokens you will
          have unlimited access to our premium features and updates!
        </div>
        <div className="tx-14 tx-muted wd-300 mx-auto">
          *Commission of the USHI tokenomics 3%
          total amount 52 000 000 USHI
        </div>
      </div>

      <div className="tx-center mx-auto d-flex justify-content-center mt-4">
        {!address || !provider ? (
          <div>
            <div>You need to connect your wallet to swap tokens.</div>

            <ConnectWalletButton />
          </div>
        ) : (
          <SwapWidget
            theme={darkTheme}
            jsonRpcUrlMap={['https://eth-mainnet.g.alchemy.com/v2/sS53pr4W_trGDG86HDBJ2vN5TpApw8mR']}
            tokenList={[{
              chainId: SupportedChainId.MAINNET,
              address: '0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55',
              symbol: 'USHI'.replace(/[^\w\s]/gi, '').replaceAll(" ", "-"),
              name: 'Ushi'.replace(/[^\w\s]/gi, '').replaceAll(" ", "-"),
              decimals: 18,
              logoURI: 'https://www.dextools.io/resources/tokens/logos/ether/0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55.png',
            }]}
            // @ts-ignore
            provider={provider ?? undefined}
            defaultOutputTokenAddress={'0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55'}
            defaultChainId={SupportedChainId.MAINNET}
          />
        )}
      </div>
    </>
  );
});

export default SubscribeStepLifetime;

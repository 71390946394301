import axios, {AxiosResponse} from "axios";
import {ENV} from "../constants";
import {applyAuthTokenInterceptor} from "axios-jwt";

export const axiosInstance = axios.create({ baseURL: `${ENV.API_URL}/api/v1` });

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh: () => {
    return new Promise((resolve, reject) => {
      resolve(localStorage.getItem('token') || '');
    });
  },  // async function that takes a refreshToken and returns a promise the resolves in a fresh accessToken
  header: "x-authorization",  // header name
  headerPrefix: "Bearer ",  // header value prefix
});

interface ApiProps {
  method: 'post' | 'get' | 'delete';
  path: string;
  data?: any;
  version?: number;
  baseURL?: string;
}

export default function api<T>({ method, path, baseURL, data, version }: ApiProps): Promise<T> {
  const url = method === 'get' ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
      baseURL: baseURL || (version ? ENV.API_V2_URL + `/api/v${version}` : ENV.API_URL + '/api/v1'),
    }).then((response) => {
      if (response.data) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch((response) => {
      reject(response);
    });
  });
}

export function apiv2<T>({ method, path, data }: ApiProps): Promise<T> {
  return api({ method, path, data, version: 2 });
}

export function apiv3<T>({ method, path, data }: ApiProps): Promise<T> {
  return api({ method, path, data, version: 3 });
}

export function apiRaw<T>({ method, path, data }: ApiProps): Promise<AxiosResponse<T>> {
  const url = method === 'get' ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
    }).then((response) => {
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    }).catch((response) => {
      reject(response);
    });
  });
}

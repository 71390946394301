import React, {useCallback, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import {chains} from "../../constants";
import searchIcon from "@assets/icons/search.svg";
import IconWithLoading from "@components/common/IconWithLoading";

const FindGem = observer(() => {
  const { tokensStore } = useStores();
  const { isLoading, chain, token, error } = tokensStore;
  const navigate = useNavigate();
  const regex = new RegExp(/^0x[a-zA-Z0-9]{25,40}$/g);
  const [isInvalid, setIsInvalid] = useState(false);
  const [address, setAddress] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  const handleSubmit = useCallback(() => {
    if (!address || !regex.test(address.toLowerCase())) {
      setIsInvalid(true);
      return;
    }

    tokensStore.searchSingleToken(address.toLowerCase(), chain.symbol);
  }, [address, tokensStore, chain]);

  useEffect(() => {
    if (isInitialized && token) {
      navigate(`/${chain.symbol}/${token.pair.address.toLowerCase()}`);
    }
  }, [isInitialized, token, chain]);

  useEffect(() => {
    if (!isInitialized && token && !address) {
      tokensStore.setToken(null);
    }
    setIsInitialized(true);
  }, [isInitialized, token, address]);

  return (
    <div className="full-page d-flex align-items-center justify-content-center flex-column">
      <div className="tx-300 tx-32 tx-md-52 tx-uppercase">let's find gem</div>

      <form className="d-flex justify-content-between align-items-center flex-column flex-md-row wd-100p wd-md-auto" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <Dropdown id="chains-dropdown" className="d-inline-block wd-100p wd-md-auto">
          <Dropdown.Toggle variant="secondary" className="btn btn-secondary py-2 px-3 wd-100p wd-md-170 ht-48">
            <img src={chain.icon} alt={chain.title} className="me-3" width={20} height={20} />
            {chain.title}
            <FontAwesomeIcon icon={faChevronDown} className="tx-12 d-inline ms-2 lh-1" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="wd-100p wd-md-200">
            {chains.map((chain) => (
              <Dropdown.Item key={`chain-filter-${chain.id}-${chain.symbol}`} onClick={(e) => {
                e.preventDefault();
                tokensStore.setChain(chain);
              }}>
                <img src={chain.icon} alt={chain.title} className="me-2" width={16} height={16} />
                {chain.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <div className="input-group wd-100p wd-md-450 ht-48 mx-md-3 my-2 my-md-0">
          <div className="input-group-text py-1 ht-100p">
            {!isLoading && (<img src={searchIcon} alt="search" />)}
            {isLoading && (
              <IconWithLoading isLoading />
            )}
          </div>
          <input
            disabled={isLoading}
            type="text"
            className={`form-control py-1 ht-100p ${isInvalid ? 'is-invalid' : ''}`}
            placeholder="Token address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value)
              setIsInvalid(false);
            }}
          />
        </div>

        <button disabled={isLoading} type="submit" className="btn btn-primary ht-48 px-3 wd-100p wd-md-auto">Search</button>
      </form>

      {!!error && (
        <div className="tx-12 tx-danger mt-2">{error}</div>
      )}
    </div>
  );
});

export default FindGem;

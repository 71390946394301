import {action, makeAutoObservable, observable} from 'mobx';

export enum Overlays {
  NONE,
  MENU,
  SEARCH,
  FAVORITES,
  CONNECT_WALLET,
  TOP_TOKENS,
  LOGIN,
  SIGNUP,
  VERIFY,
  VERIFY_RESET,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  ACCOUNT_CREATED,
}

export class OverlayStore {
  @observable
  private _activeOverlay: Overlays = Overlays.NONE;

  @observable
  private _showConnectWallet: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get
  activeOverlay() {
    return this._activeOverlay;
  }

  @action
  setActiveOverlay(value: Overlays, force = false) {
    if (force) {
      this._activeOverlay = Overlays.NONE;
    }

    this._activeOverlay = value;
  }

  get
  showConnectWallet() {
    return this._showConnectWallet;
  }

  @action
  setShowConnectWallet(value: boolean) {
    this._showConnectWallet = value;
  }
}

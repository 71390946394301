import React, {useCallback} from "react";
import FormattedNumber from "@components/common/FormattedNumber";
import {FavoritesTokenInterface} from "@stores/tokens-store";
import {useNavigate} from "react-router-dom";
import TokenLogo from "@components/common/TokenLogo";
import exchEth from "@assets/icons/exch-eth.svg";
import exch from "@assets/icons/exch-2.svg";
import {getPercentageDifference} from "@helpers/numbers";
import ChainExchange from "@components/common/ChainExchange";

interface HighlightsListItemComponentProps extends FavoritesTokenInterface {}

const HighlightsListItem = (props: HighlightsListItemComponentProps) => {

  const {
    id,
    token,
    price,
    price24h,
    symbolRef,
  } = props;

  const {
    pair,
  } = id;

  const {
    symbol,
    name,
    logo,
  } = token;

  const navigate = useNavigate();
  const priceDiffValue = getPercentageDifference(price, price24h || parseInt(''));

  const handleClick = useCallback(() => {
    navigate(`/${id.chain}/${pair}`);
  }, [navigate, id, pair]);

  return (
      <div
        onClick={handleClick}
        className="card p-2 wd-100p d-flex flex-row align-items-center justify-content-between cur-pointer"
      >
        <div className="ms-0 overflow-hidden token-logo">
          <TokenLogo logo={logo} name={name} address={pair} size={35} />
        </div>
        <div className="d-flex flex-column justify-content-between ms-2 me-auto">
          <div className="tx-16 lh-2">{symbol}<span className="tx-muted tx-14"> / {symbolRef}</span></div>
          <div className="tx-13 lh-2 tx-muted d-none d-md-block">{name}</div>
        </div>

        <ChainExchange chain={id.chain} exchange={id.exchange} className="me-3 d-none d-md-block" size={20} />

        <div className={`ms-0 me-0 tx-14 wd-90 rounded-1 tx-right d-flex flex-column align-items-end justify-content-center`}>
          <FormattedNumber
            value={price}
            suffix="$"
            className={`word-break`}
            subZeros
          />
          {!isNaN(priceDiffValue) && (
            <FormattedNumber
              value={priceDiffValue}
              postfix="%"
              className={`word-break ${priceDiffValue >= 0 ? 'tx-success' : 'tx-danger'}`}
              withSign={priceDiffValue !== 0}
            />
          )}
        </div>
      </div>
  );
};

export default HighlightsListItem;

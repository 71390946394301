import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import './styles/main.scss';
import '@uniswap/widgets/fonts.css';
import {useStores} from "@hooks/useStores";
import {alchemyToken, PageRoutes} from "./constants";
import {Route, Routes, useLocation} from "react-router-dom";
import {Overlays} from "@stores/overlay-store";
import SearchOverlay from "@components/Overlays/SearchOverlay";
import Token from "./pages/Token";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import ResetPage from "./pages/Auth/ResetPage";
import ForgotOverlay from "./pages/Auth/ForgotPage";
import AccountCreatedPage from "./pages/Auth/AccountCreatedPage";
import Profile from "./pages/Profile";
import {configureChains, createClient, mainnet, useAccount, WagmiConfig} from 'wagmi';
import {publicProvider} from 'wagmi/providers/public';
import PasswordChange from "./pages/Profile/components/PasswordChange";
import Referral from "./pages/Referral";
import useQuery from "@hooks/useQuery";

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import {alchemyProvider} from "wagmi/providers/alchemy";
import Subscribe, {SubscribePage} from "./pages/Subscribe";
import Withdrawal from "./pages/Referral/Withdrawal";
import MainLayout from "@components/MainLayout";
import ConnectionModal from "@components/ConnectionModal";
import FavoriteList from "./pages/FavoriteList";
import {ConnectionStep} from "@stores/account-store";
import FindGem from "./pages/FindGem";
import TokenList from "./pages/TokenList";

/* global BigInt */

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, LineElement, PointElement, Title);

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: alchemyToken }), publicProvider()],
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'uAnalytics',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        showQrModal: true,
        projectId: "17e8d76b244b215e0c4dd682235266d5"
      },
    }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: 'Injected',
    //     shimDisconnect: true,
    //   },
    // }),
  ],
  provider,
  webSocketProvider,
});

const publicRoutes = [
  PageRoutes.LOGIN,
  PageRoutes.SIGNUP,
  PageRoutes.COIN_LIST,
  PageRoutes.FAVORITE_LIST,
  PageRoutes.VERIFY,
  PageRoutes.VERIFY_RESET,
  PageRoutes.UPDATE_PASSWORD,
  PageRoutes.SUBSCRIBE,
  PageRoutes.SUBSCRIBE_STEP,
  PageRoutes.SEARCH,
];

const protectedRoutes = Object.values(PageRoutes).filter(route => !publicRoutes.includes(route));

const allRoutes = [
  {
    path: '*',
    element: <TokenList />
  },
  {
    path: PageRoutes.COIN_LIST,
    element: <TokenList />
  },
  {
    path: PageRoutes.SEARCH,
    element: <FindGem />
  },
  {
    path: PageRoutes.FAVORITE_LIST,
    element: <FavoriteList />
  },
  {
    path: '/:chain/:address',
    element: <Token />,
  },
  {
    path: '/:chain/:address/:tab',
    element: <Token />,
  },
  {
    path: PageRoutes.VERIFY,
    element: <Token />,
  },
  {
    path: PageRoutes.VERIFY_RESET,
    element: <Token />,
  },
  {
    path: PageRoutes.UPDATE_PASSWORD,
    element: <ResetPage />,
  },
  {
    path: PageRoutes.FORGOT_PASSWORD,
    element: <ForgotOverlay/>,
  },
  {
    path: PageRoutes.UPDATE_PASSWORD,
    element: <ResetPage/>,
  },
  {
    path: PageRoutes.ACCOUNT_CREATED,
    element: <AccountCreatedPage/>,
  },
  {
    path: PageRoutes.SUBSCRIBE,
    element: <Subscribe/>,
  },
  {
    path: PageRoutes.SUBSCRIBE_STEP,
    element: <Subscribe/>,
  },
];

const App = observer(() => {
  const {accountStore, tokensStore, overlayStore} = useStores();
  const { address, isConnected } = useAccount();
  const {address: connectedAddress, isLoggedIn} = accountStore;
  const {activeOverlay} = overlayStore;
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    accountStore.handleGetParams(query);
  }, [accountStore, query]);

  useEffect(() => {
    if (window.location.hash === '#clearmydirtybrowser') {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!address || !isConnected) {
      accountStore.setAddress(null);
      return;
    }

    accountStore.setAddress(address);
    accountStore.loadBalance(address);
    // accountStore.loadSubscription();
  }, [accountStore, address, isConnected]);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     accountStore.loadAccountInfo();
  //     tokensStore.fetchFavorites();
  //   }
  // }, [isLoggedIn, tokensStore, accountStore]);

  useEffect(() => {
    if (location.pathname === PageRoutes.VERIFY) {
      accountStore.setConnectionStep(ConnectionStep.VERIFY);
    } else if (location.pathname === PageRoutes.VERIFY_RESET) {
      accountStore.setConnectionStep(ConnectionStep.VERIFY_RESET);
    } else if (location.pathname === PageRoutes.FORGOT_PASSWORD) {
      accountStore.setConnectionStep(ConnectionStep.FORGOT_PASSWORD);
    }
  }, [location, accountStore]);

  useEffect(() => {
    if (location.pathname !== `/subscribe/${SubscribePage.THREE_MONTHS}` && location.pathname !== `/subscribe/${SubscribePage.MONTHLY}`) {
      localStorage.removeItem('invoiceId');
      localStorage.removeItem('threeMonths');
    }
  }, [location.pathname]);

  // return (
  //   <div className="d-flex ht-100p wd-100p flex-column justify-content-center align-items-center">
  //     <div>uAnalytics website is under maintenance.</div>
  //     <a
  //       href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6Dca182ac5E3f99985bC4Ee0f726d6472aB1ec55"
  //       className="btn btn-outline-primary btn-sm wd-300 mt-2"
  //       rel="noreferrer"
  //       target="_blank"
  //     >
  //       Buy $USHI while waiting ;)
  //     </a>
  //   </div>
  // );

  const wrap = useCallback((element: JSX.Element) => {
    return <div className="container py-3">{element}</div>;
  }, []);

  return (
    <WagmiConfig client={client}>
      <MainLayout>
        <Routes>
          {allRoutes.map(({path, element}) => (
            <Route path={path} element={element} key={`all-routes-${path}`} />
          ))}
          {!isLoggedIn && (
            <>
              {protectedRoutes.map((route, index) => (
                <Route path={route} element={wrap(<FindGem/>)} key={`protected-route-${route}-${index}`}/>
              ))}
            </>
          )}
          {isLoggedIn && (
            <>
              <Route path={PageRoutes.PROFILE} element={wrap(<Profile />)}/>
              {/*<Route path={PageRoutes.PROFILE_WALLET} element={<VerifyWallet/>}/>*/}
              <Route path={PageRoutes.PROFILE_PASSWORD} element={wrap(<PasswordChange />)}/>
              <Route path={PageRoutes.REFERRAL_PROGRAM} element={wrap(<Referral />)}/>
              <Route path={PageRoutes.REFERRAL_WITHDRAW} element={wrap(<Withdrawal />)}/>
            </>
          )}
        </Routes>
        <ConnectionModal />
        {activeOverlay === Overlays.SEARCH && <SearchOverlay/>}
      </MainLayout>
    </WagmiConfig>
  );
});

export default App;

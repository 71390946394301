import {observer} from "mobx-react-lite";
import {TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import React, {useMemo, useState} from "react";
import Coinmarketcap from "@assets/images/coinmarketcap-mini.svg";
import Coingecko from "@assets/images/coingecko.svg";
import Etherscan from "@assets/images/etherscan-mini.svg";
import Snowtrace from "@assets/images/snowtrace.svg";
import Bscscan from "@assets/images/bscscan.svg";
import Polygonscan from "@assets/images/polygonscan.svg";
import Arbiscan from "@assets/images/arbiscan.svg";
import Bubblemaps from "@assets/images/bubblemaps.svg";
import Twitter from "@assets/images/twitter-mini.svg";
import Telegram from "@assets/images/telegram-mini.svg";
import Discord from "@assets/images/discord-mini.svg";
import Instagram from "@assets/images/instagram.svg";
import YouTube from "@assets/images/youtube.svg";
import Tiktok from "@assets/images/tiktok.svg";
import TokenLogo from "@components/common/TokenLogo";
import {format} from "date-fns";
import FormattedNumber from "@components/common/FormattedNumber";
import NumberCard from "@components/common/NumberCard";
import CopyButton from "@components/common/CopyButton";
import {getEllipsisTxt} from "@helpers/formatters";
import TabsCard, {TabCardItem} from "@components/common/TabsCard";
import link from "@assets/icons/link.svg";
import external from "@assets/icons/external.svg";
import {Dropdown} from "react-bootstrap";
import search from "@assets/icons/search.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import user from "@assets/icons/user.svg";
import code from "@assets/icons/code.svg";
import Star from "@components/common/Star";
import ShareModel from "./ShareModal";
import SwapModal from "@components/SwapModal";
import {getPercentageDifference} from "@helpers/numbers";
import ChainExchange from "@components/common/ChainExchange";
import MobileSocialLinks from "./MobileSocialLinks";

const TokenHeader = observer(({token}: {token: TokenViewNew}) => {
  const {name, pair, symbol} = token;
  const { price, price24h, chain } = pair;
  const intervals = ['5m', '1h', '6h', '24h'];
  const tokenAddress = token.address;

  // const intervalTabs = useMemo(() => {
  //   const result: TabCardItem[] = [];
  //   intervals.forEach((interval, index) => {
  //     if (prices[index]) {
  //       result.push({
  //         label: interval.toUpperCase(),
  //         value: index,
  //       });
  //     }
  //   });
  //   return result;
  // }, [intervals, prices]);

  // const [interval, setInterval] = useState<number>(+intervalTabs[intervalTabs.length - 1]?.value || 0);

  const priceDiffValue = useMemo(() => {
    return getPercentageDifference(price, price24h?.price || price);
  }, [price24h, price]);

  const links = token.links;

  const explorers = [
    {
      title: 'Etherscan',
      icon: Etherscan,
      link: `https://etherscan.io/token/${tokenAddress}`,
      enabled: chain === 'ether',
    },
    {
      title: 'BSCScan',
      icon: Bscscan,
      link: `https://bscscan.com/token/${tokenAddress}`,
      enabled: chain === 'bsc',
    },
    {
      title: 'Arbiscan',
      icon: Arbiscan,
      link: `https://arbiscan.io/token/${tokenAddress}`,
      enabled: chain === 'arbitrum',
    },
    {
      title: 'Polygonscan',
      icon: Polygonscan,
      link: `https://polygonscan.com/token/${tokenAddress}`,
      enabled: chain === 'polygon',
    },
    {
      title: 'Snowtrace',
      icon: Snowtrace,
      link: `https://snowtrace.io/token/${tokenAddress}`,
      enabled: chain === 'avalanche',
    },
    // {
    //   title: 'Coinmarketcap',
    //   icon: Coinmarketcap,
    //   link: `https://coinmarketcap.com/currencies/${token.token.info.cmc}`,
    //   enabled: !!token.token.info.cmc
    // },
    // {
    //   title: 'Coingecko',
    //   icon: Coingecko,
    //   link: `https://www.coingecko.com/en/coins/${token.token.info.coingecko}`,
    //   enabled: !!token.token.info.coingecko
    // },
    {
      title: 'BubbleMaps',
      icon: Bubblemaps,
      link: `https://app.bubblemaps.io/eth/token/${tokenAddress}`,
      enabled: chain === 'ether',
    },
  ];

  const socials = [
    {
      title: 'Twitter',
      icon: Twitter,
      link: links.twitter,
    },
    {
      title: 'Telegram',
      icon: Telegram,
      link: links.telegram,
    },
    {
      title: 'Discord',
      icon: Discord,
      link: links.discord,
    },
    {
      title: 'Instagram',
      icon: Instagram,
      link: links.instagram,
    },
    {
      title: 'YouTube',
      icon: YouTube,
      link: links.linkedin,
    },
    {
      title: 'Tiktok',
      icon: Tiktok,
      link: links.tiktok,
    },
    {
      title: 'GitHub',
      link: links.github,
    },
    {
      title: 'BitBucket',
      link: links.bitbucket,
    },
    {
      title: 'Facebook',
      link: links.facebook,
    },
    {
      title: 'LinkedIn',
      link: links.linkedin,
    },
    {
      title: 'Medium',
      link: links.medium,
    },
    {
      title: 'Reddit',
      link: links.reddit,
    },
  ];

  const hasSocialLinks = !!socials.filter(l => !!l.link).length;

  const websiteLink = useMemo(() => {
    if (!token || !links.website) {
      return undefined;
    }

    let link = links.website.includes('http') ? links.website.split('//')[1].split('/')[0] : links.website.split('/')[0];
    if (link.includes('www.')) {
      link = link.split('www.')[1];
    }
    return link;
  }, [token, links]);

  return (
    <>
      <div className="no-gutter border-bottom border-secondary pb-2 mb-3 mb-md-0 mt-gutter tx-18">
        <div className="d-flex d-md-none align-items-center justify-content-start">
          <div className="ms-0 wd-50 ht-50 rounded-50 overflow-hidden token-logo">
            <TokenLogo logo={token.logo} name={name} address={tokenAddress} size={50} />
          </div>

          <div className="d-flex flex-column ms-3 me-3">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <div className="tx-28 lh-1">{symbol}</div>
              <div className="tx-16 tx-muted mt-1 ms-1">/ {token.pair.tokenRef.symbol}</div>
            </div>
            <div className="tx-muted tx-14">
              Created: {format(new Date(token.pair.createdAt * 1000), 'dd MMM yyyy')}
            </div>
          </div>
        </div>

        <div className="d-flex d-md-none align-items-center justify-content-between">
          <div className="d-flex justify-content-between align-items-center me-auto mt-2">
            <CopyButton
              text={tokenAddress}
              element={'Token: ' + getEllipsisTxt(tokenAddress, 4)}
              className="btn btn-link tx-muted p-0 tx-left tx-olney text-decoration-none tx-12 me-2"
              iconClass="ms-2"
            />
            <CopyButton
              text={token.pair.address}
              element={'Pair: ' + getEllipsisTxt(token.pair.address, 4)}
              className="btn btn-link tx-muted p-0 tx-left tx-olney text-decoration-none tx-12"
              iconClass="ms-2"
            />
          </div>

          <div className="ms-auto me-0">
            <ChainExchange chain={token.pair.chain} exchange={token.pair.exchange} size={25} />
          </div>
        </div>

        <div className="d-flex d-md-none align-items-center justify-content-end my-3">
          <div className="d-flex flex-column justify-content-between align-items-start ms-0 me-auto mn-wd-70">
            <div className="tx-18 lh-1">
              {price && (
                <FormattedNumber
                  suffix="$"
                  value={price}
                  decimals={18}
                  floor={false}
                  subZeros
                  className="word-break"
                />
              )}

            </div>
            <FormattedNumber
              value={priceDiffValue}
              withSign={priceDiffValue !== 0}
              className={`tx-12 ${priceDiffValue > 0 ? 'text-success' : 'text-danger'}`}
              postfix="%"
            />
          </div>

          {/*<Star token={token} className="btn btn-outline-secondary ms-auto me-2 p-2" />*/}
          <ShareModel token={token} onlyIcon />
          <SwapModal token={token} mini />
        </div>

        <div className="d-none d-md-flex align-items-center justify-content-between">
          <div className="ms-0 wd-50 ht-50 rounded-50 overflow-hidden token-logo">
            <TokenLogo logo={token.logo} name={name} address={token.address} size={50} />
          </div>

          <div className="d-flex flex-column ms-3 me-3">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="tx-28 lh-1">{symbol}</div>
              <div className="tx-16 tx-muted mt-1 ms-1">/ {token.pair.tokenRef.symbol}</div>
            </div>
            <div className="tx-muted tx-14">
              Created: {format(new Date(token.pair.createdAt * 1000), 'dd MMM yyyy')}
            </div>
          </div>

          <div className="ms-auto me-3">
            <ChainExchange chain={token.pair.chain} exchange={token.pair.exchange} />
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center me-0 tx-right mn-wd-70">
            <div className="tx-28">
              {price && (
                <FormattedNumber
                  suffix="$"
                  value={price}
                  decimals={18}
                  floor={false}
                  subZeros
                  className="word-break"
                />
              )}
              {!price && (
                <div className="tx-muted">N/A</div>
              )}
            </div>
            {!!price && (
              <NumberCard
                value={priceDiffValue}
                withSign={priceDiffValue !== 0}
                className="ms-3"
                postfix="%"
              />
            )}
          </div>
        </div>

        <div className="d-none d-md-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center me-auto mt-2">
            <div className="bg-secondary rounded-10 px-2 py-1 flex-column justify-content-between tx-right">
              <CopyButton
                text={tokenAddress}
                element={'Token: ' + getEllipsisTxt(tokenAddress, 3)}
                className="btn btn-link p-0 tx-left tx-olney text-decoration-none tx-12"
                iconClass="ms-2"
              />
            </div>
            <div className="bg-secondary rounded-10 px-2 py-1 flex-column justify-content-between tx-right ms-3">
              <CopyButton
                text={token.pair.address}
                element={'Pair: ' + getEllipsisTxt(token.pair.address, 3)}
                className="btn btn-link p-0 tx-left tx-olney text-decoration-none tx-12"
                iconClass="ms-2"
              />
            </div>
          </div>
        </div>

        <MobileSocialLinks
          common={[{title: 'Website', link: links.website, icon: link}]}
          explorers={explorers}
          socials={socials}
        />
      </div>

      <div className="d-none d-md-flex justify-content-between align-items-start mt-1 py-4 border-bottom border-secondary no-gutter mb-3">
        <div className="mn-wd-md-500 mx-wd-md-500 d-flex flex-wrap align-items-start">
          {links.website && (
            <a href={links.website} className="btn btn-secondary tx-12 py-2 px-2 me-3 my-1" target="_blank" rel="noreferrer">
              <img src={link} alt="Link" className="me-2" width={16} height={16} />
              {websiteLink}
              <img src={external} alt="External" className="ms-2" />
            </a>
          )}

          <Dropdown id="explorers-dropdown" className="me-3 d-inline-block my-1">
            <Dropdown.Toggle variant="secondary" className="btn btn-secondary tx-12 py-2 px-2">
              <img src={search} alt="Explorers" className="me-2" width={16} height={16} />
              Explorers
              <FontAwesomeIcon icon={faChevronDown} className="tx-12 d-inline ms-2 lh-1" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="wd-180">
              {explorers.map(({link, title, icon, enabled}, index) => !!link && enabled !== false && (
                <Dropdown.Item href={link} key={`explorer-link-${index}-${link}`} onClick={(e) => {
                  e.preventDefault();
                  window.open(link, '_blank');
                }}>
                  <img src={icon} alt={title} className="me-2" width={16} height={16} />
                  {title}
                  <img src={external} alt="External" className="ms-2" />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {hasSocialLinks && (
            <Dropdown id="community-dropdown" className="me-3 d-inline-block my-1">
              <Dropdown.Toggle variant="secondary" className="btn btn-secondary tx-12 py-2 px-2">
                <img src={user} alt="Community" className="me-2" width={16} height={16} />
                Community
                <FontAwesomeIcon icon={faChevronDown} className="tx-12 d-inline ms-2 lh-1" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {socials.map(({link, title, icon}, index) => !!link && (
                  <Dropdown.Item href={link} key={`community-link-${index}-${link}`} onClick={(e) => {
                    e.preventDefault();
                    window.open(link, '_blank');
                  }}>
                    <img src={icon} alt={title} className="me-2" width={16} height={16} />
                    {title}
                    <img src={external} alt="External" className="ms-2" />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {(links.github || links.bitbucket) && (
            <a href={links.github || links.bitbucket} className="btn btn-secondary tx-12 py-2 px-2 me-3 my-1" target="_blank" rel="noreferrer">
              <img src={code} alt="Code" className="me-2" width={16} height={16} />
              Source Code
              <img src={external} alt="External" className="ms-2" />
            </a>
          )}
          {/*{token.wpLink && (*/}
          {/*  <a href={token.wpLink} className="btn btn-secondary tx-12 py-2 px-2 my-1" target="_blank" rel="noreferrer">*/}
          {/*    <img src={paper} alt="Whitepaper" className="me-2" width={16} height={16} />*/}
          {/*    Whitepaper*/}
          {/*    <img src={external} alt="External" className="ms-2" />*/}
          {/*  </a>*/}
          {/*)}*/}
        </div>

        {/*<Star token={token} className="btn btn-outline-secondary ms-auto me-3 px-3" />*/}
        <ShareModel token={token} />
        <SwapModal token={token} />
      </div>
    </>
  );
});

export default TokenHeader;

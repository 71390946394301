import {observer} from "mobx-react-lite";
import {TokenViewInterface, TokenAudit, TokenHoneypot, TokenViewNew} from "@stores/tokens-store";
import TokenAnalysisChecker, {TokenAnalysisCheckerProps} from "./TokenAnalysisChecker";
import {useStores} from "@hooks/useStores";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import Preloader from "@components/common/Preloader";
import BigNumber from "bignumber.js";
import InfoTooltip from "@components/common/InfoTooltip";
import ProgressSlider from "@components/common/ProgressSlider";
import {faChevronUp} from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {chains} from "../../../../constants";
import getChainId from "@helpers/getChainId";

const formatAuditNumber = (value: number) => {
  if (value < 0.01) {
    return '0';
  }
  return new BigNumber(value).toFixed(2);
};

const SecurityAudit = observer(({token, auditInfo, isLoading, triggerResize}: {token: TokenViewNew, auditInfo: TokenAudit | null, isLoading: boolean, triggerResize: () => void}) => {
  const {tokensStore, globalStore} = useStores();
  // const {token: address, pair: pairAddress} = token.id;
  const address = token.address;
  // const [honeypotInfo, setHoneypotInfo] = useState<TokenHoneypot | null>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    triggerResize();
  }, [showMore]);

  // const fetchTokenHoneypot = useCallback(() => {
  //   if (!address) return;
  //   setIsLoading(true);
  //
  //   tokensStore.getTokenHoneypot(address).then((token) => {
  //     if (token) {
  //       setHoneypotInfo(token);
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //       toast.error('Error loading token honeypot info');
  //     }
  //   }).catch((e) => {
  //     console.error(e);
  //     setIsLoading(false);
  //     toast.error('Error loading token honeypot info');
  //   });
  // }, [tokensStore, address]);

  const auditData: TokenAnalysisCheckerProps[] = useMemo(() => {
    let list: TokenAnalysisCheckerProps[] = [];

    if (!auditInfo) return list

    list.push({
      title: 'Verified Source Code',
      condition: auditInfo.sourceCodeVerified,
      staticText: 'Unverified source code may hide various unknown mechanisms and are extremely risky.'
    })

    list.push({
      title: 'Honeypot',
      condition: !auditInfo.honeypot,
      conditionSuccess: 'No',
      conditionFail: 'Yes',
      staticText: 'Honeypot means that the token maybe cannot be sold because of the token contract\'s function, or the token contains malicious code.'
    })

    list.push({
      title: 'Renounce Ownership',
      condition: !auditInfo.hasOwner || auditInfo.flags.ownershipRenounced,
      staticText: 'Ownership is usually used to adjust the parameters and status of the contract, such as tax modification, minting, suspension of trading, setting blacklist, etc.'
    })

    list.push({
      title: 'Buy tax',
      available: auditInfo.simulationSuccess,
      condition: auditInfo.buyTax <= 5,
      value: formatAuditNumber(auditInfo.buyTax),
      unit: '%',
      staticText: 'Market buy tax above 5% may be considered a high tax rate. More than 50% tax rate means may not be tradable.'
    })

    list.push({
      title: 'Sell tax',
      available: auditInfo.simulationSuccess,
      condition: auditInfo.sellTax <= 5,
      value: formatAuditNumber(auditInfo.sellTax),
      unit: '%',
      staticText: 'Market sell tax above 5% may be considered a high tax rate. More than 50% tax rate means may not be tradable.'
    })

    list.push({
      title: 'Transfer tax',
      available: auditInfo.simulationSuccess,
      condition: auditInfo.transferTax <= 5,
      value: formatAuditNumber(auditInfo.transferTax),
      unit: '%',
      staticText: 'Token transfer tax above 5% may be considered a high tax rate.'
    })

    list.push({
      title: 'Scam Deployer',
      condition: !auditInfo.deployer.scammer,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'Deployer is related to honeypot tokens or has created scam tokens.'
    })

    const totalSnipers = auditInfo.snipers.firstBlock + auditInfo.snipers.secondBlock + auditInfo.snipers.thirdBlock
    list.push({
      title: 'Snipers',
      condition: !totalSnipers,
      conditionSuccess: `No`,
      conditionFail: `${totalSnipers}`,
      staticText: 'Total snipers who bought tokens in first 3 blocks starting from open trading block'
    })

    if (auditInfo.holders) {
      const failedHoldersPercent = (auditInfo.holders.siphoned + auditInfo.holders.failed) / auditInfo.holders.analyzed * 100
      list.push({
        title: 'Holders analysis',
        available: auditInfo.holders.analyzed > 0,
        condition: failedHoldersPercent < 1,
        value: formatAuditNumber(failedHoldersPercent),
        unit: '%',
        staticText: `The last buyers of the token to check if they can sell or not. Successful ${auditInfo.holders.successful}. Siphoned ${auditInfo.holders.siphoned}. Failed: ${auditInfo.holders.failed}`
      })
    }

    list.push({
      title: 'Initial Liquidity',
      condition: auditInfo.initialLiquidity.tokenRefReserve >= 1, // todo: adapt condition for supporting multiple networks
      value: `${auditInfo.initialLiquidity.tokenRefReserve} ${token.pair.tokenRef.symbol}`,
      staticText: 'Low initial liquidity can lead to a situation where early buyers will sell their tokens for almost all liquidity.'
    })

    list.push({
      title: 'LP Lock',
      condition: auditInfo.lpLock.totalPercent >= 90,
      conditionSuccess: `${formatAuditNumber(auditInfo.lpLock.totalPercent)}%`,
      conditionFail: auditInfo.lpLock.totalPercent === 0 ? "No" : `${formatAuditNumber(auditInfo.lpLock.totalPercent)}%`,
      staticText: 'LP tokens are locked to prevent "rugpulls" and ensure stable liquidity for traders'
    })

    list.push({
      title: 'Reclaim Ownership',
      available: auditInfo.flags.reclaimOwnership,
      condition: auditInfo.flags.reclaimOwnership,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'These risky functions may be able to be reactivated if ownership is renounced.'
    })

    list.push({
      title: 'Mintable',
      available: auditInfo.flags.mintable !== undefined,
      condition: !auditInfo.flags.mintable,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'Mintable tokens can be created by the owner at any time. This can be used to manipulate the token price.'
    })

    list.push({
      title: 'Proxy',
      available: auditInfo.flags.proxy !== undefined,
      condition: !auditInfo.flags.proxy,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'Most proxy contracts are accompanied by implementation contracts which are modifiable, potentially containing significant risk.'
    })

    list.push({
      title: 'Hidden Owner',
      available: auditInfo.flags.hiddenOwner !== undefined,
      condition: !auditInfo.flags.hiddenOwner,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'Hidden ownership is used by developers to maintain ownership ability even after abandoning ownership, and is often an indicator of malicious intent. When a hidden owner exists, it is safe to assume that ownership has not been abandoned.'
    })

    list.push({
      title: 'Self-Destruct',
      available: auditInfo.flags.selfdestruct !== undefined,
      condition: !auditInfo.flags.selfdestruct,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'When the self-destruct function is triggered, the contract will be destroyed, all of its functions will be unavailable, and all related assets will be erased.'
    })

    list.push({
      title: 'External Calls',
      available: auditInfo.flags.externalCall !== undefined,
      condition: !auditInfo.flags.externalCall,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'External calls causes the implementation of this contract to be dependent on other external contracts which may or may not be risky.'
    })

    list.push({
      title: 'Blacklist',
      available: auditInfo.flags.blacklist !== undefined,
      condition: !auditInfo.flags.blacklist,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'If there is a blacklist, some addresses may not be able to trade normally.'
    })

    list.push({
      title: 'Whitelist',
      available: auditInfo.flags.whitelist !== undefined,
      condition: !auditInfo.flags.whitelist,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'If there is a whitelist, some addresses may not be able to trade normally.'
    })

    list.push({
      title: 'Anti Whale',
      available: auditInfo.flags.antiWhale !== undefined,
      condition: !auditInfo.flags.antiWhale,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'It describes whether the contract has the function to limit the maximum amount of transactions or the maximum token position for a single address.'
    })

    list.push({
      title: 'Modifiable Anti Whale',
      available: auditInfo.flags.modifiableAntiWhale !== undefined,
      condition: !auditInfo.flags.modifiableAntiWhale,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'It describes whether the contract has the function to modify the maximum amount of transactions or the maximum token position.'
    })

    list.push({
      title: 'Trading Cooldown',
      available: auditInfo.flags.tradingCooldown !== undefined,
      condition: !auditInfo.flags.tradingCooldown,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'It describes whether the contract has a trading-cool-down mechanism that can limit the minimum time between two transactions.'
    })

    list.push({
      title: 'Personal Taxes',
      available: auditInfo.flags.personalTaxes !== undefined,
      condition: !auditInfo.flags.personalTaxes,
      conditionSuccess: `No`,
      conditionFail: `Yes`,
      staticText: 'It describes whether the owner can set a different tax rate for every assigned address.'
    })

    return list.filter((item) => !!item.available || item.available === undefined);
  }, [auditInfo, globalStore.ethPrice, token.pair.metrics.liquidity]);

  return (
    <div className="card wd-100p pb-5 overflow-hidden">
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Security Audit</span>
        <InfoTooltip text="This displays the result of the automatic audit for the main methods of fraud. *Audit is not 100% accurate" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>

      {/*{token.dextScore && <ProgressSlider*/}
      {/*  value={token.dextScore.total}*/}
      {/*  className="d-flex flex-column justify-content-center align-items-center"*/}
      {/*  gradeClassName="tx-22 mb-2"*/}
      {/*/>}*/}

      {(!auditInfo || isLoading) && (<Preloader inline />)}

      {auditInfo && auditData.slice(0, (showMore ? -1 : 8)).map((props) => (
        <TokenAnalysisChecker
          {...props}
          key={`token-analytics-${props.title}`}
        />
      ))}

      {auditData.length > 8 && (
        <button
          className="card-button"
          onClick={() => setShowMore((prev) => (!prev))}
        >
          {showMore ? 'Show less' : 'Show more'}
          <FontAwesomeIcon icon={showMore ? faChevronUp : faChevronDown} className="ms-2" />
        </button>
      )}
    </div>
  );
});

export default SecurityAudit;

import {useStores} from "@hooks/useStores";
import {useCallback} from "react";
import {Overlays} from "@stores/overlay-store";

export default function useCloseOverlay() {
  const {overlayStore} = useStores();

  return useCallback(() => {
    overlayStore.setActiveOverlay(Overlays.NONE);
  }, [overlayStore]);
};

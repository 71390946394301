import React from 'react'
import {GlobalStore} from "@stores/global-store";
import {OverlayStore} from "@stores/overlay-store";
import {TokensStore} from "@stores/tokens-store";
import {AccountStore} from "@stores/account-store";

export const storesContext = React.createContext({
  globalStore: new GlobalStore(),
  overlayStore: new OverlayStore(),
  tokensStore: new TokensStore(),
  accountStore: new AccountStore(),
});

import React, {useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {TokenAudit, TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import BigNumber from "bignumber.js";
import {format} from "date-fns";

interface LiquidityTooltipProps {
  token: TokenViewNew;
  auditInfo: TokenAudit | null;
  children: React.ReactElement;
}

interface Lock {
  burnedPercent: number;
  lockedPercent: number;
  totalPercent: number;
  until: string;
}

const LiquidityTooltip = ({ token, auditInfo, children }: LiquidityTooltipProps) => {
  const lock = useMemo(() => {
    return {
      burnedPercent: auditInfo?.lpLock.burnedPercent || 0,
      lockedPercent: auditInfo?.lpLock.lockedPercent || 0,
      totalPercent:  auditInfo?.lpLock.totalPercent || 0,
      until: format(new Date((auditInfo?.lpLock.unlockTimestamp || 0) * 1000), 'yyyy-MM-dd'),
    };
  }, [auditInfo]);

  const tooltip = (
    <Tooltip>
      {!!lock.lockedPercent && (
          <div>
            <span className="tx-primary me-2">{lock.lockedPercent.toFixed(2)}% until:</span>
            <span>{lock.until}</span>
          </div>
      )}
      {!!lock.burnedPercent && (
          <div>
            <span className="tx-primary me-2">{lock.burnedPercent.toFixed(2)}% burned</span>
          </div>
      )}
      {!lock.totalPercent && (
          <div className="tx-primary">No liquidity locks found</div>
      )}
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip}>
      <div>
        {children}
      </div>
    </OverlayTrigger>
  )
};

export default LiquidityTooltip;

import {observer} from "mobx-react-lite";
import React from "react";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import {FavoritesTokenInterface} from "@stores/tokens-store";
import HighlightsListItem from "../../TokenList/components/HighlightsListItem";
import NoFavorites from "../../TokenList/components/NoFavorites";

interface GainersLosersProps {
  favorites: FavoritesTokenInterface[];
  title: string;
  isGainers?: boolean;
}

const GainersLosersCard = observer(({favorites, title, isGainers = false}: GainersLosersProps) => {
  const {tokensStore} = useStores();
  const {isFavoritesLoading} = tokensStore;

  return (
    <div className="card px-3 py-3 flex-1">
      <div className="d-flex align-items-center justify-content-between px-2">
        <div className="tx-16 tx-md-22">{title}</div>
        <div className="tx-14 tx-muted ms-2 me-auto">24H</div>
      </div>

      {isFavoritesLoading && (
        <div className="d-flex align-items-center justify-content-center ht-100p wd-100p py-3">
          <Preloader inline />
        </div>
      )}

      {!isFavoritesLoading && favorites.length > 0 && (
        <div>
          {favorites?.slice(0, 4).map((token) => (
            <HighlightsListItem {...token} key={`gainers-losers-${token.id.token}`} />
          ))}
        </div>
      )}
      {!isFavoritesLoading && !favorites.length && (
        <div className="d-flex justify-content-center align-items-center flex-column ht-100p wd-100p tx-center tx-20">
          {isGainers && (
            <>Time to take some gainers 💪</>
          )}
          {!isGainers && (
            <>Who needs losers? 😎</>
          )}
        </div>
      )}
    </div>
  );
});

export default GainersLosersCard;

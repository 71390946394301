import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import useInterval from "@hooks/useInterval";
import CopyButton from "@components/common/CopyButton";
import QRCode from "qrcode.react";
import {
  SubscriptionInvoice,
  SubscriptionPeriod,
  SubscriptionStatus,
  SubscriptionStatusLabel
} from "@stores/account-store";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import Placeholder from "@components/common/Placeholder";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../../constants";

interface SubscribeMonthlyProps {
  threeMonths?: boolean;
}

const SubscribePayment = observer(({threeMonths = false}: SubscribeMonthlyProps) => {
  const {accountStore} = useStores();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState<SubscriptionInvoice>();
  const handleResponse = useResponseHandler();
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const storedInvoiceId = localStorage.getItem('invoiceId');
  const storedThreeMonths = localStorage.getItem('threeMonths') === 'true';

  const minutes = useMemo(() => {
    const value = Math.floor(timeLeft / 60);
    return value < 10 ? `0${value}` : value;
  }, [timeLeft]);

  const seconds = useMemo(() => {
    const value = Math.floor(timeLeft - Math.floor(timeLeft / 60) * 60);
    return value < 10 ? `0${value}` : value;
  }, [timeLeft]);

  const statusColor = useMemo(() => {
    if (!invoice) {
      return 'primary';
    }

    switch (invoice.status) {
      case SubscriptionStatus.WAITING_FOR_PAYMENT:
        return 'primary';
      case SubscriptionStatus.PAID:
        return 'success';
      case SubscriptionStatus.CANCELED:
        return 'danger';
    }
  }, [invoice]);

  const fullInvoiceId = useMemo(() => {
    if (storedInvoiceId && storedThreeMonths === threeMonths) return storedInvoiceId;
    if (invoice) return invoice.id;
    return '';
  }, [storedInvoiceId, storedThreeMonths, threeMonths, invoice]);

  const invoiceId = useMemo(() => {
    if (!fullInvoiceId) return '';

    const arr = fullInvoiceId.split('-');
    return arr[arr.length - 1];
  }, [fullInvoiceId]);

  useInterval(() => {
    setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
  }, timeLeft > 0 ? 1000 : null);

  useInterval(() => {
    if (fullInvoiceId) {
      accountStore.getSubscriptionInvoice(fullInvoiceId)
        .then((response) => {
          if (response) {
            setInvoice(response);
          } else {
            handleResponse(response);
          }
        })
        .catch((response) => {
          handleResponse(response.response.data);
        });
    }
  }, (!invoice && storedInvoiceId && storedThreeMonths === threeMonths) || (invoice && invoice.status === SubscriptionStatus.WAITING_FOR_PAYMENT) ? 5000 : null);

  useEffect(() => {
    if (!invoice) {
      if (!storedInvoiceId || storedThreeMonths !== threeMonths) {
        accountStore.buySubscription(threeMonths ? SubscriptionPeriod.THREE_MONTHS : SubscriptionPeriod.MONTH)
          .then((response) => {
            if (response.success && response.data) {
              setInvoice(response.data);
              const expires = new Date(`${response.data.depositExpiration}Z`);
              setTimeLeft(Math.floor(expires.getTime() - new Date().getTime()) / 1000);
            } else {
              handleResponse(response);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data);
          });
      } else {
        accountStore.getSubscriptionInvoice(storedInvoiceId)
          .then((response) => {
            if (response) {
              setInvoice(response);
              const expires = new Date(`${response.depositExpiration}Z`);
              setTimeLeft(Math.floor(expires.getTime() - new Date().getTime()) / 1000);
            } else {
              handleResponse(response);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data);
          });
      }
    }
  }, [storedInvoiceId, storedThreeMonths, invoice, accountStore, threeMonths, handleResponse]);

  useEffect(() => {
    if (invoice && invoice.status === SubscriptionStatus.PAID) {
      accountStore.loadSubscription()
        .then(() => {
          setTimeout(() => {
            navigate(PageRoutes.SUBSCRIBE);
          }, 3000);
        });
    }
  }, [invoice, accountStore, navigate]);

  useEffect(() => {
    if (invoice) {
      localStorage.setItem('invoiceId', invoice.id);
      localStorage.setItem('threeMonths', threeMonths ? 'true' : 'false');
    }
  }, [invoice, threeMonths]);

  return (
    <>
      <div className="tx-center mt-4">
        <div className="tx-16 tx-muted">
          Amount pay
        </div>
        <div className="tx-28">
          {threeMonths ? '700' : '350'} USDT
        </div>
        <div className="tx-18 tx-primary text-crossed">
          {(!invoice || invoice.status === SubscriptionStatus.WAITING_FOR_PAYMENT) && threeMonths ? '1,050 USDT' : ''}
        </div>
      </div>

      {(!invoice || invoice.status === SubscriptionStatus.WAITING_FOR_PAYMENT) && (
        <>
          <div className="d-flex mt-4">
            <div className="bg-semi-transparent rounded-10 d-flex justify-content-center align-items-center mx-auto px-3 py-2">
              <span className="me-2">Status:</span>
              {!invoice && <Placeholder width={130} />}
              {invoice && (
                <>
                  <span className={`me-2 tx-${statusColor}`}>{SubscriptionStatusLabel[invoice.status]}</span>
                  <div className={`subscription-payment-status ${statusColor}`} />
                </>
              )}
            </div>
          </div>

          <div className="mt-2 tx-center">
            <span className="tx-muted me-1">Expiration time:</span>
            {!invoice && <Placeholder width={60} />}
            {invoice && invoice.status === SubscriptionStatus.WAITING_FOR_PAYMENT && (<span>{minutes}:{seconds}</span>)}
          </div>
        </>
      )}

      <div className="tx-center mt-4 mb-4">
        <div className="wd-180 ht-180 rounded-10 mx-auto overflow-hidden">
          {!invoice && (
            <Placeholder width={150} height={150} />
          )}
          {invoice && (
            <QRCode value={invoice.depositAddress} size={180} bgColor="#080C16" fgColor="#516376" includeMargin/>
          )}
        </div>
      </div>

      {!invoice && (
        <div className="bg-semi-transparent ht-60 wd-100p d-flex align-items-center px-3">
          <Placeholder width={300} />
        </div>
      )}

      {invoice && invoice.status === SubscriptionStatus.WAITING_FOR_PAYMENT && (
        <CopyButton
          text={invoice.depositAddress}
          className="btn btn-dark tx-14 tx-olney bg-semi-transparent rounded-10 d-flex justify-content-between align-items-center wd-100p px-3 py-3 tx-white"
          iconClass="ms-3 tx-18 tx-white"
        />
      )}

      <div className="bg-semi-transparent rounded-10 tx-14 mt-4 p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="tx-muted">Invoice ID:</span>
          <span className="tx-white">
            {!invoice && <Placeholder width={200} />}
            {invoiceId}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="tx-muted">Payment network:</span>
          <span className="tx-primary">USDT TRC-20</span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="tx-muted">Subscription period:</span>
          <span className="tx-primary">{threeMonths ? '3 Months' : '1 Month' }</span>
        </div>
      </div>
    </>
  );
});

export default SubscribePayment;

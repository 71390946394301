import React from "react";
import {Connector, useConnect} from "wagmi";
import Preloader from "@components/common/Preloader";
import coinbase from './WalletIcons/coinbase.svg';
import metamask from './WalletIcons/metamask.svg';
import walletConnect from './WalletIcons/wallet-connect.svg';
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import {ConnectionStep} from "@stores/account-store";

const ConnectWalledModal = observer(() => {
  const { accountStore } = useStores();
  const { connectors, error, isLoading, pendingConnector, connectAsync } = useConnect();

  const handleConnect = (connector: Connector) => {
    try {
      connectAsync({ connector }).then(() => {
        accountStore.setConnectionStep(null)
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="wd-100p tx-center tx-15 py-2 mb-3">
        <div className="tx-28">Wallet connect</div>
        <div>Welcome to uAnalytics</div>
        <div>Get started with Web3 Wallet</div>
      </div>

      {connectors.map((connector) => {
        let icon;

        if (connector.name === 'MetaMask') {
          icon = metamask;
        } else if (connector.name === 'WalletConnect') {
          icon = walletConnect;
        } else if (connector.name === 'Coinbase Wallet') {
          icon = coinbase;
        }

        return (
          <button
            className="btn btn-secondary wd-100p mb-3 py-4"
            disabled={!connector.ready}
            key={connector.id}
            onClick={() => handleConnect(connector)}
          >
            {icon && (<img src={icon} alt={connector.name} className="wd-40 mb-2"/>)}
            <div className="tx-18">
              {connector.name}
              {!connector.ready && ' (unsupported)'}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                ' (connecting)'}
            </div>
          </button>
        )
      })}

      <button
        className="btn btn-outline-light border-secondary wd-100p"
        onClick={() => accountStore.setConnectionStep(ConnectionStep.LOGIN)}
      >
        Cancel
      </button>

      {error && <div className="tx-danger mt-3 tx-center">{error.message}</div>}

      <div className="tx-muted tx-center tx-11 wd-100p mt-3">
        <div>Connecting your wallet is like “logging in” to Web3.</div>
        <div>Your assets are always under your control!</div>
      </div>

      {isLoading && <Preloader/>}
    </div>
  );
});

export default ConnectWalledModal;

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {HolderTrend, TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import InfoTooltip from "@components/common/InfoTooltip";
import CircleIcon from "@components/common/Charts/CircleIcon";
import FormattedNumber from "@components/common/FormattedNumber";
import TabsCard from "@components/common/TabsCard";
import LineChart from "@components/common/Charts/LineChart";
import {useStores} from "@hooks/useStores";
import {differenceInCalendarDays, format} from "date-fns";
import Preloader from "@components/common/Preloader";
import {getPercentageDifference} from "@helpers/numbers";
import getChainId from "@helpers/getChainId";

const HoldersTrend = observer(({token}: {token: TokenViewNew}) => {
  const { tokensStore } = useStores();
  const [data, setData] = useState<HolderTrend[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [interval, setInterval] = useState(7);
  const [isInitialized, setIsInitialized] = useState(false);

  const filterData = useCallback((v: number) => {
    return data.filter((item) => {
      const diff = new Date().getTime() - new Date(item.ts).getTime();
      return diff <= v * 24 * 60 * 60 * 1000;
    });
  }, [data]);

  const filteredData = useMemo(() => {
    return filterData(interval);
  }, [filterData, interval]);

  const allHolders = useMemo(() => {
    return filteredData.map((item) => ({x: format(new Date(item.ts), 'yyyy-MM-dd'), y: item.allHolders}));
  }, [filteredData]);

  const largeHolders = useMemo(() => {
    return filteredData.map((item) => ({x: format(new Date(item.ts), 'yyyy-MM-dd'), y: item.largeHolders}));
  }, [filteredData]);

  const allChange = getPercentageDifference(allHolders[allHolders.length - 1]?.y, allHolders[0]?.y);
  const largeChange = getPercentageDifference(largeHolders[largeHolders.length - 1]?.y, largeHolders[0]?.y);

  const availableIntervals = useMemo(() => {
    const diff = differenceInCalendarDays(new Date(), new Date(data[0]?.ts));
    const intervals = [{label: '7D', value: 7}, {label: '30D', value: 30}, {label: '6M', value: 180}];

    return intervals.filter((item) => item.value <= diff);
  }, [data]);

  useEffect(() => {
    if (data.length && !isInitialized) {
      if (!filterData(7).length) {
        if (!filterData(30).length) {
          setInterval(180);
        } else {
          setInterval(30);
        }
      }
      setIsInitialized(true);
    }
  }, [data, isInitialized, filterData]);

  useEffect(() => {
    setIsLoaded(false);
    tokensStore.getTokenHoldersTrend(token.address, getChainId(token.pair.chain))
      .then((data) => {
        setIsLoaded(true);
        setData(data.history.sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime()));
      })
      .catch((e) => {
        setIsLoaded(true);
        console.error(e);
      });
  }, [tokensStore, token]);

  return (
    <div className="card wd-100p ht-100p">
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Holders Trend</span>
        <InfoTooltip text="Holders Trend" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>

      {!!data.length && isLoaded && (
        <div className="mt-3 mb-3 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
          <div className="d-flex justify-content-between align-items-center me-auto ms-0 mb-3 mb-md-0">
            <div>
              <div className="d-flex align-items-center">
                <CircleIcon className="tx-primary" fill />
                <FormattedNumber
                  value={allHolders.length ? allHolders[allHolders.length - 1].y : 0}
                  className="tx-22 d-block ms-2"
                />
                {!!allChange && (
                  <FormattedNumber
                    value={allChange}
                    postfix="%"
                    withSign
                    className={`tx-14 d-block ms-2 align-self-end mb-1 ${allChange > 0 ? 'tx-success' : 'tx-danger'}`}
                  />
                )}
              </div>
              <div className="tx-muted">All Holders</div>
            </div>

            <div className="ms-3 me-auto">
              <div className="d-flex align-items-center">
                <CircleIcon className="tx-light-red" fill />
                <FormattedNumber
                  value={largeHolders.length ? largeHolders[largeHolders.length - 1].y : 0}
                  className="tx-22 d-block ms-2"
                />
                {!!largeChange && (
                  <FormattedNumber
                    value={largeChange}
                    postfix="%"
                    withSign
                    className={`tx-14 d-block ms-2 align-self-end mb-1 ${largeChange > 0 ? 'tx-success' : 'tx-danger'}`}
                  />
                )}
              </div>
              <div className="tx-muted">Large Holders</div>
            </div>
          </div>

          <TabsCard
            list={availableIntervals}
            active={interval}
            onClick={setInterval}
            className="bg-semi-transparent"
            tabClassName="wd-100p wd-md-70"
          />
        </div>
      )}

      <div className="mt-3">
        {!isLoaded && <Preloader inline />}
        {isLoaded && !!data.length && (
          <LineChart datasets={[{label: 'All Holders', data: allHolders}, {label: 'Large Holders', data: largeHolders}]} />
        )}
        {isLoaded && !data.length && (
          <div>Holders Trend is in the process of updating. Kindly check back in a short while ⏳</div>
        )}
      </div>
    </div>
  );
});

export default HoldersTrend;

export interface TabCardItem {
  value: string | number;
  label: string;
}

interface TabsCardProps {
  list: TabCardItem[];
  active?: string | number;
  onClick?: (value: any) => void;
  className?: string;
  tabClassName?: string;
}

export default function TabsCard({list, active, onClick, className = '', tabClassName = 'wd-70'}: TabsCardProps) {
  return (
    <div className={`card flex-row p-1 justify-content-between ${className}`}>
      {list.map(({value, label}, index) => (
        <div
          className={`tx-14 rounded-2 py-1 tx-center cur-pointer ${value !== active ? 'tx-muted' : 'bg-semi-transparent'} ${tabClassName}`}
          onClick={() => onClick && onClick(value)}
          key={`tab-card-${value}-${label}-${index}`}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

import {TokenViewInterface, TokenViewNew, TradeInterface, TradeType} from "@stores/tokens-store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import FormattedNumber from "@components/common/FormattedNumber";
import {formatDistance} from "date-fns";
import botIcon from "@assets/icons/bot-trade.svg";
import zerion from "@assets/icons/zerion.svg";
import etherscan from "@assets/images/grayscale/etherscan.svg";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import toast from "react-hot-toast";
import {faCircleInfo} from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import BigNumber from "bignumber.js";
import {getPercentage} from "@helpers/numbers";
import {getEllipsisTxt} from "@helpers/formatters";
import ProgressSlider from "@components/common/ProgressSlider";
import CopyButton from "@components/common/CopyButton";
import {faTimesCircle} from "@fortawesome/pro-regular-svg-icons/faTimesCircle";

const TokenTrades = ({token, triggerResize}: {token: TokenViewNew, triggerResize: number}) => {
  const {tokensStore} = useStores();
  const [trades, setTrades] = useState<TradeInterface[]>([]);
  const [newTrades, setNewTrades] = useState<TradeInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastTimestamp, setLastTimestamp] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [holderFilter, setHolderFilter] = useState<string>('');

  const fetchTrades = useCallback(() => {
    if (!token) return;
    setIsLoading(true);

    tokensStore.fetchTokenTrades(token.address, token.pair.address, token.pair.chain).then((result) => {
      if (result) {
        setTrades(result.trades);
        if (!lastTimestamp && result.trades.length) {
          setLastTimestamp((new Date(result.trades[0].timestamp)).getTime());
        }
      }
      setIsLoading(false);
    }).catch((e) => {
      console.error(e);
      setIsLoading(false);
    });
  }, [tokensStore, lastTimestamp, token]);

  const filteredTrades = useMemo(() => {
    if (!holderFilter) return trades;
    return trades.filter((trade) => trade.maker === holderFilter);
  }, [trades, holderFilter]);

  const newTradesAlert = useCallback(() => {
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } wd-100p wd-md-400 card bg-dark p-0 overflow-hidden`}
      >
        <div className="bg-semi-success-2 d-flex flex-row justify-content-between align-items-center p-3">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <FontAwesomeIcon icon={faCircleInfo} className="tx-24 me-2"/>
            <span>New trades available!</span>
          </div>
          <div className="d-flex flex-column justify-content-between ms-3">
            <button
              onClick={() => {toast.dismiss(t.id);}}
              className="btn px-2 py-1 btn-success"
            >
              Show
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="btn px-2 py-1 btn-dark mt-3"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    ))
  }, []);

  // const list = useMemo(() => {
  //   return trades.slice(page * 20, page * 20 + 20);
  // }, [page, trades]);

  const buySellPercent = useMemo(() => {
    if (trades) {
      let total = 0;
      let buy = 0;
      let sell = 0;

      filteredTrades.forEach((trade) => {
        const value = new BigNumber(trade.tokenAmount).multipliedBy(trade.tokenPriceUsd).toNumber();
        total += value;
        if (trade.side === TradeType.BUY) {
          buy += value;
        } else {
          sell += value;
        }
      });
      return {buy, sell, buyPercent: getPercentage(buy, total), sellPercent: getPercentage(sell, total)};
    }
    return null;
  }, [filteredTrades]);

  useEffect(() => {
    fetchTrades();
  }, [token, fetchTrades]);

  // const isNewTrade = useCallback((id: string, timestamp: number) => {
  //   return newTrades.find((trade) => trade.id === id && trade.timestamp === timestamp);
  // }, [newTrades]);
  //
  // useInterval(() => {
  //   if (!lastTimestamp) return;
  //
  //   tokensStore.fetchLatestTokenTrades(token.pairAddress, lastTimestamp + 1).then((result) => {
  //     if (result && result.length) {
  //       setNewTrades((prevState) => [...result, ...prevState]);
  //       setLastTimestamp(result[0].timestamp);
  //       if (page !== 0) {
  //         newTradesAlert();
  //       }
  //     }
  //   }).catch((e) => {
  //     console.error(e);
  //   });
  // }, 15000);

  const resizeHandler = () => {
    if (window.innerWidth > 992) {
      const table = document.getElementById('trade-history-table');
      const header = document.getElementById('trade-header');
      const generic = document.querySelectorAll('#token-generic-info .card');
      if (table && generic && header) {
        let genericHeight = 0;
        generic.forEach((item) => {
          genericHeight += item.getBoundingClientRect().height;
        });
        const headerHeight = header.getBoundingClientRect().height;
        const height = genericHeight - headerHeight + 8 + 20;
        setHeight(height);
      }
    } else {
      setHeight(500);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    // Cleanup function
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  useEffect(() => {
    resizeHandler();
  }, [isLoading, triggerResize]);

  return (
    <div className="card wd-100p pb-0 overflow-hidden" id="token-trades-card">
      <div id="trade-header" className="pb-3">
        <div className="tx-22 d-flex align-items-center">
          <span>Trade History</span>
          <div className="subscription-payment-status primary ms-2" />
        </div>

        {!!holderFilter && (
          <div>
            <span className="tx-muted me-1">Showing trade history for</span>
            <span className="tx-bold tx-white">{holderFilter}</span>
            <button
              className="btn btn-secondary ms-2 bg-semi-danger-3 p-0 wd-20 ht-20 d-inline-flex justify-content-center align-items-center tx-danger"
              onClick={() => setHolderFilter('')}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        )}

        {isLoading && (<Preloader inline />)}

        {buySellPercent && (
          <div>
            <ProgressSlider value={[buySellPercent.buyPercent, buySellPercent.sellPercent]} className="mt-3" />
            <div className="d-flex justify-content-between align-items-center mt-1 tx-12">
              <div>
                <FormattedNumber
                  value={buySellPercent.buy}
                  suffix="$"
                  useFormat
                  className="d-block"
                />
                <div className="tx-muted">Buy</div>
              </div>
              <div className="tx-right">
                <FormattedNumber
                  value={buySellPercent.sell}
                  suffix="$"
                  useFormat
                  className="d-block"
                />
                <div className="tx-muted">Sell</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive" id="trade-history-table" style={{height}}>
        <table className="table">
          <thead>
            <tr className="border-muted-10 border-top">
              <th>Type</th>
              <th>Tokens</th>
              <th>Price</th>
              <th className="mn-wd-150">Time</th>
              <th className="mn-wd-180">Maker</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {!isLoading && filteredTrades.map((trade, index) => (
            <tr
              key={`token-${token.pair.address}-trade-item-${trade.side}-${trade.timestamp}-${index}`}
              className={`${trade.side === TradeType.BUY ? 'tx-success' : 'tx-danger'} valign-middle border-muted-10`}
            >
              <td>
                <div className="d-flex align-items-center">
                  <div className={`bg-semi-${trade.side === TradeType.BUY ? 'success' : 'danger'}-2 rounded-6px d-flex justify-content-center align-items-center tx-12 wd-20 ht-20 me-2`}>
                    {trade.side === TradeType.BUY ? 'B' : 'S'}
                  </div>
                  {trade.side === TradeType.BUY ? 'Buy' : 'Sell'}
                </div>
              </td>
              <td>
                <FormattedNumber
                  value={new BigNumber(trade.tokenAmount).multipliedBy(trade.tokenPriceUsd).toNumber().toFixed(0)}
                  suffix="$"
                />
                <FormattedNumber
                  value={trade.tokenAmount}
                  postfix={token.symbol}
                  className="d-block tx-12 tx-muted lh-1"
                />
              </td>
              <td>
                <FormattedNumber
                  value={trade.tokenPriceUsd}
                  decimals={trade.tokenPriceUsd.toString().split('.')[1]?.length || 0}
                  suffix="$"
                  subZeros
                />
                <FormattedNumber
                  value={trade.tokenRefAmount / trade.tokenAmount}
                  decimals={(trade.tokenRefAmount / trade.tokenAmount).toString().split('.')[1]?.length || 0}
                  postfix={token.pair.tokenRef.symbol}
                  className="d-block tx-12 lh-1 tx-muted"
                  subZeros
                />
              </td>
              <td>
                {formatDistance(new Date(trade.timestamp), new Date())} ago
              </td>
              <td>
                {trade.maker && (
                  <>
                    <a href={`https://app.zerion.io/${trade.maker}/overview`} className="tx-primary text-decoration-none" target="_blank" rel="noreferrer">
                      <img src={zerion} alt="Zerion" className="wd-20 ht-20" />
                    </a>
                    <button className="btn btn-link tx-primary text-decoration-none px-0 mx-2" onClick={() => setHolderFilter(trade.maker)}>
                      {getEllipsisTxt(trade.maker, 5)}
                    </button>
                    <CopyButton
                      text={trade.maker}
                      element={null}
                      className="btn btn-secondary bg-semi-transparent tx-primary text-decoration-none p-0 ht-30 wd-30 d-inline-flex justify-content-center align-items-center rounded-10"
                      iconClass="tx-white tx-14 wd-30 ht-30 d-flex justify-content-center align-items-center"
                    />
                  </>
                )}
              </td>
              <td>
                <div className="d-flex flex-row justify-content-between align-items-center ms-3">
                  {/*<div className="wd-20 ht-20 me-2">*/}
                    {/*{trade.others?.bot && (*/}
                    {/*  <img src={botIcon} alt="Bot trade" className="wd-20 ht-20"/>*/}
                    {/*)}*/}
                  {/*</div>*/}
                  {token.pair.chain === 'ether' && (
                    <a href={`https://etherscan.io/tx/${trade.transactionHash}`} target="_blank" rel="noreferrer" className="d-flex align-items-center justify-content-center wd-20 ht-20">
                      <img src={etherscan} alt="Etherscan" className="wd-20 ht-20"/>
                    </a>
                  )}
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      {/*{!isLoading && trades.length > 10 && (*/}
      {/*  <ReactPaginate*/}
      {/*    forcePage={page}*/}
      {/*    previousLabel={null}*/}
      {/*    nextLabel={null}*/}
      {/*    breakLabel={<FontAwesomeIcon icon={faChevronRight}/>}*/}
      {/*    pageCount={trades.length / 20}*/}
      {/*    marginPagesDisplayed={0}*/}
      {/*    pageRangeDisplayed={4}*/}
      {/*    containerClassName={'pagination'}*/}
      {/*    activeClassName={'active'}*/}
      {/*    onPageChange={(data) => handlePageChange(data.selected)}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
}

export default TokenTrades;

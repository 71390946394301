import {Line} from "react-chartjs-2";
import {getRandomInt} from "@helpers/numbers";
import {ChartColors} from "../../../constants";

interface LineChartDataset {
  label: string,
  data: {x: string, y: number}[],
}

interface LineChartProps {
  datasets: LineChartDataset[];
}

export default function LineChart({datasets}: LineChartProps) {
  const minYValue = (dataset: LineChartDataset, k: number = 1.1) => Math.round(dataset.data.map(p => p.y).reduce((acc, y) => Math.min(acc, y), dataset.data[0]?.y || 0) / k)
  const maxYValue = (dataset: LineChartDataset, k: number = 1.1) => Math.round(dataset.data.map(p => p.y).reduce((acc, y) => Math.max(acc, y), 0) * k)
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.4,
      }
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: minYValue(datasets[0]),
        max: maxYValue(datasets[0]),
        stacked: false,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        border: {
          display: false,
        },
        grid: {
          color: '#1D2B3C',
        },
        gridLines: {
          color: '#1D2B3C',
        },
        ticks: {
          color: '#fff',
        },
      },
      y1: {
        min: minYValue(datasets[1]),
        max: maxYValue(datasets[1]),
        stacked: false,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        ticks: {
          color: '#fff',
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: '#526376',
        },
        time: {
          displayFormats: {
            parser: 'MM/DD/YYYY HH:mm',
            tooltipFormat: 'll HH:mm',
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              'day': 'MM/DD/YYYY'
            },
          }
        },
      }
    },
  };

  // const labels = ['2023-01-01', '2023-01-01', '2023-01-02', '2023-01-02', '2023-01-03', '2023-01-03', '2023-01-04', '2023-01-04', '2023-01-05', '2023-01-05', '2023-01-06', '2023-01-06', '2023-01-07', '2023-01-07', '2023-01-08', '2023-01-08'];
  const colors = [ChartColors.PRIMARY, ChartColors.LIGHT_RED];
  const axisIds = ['y', 'y1'];

  const data = {
    // labels,
    datasets: datasets.map((dataset, i) => ({
      ...dataset,
      borderColor: colors[i],
      backgroundColor: colors[i],
      yAxisID: axisIds[i],
    })),
  };

  return (
    <Line data={data} options={options} />
  );
}

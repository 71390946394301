import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import reset from "@assets/images/reset.svg";
import {useStores} from "@hooks/useStores";
import PasswordStrength from "./components/PasswordStrength";
import useResponseHandler from "@hooks/useResponseHandler";
import {useLocation, useNavigate} from "react-router-dom";
import IconWithLoading from "@components/common/IconWithLoading";
import eye from "@assets/icons/eye.svg";
import eyeClosed from "@assets/icons/eye-closed.svg";
import {PageRoutes} from "../../constants";

const ResetPage = observer(() => {
  const {accountStore} = useStores();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const {pathname} = useLocation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const passwordsMatch = password === confirmPassword;
  const lengthPassed = password.length >= 8;
  const upperCasePassed = /[A-Z]/.test(password);
  const lowerCasePassed = /[a-z]/.test(password);
  const numberPassed = /[0-9]/.test(password);
  const incorrectPassword = !lengthPassed || !upperCasePassed || !lowerCasePassed || !numberPassed;

  const handleReset = () => {
    if (!passwordsMatch) {
      return;
    }

    setIsLoading(true);

    accountStore
      .resetConfirm(token, password)
      .then((response) => {
        if (response.success) {
          setResetSuccess(true);
        } else {
          handleResponse(response.data);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        handleResponse(response.response.data);
      });
  };

  useEffect(() => {
    if (pathname.includes('reset/') && pathname.split('/').length === 3) {
      setToken(pathname.split('/')[2]);
    }
  }, [pathname]);


  return (
    <div>
      {!resetSuccess ? (
        <div>
          <div className="tx-28 tx-light tx-center mt-4">
            Update your password
          </div>
          <div className="tx-14 tx-light tx-center mb-4">
            Just create your new password.
          </div>

          <div className="form-group mb-4">
            <div className="d-flex justify-content-between align-items-start">
              <label className="tx-13 tx-light mb-1 me-auto">New password</label>
              <PasswordStrength password={password} />
            </div>
            <div className="form-group">
              <input
                className={`form-control ${password && incorrectPassword ? 'is-invalid' : ''}`}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={'Enter new password'}
                id="password"
                disabled={isLoading}
              />
              <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
                <img src={showPassword ? eye : eyeClosed} alt="Show password" />
              </div>
            </div>
            <div className={`${password && incorrectPassword ? 'tx-danger' : 'tx-muted'} tx-light tx-11 mt-1`}>Minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number</div>
          </div>

          <div className="form-group mb-4">
            <label className="tx-13 tx-light mb-1">Confirm new password</label>
            <div className="form-group">
              <input
                className={`form-control ${!password || !confirmPassword || passwordsMatch ? '' : 'is-invalid'}`}
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={'Repeat new password'}
                id="new-password"
                disabled={isLoading}
              />
              <div className="show-password" onClick={() => setShowPassword((v) => !v)}>
                <img src={showPassword ? eye : eyeClosed} alt="Show password" />
              </div>
            </div>
            <div className={`tx-light tx-11 mt-1 ${!password || !confirmPassword || passwordsMatch ? 'text-muted' : 'tx-danger'}`}>Both passwords must be matched</div>
          </div>

          <button
            className="btn btn-outline-primary tx-primary wd-100p mt-2"
            onClick={handleReset}
            disabled={isLoading}
          >
            <IconWithLoading isLoading={isLoading} className="me-2" />
            Update Password
          </button>
        </div>
      ) : (<div className="d-flex flex-column justify-content-center">
          <img src={reset} alt="Verify" className="mx-auto"/>
          <div className="tx-center tx-light mt-4">
            <div className="tx-28">Password changed</div>
            <div className="my-4">Try logging in with your new password now</div>
          </div>

          <button
            className="btn btn-outline-primary tx-primary wd-100p mt-4"
            onClick={() => navigate(PageRoutes.LOGIN)}
          >
            Return to Log In
          </button>
        </div>
      )}
    </div>
  );
});

export default ResetPage;

import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import IconWithLoading from "@components/common/IconWithLoading";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {ConnectionStep} from "@stores/account-store";

const ResetOverlay = observer(() => {
  const {accountStore} = useStores();
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const handleReset = () => {
    setIsLoading(true);

    accountStore
      .reset(email)
      .then((response) => {
        if (response.success) {
          accountStore.setConnectionStep(ConnectionStep.VERIFY_RESET);
          // navigate(PageRoutes.VERIFY_RESET);
        } else {
          handleResponse(response.data);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        handleResponse(response.response.data);
      });
  };
  return (
    <div>
      <div className="tx-28 tx-light tx-center">
        Forgot password?
      </div>
      <div className="tx-16 tx-muted tx-center mb-4 px-3">
        Enter the email associated with your account. We’ll send you a link to reset your password
      </div>

      <div className="form-group mb-4">
        <input
          className="form-control"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          id="password"
          disabled={isLoading}
        />
      </div>

      <button
        className="btn btn-primary wd-100p mt-2"
        onClick={handleReset}
        disabled={isLoading}
      >
        <IconWithLoading isLoading={isLoading} className="me-2" />
        Send Link
      </button>

      <button
        className="btn btn-outline-light border-secondary wd-100p mt-3"
        onClick={() => accountStore.setConnectionStep(ConnectionStep.LOGIN)}
      >
        Cancel
      </button>
    </div>
  );
});

export default ResetOverlay;

import {useStores} from "./useStores";
import {useCallback} from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

export interface ServerError {
  code: string;
  message: string;
}

export interface ServerResponse<T> {
  success: boolean;
  data?: T | null;
  errors?: Array<ServerError> | null;
}

export interface StatusResponse<T> extends ServerResponse<T> {
  status: number;
}

export default function useResponseHandler() {
  const {accountStore} = useStores();
  const navigate = useNavigate();

  return useCallback((response?: StatusResponse<any> | string, isError?: boolean) => {
    const toastOptions: { duration: number, id?: string } = {duration: 5000};
    let redirect = false;
    let isSessionExpired = false;
    let messages: string[] = [];

    if (response && typeof response !== 'string') {
      if (response.errors?.length) {
        isError = true;
        messages = response.errors.map((error) => error.message);
      }
      if (response.status === 403) {
        isError = true;
        messages.push('Session expired');
        isSessionExpired = true;
        accountStore.logout();
      }
      if (!isError && response.status !== 200) {
        isError = true;
      }
    } else if (typeof response === 'string') {
      isError = isError || response.toLocaleLowerCase().indexOf('error') > -1;
      messages.push(response);
    }

    if (isSessionExpired) {
      toastOptions.id = 'session-expired';
    }

    if (isError && !messages.length) {
      messages.push('An error occurred');
    }

    if (isError) {
      if (!redirect) {
        messages.forEach((message) => {
          toast.error(message, toastOptions);
        });
      } else {
        navigate('/error', {state: {messages}});
      }
    } else {
      messages.forEach((message) => {
        toast.success(message, toastOptions);
      });
    }
  }, [accountStore, navigate]);
}

import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import Toggle from 'react-toggle';
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import {useStores} from "@hooks/useStores";
import GainersLosersCard from "./GainersLosersCard";
import {getPercentageDifference} from "@helpers/numbers";
import {FavoritesTokenInterface} from "@stores/tokens-store";

interface WithDiff extends FavoritesTokenInterface {
  diff: number;
}

const GainersLosers = observer(() => {
  const [show, setShow] = useState(true);
  const {tokensStore} = useStores();
  const {favorites} = tokensStore;

  const sorted: WithDiff[] = favorites.map((t) => {
    return {...t, diff: getPercentageDifference(t.price, t.price24h)}
  }).sort((a, b) => {
    const diffA = getPercentageDifference(a.price, a.price24h);
    const diffB = getPercentageDifference(b.price, b.price24h);
    return diffB - diffA;
  });

  const gainers = sorted.filter((t) => t.diff > 0).slice(0, 5);
  const losers = sorted.filter((t) => t.diff < 0).slice(-5);

  return (
    <div className="mt-0 mb-4">
      <div className="d-flex justify-content-between align-items-center mb-2 mb-md-4">
        <h2 className="tx-28">
          Favorites
        </h2>
        <label className="d-flex align-items-center">
          <span className="d-none d-md-inline tx-muted tx-14 me-2">Highlights</span>
          <Toggle
            defaultChecked={show}
            icons={false}
            className="styled-toggle"
            onChange={(value) => setShow(value.target.checked)}
          />
        </label>
      </div>
      <div className="scroll-padding-sm no-gutter px-0">
        <OverlayScrollbarsComponent
          defer
          options={{
            paddingAbsolute: false,
            showNativeOverlaidScrollbars: false,
            scrollbars: {
              visibility: 'hidden',
            },
          }}
        >
          <div className={`${show ? 'row mx-0 mx-md-3 mn-wd-700' : 'd-none'}`}>
            <div className="col-6 mn-wd-320 d-flex">
              <GainersLosersCard favorites={gainers} title="Gainers" isGainers />
            </div>
            <div className="col-6 mn-wd-320 d-flex">
              <GainersLosersCard favorites={losers} title="Losers" />
            </div>
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
});

export default GainersLosers;

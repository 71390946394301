import React from "react";
import {Link} from "react-router-dom";
import {PageRoutes} from "../constants";
import {ReactComponent as Rocket} from "@assets/icons/navigation/rocket.svg";
// import {ReactComponent as Star} from "@assets/icons/navigation/star.svg";
import {ReactComponent as Search} from "@assets/icons/navigation/search.svg";
import coin from "@assets/images/coin.svg";

const Sidebar = () => {
  return (
    <>
      <div className="fixed-sidebar" id="sidebar">
        <Link to={PageRoutes.COIN_LIST} className="wd-35 ht-35 mb-4">
          <img src={coin} alt="$USHI" />
        </Link>
        <Link
          to={PageRoutes.COIN_LIST}
          className="wd-35 ht-35 tx-muted d-flex justify-content-center align-items-center mb-4 cur-pointer"
        >
          <Rocket />
        </Link>
        <Link
          to={PageRoutes.SEARCH}
          className="wd-35 ht-35 tx-muted d-flex justify-content-center align-items-center mb-4 cur-pointer"
        >
          <Search />
        </Link>
        {/*<Link*/}
        {/*  to={PageRoutes.FAVORITE_LIST}*/}
        {/*  className="wd-35 ht-35 tx-muted d-flex justify-content-center align-items-center mb-4 cur-pointer"*/}
        {/*>*/}
        {/*  <Star />*/}
        {/*</Link>*/}
      </div>
    </>
  );
};

export default Sidebar;

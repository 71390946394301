import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";

interface CircleChartProps {
  value: number;
  color?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fixed';
  fixedSize?: number;
  icon?: IconDefinition;
  drawValue?: boolean;
  valueClassName?: string;
  className?: string;
  ushiScore?: boolean;
}

const CircleChart = ({value, color, size = 'md', icon, drawValue, valueClassName = '', fixedSize, className = '', ushiScore = false}: CircleChartProps) => {
  let chartColor = color || 'tx-primary';
  const [isMounted, setIsMounted] = useState<boolean>(false);

  if (!color) {
    if (value < 26) {
      chartColor = 'tx-dark-red';
    } else if (value < 51) {
      chartColor = 'tx-warning';
    } else if (value < 76) {
      chartColor = 'tx-dark-green';
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 300);
  }, []);

  return (
    <div className={`circle-chart circle-chart--${size} mx-auto ${chartColor} ${className}`} style={{width: fixedSize}}>
      <svg width="50" height="50" viewBox="0 0 120 120" className="mx-auto circle-chart-svg">
        <circle
          className="circle-chart-bg"
          cx="60"
          cy="60"
          r="54"
          fill="none"
          strokeWidth="10"
        />
        <circle
          className="circle-chart-value"
          strokeDasharray="100"
          strokeDashoffset={100 - (isMounted ? value : 0)}
          strokeLinecap="round"
          cx="60"
          cy="60"
          r="54"
          fill="none"
          strokeWidth="10"
          pathLength="100"
        />
      </svg>
      {drawValue && <div className={`circle-chart-icon tx-24 ${valueClassName}`}>{value}%</div>}
      {ushiScore && (
        <div className={`circle-chart-icon tx-24 ${valueClassName}`}>
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={valueClassName}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0065 0L12.669 4.99399L10.0065 6.77094L7.3439 4.99399L10.0065 0ZM14.3643 6.47479C15.4384 6.28599 16.4413 5.83434 17.3471 5.13096L17.3374 5.11986L20 3.05414L18.8127 6.54142C17.8518 9.35863 15.9495 11.602 13.5717 12.7348C12.7208 13.9861 11.6402 15.0153 10.4303 15.7335L9.98382 16L9.53736 15.7335C8.31122 15.0042 7.22096 13.9528 6.36363 12.683C3.95988 11.4947 2.06729 9.18093 1.14849 6.29338L0 2.67654L2.66256 4.92365C3.55548 5.67516 4.55516 6.17863 5.63571 6.41556C6.08541 6.51551 6.54481 6.56363 7.00745 6.56363C7.10126 6.56363 7.19185 6.56363 7.28567 6.55993L8.46975 8.78483C7.98447 8.87367 7.49919 8.91809 7.00421 8.91809C6.42187 8.91809 5.83953 8.85886 5.27013 8.733C5.07926 8.69227 4.89161 8.64415 4.70397 8.58862C5.4416 9.46969 6.33776 10.1657 7.34067 10.6173L7.60919 10.7395L7.78065 11.0134C8.37917 11.9537 9.13296 12.7534 9.98382 13.3494C10.8282 12.7645 11.5723 11.9796 12.1611 11.0578L12.339 10.7839L12.614 10.6654C13.6202 10.2286 14.526 9.54003 15.2766 8.67007C15.0728 8.72189 14.869 8.76631 14.6587 8.80333C14.1993 8.88848 13.7237 8.9292 13.2449 8.9292C12.6723 8.9292 12.1126 8.86997 11.5594 8.75151L12.7305 6.55623C12.902 6.56734 13.0734 6.57474 13.2449 6.57474C13.6202 6.57474 13.9987 6.54142 14.3643 6.47479ZM10.0065 9.80287L12.6723 5.56039L10.0065 7.34105L7.3439 5.56039L10.0065 9.80287Z" fill="currentColor"/>
          </svg>
        </div>
      )}
      {icon && <div className="circle-chart-icon"><FontAwesomeIcon icon={icon} /></div>}
    </div>
  );
}

export default CircleChart;

import {HolderIconColor} from "./HolderIcon";

export default function IconShrimp({color}: HolderIconColor) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.15027 7.01139C4.36004 7.07022 4.57871 6.94698 4.63659 6.73626C4.69546 6.5264 4.57269 6.30825 4.36236 6.25037C4.06824 6.17071 3.80097 5.95208 3.70696 5.71446C3.64581 5.56527 3.61152 5.39301 3.61152 5.2119C3.61199 4.89044 3.71903 4.5421 3.96679 4.23225C4.21463 3.92235 4.60651 3.64306 5.20447 3.47586C5.59772 3.36608 6.22073 3.25535 6.93036 3.14419C7.99895 2.97562 9.2732 2.80095 10.3757 2.58237C10.9278 2.47305 11.4363 2.35399 11.8644 2.21365C12.2924 2.06962 12.6388 1.92369 12.8968 1.67542C13.052 1.52206 13.0524 1.27198 12.8987 1.11678C12.7454 0.96162 12.4948 0.961147 12.3401 1.11493C12.29 1.16684 12.1538 1.25579 11.9561 1.33773C11.61 1.48554 11.0861 1.62772 10.4849 1.75371C9.58215 1.94453 8.50057 2.10528 7.50331 2.25585C6.50325 2.40873 5.5954 2.54812 4.99276 2.71441C4.25863 2.91765 3.7088 3.28774 3.34941 3.73888C2.98903 4.18915 2.82137 4.7144 2.82137 5.21185C2.82137 5.49304 2.87413 5.76538 2.97648 6.0146C3.1933 6.52924 3.6514 6.87011 4.15027 7.01139Z" fill={color}/>
      <path d="M8.3498 12.2687C8.20624 12.1043 7.95608 12.0877 7.79258 12.2317L5.35199 14.3661C5.18755 14.5097 5.17083 14.7598 5.31495 14.9233C5.45855 15.0877 5.7082 15.1045 5.87217 14.9608L8.31229 12.8264C8.47673 12.6828 8.49336 12.4327 8.3498 12.2687Z" fill={color}/>
      <path d="M11.0453 12.6174C10.9013 12.453 10.6516 12.4364 10.4877 12.5799L8.04568 14.7158C7.88124 14.8594 7.86452 15.1086 8.00817 15.273C8.15173 15.4374 8.40142 15.4541 8.56586 15.3105L11.0078 13.1747C11.1723 13.0311 11.1889 12.7819 11.0453 12.6174Z" fill={color}/>
      <path d="M13.557 12.3318L11.0697 14.4125C10.9025 14.5528 10.8803 14.802 11.0201 14.9692C11.16 15.1364 11.4092 15.1587 11.5769 15.0188L14.0638 12.9386C14.2314 12.7983 14.2532 12.549 14.1138 12.3818C13.9734 12.2146 13.7243 12.1918 13.557 12.3318Z" fill={color}/>
      <path d="M18.0073 12.5619C18.1917 12.7694 18.3575 12.9941 18.5112 13.229L22.5216 10.8157C22.1223 10.1348 21.6249 9.50855 21.0472 8.94901C20.0977 8.03003 18.9332 7.29444 17.6548 6.78638L16.4005 11.3344C17.0096 11.6309 17.5538 12.0529 18.0073 12.5619Z" fill={color}/>
      <path d="M10.4695 11.2894C10.5255 11.2894 10.5769 11.2871 10.6223 11.2825C12.1416 11.1362 12.4353 11.0379 13.9152 10.8855C14.0713 10.8702 14.226 10.8624 14.3798 10.8624C14.6762 10.8624 14.9639 10.8976 15.2455 10.9527L16.5003 6.40227C16.0288 6.27255 15.5494 6.16697 15.0607 6.10071C14.3497 6.00347 13.6206 5.95483 12.8888 5.95483C11.426 5.95483 9.94888 6.1494 8.56064 6.53663C8.55785 6.53711 7.21322 6.92709 5.74669 7.35786C4.28158 7.78819 2.68958 8.26021 2.2222 8.41532C2.16248 8.43475 2.11706 8.45051 2.11663 8.45146C2.05084 8.47832 2.00916 8.53018 2.00542 8.54595L2.00495 8.54689L2.01793 8.56357C2.04109 8.58767 2.1064 8.63399 2.23423 8.67288C2.37741 8.71778 2.665 8.82989 3.08605 8.99386C3.50388 9.1574 4.04117 9.36769 4.64058 9.59508C5.8398 10.0504 7.28726 10.5743 8.50736 10.9166C9.31745 11.1458 10.0355 11.2903 10.4695 11.2894Z" fill={color}/>
      <path d="M12.9253 21.0152L8.6347 18.4269L9.70936 22.1719C9.78945 22.195 9.89464 22.2246 10.0308 22.2613C10.3351 22.3438 10.7696 22.453 11.2856 22.5628C11.8252 22.6772 12.4552 22.7902 13.1157 22.8731L12.7767 21.0426L12.9253 21.0152Z" fill={color}/>
      <path d="M17.534 20.0064C16.553 20.6966 15.3158 21.0982 14.0443 21.2038L14.3727 22.9774C14.5696 22.9866 14.7674 23 14.9628 23C15.0833 23 15.2037 22.9978 15.3227 22.9941C18.0121 22.9028 20.3901 21.635 21.8997 19.684L19.1107 18.1434C18.7698 18.8984 18.2167 19.5288 17.534 20.0064Z" fill={color}/>
      <path d="M23.0495 11.9167L19.0762 14.3082C19.336 14.9697 19.4796 15.6853 19.4801 16.419C19.481 16.5973 19.4675 16.771 19.4476 16.941L22.5775 18.6706C23.2255 17.5079 23.5951 16.1707 23.5951 14.7315C23.5952 13.7315 23.3951 12.7908 23.0495 11.9167Z" fill={color}/>
    </svg>
  );
}

import React from "react";
import {formatNumber, numberWithCommas} from "@helpers/numbers";
import Placeholder from "@components/common/Placeholder";
import BigNumber from "bignumber.js";
import IconWithLoading from "@components/common/IconWithLoading";

export interface FormattedNumberProps {
  value?: string | number | null;
  decimals?: number;
  floor?: boolean;
  className?: string;
  withSign?: boolean;
  postfix?: string;
  postfixClass?: string;
  suffix?: string;
  suffixClass?: string;
  subZeros?: boolean;
  useSpinner?: boolean;
  useFormat?: boolean;
}

const FormattedNumber = (props: FormattedNumberProps) => {
  const {
    value: originalValue,
    decimals = 2,
    floor,
    className,
    withSign,
    postfix,
    postfixClass,
    suffix,
    suffixClass,
    subZeros = false,
    useSpinner = false,
    useFormat = false,
  } = props;

  if (originalValue === undefined || originalValue === null) {
    return (<span className={className}>{useSpinner ? <IconWithLoading className="" isLoading={true} /> : <Placeholder/>}</span>);
  }

  const value = new BigNumber(originalValue).absoluteValue().toNumber();

  return (
    <span className={className}>
      {suffix === '$' && withSign && (
        <>
          {new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}
        </>
      )}
      {!!suffix && (
        <span className={suffixClass}>
          {suffix}
        </span>
      )}

      {suffix !== '$' && withSign && (
        <>
          {new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}
        </>
      )}
      {useFormat ? formatNumber(value, '', decimals) : numberWithCommas(value, floor, decimals, subZeros)}
      {!!postfix && (
        <span className={postfixClass}>
          {['$', '%', '(', ')'].some(v => postfix.includes(v)) ? '' : ' '}
          {postfix}
        </span>
      )}
    </span>
  );
}

export default React.memo(FormattedNumber);

import IconShrimp from "./IconShrimp";
import IconWhale from "./IconWhale";
import IconShark from "./IconShark";
import IconOctopus from "./IconOctopus";
import {HolderFilter} from "../../../../../constants";

export interface HolderIconColor {
  color: string;
}

interface HolderIconProps {
   type: HolderFilter;
   // isActive: boolean;
}

export default function HolderIcon({type}: HolderIconProps) {
  let Icon;

  switch (type) {
    case HolderFilter.WHALE:
      Icon = IconWhale;
      break;
    case HolderFilter.SHARK:
      Icon = IconShark;
      break;
    case HolderFilter.OCTOPUS:
      Icon = IconOctopus;
      break;
    default:
      Icon = IconShrimp;
      break;
  }

  return (
    <div>
      <Icon color="currentColor" />
    </div>
  );
}

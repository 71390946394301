import {TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import InfoTooltip from "@components/common/InfoTooltip";
import {isNumber} from "@helpers/bignumber";
import {formatNumber} from "@helpers/numbers";
import React from "react";
import BigNumber from "bignumber.js";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";

interface AdditionalMetricsProps {
  token: TokenViewNew;
}

const AdditionalMetrics = observer(({token}: AdditionalMetricsProps) => {
  const {globalStore} = useStores();

  const metrics = [
    {
      label: 'Total Tx',
      tooltip: 'Total Tx',
      hide: true,
      value: token.metrics.txCount,
    },
    {
      label: `Pooled ${token.symbol}`,
      tooltip: `Pooled ${token.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenReserve,
    },
    {
      label: `Pooled ${token.pair.tokenRef.symbol}`,
      tooltip: `Pooled ${token.pair.tokenRef.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenRefReserve,
    },
    {
      label: `% Pooled ${token.symbol}`,
      tooltip: `% Pooled ${token.symbol}`,
      hide: true,
      value: token.pair.metrics.tokenReserve / token.metrics.totalSupply * 100,
      unit: '%',
    },
    {
      label: `1 ETH`,
      tooltip: `Amount of ${token.symbol} you get for 1 ETH`,
      hide: true,
      value: globalStore.ethPrice ? new BigNumber(globalStore.ethPrice).dividedBy(token.pair.price).toFixed(6) : null,
      unit: token.symbol,
    },
  ];

  return (
    <div className="card wd-100p mb-3 d-flex d-md-none">
      <div className="tx-22 d-flex align-items-center mb-2">
        <span>Additional Metrics</span>
        <InfoTooltip text="Additioinal metrics for coin analysis selected by DEX investing industry experts" size={20} className="d-inline-flex tx-muted ms-2" />
      </div>
      <div>
        {metrics.map(({label, value, unit, tooltip}, index) => (
          <div className="d-flex align-items-center justify-content-between tx-14 mt-2 ht-25" key={`key-metric-${label}-${index}`}>
            <div className="tx-muted d-flex align-items-center">
              {!!tooltip && <InfoTooltip text={tooltip} className="d-inline-flex me-2" />}
              <span>{label}</span>
            </div>
            <div className="d-flex align-items-center">
              <span>{value && isNumber(value) ? formatNumber(value as number, unit) : value || 'N/A'}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default AdditionalMetrics;

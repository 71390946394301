import FormattedNumber from "@components/common/FormattedNumber";
import {TokeListItem, TokenInterface} from "@stores/tokens-store";
import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {formatNumber, getPercentageDifference} from "@helpers/numbers";
import {format, formatDistance} from "date-fns";
import TokenLogo from "@components/common/TokenLogo";
import Star from "@components/common/Star";
import {observer} from "mobx-react-lite";
import NumberCard from "@components/common/NumberCard";
import ChainExchange from "@components/common/ChainExchange";

interface ListItemComponentProps extends TokeListItem {
  showAnalytics?: boolean;
  hideDetails?: boolean;
}

const ListItem = observer((props: ListItemComponentProps) => {
  const {
    showAnalytics,
    hideDetails,
    ...rest
  } = props;

  const {
    token,
    tokenRef,
    price,
    priceChange24h,
    liquidity,
    volume,
    marketCap,
    createdAt,
    pairAddress,
    swaps,
    activeTraders,
    exchange,
  } = rest;

  // const {
  //   token: address,
  //   exchange,
  //   pair: pairAddress,
  // } = _id;

  const {
    symbol,
    name,
    address,
  } = token;

  const navigate = useNavigate();
  // const priceDiffValue = getPercentageDifference(price, price24h || price6h || price1h || price5m || 0);

  const data = [
    {
      key: 'liquidity',
      title: 'Liquidity',
      value: liquidity,
      unit: '$',
    },
    {
      key: 'price',
      title: 'Price',
      value: price,
      unit: '$',
    },
    {
      key: 'price-change',
      title: 'Price Change',
      value: price,
      unit: '$',
    },
    {
      key: 'mcap',
      title: 'MCap',
      value: marketCap,
      unit: '$',
    },
    {
      key: 'volume',
      title: 'Volume',
      value: volume,
      unit: '$',
    },
    // {
    //   key: 'holders',
    //   title: 'Holders',
    //   value: holders,
    //   decimals: 0,
    // },
  ];

  const handleClick = useCallback(() => {
    navigate(`/ether/${pairAddress.toLowerCase()}`);
  }, [navigate, pairAddress]);

  return (
    <div className="table-row">
      {/*<div className="table-col col-favorite">*/}
        {/*<Star token={props} className="cur-pointer tx-center" />*/}
      {/*</div>*/}

      <div className="table-col col-logo cur-pointer" onClick={handleClick}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="pos-relative">
            <div className="overflow-hidden token-logo">
              <TokenLogo logo={`https://www.dextools.io/resources/tokens/logos/3/ether/${address.toLowerCase()}.png?${Math.floor(Date.now() / 1000)}`} name={name} address={pairAddress} size={40} />
            </div>
            <ChainExchange chain={'ether'} exchange={exchange} className="exchange-logo-list--mobile d-md-none" size={20} />
          </div>
          <div className="d-flex flex-column justify-content-between ms-2 me-auto">
            <div className="tx-16 lh-2 mx-wd-150 word-break">{symbol}<span className="tx-muted tx-14"> / {tokenRef.symbol}</span></div>
            <div className="tx-13 lh-2 tx-muted"><div className="d-inline-block word-break mx-wd-100">{name.substring(0, 30)}</div><span className="d-md-none"> · {createdAt ? format(new Date(createdAt), 'dd.MM.yyyy') : 'N/A'}</span></div>
          </div>
        </div>
      </div>

      {data.map(({title, value, unit, key}) => {
        const isPrice = title === 'Price';
        const isPriceChange = title === 'Price Change';
        return (
          <div className={`table-col cur-pointer col-${key}`} key={`token-list-${pairAddress}-${key}`} onClick={handleClick}>
            <div>
              {!isPrice && !isPriceChange && (
                <div className="tx-muted d-md-none">{title}</div>
              )}
              {!!value && isPrice && (
                <FormattedNumber
                  suffix="$ "
                  value={price}
                  decimals={18}
                  floor={false}
                  subZeros
                  className="word-break"
                />
              )}
              {isPrice && (
                <div className="d-md-none">
                  <FormattedNumber
                    value={priceChange24h}
                    postfix="%"
                    className={`word-break ${priceChange24h >= 0 ? 'tx-success' : 'tx-danger'}`}
                    withSign={priceChange24h !== 0}
                  />
                </div>
              )}
              {isPriceChange && (
                <NumberCard
                  value={priceChange24h}
                  postfix="%"
                  withSign={priceChange24h !== 0}
                />
              )}
              {(!isPrice || !value) && !isPriceChange && (
                <span className="word-break">
                  {value ? formatNumber(value, unit || '') : 'N/A'}
                </span>
              )}
            </div>
          </div>
        );
      })}

      <div className="table-col col-exchanges cur-pointer" onClick={handleClick}>
        <ChainExchange chain={'ether'} exchange={exchange} className="me-3" size={20} />
      </div>

      <div className="table-col col-date cur-pointer" onClick={handleClick}>
        {createdAt ? formatDistance(new Date(createdAt), new Date()) : 'N/A'}
      </div>
    </div>
  );
});

export default ListItem;

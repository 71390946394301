import {action, computed, makeAutoObservable, observable} from 'mobx';
import {Chain, ChainId, chains, chainSymbols, Sort} from "../constants";
import axios from "axios";
import {addDays, subMonths} from "date-fns";
import api, {apiv2, apiv3} from "@helpers/api";
import {StatusResponse} from "@hooks/useResponseHandler";

// export interface TokenInterface {
//   address: string,
//   name: string,
//   symbol: string,
//   price: number,
//   priceDiff?: number,
//   price1hUsdDiff?: number,
//   price6hUsdDiff?: number,
//   price24hUsdDiff?: number,
//   mcap?: number,
//   liquidityUsd?: number,
//   initialLiquidityUsd?: number,
//   volume24hUsd?: number,
//   holders?: number,
//   swaps?: number,
//   creationDate?: string,
//   logo?: string | null,
//   poolScore?: number,
//   totalScore?: number,
// }

export interface TokenId {
  chain: string,
  exchange: string,
  pair: string,
  token: string,
  tokenRef: string,
}

export interface TokenPairMetrics {
  reserve: number,
  reserveRef: number,
  liquidity: number,
}

export interface TokenLock {
  _id: string,
  percent?: number,
  unlockDate: string,
  amount: number,
  providerId: string,
  api: string,
  type: string,
}

export interface TokenDextScore {
  information: number,
  trading: number,
  team: number,
  audit: number,
  community: number,
  total: number,
}

export interface TokenPair {
  symbol: string,
  name: string,
  symbolRef: string,
  nameRef: string,
  type: string,
  metrics: TokenPairMetrics,
  fee: number,
  creationBlock: number,
  creationTime: string,
  locks: TokenLock[],
  dextScore: TokenDextScore,
}

export interface TokenAudit {
  sourceCodeVerified: boolean;
  simulationSuccess: boolean;
  honeypot: boolean;
  hasOwner: boolean;
  snipers: {
    firstBlock: number;
    secondBlock: number;
    thirdBlock: number;
  },
  buyTax: number;
  sellTax: number;
  transferTax: number;
  deployer: {
    scammer: boolean;
    address: string;
    tokenPercents: number;
    tokenBalance: number;
    nativeBalance: number;
  },
  holders: {
    analyzed: number;
    successful: number;
    siphoned: number;
    failed: number;
  },
  initialLiquidity: {
    addedAtBlockNumber: number;
    addedAt: number;
    pair: string;
    tokenReserve: number;
    tokenRefReserve: number;
  },
  lpLock: {
    burnedPercent: number;
    lockedPercent: number;
    totalPercent: number;
    unlockTimestamp: number;
  },
  flags: {
    proxy?: boolean;
    mintable?: boolean;
    ownershipRenounced?: boolean;
    reclaimOwnership?: boolean;
    hiddenOwner?: boolean;
    selfdestruct?: boolean;
    externalCall?: boolean;
    blacklist?: boolean;
    whitelist?: boolean;
    antiWhale?: boolean;
    modifiableAntiWhale?: boolean;
    tradingCooldown?: boolean;
    personalTaxes?: boolean;
  }
}

// export interface TokenAudit {
//   codeVerified: boolean,
//   date: string,
//   lockTransactions: boolean,
//   mint: boolean,
//   proxy: boolean,
//   status: string,
//   unlimitedFees: boolean,
//   version: number,
//   is_contract_renounced: boolean,
//   provider: string,
//   external?: {
//     goplus?: {
//       anti_whale_modifiable: string;
//       buy_tax: number;
//       can_take_back_ownership: string;
//       cannot_buy: string;
//       cannot_sell_all: string;
//       creator_address: string;
//       creator_balance: number;
//       creator_percent: number;
//       external_call: string;
//       hidden_owner: string;
//       holder_count: number;
//       honeypot_with_same_creator: string;
//       is_anti_whale: string;
//       is_blacklisted: string;
//       is_honeypot: string;
//       is_in_dex: string;
//       is_mintable: string;
//       is_open_source: string;
//       is_proxy: string;
//       is_whitelisted: string;
//       lp_holder_count: number;
//       lp_total_supply: number;
//       owner_address: string;
//       owner_balance: number;
//       owner_change_balance: string;
//       owner_percent: number;
//       personal_slippage_modifiable: string;
//       selfdestruct: string;
//       sell_tax: number;
//       slippage_modifiable: string;
//       token_name: string;
//       token_symbol: string;
//       total_supply: number;
//       trading_cooldown: string;
//       transfer_pausable: string;
//       updatedAt: string;
//       updatedResult: true;
//       holders: {
//         address: string;
//         balance: number;
//         is_contract: number;
//         is_locked: number;
//         percent: number;
//         tag: string;
//       }[];
//       dex: {
//         liquidity: number;
//         name: string;
//         pair: string;
//       }[];
//     }
//   }
// }

export interface TokenInfo {
  blueCheckmark: boolean,
  cmc: string,
  coingecko: string,
  description: string,
  dextools: boolean,
  ventures: boolean,
  email: string,
  extraInfo: string,
  nftCollection: string,
}

export interface TokenLinks {
  github: string,
  website: string,
  bitbucket: string,
  discord: string,
  facebook: string,
  instagram: string,
  linkedin: string,
  medium: string,
  reddit: string,
  telegram: string,
  tiktok: string,
  twitter: string,
  youtube: string,
}

export interface TokenMetrics {
  maxSupply: number,
  totalSupply: number,
  holders: number,
  txCount: number,
  mcap: number | null,
  fdv: number,
}

export interface TokenReprPair {
  id: TokenId,
  updatedAt: string,
}

export interface TokenData {
  audit?: TokenAudit,
  decimals: string,
  info: TokenInfo,
  links: TokenLinks,
  logo: string,
  metrics: TokenMetrics,
  name: string,
  symbol: string,
  totalSupply: string,
  creationBlock: number,
  creationTime: string,
  reprPair: TokenReprPair,
}

export interface TokenInterface {
  _id: TokenId,
  pair: TokenPair,
  token: TokenData,
  price: number,
  price24h?: number,
  price6h?: number,
  price1h?: number,
  price5m?: number,
  volume24h: number,
  swaps24h: number,
}

export interface FavoritesTokenInterface {
  id: TokenId;
  token: TokenData,
  symbolRef: string;
  price: number;
  price24h: number;
  volume: number;
  swaps: number;
  metrics: {
    liquidity: number;
  }
}

export interface TokenPrice {
  buys: number;
  price: number;
  priceChain: number;
  sells: number;
  swaps: number;
  volume: number;
}

interface TokenSkeleton {
  score?: {
    total: number;
    chain: number;
    pair: number;
    liquidity: number;
    dextScore: number;
  },
  id: TokenId;
  creationBlock: number;
  creationTime: string;
  dextScore: {
    information: number;
    holders: number;
    pool: number;
    transactions: number;
    creation: number;
    total: number;
  };
  metrics: {
    initialLiquidity: number;
    liquidity: number;
    liquidityUpdatedAt: string;
    reserve: number;
    reserveRef: number;
    txCount?: number;
  };
  name: string;
  nameRef: string;
  symbol: string;
  symbolRef: string;
  type: string;
  team: {
    wallet: string;
  };
  votes?: {
    _warning: number;
    downvotes: number;
    upvotes: number;
  };
  token: TokenData;
  price: number;
  volume: number;
  swaps: number;
}

export interface SearchTokenInterface extends TokenSkeleton{
  price5m?: number;
  price1h?: number;
  price6h?: number;
  price24h?: number;
  locks: TokenLock[];
}

export interface TokenViewInterface extends TokenSkeleton {
  price5m?: TokenPrice;
  price1h?: TokenPrice;
  price6h?: TokenPrice;
  price24h?: TokenPrice;
  info?: TokenInfo,
  locks: {
    [key: string]: {
      lockedPercent?: string;
      locks: any;
      totalSupply: number;
      updatedAt: string;
      updatedResult: boolean;
    };
  };
}

export interface TokenViewNew {
  name: string;
  symbol: string;
  address: string;
  chain: string;
  logo: string;
  description: string;
  metrics: {
    holders: number;
    txCount: number;
    maxSupply: number;
    totalSupply: number;
    circulatingSupply: number;
    marketCap: number;
  },
  links: {
    discord: string;
    instagram: string;
    telegram: string;
    twitter: string;
    website: string;
    youtube: string;
    bitbucket: string;
    facebook: string;
    github: string;
    linkedin: string;
    medium: string;
    reddit: string;
    tiktok: string;
  },
  pair: {
    chain: string;
    exchange: string;
    address: string;
    createdAt: number;
    price: number;
    metrics: {
      liquidity: number;
      tokenReserve: number;
      tokenRefReserve: number;
    },
    tokenRef: {
      address: string;
      name: string;
      symbol: string;
    },
    price24h: {
      volume: number;
      swaps: number;
      price: number;
      priceChain: number;
      buys: number;
      sells: number;
    },
    price1h: {
      volume: number;
      swaps: number;
      price: number;
      priceChain: number;
      buys: number;
      sells: number;
    }
  }
}

interface TokensResponse {
  content: TokenInterface[],
  pageable: {
    sort: {
      empty: boolean,
      sorted: boolean,
      unsorted: boolean
    },
    offset: number,
    pageNumber: number,
    pageSize: number,
    paged: boolean,
    unpaged: boolean
  },
  totalPages: number,
  totalElements: number,
  last: boolean,
  size: number,
  number: number,
  sort: {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean
  },
  numberOfElements: number,
  first: boolean,
  empty: boolean,
}

export enum HolderType {
  WALLET = 'WALLET',
  LABELED_WALLET = 'LABELED_WALLET',
  BURN_ADDRESS = 'BURN_ADDRESS',
  LIQUIDITY_PAIR = 'LIQUIDITY_PAIR',
}

export interface TokenHolder {
  type: HolderType;
  label?: string;
  address: string;
  tokenBalance: number;
  tokenUsdBalance: string;
  totalUsdBalance?: number;
}

export enum HolderProfileProfit {
  IN = 'in',
  OUT = 'out',
  AT = 'at_money',
}

export enum HolderProfileType {
  SMART_CONTRACT = 'smart_contract',
  LIQUIDITY_POOL = 'liquidity_pool',
  WALLET = 'wallet',
  BURN_ADDRESS = 'burn_address',
}

export interface HolderProfile {
  address: string;
  amount: number;
  inAmountWeekSum: number;
  outAmountWeekSum: number;
  amountUSD: number;
  change: number;
  acquisitionPrice: number;
  profit: HolderProfileProfit;
  holderType: HolderProfileType;
  label: string;
  supplyOwned: number;
  isInsider: boolean;
}

export interface HolderTrendResponse {
  since: string;
  history: HolderTrend[];
}

export interface HolderTrend {
  allHolders: number;
  largeHolders: number;
  ts: string;
}

// export interface TokenAudit {
//   sourceCodeVerified: boolean;
//   contractContainsFeeModifiers: boolean;
//   ownershipRenounced: boolean;
//   ownerTokenSupplyShare: number;
//   maxHolderTokenSupplyShare: number;
//   initialLiquidity: number;
//   currentLiquidity: number;
//   lockedLiquidityShare: number;
//   ownerLiquidityShare: number;
//   maxHolderLiquidityShare: number;
// }

export interface TokenHoneypot {
  honeypot: boolean;
  sellFeePercents: number;
  buyFeePercents: number;
}

export enum TokensCategory {
  TOP = 'top',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  LIVE = 'live',
}

export enum TokenListInterval {
  H3 = 'H3',
  H24 = 'H24',
  D3 = 'D3',
  D7 = 'D7',
}

export enum TradeType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface CandleInterface {
  close: number;
  high: number;
  low: number;
  open: number;
  time: number;
  timeString: string;
  volume: number;
}

export interface TradeResponse {
  "token": {
    "name": string;
    "symbol": string;
    "address": string;
    "decimals": number;
  },
  "tokenRef": {
    "name": string;
    "symbol": string;
    "address": string;
    "decimals": number;
  },
  "trades": TradeInterface[];
}

export interface TradeInterface {
  side: TradeType;
  maker: string;
  transactionHash: string;
  timestamp: string;
  tokenPriceUsd: number;
  tokenRefAmount: number;
  tokenAmount: number;
}

export interface TokenHolderWalletBalance {
  address: string;
  usdBalance: number;
}

export interface TokenWalletProfitLoss {
  averageUsdTokenPrice: number;
  usdProfit: number;
}

export interface TwitterProfile {
  id: string;
  username: string;
  name: string;
  location: string;
  verified: boolean;
  registrationDate: string;
  imageUrl: string;
  description: string;
  tweets: number;
  followers: number;
  following: number;
}

export interface TwitterMention {
  tweetId: string;
  author: TwitterProfile;
  date: string;
  text: string;
  link: string;
}

export interface TwitterHashtag {
  tag: string;
  frequency: number;
}

export interface TwitterEmoji {
  emoji: string;
  frequency: number;
}

export interface TwitterFollowers {
  total: number;
  activePercent: number;
  passivePercent: number;
  totalActive: number;
  totalPassive: number;
  totalFake: number;
  fakePercent: number;
}

export interface TwitterEngagement {
  tweetsPerDay: number;
  curTweetsPerDay: number;
  engagementScore: number;
  likesPerTweet: number;
  repliesPerTweet: number;
  retweetsPerTweet: number;
  quotesPerTweet: number;
  impressionsPerTweet: number;
}

export interface TwitterAiScore {
  score: number;
  explanation: string;
}

export interface TwitterLink {
  date: string;
  url: string;
  imageUrl: string;
  title: string;
  description: string;
}

export interface TwitterAnalytics {
  profile: TwitterProfile;
  mentions: TwitterMention[];
  hashtags: TwitterHashtag[];
  emojis: TwitterEmoji[];
  followers: TwitterFollowers;
  engagement: TwitterEngagement;
  aiScore: TwitterAiScore;
  postedLinks: TwitterLink[];
  score: number;
}

export enum TokenSort {
  MCAP = 'mCap',
  LIQUIDITY = 'liquidity',
  VOLUME = 'volume',
}

interface TokeListItemToken {
  address: string;
  name: string;
  symbol: string;
}

export interface TokeListItem {
  activeTraders: number;
  createdAt: string;
  exchange: string;
  liquidity: number;
  marketCap: number;
  pairAddress: string;
  price: number;
  priceChange24h: number;
  swaps: number;
  token: TokeListItemToken;
  tokenRef: TokeListItemToken;
  volume: number;
}

export class TokensStore {
  // private API_URL: string = (process.env.REACT_APP_API_URL as string);

  @observable
  // private _response: TokensResponse | null = null;
  private _response: TokeListItem[] = [];

  @observable
  private _token: TokenViewNew | null = null;

  @observable
  private _error: string = '';

  @observable
  private _isLoading: boolean = false;

  @observable
  private _isFavoritesLoading: boolean = false;

  @observable
  private _isSearchLoading: boolean = false;

  @observable
  private _category: TokensCategory = TokensCategory.TOP;

  @observable
  private _interval: TokenListInterval = TokenListInterval.H3;

  @observable
  private _scrollTop: number = 0;

  @observable
  private _prevSearchResult: SearchTokenInterface[] = [];

  @observable
  private _searchResult: SearchTokenInterface[] = [];

  @observable
  private _searchQuery: string = '';

  @observable
  private _favorites: Array<FavoritesTokenInterface> = [];

  @observable
  private _mCapSort: Sort = Sort.DEFAULT;

  @observable
  private _liquiditySort: Sort = Sort.DEFAULT;

  @observable
  private _volumeSort: Sort = Sort.DEFAULT;

  @observable
  private _chain: Chain = chains[0];

  private _singleTries: number = 0;
  private _singleTriesAddress: string = '';

  @action
  loadList() {
    this._isLoading = true;

    api<{data: TokeListItem[]}>({
      method: 'get',
      path: `/pairs/top`,
      baseURL: 'https://dex-indexer-api-42c18bdca269.herokuapp.com/api/v1',
    }).then((response) => {
      this._response = response.data;
      this._isLoading = false;
    })
    .catch((error) => {
      console.error(error);
      this._isLoading = false;
    });
  }

  constructor() {
    makeAutoObservable(this);

    // this._favorites = localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites') as string) : [];

    this.restoreStore();

    if (!this._response.length) {
      this.loadList();
    }
  }

  @action
  private persistStore() {
    localStorage.setItem('category', JSON.stringify(this._category));
    localStorage.setItem('mCapSort', JSON.stringify(this._mCapSort));
    localStorage.setItem('liquiditySort', JSON.stringify(this._liquiditySort));
    localStorage.setItem('volumeSort', JSON.stringify(this._volumeSort));
  }

  @action
  private restoreStore() {
    this._category = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category') as string) : TokensCategory.TOP;
    this._interval = localStorage.getItem('interval') ? JSON.parse(localStorage.getItem('interval') as string) : TokenListInterval.H3;
    this._mCapSort = localStorage.getItem('mCapSort') ? JSON.parse(localStorage.getItem('mCapSort') as string) : Sort.DEFAULT;
    this._liquiditySort = localStorage.getItem('liquiditySort') ? JSON.parse(localStorage.getItem('liquiditySort') as string) : Sort.DEFAULT;
    this._volumeSort = localStorage.getItem('volumeSort') ? JSON.parse(localStorage.getItem('volumeSort') as string) : Sort.DEFAULT;
    this._prevSearchResult = localStorage.getItem('previousSearch-v2') ? JSON.parse(localStorage.getItem('previousSearch-v2') as string) : [];
  }

  @computed
  get list() {
    // const start = this._page * 10;
    // const end = start + 10;
    if (this.isTopOrVerified) {
      let sort: string = '';
      let sortDirection = 1;
      if (this._mCapSort !== Sort.DEFAULT) {
        sort = 'mcap';
        sortDirection = this._mCapSort === Sort.ASC ? 1 : -1;
      } else if (this._liquiditySort !== Sort.DEFAULT) {
        sort = 'liquidity';
        sortDirection = this._liquiditySort === Sort.ASC ? 1 : -1;
      } else if (this._volumeSort !== Sort.DEFAULT) {
        sort = 'volume';
        sortDirection = this._volumeSort === Sort.ASC ? 1 : -1;
      }

      let result = this._response
        .slice()
        // .filter((token) => chainSymbols.includes(token._id.chain))
        .sort((_a, _b) => {
          if (sort) {
            let a = 0;
            let b = 0;
            if (sort === 'mcap') {
              a = _a.marketCap;
              b = _b.marketCap;
            } else if (sort === 'liquidity') {
              a = _a.liquidity;
              b = _b.liquidity;
            } else if (sort === 'volume') {
              a = _a.volume;
              b = _b.volume;
            }
            return (a - b) * sortDirection;
          }
          return 0;
        })

      // if (this._chain) {
      //   result = result.filter((token) => token._id.chain === this._chain?.symbol);
      // }

      return result;
      // return result.filter((_token, index) => index >= start && index < end);
    }
    return this._response;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isTopOrVerified() {
    return this._category === TokensCategory.TOP || this._category === TokensCategory.VERIFIED;
  }

  get isSearchLoading() {
    return this._isSearchLoading;
  }

  get totalTokens() {
    return this._response.length;
  }

  get totalPages() {
    return this._response.length / 10;
  }

  get category() {
    return this._category;
  }

  get interval() {
    return this._interval;
  }

  get favorites() {
    return this._favorites;
  }

  @computed
  isFavorite(address: string) {
    return this._favorites.some((token) => token.id.pair === address);
  }

  get scrollTop() {
    return this._scrollTop;
  }

  @action
  setScrollTop(value: number) {
    this._scrollTop = value;
  }

  get searchResult() {
    return this._searchQuery ? this._searchResult : [];
  }

  @computed
  getSearchResult() {
    return this._searchQuery ? this._searchResult : [];
  }

  get previousSearchResult() {
    return this._prevSearchResult;
  }

  get sort() {
    return {mCap: this._mCapSort, liquidity: this._liquiditySort, volume: this._volumeSort};
  }

  get singleTries() {
    return this._singleTries;
  }

  @action
  setCategory(value: TokensCategory) {
    this._response = [];
    this._category = value;

    this.loadList();
    this.persistStore();
  }

  @action
  setInterval(value: TokenListInterval) {
    this._response = [];
    this._interval = value;

    this.loadList();
    this.persistStore();
  }

  @action
  resetSort() {
    this._mCapSort = Sort.DEFAULT;
    this._liquiditySort = Sort.DEFAULT;
    this._volumeSort = Sort.DEFAULT;
  }

  @action
  setSort(sort: Sort, type: TokenSort) {
    this.resetSort();
    switch (type) {
      case 'mCap':
        this._mCapSort = sort;
        break;
      case 'liquidity':
        this._liquiditySort = sort;
        break;
      case 'volume':
        this._volumeSort = sort;
        break;
    }
    if (!this.isTopOrVerified) {
      this.loadList();
    }
    this.persistStore();
  }

  @action
  async toggleFavorite(address: string, token: SearchTokenInterface | TokenViewInterface | TokenInterface | FavoritesTokenInterface) {
    let favorites = this._favorites.slice();
    let response;
    if (favorites.find((_token) => _token.id.pair === address)) {
      response = await this.removeFavorite(address);
    } else {
      response = await this.addFavorite({pairAddress: address, metadata: token});
    }

    // this.fetchFavorites();

    return response;
  }

  get
  token() {
    return this._token;
  }

  get
  error() {
    return this._error;
  }

  @action
  setToken(token: TokenViewNew | null) {
    this._token = token;
  }

  @action
  setError(error: string) {
    this._error = error;
  }

  @action
  async searchSingleToken(address: string, chain = this._chain.symbol) {
    this._isLoading = true;
    this._error = '';

    return apiv3<TokenViewNew>({
      method: 'get',
      path: `/search/token`,
      data: {
        address,
        chain,
        sourceCodeLinks: true,
      },
    }).then((response) => {
      this._token = response;
      this._isLoading = false;
    }).catch((error) => {
      console.error(error);
      this._error = error.message;
      this._isLoading = false;
    });
  }

  @action
  async searchSinglePair(address: string, chain = this._chain.symbol) {
    this._isLoading = true;
    this._error = '';

    return apiv3<TokenViewNew>({
      method: 'get',
      path: `/search/pair`,
      data: {
        address,
        chain,
        sourceCodeLinks: true,
      },
    }).then((response) => {
      this._token = response;
      this._isLoading = false;
    }).catch((error) => {
      console.error(error);
      this._error = error.message;
      this._isLoading = false;
    });
  }

  @action
  searchTokens(search: string) {
    this._searchResult = [];

    if (!search) {
      this._searchQuery = '';
      return;
    }

    this._isSearchLoading = true;
    this._searchQuery = search;
    apiv2<SearchTokenInterface[]>({
      method: 'get',
      path: `/pairs/search`,
      data: {
        query: search,
      }
    }).then((response) => {
      this._searchResult = response;
      this._isSearchLoading = false;
      if (response.length) {
        const filtered = response.filter((token) => chainSymbols.includes(token.id.chain));
        localStorage.setItem('previousSearch-v2', JSON.stringify(filtered));
        this._prevSearchResult = filtered;
      }
    })
      .catch((error) => {
        console.error(error);
        this._isSearchLoading = false;
      });
  }

  @action
  async fetchToken(address: string, force = false, chain = 'ether'): Promise<TokenViewInterface | null> {
    if (force) {
      this._singleTries = 0;
    } else if (this._singleTries > 10 && address === this._singleTriesAddress) {
      return null;
    }

    if (address !== this._singleTriesAddress) {
      this._singleTries = 1;
      this._singleTriesAddress = address;
    } else {
      this._singleTries++;
    }

    const result = apiv2<TokenViewInterface[]>({
      method: 'get',
      path: `/pair`,
      data: {
        address,
        chain,
      //   page: this._page,
      //   size: 10,
      }
    }).then((response) => {
      const token = response && response.length ? response[0] : null;

      if (token && chainSymbols.includes(token.id.chain)) {
        return token;
      }

      return null;
    })
    .catch((error) => {
      console.error(error);
      return null
    });

    // const result = await api<TokenViewInterface>({
    //   method: 'get',
    //   path: `/token/${address}`,
    // }).then((response) => {
    //   return response;
    // }).catch((error) => {
    //   console.error(error);
    //   return null;
    // });

    if (result) {
      this._singleTries = 0;
      return result;
    } else {
      return this.fetchToken(address, false, chain);
    }
  }

  // @action
  // async fetchTokenList(address: string[]): Promise<TokenViewInterface[] | null> {
  //   return await api<TokenViewInterface[]>({
  //     method: 'get',
  //     path: `/token/prices`,
  //     data: {
  //       address,
  //     }
  //   }).then((response) => {
  //     return response;
  //   }).catch((error) => {
  //     console.error(error);
  //     return null;
  //   });
  // }

  get
  isFavoritesLoading() {
    return this._isFavoritesLoading;
  }

  @action
  addFavorite(token: {pairAddress: string, metadata: any}) {
    return api<StatusResponse<any>>({method: 'post', path: '/favorites', data: {...token}});
  }

  @action
  removeFavorite(pairAddress: string) {
    return api<StatusResponse<any>>({method: 'delete', path: `/favorites/${pairAddress}`});
  }

  @action
  fetchFavorites() {
    this._isFavoritesLoading = true;
    api<Array<FavoritesTokenInterface>>({method: 'get', path: '/favorites'})
      .then((favorites) => {
        this._isFavoritesLoading = false;
        this._favorites = favorites;
      })
      .catch((error) => {
        this._isFavoritesLoading = false;
        console.error(error);
      });
  }

  @action
  async getTokenHoneypot(tokenAddress: string): Promise<TokenHoneypot | null> {
    return await axios.get<TokenHoneypot>(
      `https://uanalytics-web3-api.herokuapp.com/api/v1/audit/honeypot?tokenAddress=${tokenAddress}`
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error(error);
      return null;
    });
  }

  @action
  async getTokenAudit(tokenAddress: string, pairAddress: string, chain = this._chain.symbol): Promise<TokenAudit | null> {
    return apiv3<TokenAudit>({
      method: 'get',
      path: `/audit`,
      data: {
        tokenAddress,
        pairAddress,
        chain,
      },
    });
    // return await axios.get<TokenAudit>(
    //   `https://uanalytics-web3-api.herokuapp.com/api/v1/audit/sniffer?tokenAddress=${tokenAddress}&poolAddress=${poolAddress}`
    // ).then((response) => {
    //   return response.data;
    // }).catch((error) => {
    //   console.error(error);
    //   return null;
    // });
  }

  @action
  fetchTokenTrades(tokenAddress: string, pairAddress: string, chain = this._chain.symbol) {
    return apiv3<TradeResponse>({
      method: 'get',
      path: `/trades`,
      data: {
        tokenAddress,
        pairAddress,
        chain,
      },
    }).then((response) => {
      return response;
    }).catch((error) => {
      console.error(error);
      return null;
    });
    // const monthAgo = subMonths(addDays((new Date()).setHours(0, 0, 0, 0), 1), 1).getTime();
    // return axios.get<TradeInterface[]>(
    //   `https://api.ushi.pro/api/v1/token/trades/${pairAddress}?from=${monthAgo}&range=month&interval=15m`
    // ).then((response) => {
    //   const result = response.data;
    //   return Array.isArray(result) ? result.reverse() : [];
    // }).catch((error) => {
    //   console.error(error);
    //   return null;
    // });
  }

  @action
  // fetchLatestTokenTrades(pairAddress: string, timestamp: number) {
  fetchLatestTokenTrades(pair: string, chainId = ChainId.ETH) {
    return apiv2<TradeInterface[]>({
      method: 'get',
      path: `/pair/swaps`,
      data: {
        pair,
        chainId,
      },
    }).then((response) => {
      return Array.isArray(response) ? response.reverse() : [];
    });
    // return axios.get<TradeInterface[]>(
    //   `https://api.ushi.pro/api/v1/trades/${pairAddress}/latest?ts=${timestamp}`
    // ).then((response) => {
    //   const result = response.data;
    //   return Array.isArray(result) ? result.reverse() : [];
    // }).catch((error) => {
    //   console.error(error);
    //   return null;
    // });
  }

  @action
  getTokenHoldersTrend(address: string, chainId = ChainId.ETH) {
    return apiv2<HolderTrendResponse>({
      method: 'get',
      path: `/holders/trend`,
      data: {
        address,
        chainId,
      },
    });
  }

  @action
  getTokenHolders(tokenAddress: string, chainId = ChainId.ETH) {
    return apiv2<TokenHolder[]>({
      method: 'get',
      path: `/holders`,
      data: {
        tokenAddress,
        chainId,
      },
    });
  }

  @action
  getHolderProfiles(tokenAddress: string, network = 'eth', limit = 200) {
    return apiv2<HolderProfile[]>({
      method: 'get',
      path: `/holders/profiles`,
      data: {
        tokenAddress,
        network,
        limit,
      },
    });
  }

  @action
  getWalletProfitLoss(tokenAddress: string, holderAddress: string) {
    return api<TokenWalletProfitLoss>({
      method: 'get',
      path: `/holders/${tokenAddress}/profit-loss/${holderAddress}`,
    });
  }

  @action
  getTwitterAnalytics(username: string) {
    return api<TwitterAnalytics>({
      method: 'get',
      path: `/twitter/analytic/${username}`,
    });
  }

  @action
  fetchTokenCandles(address: string) {
    const monthAgo = subMonths(addDays((new Date()).setHours(0, 0, 0, 0), 12), 1).getTime();
    return axios.get<CandleInterface[]>(
      `https://uanalytics-api.herokuapp.com/api/v1/token/candles/${address}?from=${monthAgo}&range=month&interval=15m`
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error(error);
      return null;
    });
  }

  @action
  setChain(chain: Chain) {
    this._chain = chain;
    this.loadList();
  }

  get
  chain() {
    return this._chain;
  }
}

import {action, computed, makeAutoObservable, observable} from 'mobx';
// import {getSubscription, SubscriptionResponse, usdtToUshi} from "@contracts/SubscriptionManager";
// import {allowance} from "@contracts/UshiToken";
import axios from "axios";
import {apiv2} from "@helpers/api";

interface Gas {
  acceptance: number;
  baseFee: number;
  estimatedFee: number;
  maxFeePerGas: number;
  maxPriorityFeePerGas: number;
}

export class GlobalStore {
  // private API_URL: string = (process.env.REACT_APP_API_URL as string);

  @observable
  private _refID: string | null = localStorage.getItem('referral');

  @observable
  private _web3Enabled: boolean = false;

  @observable
  private _fearGreed: number | null = null;

  @observable
  private _ethPrice: number = 0;

  @observable
  private _ethDiff: number = 0;

  @observable
  private _ethGas: Gas[] = [];

  @action
  public loadGlobalInfo() {
    if (!this._web3Enabled) {
      return;
    }
  }

  @action
  loadEthPrice() {
    axios
      .get('https://uanalytics-be-v2.herokuapp.com/api/v2/eth')
      .then((response) => {
        this._ethPrice = response.data.eth.usdPrice;
        this._ethDiff = response.data.eth.priceDiff24h;
        this._ethGas = response.data.gas?.sort((a: any, b: any) => a.acceptance - b.acceptance).slice(0, 3) || [];
      })
      .catch((e) => {
        console.error(e);
      });
  }

  @action
  loadFearAndGreed() {
    apiv2<{value: number}>({
      method: 'get',
      path: '/fear-and-greed',
    }).then((response) => {
      this._fearGreed = response.value;
    }).catch((e) => {
      console.error(e);
    });
  }

  constructor() {
    makeAutoObservable(this);
    this.loadEthPrice();
    this.loadFearAndGreed();

      // this._web3Enabled = true;
    this.loadGlobalInfo();
  }

  @action
  public handleReferral(searchParams: URLSearchParams) {
    this.setRefID(searchParams.get('ref'));
  }

  @action
  setRefID(value: string | null) {
    if (value) {
      this._refID = value;
      localStorage.setItem('referral', value);
    }
  }

  @computed
  getRefID() {
    return this._refID;
  }

  get
  fearAndGreed() {
    return this._fearGreed;
  }

  get
  ethPrice() {
    return this._ethPrice;
  }

  get
  ethDiff() {
    return this._ethDiff;
  }

  get
  ethGas() {
    return this._ethGas;
  }

  get
  ethGasBase() {
    return this._ethGas.length > 1 ? this._ethGas[1].baseFee : 0;
  }

  @action
  resetStore() {
  }
}

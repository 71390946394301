import {useMemo} from "react";
import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import link from "@assets/icons/link.svg";
import share from "@assets/icons/share-white.svg";
import referralImg from "@assets/images/referral.png";
import FormattedNumber from "@components/common/FormattedNumber";
import CopyButton from "@components/common/CopyButton";
import toast from "react-hot-toast";
import {useStores} from "@hooks/useStores";
import AsyncValue from "@components/common/AsyncValue";

const shareContent = (
  <>
    <div className="d-flex justify-content-between mb-1 wd-100p">
      <span>Share</span>
      <img src={share} alt="Share" />
    </div>
    <div className="tx-muted tx-12">
      Share Withdrawal Link
    </div>
  </>
);

const Referral = observer(() => {
  const navigate = useNavigate();
  const {accountStore} = useStores();
  const {refInfo} = accountStore;

  const referralLink = useMemo(() => {
    if (!refInfo) {
      return '';
    }

    return 'https://ushi.pro/?ref=' + refInfo.refId;
  }, [refInfo]);

  const handleShare = () => {
    try {
      navigator.share({
        url: referralLink,
        title: `Join uAnalytics with me`,
        text: `Check tokens price, charts, trade history, audit and other information on uAnalytics`,
      });
    } catch (error) {
      toast.error('Share failed', {duration: 3000});
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-4 tx-center">
          <img src={referralImg} alt="Referral program" className="mx-auto"/>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="tx-28 tx-light tx-center mb-1 mt-4">
            Withdrawal program
          </div>
          <div className="tx-13 tx-light tx-center mb-3">
            Get bonuses for every new friend who registers via your referral link and makes a subscription purchase
          </div>

          <div className="d-flex justify-content-between mb-3">
            <CopyButton
              text={referralLink}
              element={(
                <div className="d-flex justify-content-between flex-column mb-1 wd-100p">
                  <div className="d-flex justify-content-between mb-1">
                    <span>Link</span>
                    <img src={link} alt="Link" />
                  </div>
                  <div className="tx-muted tx-left text-truncate tx-12">
                    {referralLink.replace('http://', '').replace('https://', '')}
                  </div>
                </div>
              )}
              className="card me-1 wd-100p d-flex flex-column"
              noIcon
            />
            {!navigator.canShare ? (
              <CopyButton
                text={referralLink}
                element={shareContent}
                className="card ms-1 wd-100p d-flex flex-column"
                noIcon
              />
            ) : (
              <div className="card ms-1 wd-100p cur-pointer" onClick={handleShare}>
                {shareContent}
              </div>
            )}
          </div>

          <div className="card d-flex flex-row justify-content-between align-items-center mb-3">
            <div className="tx-muted tx-14">Withdrawal balance</div>
            <FormattedNumber
              value={(refInfo?.totalUsdEarned || 0) - (refInfo?.totalUsdWithdrawn || 0)}
              suffix="$"
              decimals={2}
              className="tx-18"
            />
          </div>

          <button
            className="btn btn-outline-primary tx-primary wd-100p tx-olney me-2"
            onClick={() => navigate(PageRoutes.REFERRAL_WITHDRAW)}
          >
            Withdraw
          </button>
        </div>

        <div className="col-lg-4">
          <div className="row">
            <div className="col-md-6 col-lg-12">
              <div className="d-flex justify-content-between mt-4 mb-3 tx-15">
                <span>Invited by You</span>
                <span>Invite bonus <span className="tx-success">10%</span></span>
              </div>

              <div className="card">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="wd-100p">
                    <div className="tx-muted tx-14">Invited Users</div>
                    <div><AsyncValue value={refInfo ? refInfo.lines.length ? refInfo.lines[0].count : 0 : null} /> Users</div>
                  </div>
                  <div className="rounded-10 px-4 py-2 tx-center bg-semi-transparent">
                    <div className="tx-muted tx-12">Reward</div>
                    <FormattedNumber
                      value={refInfo ? refInfo.lines.length ? refInfo?.lines[0].usdReward : 0 : null}
                      suffix="$"
                      decimals={2}
                      className="d-block tx-14"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-12">
              <div className="d-flex justify-content-between mt-4 mb-3 tx-15">
                <span>Invited by your Friends</span>
                <span>Invite bonus <span className="tx-success">5%</span></span>
              </div>

              <div className="card">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="wd-100p">
                    <div className="tx-muted tx-14">Invited Users</div>
                    <div><AsyncValue value={refInfo ? refInfo.lines.length > 1 ? refInfo?.lines[1].count : 0 : null} /> Users</div>
                  </div>
                  <div className="rounded-10 px-4 py-2 tx-center bg-semi-transparent">
                    <div className="tx-muted tx-12">Reward</div>
                    <FormattedNumber
                      value={refInfo ? refInfo.lines.length > 1 ?refInfo?.lines[1].usdReward : 0 : null}
                      suffix="$"
                      decimals={2}
                      className="d-block tx-14"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Referral;

import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import Preloader from "@components/common/Preloader";
import TokenInfo from "./tabs/TokenInfo";
import TokenSocialAnalytics from "./components/TokenSocialAnalytics";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import TokenHolders from "./tabs/components/TokenHolders";
import {TokenActions} from "../../constants";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import TokenHeader from "./components/TokenHeader";
import getChainId from "@helpers/getChainId";
import toast from "react-hot-toast";

const Token = observer(() => {
  const {tokensStore} = useStores();
  const { token, isLoading } = tokensStore;
  const {address, tab = TokenActions.INFO, chain} = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TokenActions>(TokenActions.INFO);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const fetchToken = useCallback((force = false) => {
    if (!address) return;
    setIsInitialized(false);

    tokensStore.searchSinglePair(address, chain).then(() => {
      setIsInitialized(true);
    }).catch(() => {
      navigate('/');
    });
  }, [tokensStore, chain, address]);

  // useEffect(() => {
  //   if (token && !tokenSearch && !isInitialized && !tokensStore.isSearchLoading && tokensStore.searchResult.length > 0) {
  //     setIsInitialized(true);
  //     setIsLoading(false);
  //     const found = tokensStore.searchResult.find((t) => t.id.pair === token.pairAddress && t.id.token === token.address) || null;
  //     setTokenSearch(found);
  //   }
  // }, [token, tokensStore.searchResult, tokensStore.isSearchLoading, tokenSearch, isInitialized]);

  useEffect(() => {
    if (!isInitialized && !token && address) {
      fetchToken();
      document.getElementById('container')?.scrollTo(0, 0);
    } else if (!token && !address) {
      navigate('/');
    }
  }, [isInitialized, token, address, fetchToken]);

  useEffect(() => {
    if (token && address && token.pair.address !== address) {
      fetchToken();
      document.getElementById('container')?.scrollTo(0, 0);
    } else if (token && address && token.pair.address === address) {
      setIsInitialized(true);
    }
  }, [token, address, fetchToken]);

  // useInterval(() => {
  //   fetchTrades();
  // }, 10000);

  useEffect(() => {
    if (tab && Object.values(TokenActions).includes(tab as TokenActions)) {
      setActiveTab(tab as TokenActions);
    }
  }, [tab]);

  if (!token || !isInitialized) {
    return (
      <div>
        <div className="container tx-center">
          {/*{isLoading && (*/}
          {/*  <div className="d-flex flex-column align-items-center mb-2">*/}
          {/*    {tokensStore.singleTries > 1 && (*/}
          {/*      <div>*/}
          {/*        Servers are busy, please wait...*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}
          <Preloader inline />
        </div>
      </div>
    );
  }

  const tabs =  [
    {
      title: 'General',
      component: <TokenInfo token={token} />,
      id: TokenActions.INFO,
      visible: true,
    },
    {
      title: 'Holders',
      component: <TokenHolders token={token} />,
      id: TokenActions.HOLDERS,
    },
    // {
    //   title: 'Social Analytics',
    //   component: <TokenSocialAnalytics token={token} />,
    //   visible: !!token.token.links.twitter,
    //   id: TokenActions.TWITTER,
    // },
  ];

  const {symbol} = token;
  const activeTabComponent = tabs.find((tab) => tab.id === activeTab)!.component;

  return (
    <div>
      <TokenHeader token={token} />

      <div className="card d-block py-0 mb-3 px-3 pos-relative no-scroll-padding">
        <OverlayScrollbarsComponent
          defer
          options={{
            paddingAbsolute: true,
          }}
        >
          <div className="d-flex align-items-center justify-content-start wd-md-350">
            {tabs.map(({title, visible, id}) => visible === false ? null : (
              <button
                className={`btn btn-tab ${id === activeTab && 'active'}`}
                onClick={() => navigate(`/${token?.pair.chain}/${address}` + (id !== TokenActions.INFO ? `/${id}` : ``)) }
                key={`${symbol}-token-view-tab-${title}`}
              >
                {title}
              </button>
            ))}
          </div>
        </OverlayScrollbarsComponent>
      </div>

      {activeTabComponent}
    </div>
  )
});

export default Token;

import React, {useMemo} from "react";
import InfoTooltip from "@components/common/InfoTooltip";

interface ScoreBarsProps {
  score: number;
  title?: string;
  tooltip?: string;
  className?: string;
  noValue?: boolean;
}

export default function ScoreBars({score, title, tooltip, noValue = false, className = 'bg-semi-transparent'}: ScoreBarsProps) {
  const bars = useMemo(() => {
    if (score > 80) {
      return 3;
    }
    if (score > 50) {
      return 2
    }
    return 1;
  }, [score]);

  const color = useMemo(() => {
    if (score > 80) {
      return "bg-success";
    }
    if (score > 50) {
      return "bg-primary";
    }
    return "bg-danger";
  }, [score]);

  const textColor = useMemo(() => {
    let scoreColor = 'tx-success';

    if (score < 50) {
      scoreColor = 'tx-danger';
    } else if (score < 80) {
      scoreColor = 'tx-primary';
    } else if (score <= 100) {
      scoreColor = 'tx-success';
    }

    return scoreColor;
  }, [score]);

  return (
    <div className={`flex-column justify-content-between tx-center px-2 py-2 rounded-10 ht-50 ${className}`}>
      {!noValue && (
        <div className="d-flex justify-content-between tx-12 mb-1">
          {!!tooltip && (<InfoTooltip text={tooltip} />)}
          {!!title && (<div className={`tx-muted me-1 ${tooltip ? 'ms-1' : ''}`}>{title}</div>)}
          <div className={textColor}>{score.toFixed(0)}</div>
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center gap-1 wd-100p">
        {Array(3).fill(null).map((_, index) => (
          <div
            key={`score-bar-${index}`}
            className={`score-bar ${index < bars ? color : 'bg-semi-transparent'}`}
          />
        ))}
      </div>
    </div>
  );
}

import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {PageRoutes} from "../../constants";
import OverlayHeader from "@components/common/Overlay/OverlayHeader";
import TermsPrivacy from "@components/common/TermsPrivacy";
import SubscribePlans from "./components/SubscribePlans";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import SubscribePayment from "./components/SubscribePayment";
import SubscribeStepLifetime from "./components/SubscribeStepLifetime";
import SubscribeStepActive from "./components/SubscribeStepActive";

export enum SubscribePage {
  PLANS = 'plans',
  MONTHLY = 'monthly',
  THREE_MONTHS = 'three-months',
  LIFETIME = 'lifetime',
}

interface SubscribeParams {
  page?: SubscribePage;
  [key: string]: string | undefined;
}

const Subscribe = observer(() => {
  const {accountStore} = useStores();
  const {isSubscriber, ushiBalance, address} = accountStore;
  const navigate = useNavigate();
  const {page} = useParams<SubscribeParams>();
  const [step, setStep] = useState<SubscribePage>(page || SubscribePage.PLANS);
  const [forcePlans, setForcePlans] = useState<boolean>(false);

  useEffect(() => {
    setStep(page || SubscribePage.PLANS);
  }, [page]);

  return (
    <div className="mx-wd-md-600 mx-auto">
      <div className="container">
        <div className="tx-center tx-20 tx-bold">
          USHI Premium
        </div>

        {address && ushiBalance === null && <Preloader inline />}
        {(forcePlans || !isSubscriber) && (
          <>
            {step === SubscribePage.PLANS && (<SubscribePlans />)}
            {(step === SubscribePage.MONTHLY || step === SubscribePage.THREE_MONTHS) && (<SubscribePayment threeMonths={step === SubscribePage.THREE_MONTHS} />)}
            {step === SubscribePage.LIFETIME && (<SubscribeStepLifetime />)}
          </>
        )}
        {!forcePlans && isSubscriber && (
          <SubscribeStepActive showPlans={() => setForcePlans(true)} />
        )}

        <TermsPrivacy />
      </div>
    </div>
  );
});

export default Subscribe;

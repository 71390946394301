import Overlay from "@components/common/Overlay";
import {useStores} from "@hooks/useStores";
import React, {useEffect, useState} from "react";
import useDebounce from "@hooks/useDebounce";
import {observer} from "mobx-react-lite";
import searchIcon from "@assets/icons/search.svg";
import IconWithLoading from "@components/common/IconWithLoading";
import useCloseOverlay from "@hooks/useCloseOverlay";
import close from "@assets/icons/close.svg";
import SearchItemMobile from "@components/Search/SearchItemMobile";

const SearchOverlay = observer(() => {
  const {tokensStore} = useStores();
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {previousSearchResult, isSearchLoading} = tokensStore;
  const searchResult = tokensStore.getSearchResult();
  const closeOverlay = useCloseOverlay();

  const debouncedSearch = useDebounce(search, 1500);

  useEffect(() => {
    tokensStore.searchTokens(debouncedSearch);
  }, [tokensStore, debouncedSearch]);

  useEffect(() => {
    setIsLoading(true);
  }, [search]);

  useEffect(() => {
    if (!isSearchLoading) {
      setIsLoading(false);
    }
  }, [isSearchLoading]);

  return (
    <Overlay className="pb-5">
      <div className="row">
        <div className="d-flex justify-content-between pb-2 mb-2 border-bottom border-muted-10">
          <div className="input-group ht-40 wd-100p">
            <div className="input-group-text py-1 ht-100p bg-semi-transparent">
              {(!search || (!isLoading && !isSearchLoading)) && (<img src={searchIcon} alt="search" />)}
              {!!search && (isLoading || isSearchLoading) && (
                <IconWithLoading isLoading />
              )}
            </div>
            <input
              type="text"
              className="form-control py-1 ht-100p bg-semi-transparent"
              placeholder="Search Tokens"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="mn-wd-40 mx-wd-40 ht-40 ms-2">
            <button className="btn btn-secondary bg-semi-transparent p-0 d-flex justify-content-center align-items-center wd-100p ht-100p" onClick={closeOverlay}>
              <img src={close} alt="Close" />
            </button>
          </div>
        </div>
      </div>

      {!search && previousSearchResult?.length !== 0 && (
        <div className="tx-bold mb-2">Previous Searches</div>
      )}
      {search && searchResult.length !== 0 && (
        <>
          {searchResult.map((item, index) => (
            <SearchItemMobile
              key={`search-list-item-mobile-${item.id.token}-${index}`}
              {...item}
            />
          ))}
        </>
      )}
      {!search && previousSearchResult?.slice(0, 5).map((item, index) => (
        <SearchItemMobile
          key={`prev-search-list-item-mobile-${item.id.token}-${index}`}
          {...item}
        />
      ))}
      {!!search && searchResult?.length === 0 && !isLoading && (
        <div className="tx-bold">Nothing found</div>
      )}
    </Overlay>
  );
});

export default SearchOverlay;

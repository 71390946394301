import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface InfoTooltipProps {
  text: string;
  className?: string;
  size?: number;
}

const InfoTooltip = ({text, className = 'me-1 tx-muted', size = 16}: InfoTooltipProps) => {
  const tooltip = (
    <Tooltip id={text.substring(0, 30).split(' ').join('-')}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip}>
      <div className={`d-flex align-items-center ${className}`}>
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5327 8.99935C16.5327 13.1599 13.1599 16.5327 8.99935 16.5327C4.8388 16.5327 1.46602 13.1599 1.46602 8.99935C1.46602 4.8388 4.8388 1.46602 8.99935 1.46602C13.1599 1.46602 16.5327 4.8388 16.5327 8.99935ZM10.5833 9.14876C10.5833 8.48234 10.1718 7.91203 9.58904 7.67801C10.1713 7.44379 10.5823 6.87378 10.5824 6.20773L10.5825 5.52085C10.5825 4.64586 9.87329 3.93647 8.9983 3.93639C8.12331 3.93631 7.41392 4.64557 7.41384 5.52056L7.41377 6.20744C7.41371 6.87392 7.8252 7.44432 8.408 7.67836C7.8257 7.9126 7.41463 8.48271 7.41464 9.14882L7.41471 12.8935C7.41473 13.7685 8.12407 14.4778 8.99906 14.4777C9.87405 14.4777 10.5834 13.7684 10.5833 12.8934L10.5833 9.14876Z" fill="currentColor" stroke="currentColor" strokeWidth="1.6"/>
        </svg>
      </div>
    </OverlayTrigger>
  );
};

export default InfoTooltip;

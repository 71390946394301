import React, {useCallback, useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import bull from "@assets/icons/bull.svg";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../../constants";
import {useStores} from "@hooks/useStores";
import {SubscriptionFlow, SubscriptionType} from "@stores/account-store";
import {format, isPast} from "date-fns";
import CopyButton from "@components/common/CopyButton";
import Placeholder from "@components/common/Placeholder";
import copy from "@assets/icons/copy.svg";

interface SubscribeStepActiveProps {
  showPlans: () => void;
}

const SubscribeStepActive = observer(({showPlans}: SubscribeStepActiveProps) => {
  const navigate = useNavigate();
  const {accountStore} = useStores();
  const {isSubscriber, subscription, isEnoughBalance, profile} = accountStore;

  const list = [
    {
      icon: '💎',
      title: 'Unique algorithm detects high-yield tokens',
    },
    {
      icon: '🤖',
      title: 'Social - media AI analytic',
    },
    {
      icon: '🐋',
      title: 'Analysis Major players and their behavior',
    },
    {
      icon: '👨‍💻',
      title: 'Fit for novice and experienced investors',
    },
  ];

  useEffect(() => {
    if (!isSubscriber) {
      navigate(PageRoutes.SUBSCRIBE);
    }
  }, [isSubscriber, navigate]);

  const copyText = useMemo(() => {
    if (!profile) return '';

    return `Hello, i pay sub and would like join to gem hunters community. My id ${profile.username}`;
  }, [profile]);

  const handleInvite = useCallback(() => {
    if (accountStore.isLoggedIn) {
      navigate(PageRoutes.REFERRAL_PROGRAM);
    } else {
      accountStore.setSubscriptionFlow(SubscriptionFlow.REFERRAL_PROGRAM);
      navigate(PageRoutes.LOGIN);
    }
  }, [navigate, accountStore]);

  return !isSubscriber ? null : (
    <>
      <div className="tx-center mt-4">
        <img src={bull} alt="Bull" className="wd-100 mb-3" />
        <div className="tx-28">
          Premium Subscription
        </div>
        <div className="tx-14 my-3">
          Your subscription is active<br />
          {subscription && subscription.type === SubscriptionType.PAID &&  !isPast(new Date(subscription.endDate)) && (<>
            Paid subscription lasts up to: <span className="tx-primary">{format(new Date(subscription.endDate), 'dd.MM.yyyy')}</span>
          </>)}
        </div>
      </div>

      {!isEnoughBalance && (
        <button
          className="btn btn-primary bg-semi-transparent-2 border-0 d-block wd-100p tx-olney tx-primary mt-4"
          onClick={showPlans}
        >
          Renew Subscription
        </button>
      )}

      <button
        className={`btn btn-outline-primary d-block wd-100p tx-olney tx-primary ${isEnoughBalance ? 'mt-4' : 'mt-3'}`}
        onClick={handleInvite}
      >
        Invite Friends
      </button>

      <div className="rounded-10 bg-semi-transparent px-3 py-1 mt-4">
        {!isEnoughBalance && (
          <div
            className="tx-left py-2 border-bottom border-secondary"
          >
            <div className="d-flex justify-content-between align-items-start">
              <span className="tx-24 wd-35">🎩</span>
              <span className="tx-15 ms-2 flex-1 tx-left">
                <div className="mb-3">Private Gem hunting experts community.</div>
                <div>
                  For join our private community write message to
                  <a
                    href="https://t.me/ushi_support"
                    target="_blank"
                    rel="noreferrer"
                    className="tx-primary text-decoration-underline ms-1"
                  >
                    manager
                  </a>
                </div>
              </span>
            </div>
            <CopyButton
              text={copyText}
              className="btn btn-outline-primary bg-semi-transparent px-3 border-0 d-block wd-100p tx-olney tx-left tx-muted mt-2 mb-1"
              element={
                <div className="d-flex justify-content-between align-items-start">
                  <div className="me-3 wd-100p">
                    «Hello, i pay sub and would like join to gem hunters community. My
                    <span className="tx-white ms-1">id {profile ? profile.username : <Placeholder />}</span>
                    »
                  </div>
                  <img src={copy} alt="Copy" />
                </div>
              }
              noIcon
            />
          </div>
        )}
        {isEnoughBalance && (
          <div
            className="tx-left py-2 border-bottom border-secondary"
          >
            <div className="d-flex justify-content-between align-items-start">
              <span className="tx-24 wd-35">🎩</span>
              <span className="tx-15 ms-2 flex-1 tx-left">
                Private Gem hunting experts community.
              </span>
            </div>
            <a
              href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxOTAwNTQ5MTc1"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary tx-olney mt-2 wd-100p"
            >
              Join Community
            </a>
          </div>
        )}
        {list.map(({icon, title}, index) => (
          <div
            className={`d-flex justify-content-between align-items-center tx-left py-2 ${index !== list.length - 1 && 'border-bottom border-secondary'}`}
            key={`sub-step-active-list-${index}`}
          >
            <span className="tx-24 wd-35">{icon}</span>
            <span className="tx-15 ms-2 flex-1 tx-left">{title}</span>
          </div>
        ))}
      </div>

      <button
        className="btn btn-primary d-block wd-100p tx-olney mt-4"
        onClick={() =>  navigate(PageRoutes.COIN_LIST)}
      >
        Let's Start
      </button>
    </>
  );
});

export default SubscribeStepActive;

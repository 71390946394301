import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {TokenAudit, TokenViewInterface, TokenViewNew} from "@stores/tokens-store";
import {formatNumber} from "@helpers/numbers";
import CircleChart from "@components/common/Charts/CircleChart";
import TokenTrades from "./components/TokenTrades";
import InfoTooltip from "@components/common/InfoTooltip";
import BigNumber from "bignumber.js";
import {faLock} from "@fortawesome/pro-solid-svg-icons/faLock";
import SecurityAudit from "./components/SecurityAudit";
import ProgressSlider from "@components/common/ProgressSlider";
import CommunityTrust from "./components/CommunityTrust";
import Information from "./components/Information";
import TokenHolders from "./components/TokenHolders";
import TokenSocialAnalytics from "../components/TokenSocialAnalytics";
import TVChartContainer from "@components/TVChartContainer";
import KeyMetrics from "./components/KeyMetrics";
import AdditionalMetrics from "./components/AdditionalMetrics";
import LiquidityTooltip from "@components/common/LiquidityTooltip";
import getChainId from "@helpers/getChainId";
import toast from "react-hot-toast";
import {useStores} from "@hooks/useStores";

interface TokenInfoProps {
  token: TokenViewNew;
}

const TokenInfo = observer(({token}: TokenInfoProps) => {
  const {tokensStore} = useStores();
  const mcap = token.metrics.marketCap;
  const [triggerResize, setTriggerResize] = useState(0);
  const [auditInfo, setAuditInfo] = useState<TokenAudit | null>(null);
  const [isAuditLoading, setIsAuditLoading] = useState<boolean>(false);
  const [lastAddress, setLastAddress] = useState<string>('');

  const fetchTokenAudit = useCallback(() => {
    if (!token) return;
    setIsAuditLoading(true);

    tokensStore.getTokenAudit(token.address, token.pair.address, token.pair.chain).then((token) => {
      if (token) {
        setAuditInfo(token);
        setIsAuditLoading(false);
      } else {
        setIsAuditLoading(false);
        toast.error('Error loading token audit info');
      }
      setTriggerResize((prev) => prev + 1);
    }).catch((e) => {
      console.error(e);
      setIsAuditLoading(false);
      toast.error('Error loading token audit info');
    });
  }, [tokensStore, token]);

  useEffect(() => {
    // fetchTokenHoneypot();
    if (lastAddress !== token.address) {
      fetchTokenAudit();
      setLastAddress(token.address);
    }

    // return () => {
    //   setAuditInfo(null);
    // }
  }, [fetchTokenAudit, token, lastAddress]);

  const locked = useMemo(() => {
    // Object.keys(token.locks || {}).forEach((key) => {
    //   Object.keys(token.locks[key].locks || {}).forEach((lockKey) => {
    //     percents += new BigNumber(token.locks[key].locks[lockKey].percent || 0).toNumber();
    //   });
    // });

    return auditInfo?.lpLock.totalPercent || 0;
  }, [auditInfo]);

  const lockedColor = useMemo(() => {
    let result = 'tx-dark-red';
    if (locked < 5) {
      result = 'tx-dark-red';
    } else if (locked < 51) {
      result = 'tx-warning';
    } else {
      result = 'tx-dark-green';
    }
    return result;
  }, [locked]);

  return (
    <div>
      <div className="row">
        <div className="col-md-4 col-lg-3 d-flex flex-column">
          {/*<div className="card wd-100p mb-3 d-flex flex-row align-items-center justify-content-between">*/}
          {/*  <div>*/}
          {/*    <div className="tx-22">{token.dextScore.total} / 100</div>*/}
          {/*    <div className="tx-muted tx-14">Ushi Score</div>*/}
          {/*  </div>*/}

          {/*  <CircleChart*/}
          {/*    value={token.dextScore.total}*/}
          {/*    size="fixed"*/}
          {/*    fixedSize={50}*/}
          {/*    className="wd-50 me-0"*/}
          {/*    ushiScore*/}
          {/*  />*/}
          {/*</div>*/}

          <KeyMetrics token={token} auditInfo={auditInfo} percents={locked} lockedColor={lockedColor} />
        </div>

        <div className="col-md-8 col-lg-9 mb-3">
          <div className="card wd-100p ht-lg-100p p-2">
            {/*<TVChartContainer*/}
            {/*  symbol={`${token.id.chain}:${token.id.pair}/usd`}*/}
            {/*  locale="en"*/}
            {/*  interval="1H"*/}
            {/*  timeframe="1W"*/}
            {/*/>*/}
            {/*<iframe*/}
            {/*  width="100%"*/}
            {/*  height="500"*/}
            {/*  title="uAnalytics Pool Chart"*/}
            {/*  src={`https://coinbrain.com/embed/eth-${token.address}?theme=custom&accent=299fba&padding=0&background=071524&chart=1&trades=0`}*/}
            {/*/>*/}
            <iframe
              id="dextools-widget"
              title="DEXTools Trading Chart"
              width="100%"
              height="500"
              src={`https://www.dextools.io/widget-chart/en/${token.pair.chain}/pe-light/${token.pair.address}?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false&tvPlatformColor=071524&headerColor=071524&tvPanecolor=071524`}
            />

          </div>
        </div>

        <div className="col-md-4 col-lg-3 mb-3 order-2 order-md-1" id="token-generic-info">
          <div className="card wd-100p d-none d-md-flex mb-3">
            <div className="tx-22 d-flex align-items-center mb-2">
              <span>Liquidity</span>
              <InfoTooltip text="The ease with which a digital currency or token can be converted to another digital asset or cash without impacting the price and vice-versa." size={20} className="d-inline-flex tx-muted ms-2" />
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-between tx-14 mt-2 ht-25">
                <div className="tx-muted d-flex align-items-center">
                  <span>Liquidity</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{token.pair.metrics.liquidity ? formatNumber(token.pair.metrics.liquidity, '$') : 'N/A'}</span>
                  <LiquidityTooltip token={token} auditInfo={auditInfo}>
                    <CircleChart
                      value={locked > 100 ? 100 : locked}
                      color={lockedColor}
                      size="fixed"
                      fixedSize={24}
                      icon={faLock}
                      className="tx-12 d-inline-flex ms-2"
                    />
                  </LiquidityTooltip>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between tx-14 mt-2 ht-25">
                <div className="tx-muted d-flex align-items-center">
                  <span>Liquidity/MCap</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>
                    {token.pair.metrics.liquidity && mcap ? formatNumber(new BigNumber(token.pair.metrics.liquidity).div(mcap).times(100).toNumber(), '%') : 'N/A'}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center tx-14 mt-2 ht-25 tx-muted">
                <InfoTooltip text="Volatility" className="d-inline-flex me-2" />
                <span>Volatility</span>
              </div>
              <ProgressSlider value={Math.max(100 - new BigNumber(token.pair.metrics.liquidity).div(mcap).times(100).toNumber(), 0)} className="mt-1" hideValue hideSteps multicolor />

              {/*<div className="d-flex align-items-center tx-14 mt-2 ht-25 tx-muted mb-1">*/}
              {/*  <InfoTooltip text="Price impact" className="d-inline-flex me-2" />*/}
              {/*  <span>Price impact</span>*/}
              {/*</div>*/}

            {/*  <TabsCard*/}
            {/*    list={[{label: 'Sell', value: 'sell'}, {label: 'Buy', value: 'buy'}]}*/}
            {/*    active={priceImpactTab}*/}
            {/*    onClick={(value) => setPriceImpactTab(value)}*/}
            {/*    className="bg-semi-transparent"*/}
            {/*    tabClassName="wd-50p"*/}
            {/*  />*/}
            {/*  <div className="card flex-row bg-semi-transparent mt-2 px-3 py-2 d-flex justify-content-between align-items-center tx-14">*/}
            {/*    <div className="tx-muted">Enter amount: $100</div>*/}
            {/*    <div>Impact: <span className="tx-success">6%</span></div>*/}
            {/*  </div>*/}
            </div>
          </div>

          <AdditionalMetrics token={token} />
          <SecurityAudit token={token} auditInfo={auditInfo} isLoading={isAuditLoading} triggerResize={() => setTriggerResize((prev) => prev + 1)} />
          {/*<CommunityTrust token={token} />*/}
          <Information token={token} />
        </div>

        <div className="col-md-8 col-lg-9 mb-3 order-1 order-md-2">
          <TokenTrades token={token} triggerResize={triggerResize} />
        </div>
      </div>

      <TokenHolders token={token} />

      {/*{!!token.token.links.twitter && (*/}
      {/*  <TokenSocialAnalytics token={token} />*/}
      {/*)}*/}
    </div>
  );
});

export default TokenInfo;

import Overlay from "@components/common/Overlay";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import PasswordChange from "./components/PasswordChange";
import VerifyWallet from "./components/VerifyWallet";
import chevron from "@assets/icons/chevron.svg";
import wallet from "@assets/icons/wallet.svg";
import profile from "@assets/icons/profile.svg";

const Profile = observer(() => {
  const navigate = useNavigate();

  const links = [
    // {
    //   icon: wallet,
    //   title: 'Verify Wallet',
    //   onClick: () => {
    //     navigate(PageRoutes.PROFILE_WALLET);
    //   },
    // },
    {
      icon: profile,
      title: 'Change password',
      onClick: () => {
        navigate(PageRoutes.PROFILE_PASSWORD);
      },
    },
  ];

  return (
    <Overlay onClose={() => navigate(PageRoutes.COIN_LIST)}>
      <div className="pt-4">
        {links.map(({title, icon, onClick}) => (
          <div className="mb-4 tx-18 d-flex justify-content-between cur-pointer" onClick={onClick}>
            <div className="wd-40 tx-center">
              <img src={icon} />
            </div>

            <div className="ms-0 me-auto">{title}</div>

            <div className="d-flex justify-content-center align-items-center transition wd-30 ht-30">
              <img src={chevron} />
            </div>
          </div>
        ))}
      </div>
    </Overlay>
  );
});

export default Profile;
